import React from 'react'
import spinner from "../../assets/images/spinner.gif"
import "./Spinner.css"

const Spinner = () => {
    return (
        <div className="spinner_container">
        <img src={spinner} alt="spinner"/>
        </div>
    )
}

export default Spinner
