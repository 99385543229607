import React, { useCallback, useMemo, useRef, useState } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import Pagination from "../../../components/Pagination/Pagination";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";

ModuleRegistry.registerModules([CsvExportModule]);

function LinkComponent(props) {
  return (
    <a
      target="_blank"
      href={"https://dashboard.stripe.com/subscriptions/" + props.value}
      style={{ color: "var(--primary-color)", textDecoration: "underline" }}
    >
      Stripe Info
    </a>
  );
}

const SubscriptionAnalytics = ({
  loading,
  data,
  analyticsCount,
  pageNumber,
  setPageNumber,
}) => {
  const gridRef = useRef();

  const getDate = (params) => {
    const date = new Date(params.value * 1000);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const getNormalDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const capitalize = (params) => {
    if (params !== "")
      return params.value.charAt(0).toUpperCase() + params.value.slice(1);
    return "";
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "LATO" },
    wrapText: true,
    autoHeight: true,
  }));

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Name",
      field: "userDetails.userFName",
    },
    {
      headerName: "Closet Name",
      field: "userDetails.closetName",
    },
    {
      headerName: "Email",
      field: "userDetails.userEmail",
    },
    {
      headerName: "Subscription Amount",
      field: "plan.amount_decimal",
      valueFormatter: (params) => "$" + params.value / 100,
    },
    {
        headerName: "Subscription Type",
        field: "membership",
    },
    {
      headerName: "Date Created",
      field: "created",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Current Status",
      field: "status",
    },
    {
      headerName: "Cancelled Date",
      field: "canceled_at",
      valueFormatter: (params) => (params.value ? getDate(params) : "-"),
    },
    {
      headerName: "Cancelling/Canceled At Period End",
      field: "cancel_at_period_end",
      valueFormatter: (params) => (params.value !== null ? params.value : "-"),
    },
    {
      headerName: "Stripe Id",
      field: "customer",
      // cellRenderer: LinkComponent,
    },
    {
      headerName: "More Info",
      field: "id",
      cellRenderer: LinkComponent,
    },
    {
      headerName: "Next Billing Date",
      field: "current_period_end",
      valueFormatter: (params) => (params.value ? getDate(params) : "-"),
    },
    {
      headerName: "Last Payment Date",
      field: "paymentLast",
      valueFormatter: (params) => (params.value ? getDate(params) : "-"),
    },
    {
      headerName: "Last Ordered Date",
      field: "lastOrder",
      valueFormatter: (params) =>
        params.value !== "No Order Placed" ? getNormalDate(params) : "-",
    },
    {
      headerName: "Payment Error",
      field: "paymentError",
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    // {
    //   headerName: "Membership Status",
    //   field: "memberships",
    //   valueFormatter: (params) => {
    //     // console.log(params)
    //     return params.value.membershipHistory.length>0?params.value.membershipHistory[params.value.membershipHistory.length-1].type === "Cancelled"?"Cancelled":params.value.membershipHistory[params.value.membershipHistory.length-1].type==="trial"?"Free":"Active":"Free"
    //   },
    // },
    // {
    //   headerName: "Membership type",
    //   field: "membership",
    //   // valueFormatter: (params) => capitalize(params),
    // },
    // {
    //   headerName: "Card Flagged (Hover for detail)",
    //   field: "charges",
    //   tooltipField:"failure_message"
    // },
    // // {
    // //   headerName: "Membership status",
    // //   field: "membershipStatus",
    // // },
    // {
    //   headerName: "Member expiration date",
    //   field: "membershipExp",
    //   valueFormatter: (params) => getDate(params),
    // },
    // {
    //   headerName: "Address City",
    //   field: "city",
    // },
    // {
    //   headerName: "Address State",
    //   field: "state",
    // },
    // {
    //   headerName: "Adress Zip Code",
    //   field: "zipCode",
    // },
    // {
    //   headerName: "Gender",
    //   field: "gender",
    // },
    // {
    //   headerName: "Number of Items/Products in closet",
    //   field: "userProducts.productsId.length",
    // },
    // {
    //   headerName: "Item value",
    //   field: "totalProductValue",
    // },
    // {
    //   headerName: "Number of purchases",
    //   field: "userProducts.purchaseId.length",
    // },
    // {
    //   headerName: "Number of orders",
    //   field: "checkoutInfo.length",
    // },
    // {
    //   headerName: "Number of favorites",
    //   field: "userProducts.favouritesId.length",
    // },
    // {
    //   headerName: "Number of friends",
    //   field: "",
    // },
    // {
    //   headerName: "Number of chats",
    //   field: "",
    // },
    // {
    //   headerName: "Hanger Credits",
    //   field: "userHanger",
    // },
    // {
    //   headerName: "Subscription Money",
    //   field: "subscriptionMoney",
    // },
    // {
    //   headerName: "Last Login",
    //   field: "userLastActivated",
    //   valueFormatter: (params) => getDate(params),
    // },
    // {
    //   headerName: "Referrals",
    //   field: "referrals.length",
    // },
    // {
    //   headerName: "Last purchase date",
    //   field: "latestCheckoutTime",
    //   valueFormatter: (params) => {
    //     let lastSubTime;
    //     if (params.data.memberships.membershipHistory.length>0) {
    //       const filteredData = params.data.memberships.membershipHistory.filter(item => parseFloat(item.money) > 0);

    //       if(filteredData.length>0){
    //         lastSubTime = filteredData[filteredData.length - 1].time;
    //       }
    //     }
    //     // return the value which is greater from params and lastSubTime, params and lastSubTime is in string format and also can be undefined
    //     return lastSubTime && params.value?new Date(lastSubTime).getTime() > new Date(params.value).getTime()?getDate({value:lastSubTime}):getDate({value:params.value}):lastSubTime?getDate({value:lastSubTime}):params.value?getDate(params):""
    //     // return params.value?getDate(params):""
    //   },
    // },
    // {
    //   headerName: "Last chat date",
    //   field: "latestChatDate",
    //   valueFormatter: (params) => params.value?getDate(params):"",
    // },
    // {
    //   headerName: "Last profile update date",
    //   field: "lastUpdateDate",
    //   valueFormatter: (params) => getDate(params),
    // },
  ]);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <div className="analytics-con" style={{ marginTop: "1rem" }}>
      {loading && data.length == 0 ? (
        <Spinner1 />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div>
              <h1 className="h2 background-text bold-text heading-title">
                Subscription Details{" "}
              </h1>
            </div>
            <button className="btn" onClick={onBtnExport}>
              Download CSV export file
            </button>
          </div>
          <div className="ag-theme-quartz" style={{ height: 900 }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={data}
              columnDefs={colDefs}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionAnalytics;
