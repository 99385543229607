import React from "react";
import "./Search.css";
const Search = ({ setSearchActive, searchActive, searchQuery }) => {
  return (
    <div id="wrap">
      <div className="searched-items">
        <i
          className="fa fa-close close-search"
          onClick={() => setSearchActive(false)}
        ></i>

        <h1>Search Anything !</h1>
        <p className="searchquery">{searchQuery}</p>
      </div>
    </div>
  );
};

export default Search;
