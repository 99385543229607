import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HangerTable.css";
import hanger from "../../assets/images/hanger.svg";
import InfoCard from "../InfoCard/InfoCard";
import AdminMenu from "../AdminMenu/AdminMenu";
import Collapse, { Panel } from "rc-collapse";

const HangerTable = ({ hangerHistory, userInfo }) => {
  //consolele.log(hangerHistory);
  return (
    <div className="hanger_table_container">
      <table>
        <tr>
          <th>Event</th>
          <th>Hanger History</th>
          <th>Event Time</th>
        </tr>

        {hangerHistory && hangerHistory.length > 0 ? (
          hangerHistory.map((val, index) => (
            <tr
              key={index}
              className={val.read === false ? "blink-bg" : ""}
              style={
                val.read === false
                  ? { backgroundColor: "rgba(244,127,114,0.37)" }
                  : {}
              }
            >
              <td>
                {val.eventName.indexOf("-") > -1
                  ? val.eventName.slice(0, val.eventName.indexOf("-"))
                  : val.eventName}
                {val.pid ? (
                  <>
                    -
                    <Link
                      to={`/${
                        val.eventName.indexOf("Purchased") > -1
                          ? "purchased"
                          : "product"
                      }/${encodeURIComponent(val.brand)}-${encodeURIComponent(
                        val.productName
                      )}`}
                      state={
                        val.eventName.indexOf("Purchased") > -1
                          ? { pId: val.pid, id: val.cid }
                          : { id: val.pid }
                      }
                      style={{ color: "#F26654", lineBreak: "anywhere" }}
                    >
                      {val.productName}
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </td>
              <td>
                {val.hanger.indexOf("-") > -1 ? (
                  <span className="minus">-</span>
                ) : (
                  <span className="plus">+</span>
                )}{" "}
                {Math.abs(val.hanger)}{" "}
                <img
                  style={{ height: "20px" }}
                  src={hanger}
                  alt="hanger icon"
                />
              </td>
              <td>
                {new Date(val.checkoutTime).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </td>
            </tr>
          ))
        ) : (
          <h1>No Hangers</h1>
        )}
        {/* <tr>
                <td>Signing Bonus</td>
                <td><span className='plus'>+</span> 1 <img style={{height:"20px"}} src={hanger} alt="hanger icon"/></td>
                <td>{userInfo && new Date(userInfo.userDetailsId.createdAt).toDateString()}</td>
            </tr> */}
      </table>
    </div>
  );
};

export default HangerTable;
