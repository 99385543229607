import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  membershipUpgradeStatus: "idle",
  membershipUpgradeInfo: null,
  membershipUpgradeError: null,
}
export const membershipUpgrade = createAsyncThunk(
  "membership/upgrade",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/membershipupgrade",
        payload
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const buyStoreMembership = createAsyncThunk(
  "membership/buyStoreMembership",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/buystoremembership",
        payload
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const membershipUpgradeSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    membershipUpgradeReset(state,action){
      state.membershipUpgradeError=null
      state.membershipUpgradeInfo=null
    }
  },
  extraReducers: {
    [membershipUpgrade.pending]: (state, action) => {
      state.membershipUpgradeStatus = "loading"
    },
    [membershipUpgrade.fulfilled]: (state, action) => {
      state.membershipUpgradeStatus = "succeeded"
      state.membershipUpgradeInfo = action.payload.message
    },
    [membershipUpgrade.rejected]: (state, action) => {
      state.membershipUpgradeStatus = "failed"
      state.membershipUpgradeError = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },

    [buyStoreMembership.pending]: (state, action) => {
      state.membershipUpgradeStatus = "loading"
    },
    [buyStoreMembership.fulfilled]: (state, action) => {
      state.membershipUpgradeStatus = "succeeded"
      state.membershipUpgradeInfo = action.payload.message
    },
    [buyStoreMembership.rejected]: (state, action) => {
      state.membershipUpgradeStatus = "failed"
      state.membershipUpgradeError = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { membershipUpgradeReset } = membershipUpgradeSlice.actions

export default membershipUpgradeSlice.reducer 