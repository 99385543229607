import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  membershipCancelStatus: "idle",
  membershipCancelInfo: null,
  membershipCancelError: null,
}

export const membershipCancel = createAsyncThunk(
  "membership/cancel",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/membershipcancel",
        {token},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const storeMembershipCancel = createAsyncThunk(
  "membership/storeMembershipCancel",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/storemembershipcancel",
        {token},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const membershipCancelSlice = createSlice({
  name: "membershipcancel",
  initialState,
  reducers: {
    membershipCancelReset(state,action){
      state.membershipCancelError=null
      state.membershipCancelInfo=null
    }
  },
  extraReducers: {
    [membershipCancel.pending]: (state, action) => {
      state.membershipCancelStatus = "loading"
    },
    [membershipCancel.fulfilled]: (state, action) => {
      state.membershipCancelStatus = "succeeded"
      state.membershipCancelInfo = action.payload.message
    },
    [membershipCancel.rejected]: (state, action) => {
      state.membershipCancelStatus = "failed"
      state.membershipCancelError = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },


    [storeMembershipCancel.pending]: (state, action) => {
      state.membershipCancelStatus = "loading"
    },
    [storeMembershipCancel.fulfilled]: (state, action) => {
      state.membershipCancelStatus = "succeeded"
      state.membershipCancelInfo = action.payload.message
    },
    [storeMembershipCancel.rejected]: (state, action) => {
      state.membershipCancelStatus = "failed"
      state.membershipCancelError = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { membershipCancelReset } = membershipCancelSlice.actions

export default membershipCancelSlice.reducer 