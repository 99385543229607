import React from "react";
import "./MembershipSelect.css";
import { Link } from "react-router-dom";

const MembershipSelect = ({ membership, setMembership, formik }) => {
  return (
    <div className="membershipselect_container">
      <h1 className="section_title bold-text">Step 1: Choose Membership</h1>
      <div className="membership_card_container">
        <div
          className={`${
            formik.values.membership === "trial" ? `membership_select` : ``
          } membership_card`}
          onClick={() => {
            formik.setFieldValue("membership", "trial");
            setMembership("trial");
          }}
        >
          <div className="membership_title h3">$0 Guest</div>
          <div className="membership_desc">
            Unlimited transactions with minimal fees
          </div>
          <div className="membership_feature">
            <ul>
              <li>
                <span className="bullet"></span>
                <span>Shop non member exclusive Items</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>
                  Buy hanger credits with cash at checkout at full price
                </span>
              </li>
            </ul>
          </div>
          <button className="btn dark-btn">
            {membership == "trial" ? "Guest Plan Selected" : "Select Guest"}
          </button>
        </div>
        <div
          className={`${
            formik.values.membership === "monthly" ? `membership_select` : ``
          } membership_card`}
          onClick={() => {
            formik.setFieldValue("membership", "monthly");
            setMembership("monthly");
          }}
        >
          <div className="membership_title h3">$20 Monthly</div>
          <div className="membership_desc">
            Unlimited transactions with no fee
          </div>
          <div className="membership_feature">
            <ul>
              <li>
                <span className="bullet"></span>
                <span>Earn hanger credits for every item in your closet</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Shop other closets w/ your hanger credits</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>
                  Buyers pay for shipping (local pickup is encouraged)
                </span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Unlimited transactions, no fee per item</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Discounted price on hanger credits if you are short</span>
              </li>
            </ul>
          </div>
          <button className="btn dark-btn">
            {membership == "monthly" ? "Monthly Selected" : "Select Monthly"}
          </button>
        </div>
        <div
          className={`${
            formik.values.membership === "annualy" ? `membership_select` : ``
          } membership_card`}
          onClick={() => {
            formik.setFieldValue("membership", "annualy");
            setMembership("annualy");
          }}
        >
          <div className="membership_title h3">$160 Annually</div>
          <div className="membership_desc">
            Unlimited transactions with no fee to help make the biggest impact
            on the environment while saving 33%!
          </div>
          <div className="membership_feature">
            <ul>
              <li>
                <span className="bullet"></span>
                <span>Earn hanger credits for every item in your closet</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Shop other closets w/ your hanger credits</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>
                  Buyers pay for shipping (local pickup is encouraged)
                </span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Unlimited transactions, no fee per item</span>
              </li>
              <li>
                <span className="bullet"></span>
                <span>Discounted price on hanger credits if you are short</span>
              </li>
            </ul>
          </div>
          <button className="btn dark-btn">
            {membership == "annualy" ? "Annualy Selected" : "Select Annualy"}
          </button>
        </div>
      </div>
      <h3 className="h3 background-text text-center">
        Non members can shop can shop a limited selection of items
        <br />
        <Link
          to={"/shop"}
          style={{ borderBottom: "2px solid var(--background-color)" }}
        >
          Shop here
        </Link>
      </h3>
    </div>
  );
};

export default MembershipSelect;
