import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import RightSection from "../../components/RightSection/RightSection";
import Sidebar from "../../components/Sidebar/Sidebar";
import ToggleSidebar from "../../components/ToggleSidebar/ToggleSidebar";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./AdminHome.css";

function AdminHome() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    window.innerWidth < 767 ? false : true
  );
  const [currentTab, setCurrentTab] = useState("users");

  window.addEventListener("resize", () => {
    if (window.innerWidth <= 767) {
      document.documentElement.style.width = "100vw";
    } // Adjust breakpoint as needed
  });

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // console.log(isSidebarOpen);
  return (
    <div className="admin-home-con">
      {/* <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Header handleToggle={handleToggle} />
      <Sidebar
        isOpen={isSidebarOpen}
        setCurrentTab={setCurrentTab}
        setIsOpen={setIsSidebarOpen}
      />
      <RightSection isOpen={isSidebarOpen} currentTab={currentTab} />
      {/* Add other content for the admin panel here */}
    </div>
  );
}

export default AdminHome;
