import React, { useEffect, useState } from "react";
import "./MyCloset.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import {
  reactivateItem,
  resetReactivate,
} from "../components/StateSlices/reactivateitemSlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Pagination from "../components/Pagination/Pagination";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import Tabs from "../admin/components/Tabs/Tabs";
import { myClosetTabs } from "../admin/components/Tabs/TabsContent";
import { Helmet } from "react-helmet";

const MyCloset = ({ tab }) => {
  // debugger
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { status, userInfo, error, profile, userProductCount } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(false);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { reactivateStatus, reactivateInfo, reactivateError } = useSelector(
    (state) => state.reactivate
  );
  // const [pageNumber, setPageNumber] = useState(0);
  const [activePageNum, setActivePageNum] = useState(
    parseInt(searchParams.get("activepage")) || 0
  );
  const [archivePageNum, setArchivePageNum] = useState(
    parseInt(searchParams.get("archivepage")) || 0
  );
  const [activeLoading, setActiveLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [pData, setPData] = useState(null);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    // setSpinner(true);
  }, []);

  useEffect(() => {
    // debugger
    if (userInfo) {
      setPData(userInfo);
      setActiveLoading(false);
      setArchiveLoading(false);
    }
  }, [userInfo]);



  useEffect(() => {
    // console.log("PAGE", pageNumber);
    // debugger
   
      let token = localStorage.getItem("clttoken");
      if (token) {
        setArchiveLoading(true);
        // dispatch(reset())
        dispatch(
          getuserInfo({
            token,
            activepage: activePageNum,
            archivepage: archivePageNum,
          })
        );
        scrollToSection("archiveSection")
        // window.scrollTo(0, 0);
      } else {
        navigate("/login", { replace: true });
      }
    
  }, [archivePageNum]);

  useEffect(() => {
    // console.log("PAGE", pageNumber);
    // debugger
   

    let token = localStorage.getItem("clttoken");
    if (token) {
      // dispatch(reset())
      setActiveLoading(true);
      dispatch(
        getuserInfo({
          token,
          activepage: activePageNum,
          archivepage: archivePageNum,
        })
      );
      scrollToSection("activeSection")
      // window.scrollTo(0, 0);
    } else {
      navigate("/login", { replace: true });
    }
  }, [activePageNum]);

  let lastDeletedId;

  useEffect(() => {
    if (profile) {
      console.log(profile);
      if (profile !== "complete") {
        navigate("/setup-closest");
      }
      if (!userInfo.userDetailsId.verified) {
        navigate("/confirmation?error=AccountNotVerified", { state: userInfo });
      }
    }
  }, [profile]);

  const deletekey = (id) => {
    lastDeletedId = id;
    swal({
      title: "Are you sure?",
      text: "Do you want to archive this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(deleteItem({ token, id }));
      }
    });
  };

  const reactivateKey = (id) => {
    lastDeletedId = id;
    swal({
      title: "Reactivate Item",
      text: "Once reactivated, it will be visible for sale!",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willReactive) => {
      if (willReactive) {
        console.log(`Please Reactivate it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(reactivateItem({ token, id }));
      }
    });
  };

  if (deleteInfo) {
    console.log(deleteInfo);
    dispatch(resetDelete());
    toast.success("Product Successfully Archived", toastOption);
    setTimeout(() => {
      setSpinner(false);

      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
        window.scrollTo(0, 0);
      } else {
        navigate("/login", { replace: true });
      }
    }, 5000);
  }

  if (reactivateInfo) {
    console.log(reactivateInfo);
    dispatch(resetReactivate());
    toast.success("Product Successfully Reactivated", toastOption);
    setTimeout(() => {
      setSpinner(false);

      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
        window.scrollTo(0, 0);
      } else {
        navigate("/login", { replace: true });
      }
    }, 5000);
  }

  const searchProduct = (search) => {
    setSpinner(true);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token, search }));
    window.scrollTo(0, 0);
  };

  const resetSearch = () => {
    setSpinner(true);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token }));
    window.scrollTo(0, 0);
  };

  if (error) {
    localStorage.removeItem("clttoken");
    dispatch(reset());
    navigate("/");
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for view/edit/update user's products to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for view/edit/update user's products to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for view/edit/update user's products to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      {
        <>
          <div className="page-container" style={{ position: "relative" }}>
            {/* {spinner ? (
              <div style={{ width: "100%", height: "520px" }}>
                <Spinner />
              </div>
            ) : (
              ""
            )} */}

            {/* <ToastContainer
            position="bottom-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}

            {/* <div className="my-closet">
        <div className="my-closet-left">
          <Collapse collapsible={true} defaultActiveKey={["0", "1"]}>
            <Panel
              showArrow={false}
              header="User Info"
              headerClass="accordion-header h3"
              style={{
                background: "var(--primary-color)",
                color: "var(--white-color)",
              }}
              isActive={true}
            >
              <InfoCard userInfo={userInfo} />
            </Panel>
            <Panel
              isActive={true}
              showArrow={false}
              header="Quick Actions"
              headerClass="accordion-header h3"
            >
              <AdminMenu />
            </Panel>
          </Collapse>
        </div>
        <div className="my-closet-right"> */}
            {/* <Tabs tabs={myClosetTabs} /> */}
            {userInfo && userProductCount !== null && (
              <>
                <ClosetItem
                  userInfo={userInfo}
                  deletekey={deletekey}
                  productCount={userProductCount}
                  // pageNumber={pageNumber}
                  reactivateKey={reactivateKey}
                  searchProduct={searchProduct}
                  resetSearch={resetSearch}
                  activePageNum={activePageNum}
                  archivePageNum={archivePageNum}
                  setActivePageNum={setActivePageNum}
                  setArchivePageNum={setArchivePageNum}
                  activeLoading={activeLoading}
                  archiveLoading={archiveLoading}
                />
                {/* {userProductCount > 20 && (
                  <Pagination
                  noMarginLeft={true}
                    pageCount={Math.ceil(userProductCount / 20)}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                )} */}
              </>
            )}
            {/* </div> */}
            {/* </div> */}
          </div>
        </>
      }
    </>
  );
};

export default MyCloset;
