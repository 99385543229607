import React, { useEffect, useMemo, useState } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import Pagination from "../../../components/Pagination/Pagination";
import { AgGridReact } from "ag-grid-react";
import { FaUndo } from "react-icons/fa";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";

ModuleRegistry.registerModules([CsvExportModule]);

const OverallAnalytics = ({
  loading,
  data,
  resetSearch,
  userSearchVal,
  setUserSearchVal,
  searchUser,
  gridRef
}) => {
  const [rowData, setRowData] = useState([]);
  let monthYearState = [];
  const transposeData = (data) => {
    const keys = Object.keys(data[data.length - 1]);
    const transposed = keys.map((key) => ({
      [key]: data.map((row) => {
        let temp = row[key];
        switch (key) {
          case "MonthlyRevenue":
          case "totalItemValue":
          case "AnnualRevenue":
          case "newMonthlyRevenue":
          case "newAnnualRevenue":
          case "TotalRevenue":
          case "newTotalRevenue":
            temp = row[key] ? "$ " + row[key].toFixed(2) : 0;
            break;
          case "nonMembertotalOrders":
          case "nonMemberhangerPaid":
          case "nonMembertotalProductsBought":
          case "storeRevenue":
            temp = row[key] ? row[key] : 0;
            break;
          default:
            temp = row[key];
        }
        return temp;
      }),
    }));
    return transposed;
  };

  const transformForAgGrid = (data) => {
    return data.map((item) => {
      const key = Object.keys(item)[0];
      return {
        field: key,
        value: item[key].join(", "),
      };
    });
  };

  const transformForAgGrid2 = (data) => {
    return data.map((item) => {
      const values = item.value.split(", ");
      return {
        field: prevMappings[item.field],
        ...values.reduce((acc, val, idx) => {
          acc[`value${idx + 1}`] = val;
          return acc;
        }, {}),
      };
    });
  };

  const getColumnDefs = (numColumns) => {
    console.log("Month Year State", monthYearState);
    const baseColumnDefs = [
      {
        headerName: "Field",
        field: "field",
        cellStyle: {
          color: "var(--background-color)",
          fontWeight: 700,
        },
        pinned: "left",
        width: 275,
      },
    ];
    if (monthYearState) {
      const valueColumnDefs = Array.from({ length: numColumns }, (_, idx) => ({
        // headerName: `Value ${idx + 1}`,
        headerName: monthYearState[idx],
        field: `value${idx + 1}`,
      }));
      return [...baseColumnDefs, ...valueColumnDefs];
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      console.log("MERA DATA", data)
      const transposedData = transposeData(data);
      if (transposedData) {
        console.log(transposedData[0]["month"]);
        monthYearState = transposedData[0]["month"];
        const rowDataTemp = transformForAgGrid(transposedData);
        if (rowDataTemp) {
          {
            console.log("rowDataTemp ", rowDataTemp);
            let rowDataTemp2 = transformForAgGrid2(rowDataTemp);
            if (rowDataTemp2) {
              rowDataTemp2 = rowDataTemp2.filter((key) => key.field !== "Date");
              setRowData(rowDataTemp2);
              console.log("rowDataTemp2 ", rowDataTemp2);
            }
          }
          const numValues = rowDataTemp[0]?.value?.split(", ").length;

          setColDefs(getColumnDefs(numValues));
        }
      }
    }
  }, [data]);

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "LATO" },
  }));

  const prevMappings = {
    month: "Date",
    TotalRevenue:"Total Paid Member Revenue",
    MonthlyRevenue:"Total Monthly Paid Member Revenue",
    AnnualRevenue:"Total Annual Paid Member Revenue",
    newTotalRevenue:"New Member Revenue",
    newMonthlyRevenue:"New Monthly Member Revenue",
    newAnnualRevenue:"New Annual Member Revenue",
    active10: "Active $10",
    active100: "Active $100",
    active20: "Active $20",
    active160: "Active $160",
    new10: "New $10",
    new100: "New $100",
    new20: "New $20",
    new160: "New $160",
    cancelled: "Cancelled",
    totalOrders: "Total Orders",
    totalProductsBought: "Item Swapped",
    totalSpendHangers: "Hanger Spend",
    totalEarnHangers: "Hanger Earned",
    totalItemValue: "ItemValue Posted",
    monthYear: "Month Year",
    nonMemberhangerPaid: "Non Member Hanger Spend",
    nonMembertotalOrders: "Non Member Total Orders",
    nonMembertotalProductsBought: "Non Member Item Bought",
    storeRevenue:"Non Member Store Revenue",
    newFreeMember:"New Free Plan Member"
  };

  const columnMappings = {
    month: "field",
    value1: "active10",
  };

  const EmtpyHeader = () => <></>;
  const [colDefs, setColDefs] = useState([
    // {
    //   headerName: "Date",
    //   field: "month",
    // },
    // {
    //   headerName: "Active $10",
    //   field: "active10",
    // },
    // {
    //   headerName: "Active $100",
    //   field: "active100",
    // },
    // {
    //   headerName: "Active $20",
    //   field: "active20",
    // },
    // {
    //   headerName: "Active $160",
    //   field: "active160",
    // },
    // {
    //   headerName: "New $10",
    //   field: "new10",
    // },
    // {
    //   headerName: "New $100",
    //   field: "new100",
    // },
    // {
    //   headerName: "New $20",
    //   field: "new20",
    // },
    // {
    //   headerName: "New $160",
    //   field: "new160",
    // },
    // {
    //   headerName: "Cancelled",
    //   field: "cancelled",
    // },
    // {
    //   headerName: "Total Orders",
    //   field: "totalOrders",
    // },
    // {
    //   headerName: "Item Swapped",
    //   field: "totalProductsBought",
    // },
    // {
    //   headerName: "Hanger Spend",
    //   field: "totalSpendHangers",
    // },
    // {
    //   headerName: "Hanger Earned",
    //   field: "totalEarnHangers",
    // },
    // {
    //   headerName: "ItemValue Posted",
    //   field: "totalItemValue",
    //   valueFormatter: (params) =>
    //     params && params.value ? params.value.toFixed(2) : 0,
    // },
    // {
    //   headerName: "Non Member Total Orders",
    //   field: "nonMembertotalOrders",
    //   valueFormatter: (params) => (params && params.value ? params.value : 0),
    // },
    // {
    //   headerName: "Non Member Item Bought",
    //   field: "nonMembertotalProductsBought",
    //   valueFormatter: (params) => (params && params.value ? params.value : 0),
    // },
    // {
    //   headerName: "Non Member Hanger Spend",
    //   field: "nonMemberhangerPaid",
    //   valueFormatter: (params) => (params && params.value ? params.value : 0),
    // },
    // // {
    // //   headerName: "Buyer Email",
    // //   field: "BuyerInfo.userEmail",
    // // },
    // // {
    // //   headerName: "Seller Email",
    // //   field: "sellerInfo.userEmail",
    // // },
    // // {
    // //   headerName: "Shipping Method",
    // //   field: "checkout.products.shippingMethod",
    // // },
    // // {
    // //   headerName: "Hangers Paid (without the bought ones)",
    // //   field: "checkout.productDetail.productHanger",
    // //   valueFormatter: (params) => params && params.value,
    // // },
    // // {
    // //   headerName: "Hangers Bought",
    // //   field: "checkout.products.hangerPurchased",
    // //   valueFormatter: (params) => params && params.value,
    // // },
    // // {
    // //   headerName: "Fulfilled",
    // //   field: "checkout.products.fulfilled",
    // //   valueFormatter: (params) => params && params.value,
    // // },
    // // {
    // //   headerName: "Ordered By (user/admin)",
    // //   field: "checkout.orderdBy",
    // //   valueFormatter: (params) => params && params.value,
    // // },
  ]);
  return (
    <div className="analytics-con" style={{ marginTop: "1rem" }}>
      {loading && rowData.length == 0 ? (
        <div style={{ borderRadius: "10px", overflow: "hidden" }}>
          <Spinner1 />
        </div>
      ) : (
        <>
          <div
            className="input_container search_closet"
            style={{
              marginTop: "0",
              padding: "10px 0",
            }}
          >
            <form
              className="input_container_wrapper"
              // style={{ marginRight: "1rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                let token = localStorage.getItem("clttoken");
                if (token) {
                  searchUser();
                  // setPageNumber(0)
                  //   navigate(`/admin/users?page=1`, { replace: true });
                  //   if (
                  //     location.search.slice(-1) &&
                  //     location.search.slice(location.search.indexOf("=") + 1) -
                  //       1 ==
                  //       0
                  //   ) {
                  //     dispatch(
                  //       getUsers({
                  //         token,
                  //         p: 0,
                  //         search: userSearchVal,
                  //         status: tab,
                  //         type,
                  //       })
                  //     );
                  //   }
                } else {
                  //   navigate("/404", { replace: true });
                }
              }}
            >
              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <input
                  type="text"
                  id="userSearch"
                  name="userSearch"
                  onChange={(e) => setUserSearchVal(e.target.value)}
                  value={userSearchVal}
                  placeholder="Enter User Email"
                  style={{
                    borderRadius: "5px 0 0 5px",
                    marginTop: 0,
                    fontFamily: "inherit",
                    padding: "8px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    borderRadius: "0 5px 5px 0",
                    padding: "9px 12px",
                  }}
                  className="btn"
                >
                  <i class="fas fa-search fa-lg"></i>
                </button>
                {userSearchVal && (
                  <span
                    className="small-text"
                    style={{
                      marginLeft: "1rem",
                      width: "65px",
                      cursor: "pointer",
                      color: "var(--background-color)",
                      fontSize: "12px",
                      fontFamily: "LATO",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      resetSearch();
                      setUserSearchVal("");
                    }}
                  >
                    Reset <FaUndo style={{ fontSize: "11px" }} />
                  </span>
                )}
              </div>
            </form>
          </div>
          <div className="ag-theme-quartz" style={{ height: 825 }}>
            <AgGridReact
              ref={gridRef}
              // {console.log(colDefs)}
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={colDefs}
            />
          </div>
          {/* <Pagination
            pageCount={Math.ceil(analyticsCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          /> */}
        </>
      )}
    </div>
  );
};

export default OverallAnalytics;
