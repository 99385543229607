import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getuserInfo } from "../components/StateSlices/getuserSlice";
import "./Home.css";
import Header1 from "../components/Header1/Header1";
import Header2 from "../components/Header2/Header2";
import HomeCarousel from "../components/HomeCarousel/HomeCarousel";
import FeaturedProducts from "../components/FeaturedProducts/FeaturedProducts";
import LatestProducts from "../components/LatestProducts/LatestProducts";
import ClosestOffer from "../components/ClosestOffer/ClosestOffer";
import Feature from "../components/Feature/Feature";
import TrendingProducts from "../components/TrendingProducts/TrendingProducts";
import TopProducts from "../components/TopProducts/TopProducts";
import NewsLetter from "../components/NewsLetter/NewsLetter";
import Partners from "../components/Partners/Partners";
import LatestEvent from "../components/LatestEvent/LatestEvent";
import FeaturedItemW from "../components/FeaturedItemW/FeaturedItemW";
import FeaturedItemG from "../components/FeaturedItemsG/FeaturedItemG";
import ClosestItem from "../components/ClosestItem/ClosestItem";
import MostViewed from "../components/MostViewed/MostViewed";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../components/Spinner/Spinner";
import { gethomeInfo, resetHome } from "../components/StateSlices/getHomeSlice";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../components/Alert/Alert";
import LoginHomeCarousel from "../components/LoginHomeCarousel/LoginHomeCarousel";
import ScrollAnimation from "react-animate-on-scroll";

const Home = ({ content }) => {
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  const { homeStatus, homeInfo, homeError } = useSelector(
    (state) => state.homeInfo
  );

  const { staticContentInfo, staticContentStatus } = useSelector(
    (state) => state.staticContent
  );

  const [spinner, setSpinner] = useState(false);
  const [newsetItem, setNewsetItem] = useState([]);
  const [featureItem, setFeatureItem] = useState([]);
  const [menItem, setMenItem] = useState([]);
  const [boytItem, setBoyItem] = useState([]);
  const [womenItem, setWomenItem] = useState([]);
  const [girlItem, setGirlItem] = useState([]);
  const [closetItem, setClosetItem] = useState([]);

  let navigate = useNavigate();
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (!userInfo) {
      let token = localStorage.getItem("clttoken");
      if (token) {
        setSpinner(true);
        dispatch(getuserInfo({ token: token }));
        window.scrollTo(0, 0);
      } else {
        // navigate("/public/home");
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      setSpinner(true);
      let token = localStorage.getItem("clttoken");
      dispatch(gethomeInfo({ token: token }));
    }
  }, [userInfo]);

  if (homeInfo) {
    setSpinner(false);
    setNewsetItem(homeInfo.newestProduct);
    setWomenItem(homeInfo.womenProduct);
    setGirlItem(homeInfo.girlProduct);
    setClosetItem(homeInfo.closetProduct);
    setFeatureItem(homeInfo.featureProduct);
    setMenItem(homeInfo.menProduct);
    setBoyItem(homeInfo.boyProduct);
    dispatch(resetHome());
  }

  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
      <div>
        <div class="page-container" style={{ position: "relative" }}>
          {spinner ? <Spinner /> : ""}
          {/* <ToastContainer
          position="bottom-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
          <LoginHomeCarousel content={content.loggedInHomePageCarousel} />
          {/* <HomeCarousel /> */}
          <FeaturedProducts
            newsetItem={featureItem}
            title={"Featured Products"}
          />
          <ClosestItem closetItem={closetItem} />
          <FeaturedProducts newsetItem={newsetItem} title={"Newest Products"} />

          {menItem.length >= 4 ? (
            <FeaturedProducts newsetItem={menItem} title={"Men's Products"} />
          ) : (
            ""
          )}
          <FeaturedItemW womenItem={womenItem} />
          {girlItem.length >= 4 ? (
            <FeaturedProducts newsetItem={girlItem} title={"Girls Products"} />
          ) : (
            ""
          )}
          {boytItem.length >= 4 ? (
            <FeaturedProducts newsetItem={boytItem} title={"Boys Products"} />
          ) : (
            ""
          )}
          {/* <LatestProducts/> */}
          {/* <ClosestOffer/> */}
          {/* <Feature/> */}
          {/* <TrendingProducts/> */}
          {/* <TopProducts/> */}
          {/* <NewsLetter
          toast={toast}
          setSpinner={setSpinner}
          toastOption={toastOption}
        /> */}
          {/* <FeaturedItemG girlItem={girlItem} /> */}
          {/* <Partners/> */}
          {/* <LatestEvent /> */}

          {/* <MostViewed /> */}
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Home;
