import React from "react";
import hanger from "../../assets/images/hanger.svg";
import "./PurchasedDescription.css";
import productLogo from "../../assets/images/GAP_Logo.png";

const PurchasedDescription = ({
  productInfo,
  userInfo,
  pid,
  AddToCart,
  purchaseInfo,
}) => {
  //consolele.log(purchaseInfo, pid,productInfo);
  return (
    productInfo &&
    Object.keys(purchaseInfo).length > 0 && (
      <div className="purchased_description_container">
        <div className="product_title">{productInfo.productName}</div>
        <div className="brand">
          <img
            style={{ height: "30px" }}
            src={productLogo}
            alt="product icon"
          />
        </div>
        <div className="product_meta_container">
          <div className="product_meta_data">
            <div className="product_price">
              {productInfo.productHanger}{" "}
              <img style={{ height: "25px" }} src={hanger} alt="hanger icon" />
            </div>
            <div className="product_description">{productInfo.productDesc}</div>
            <div className="product_info">
              <div>
                Size:{" "}
                {productInfo.productStats && productInfo.productStats.size}
              </div>
              <div>
                Category:{" "}
                {productInfo.productStats &&
                  productInfo.productStats.category
                    .toString()
                    .replaceAll(",", ", ")}
              </div>
              <div>
                Brand:{" "}
                {productInfo.productStats && productInfo.productStats.brand}
              </div>
              <div>
                Colors:{" "}
                {productInfo.productStats && productInfo.productStats.color}
              </div>
              <div>Original Price: {productInfo.productPrice}</div>
              <div>
                Current Condition:{" "}
                {productInfo.productStats && productInfo.productStats.condition}
              </div>
              {purchaseInfo.userId &&
              purchaseInfo.userId.userDetailsId &&
              purchaseInfo.userId.userDetailsId.userEmail ? (
                <div>
                  Purchaser Email:{" "}
                  {purchaseInfo.userId &&
                    purchaseInfo.userId.userDetailsId &&
                    purchaseInfo.userId.userDetailsId.userEmail}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="product_actions">
            <div className="product_meta_data" style={{ width: "100%" }}>
              <div className="product_info">
                <div>
                  <b>Purchased At:</b>{" "}
                  {new Date(purchaseInfo.checkoutTime).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                </div>
                <div>
                  <b>Price Paid:</b>{" "}
                  {"$" + purchaseInfo.pricePaid + " (Will be corrected later)"}
                </div>
                <div>
                  <b>Shipping With:</b>{" "}
                  {purchaseInfo.products.find((val) => val.productId === pid)
                    ? purchaseInfo.products.find((val) => val.productId === pid)
                        .shippingMethod === "free"
                      ? "Local Pickup"
                      : purchaseInfo.products.find(
                          (val) => val.productId === pid
                        ).shippingMethod
                    : ""}
                </div>
                <div>
                  <b>Shipping Address:</b>{" "}
                  {purchaseInfo.shippingAddress +
                    ", " +
                    purchaseInfo.shippingCity +
                    ", " +
                    purchaseInfo.shippingState +
                    "-" +
                    purchaseInfo.shippingZipCode}
                </div>
                <div>
                  <b>Hanger Used:</b>
                  {1}
                </div>
                {/* <div className="chat-button btn">Chat with User</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PurchasedDescription;
