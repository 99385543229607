import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Marquee.css";
import b2 from "../../assets/images/brand logos/brand (2).png";
import b3 from "../../assets/images/brand logos/brand (3).png";
import b4 from "../../assets/images/brand logos/brand (4).png";
import b5 from "../../assets/images/brand logos/brand (5).png";
import b7 from "../../assets/images/brand logos/brand (7).png";
import b8 from "../../assets/images/brand logos/brand (8).png";
import b9 from "../../assets/images/brand logos/brand (9).png";
import b10 from "../../assets/images/brand logos/brand (10).png";
import b11 from "../../assets/images/brand logos/brand (11).png";
import b12 from "../../assets/images/brand logos/brand (12).png";
import b13 from "../../assets/images/brand logos/brand (13).png";
import b14 from "../../assets/images/brand logos/brand (14).png";
import b15 from "../../assets/images/brand logos/brand (15).png";
import b16 from "../../assets/images/brand logos/brand (16).png";
import b17 from "../../assets/images/brand logos/brand (17).png";
import b18 from "../../assets/images/brand logos/brand (18).png";
import b19 from "../../assets/images/brand logos/brand (19).png";
import b20 from "../../assets/images/brand logos/brand (20).png";
import b21 from "../../assets/images/brand logos/brand (21).png";
import b22 from "../../assets/images/brand logos/brand (22).png";
import b23 from "../../assets/images/brand logos/brand (23).png";
import b24 from "../../assets/images/brand logos/brand (24).png";
import b25 from "../../assets/images/brand logos/brand (25).png";
import b27 from "../../assets/images/brand logos/brand (27).png";
import b28 from "../../assets/images/brand logos/brand (28).png";
import b29 from "../../assets/images/brand logos/brand (29).png";
import b30 from "../../assets/images/brand logos/brand (30).png";
import b31 from "../../assets/images/brand logos/brand (31).png";
import b32 from "../../assets/images/brand logos/brand (32).png";
const Marquee = () => {
  const brands = [
    { brandName: "Mossimo", logo: b2 },
    { brandName: "Paige", logo: b3 },
    { brandName: "Patagonia", logo: b4 },
    { brandName: "Ralph Lauren", logo: b5 },
    { brandName: "REI", logo: b7 },
    { brandName: "Tahari", logo: b8 },
    { brandName: "North Face", logo: b9 },
    { brandName: "Tommy Bahama", logo: b10 },
    { brandName: "Tory Burch", logo: b11 },
    { brandName: "Urban Outfitters", logo: b12 },
    { brandName: "Zara", logo: b13 },
    { brandName: "Anthropologie", logo: b14 },
    { brandName: "Lucky", logo: b15 },
    { brandName: "Adrianna Papell", logo: b16 },
    { brandName: "Eddie Bauer", logo: b17 },
    { brandName: "Forever 21", logo: b18 },
    { brandName: "Ann Taylor", logo: b19 },
    { brandName: "Ann Taylor", logo: b20 },
    { brandName: "Athleta", logo: b21 },
    { brandName: "Banana Republic", logo: b22 },
    { brandName: "Chicos", logo: b23 },
    { brandName: "Patagonia", logo: b24 },
    { brandName: "Cynthia Rowley", logo: b25 },
    { brandName: "Free People", logo: b27 },
    { brandName: "Gap", logo: b28 },
    { brandName: "J Brand", logo: b30 },
    { brandName: "J. Crew", logo: b31 },
    { brandName: "Lululemon", logo: b32 },
  ];
  const categories = [
    "Accessories",
    "Tops",
    "Pants",
    "Skirts",
    "Shorts",
    "Dresses",
    "Outerwear",
    "Jeans",
    "Sweaters",
    "Swim",
    "Activewear",
    "Maternity",
    "Shoes",
    "Handbags",
    "Jewelry ",
    "Men",
    "T-shirts",
    "Shirts",
    "Dress Shirts",
    "Shorts",
    "Jeans",
    "Pants",
    "Sweaters",
    "Swimwear",
    "Outerwear",
    "Suits & Blazers",
    "Activewear",
    "Shoes",
    "Accessories",
    "Boy",
    "Newborn",
    "Shirts",
    "Sweaters",
    "Shorts",
    "Jeans",
    "Pants",
    "Outerwear",
    "Special Occassion",
    "Shoes",
    "Girl",
    "Newborn",
    "Tops",
    "Blouses",
    "Sweaters",
    "Shorts",
    "Skirts",
    "Dresses",
    "Jeans",
    "Pants",
    "Outerwear",
    "Special Occasion",
    "Shoes",
    "Other",
    "Books",
    "Electronics",
    "Video Games",
    "Toys",
    "Sports",
    "Sweatshirts",
    "Romper",
    "Bodysuits",
    "T-Shirts",
    "Cardigans",
    "Blazers",
    "Sweatshirts",
    "Pajamas",
  ];
  return (
    // <div class="Marquee">
    //   <div class="Marquee-content">
    //     {brandImages.map((category, index) => (
    //       <Link to={"/shop"} key={index}>
    //         <div class="Marquee-tag">
    //           <img style={{ width: "100%" }} src={brandImages[index]} />
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
    <div className="marquee__con">
      <div class="marquee">
        <div class="marquee__group">
          {brands.map((category, index) => (
            <Link
              to={`/shop`}
              state={{ brandName: category.brandName }}
              key={index}
            >
              <div class="Marquee-tag">
                <img
                  alt={category.brandName}
                  style={{ width: "100%" }}
                  src={category.logo}
                />
              </div>
            </Link>
          ))}
        </div>

        <div aria-hidden="true" class="marquee__group">
          {brands.map((category, index) => (
            <Link
              to={`/shop`}
              state={{ brandName: category.brandName }}
              key={index}
            >
              <div class="Marquee-tag">
                <img
                  alt={category.brandName}
                  style={{ width: "100%" }}
                  src={category.logo}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
