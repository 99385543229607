import React, { useState } from "react";
import "./HowItWorks.css";
import cimg from "../assets/images/hiwcover.png";
import stp1 from "../assets/images/step1.jpeg";
import stp2 from "../assets/images/step2.png";
import stp3 from "../assets/images/step3.jpeg";
import tip1 from "../assets/images/tip1.png";
import tip2 from "../assets/images/tip2.png";
import tip3 from "../assets/images/tip3.png";
import { Link, useNavigate } from "react-router-dom";
import { Facebook, Twitter, Pinterest } from "react-social-sharing";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { referLink, resetItem } from "../components/StateSlices/referLinkSlice";
import { MdContentCopy } from "react-icons/md";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  let navigate = useNavigate();
  const toastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  // var close = document.getElementsByClassName("alertCloseBtn");
  var close = document.getElementsByClassName("alert");
  var i;

  for (i = 0; i < close.length; i++) {
    close[i].onclick = function () {
      setIsReferModalActive(false);
      var div = this.parentElement;
      div.style.opacity = "0";
      setTimeout(function () {
        div.style.display = "none";
      }, 600);
    };
  }
  const [isReferModalActive, setIsReferModalActive] = useState(false);
  const [refUrl, setRefUrl] = useState("");

  const dispatch = useDispatch();
  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );

  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://closestcloset.com/signup?referral=" + encodeURIComponent(referId)
    );
    setIsReferModalActive((prev) => !prev);
  }

  const getUrl = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(referLink({ token: token }));
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <Helmet>
        <title>ClosestCloset - How It Works?</title>
        <meta
          name="description"
          content="This page describes closestcloset working principle and how it withstands reducing carbon emissions encouraging green revolution of the planet"
        />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page describes closestcloset working principle and how it withstands reducing carbon emissions encouraging green revolution of the planet"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page describes closestcloset working principle and how it withstands reducing carbon emissions encouraging green revolution of the planet"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <Modal
        ariaHideApp={false}
        isOpen={isReferModalActive}
        onRequestClose={() => setIsReferModalActive(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: 70,
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 9.5px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
        </div>

        <div className="terms-modal-con">
          <div
            className="terms-modal-wrapper"
            style={{ marginTop: 0, padding: "1.5rem 0", paddingTop: ".5rem" }}
          >
            <h3 className="primary-text" style={{ marginBottom: ".5rem" }}>
              Refer to your friends
            </h3>
            <h6 className="background-text">Earn 1 Hanger on each Referral!</h6>
            <div className="reference-section">
              <input type="text" id="reference-input" value={refUrl} disabled />
              <button
                id="copy-button"
                onClick={() => {
                  // refUrl.select();
                  // refUrl.setSelectionRange(0, 99999); // For mobile devices
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(refUrl);
                  toast.success(
                    "Referral URL copied successfully!",
                    toastOption
                  );
                }}
              >
                <MdContentCopy />
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="page-container how-it-works-container">
        <div className="cover-container">
          <div className="cover-text">
            <h1
              className="x-large-text bold-text"
              style={{ textAlign: "center" }}
            >
              How Closest Closet Works
            </h1>
            <div className="h2" style={{ marginTop: "1rem" }}>
              Simple as ABC
            </div>
          </div>
          <div className="cover-image">
            <img src={cimg} alt="How It Works" />
          </div>
        </div>
        <div className="steps">
          <h2 className="section_title large-text" style={{ fontWeight: 700 }}>
            Basics Of Closest Closet
          </h2>
          <br />
          <div className="step-card">
            <div className="step-text">
              <h3 className="h3 background-text">
                Step 1 - Add Item{"("}s{")"}
              </h3>
              <div className="h5" style={{ color: "var(--grey-color" }}>
                Just because an item no longer works in your closet, doesn’t
                mean it won’t enhance someone else’s. Take a photo of that item
                and post it to your online closet.
              </div>
              <Link to="/add-items">
                <button className="btn" onClick={() => navigate("/add-items")}>
                  + Add Items
                </button>
              </Link>
            </div>
            <div className="step-img">
              <img src={stp1} alt="Step1" />
            </div>
          </div>
          <hr />
          <div className="step-card step-card-second">
            <div className="step-img">
              <img src={stp2} alt="Step2" />
            </div>
            <div className="step-text">
              <h3 className="h3 background-text">Step 2 - Be Rewarded</h3>
              <div className="h5" style={{ color: "var(--grey-color)" }}>
                Every item you upload will earn you a hanger that can be
                redeemed for items that complement your unique style.
              </div>
              <div className="h5" style={{ color: "var(--grey-color)" }}>
                Every Closest Closet member you refer will earn you another
                hanger. More friends that join, the more items to shop!
              </div>

              <button className="btn" onClick={() => getUrl()}>
                <i className="fas fa-user-friends"></i> Refer A Friend
              </button>
            </div>
          </div>
          <hr />
          <div className="step-card">
            <div className="step-text">
              <h3 className="h3 background-text">
                Step 3 - Shop Other Members’ Closets
              </h3>
              <div className="h5" style={{ color: "var(--grey-color)" }}>
                Use the hangers you have earned to redeem clothing in your
                network of closets. Expand your wardrobe by selecting items from
                your friends’ and their friends’ closets with your earned
                hangers! Only pay for shipping or choose free local pickup at
                checkout.
              </div>
              <Link to={"/shop"}>
                <button className="btn" onClick={() => navigate("/shop")}>
                  <i class="fas fa-shopping-bag"></i> Shop Items
                </button>
              </Link>
            </div>
            <div className="step-img">
              <img src={stp3} alt="Step3" />
            </div>
          </div>
          <hr />
        </div>
        <br />
        <section className="buy-sell">
          <h2 className="section_title large-text" style={{ fontWeight: 700 }}>
            Buying & Selling
          </h2>
          <blockquote>
            Closest Closet is an online platform for items in your closet that
            no longer add to your wardrobe, but will be fresh and new to another
            Closest Closet member. As you post items from your closet you will
            open up space to add fresh and new items from your vast network of
            closets.
            <span>@Danielle</span>
          </blockquote>
          <table class="table-resp">
            <caption></caption>
            <thead>
              <tr className="h1 bold-text">
                <th style={{ fontSize: "24px" }}>
                  <div className="buy-icon" style={{ fontSize: "32px" }}>
                    <i className="fas fa-shopping-bag"></i>
                  </div>
                  Items Bought w/ Hangers
                </th>
                <th style={{ fontSize: "24px" }}>
                  <div className="buy-icon" style={{ fontSize: "32px" }}>
                    <i className="fas fa-shipping-fast"></i>
                  </div>
                  Items Sold From Your Closet
                </th>
              </tr>
            </thead>
            <tbody className="h6">
              <tr>
                <td>
                  When you bought an item with a hanger and chose the free local
                  pickup option, you can arrange a time with the closet owner by
                  clicking the chat link in the email or on the My Purchases
                  page.
                </td>
                <td>
                  When an item is bought from your closet, you will get an email
                  confirming the order. If the buyer chose local pickup, they
                  may contact you or you may initiate the exchange via the chat
                  link in the email or on the My Orders page.
                </td>
              </tr>
              <tr>
                <td>
                  If you bought an item that needs to be shipped, you can sit
                  back and relax as the Closet owner received a shipping label
                  to ship the items directly to you.
                </td>
                <td>
                  If they bought the item with shipping, you will receive an
                  email with an attached shipping label. Simply print the label,
                  package up the item(s) bought and drop off at the carrier
                  chosen by the buyer.
                </td>
              </tr>
              <tr>
                <td>
                  For local pickup, you can initiate the exchange via the chat
                  link in the email or from the My Purchases page
                </td>
                <td>
                  Buyer pays for shipping or arranges for local pickup with you
                </td>
              </tr>
              <tr>
                <td>
                  Item doesn’t fit- no problem. Repost the item and earn a
                  hanger
                </td>
                <td>
                  Buyer chooses the shipping carrier so make sure to drop the
                  item at the right one as shown on the emailed shipping label
                </td>
              </tr>
              <tr>
                <td>-</td>
                <td>
                  Local pickup requires you to use the chat interface with the
                  buyer to arrange for pickup of the item
                </td>
              </tr>
              <tr>
                <td>
                  <Link to={"/user/purchases"}>
                    <button className="btn">
                      <i className="fas fa-shopping-bag"></i> My Purchases
                    </button>
                  </Link>
                </td>
                <td>
                  <Link to={"/user/orders"}>
                    <button className="btn">
                      <i className="fas fa-shipping-fast"></i> My Orders
                    </button>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <br />
        <section className="additional-tip-container">
          <h2 className="section_title large-text" style={{ fontWeight: 700 }}>
            Additional Tips
          </h2>
          <div className="additional-tip-card-container">
            <div
              className="additional-tip-card"
              onClick={() => navigate("/quality-items-only")}
            >
              <div className="additional-tip-card-img">
                <img src={tip1} alt="additional-tip-1" />
              </div>
              <h3>Quality Items Only</h3>
              <div className="additional-item-card-text h6 grey-text">
                A high quality experience depends on the upcycling of high
                quality, gently used clothing and other items. Follow these tips
                to ensure a successful experience.
              </div>
              <div className="additional-tip-card-learn-more dark-grey-text">
                Learn More
              </div>
            </div>
            <div
              className="additional-tip-card"
              onClick={() => navigate("/clean-your-closet")}
            >
              <div className="additional-tip-card-img">
                <img src={tip2} alt="additional-tip-1" />
              </div>
              <h3>Clean Your Closet</h3>
              <div className="additional-item-card-text h6 grey-text">
                If you haven’t worn something in 6 months, you never will.
                Upcycle items that are taking up prime, and make room for the
                new to you items in your network of closets.
              </div>
              <div className="additional-tip-card-learn-more dark-grey-text">
                Learn More
              </div>
            </div>
            <div className="additional-tip-card" onClick={() => getUrl()}>
              <div className="additional-tip-card-img">
                <img src={tip3} alt="additional-tip-1" />
              </div>
              <h3>Invite Your Friends</h3>
              <div className="additional-item-card-text h6 grey-text">
                You know that co-worker who always looks so pulled together and
                trendy? She has a closet too! Take full advantage of your
                membership and invite your friends to join.
              </div>
              <div className="additional-tip-card-learn-more dark-grey-text">
                Invite Friends Here
              </div>
            </div>
          </div>
        </section>
        <br />
        <hr />
        <section className="socials">
          <h2 className="section_title large-text" style={{ fontWeight: 700 }}>
            Help spread the word and get more closets to shop!
          </h2>

          <div className="social-btn-container" style={{ marginTop: "2rem" }}>
            <Facebook link="https://closestcloset.com/" />
            <Twitter link="https://closestcloset.com/" />
            <Pinterest link="https://closestcloset.com/" />
          </div>
        </section>
      </div>
    </>
  );
};

export default HowItWorks;
