import React, { useState } from "react";
import "./SmallHeader.css";
import logo from "../../assets/images/CCfinallogo_boxed.png";
import hanger from "../../assets/images/hanger.svg";
import bag from "../../assets/images/bag.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SmallHeader = () => {
  const [sidebar, setSidebar] = useState(false);
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  return (
    <div className="small_header">
      <div
        className="sidebar"
        style={sidebar ? { width: "250px", height: "100%" } : { width: "0px" }}
      >
        <div
          className="closebtn"
          onClick={() => setSidebar(false)}
          style={{ minHeight: "100px" }}
        >
          &times;
        </div>
        <br />
        <div className="searchbar_container">
          <input type="text" className="input_search" />
          <button>
            <i class="fas fa-search fa-lg"></i>
          </button>
        </div>

        {/* <a href="./feed" className="nav_link">Feed</a> */}
        <Link
          to={`/shop`}
          style={{ textDecoration: "none" }}
          className="nav_link"
        >
          Shop
        </Link>
        {/* <a href="javascript:void(0)" className="nav_link">Shop Closets</a> */}
        <Link
          to={`/user/my-closet`}
          style={{ textDecoration: "none" }}
          className="nav_link"
        >
          My Closet
        </Link>
      </div>
      <div className="menu_container">
        <div className="left_container">
          <span
            onClick={() => setSidebar(true)}
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            &#9776;
          </span>
          <Link to={`/`} style={{ textDecoration: "none" }}>
            <img
              src={logo}
              style={{ height: "50px" }}
              alt="Closest Closet Logo"
            />
          </Link>
        </div>
        <div className="small_icon_container">
          <Link
            to={`/`}
            style={{ textDecoration: "none" }}
            className="icon_container"
          >
            <i className="far fa-bell "></i>
          </Link>
          <Link
            to={`/login`}
            style={{ textDecoration: "none" }}
            className="icon_container"
          >
            <i className="fas fa-user-circle "></i>
          </Link>
          <Link
            to={`/add-items`}
            style={{ textDecoration: "none" }}
            className="icon_container"
          >
            <i class="fas fa-plus-circle"></i>
          </Link>
          {userInfo && userInfo.userDetailsId ? (
            <>
              <Link
                to={`/`}
                style={{ textDecoration: "none" }}
                className="icon_container"
              >
                <img
                  style={{ height: "21px" }}
                  src={hanger}
                  alt="hanger icon"
                />
                <span className="badge">{userInfo.userHanger}</span>
              </Link>
              <Link
                to={`/`}
                style={{ textDecoration: "none" }}
                className="icon_container"
              >
                <img style={{ height: "21px" }} src={bag} alt="hanger icon" />
                <span
                  className="badge-bag"
                  style={{
                    left: "50%",
                    top: "44%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  {userInfo.userProductsId.cartId.length}
                </span>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SmallHeader;
