import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import { closetSetup } from "../components/StateSlices/closestsetupSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import closetImg from "../assets/images/qc4.png";
import "./SetupClosest.css";

import jayCloset from "../assets/images/similar closet images/jay.jpg";
import k2lCloset from "../assets/images/similar closet images/k2l.jpg";
import steveCloset from "../assets/images/similar closet images/funcoland.jpg";
import { getTopCloset } from "../components/StateSlices/getTopClosets";
import ConfettiNew from "../components/Confetti/ConfettiNew";
import { BsChevronRight } from "react-icons/bs";

const SetupClosestComplete = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = location.state || {};
  const [spinner, setSpinner] = useState(false);
  const [topClosets, setTopClosets] = useState(null);
  const dispatch = useDispatch();
  const { topClosetsInfo } = useSelector((state) => state.topClosets);
  // dispatch(reset());
  useEffect(() => {
    // dispatch(reset());
    setSpinner(true);
    dispatch(getTopCloset({ values: "Top" }));
  }, []);

  useEffect(() => {
    if (topClosetsInfo) {
      setTopClosets(topClosetsInfo);
      setSpinner(false);
      console.log("kkc ", topClosets);
    }
  }, [topClosetsInfo]);

  const exploreClosets = [
    {
      closetName: "Community Closet",
      closetOwner: "Community Closet",
      closetImg: closetImg,
      masterId: "63ae8146cb64891f59b87bbc",
    },
    {
      closetName: "Funcoland",
      closetOwner: "Steven Tazic",
      closetImg: steveCloset,
      masterId: "63ae8195cb64891f59b88b02",
    },
    {
      closetName: "K2l's closet",
      closetOwner: "Ketul Chhaya",
      closetImg: k2lCloset,
      masterId: "63766bb54fa727037465a2d9",
    },
    {
      closetName: "andillow's store",
      closetOwner: "Ashley Dillow",
      closetImg:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNkaH4qXvLo-0kmAyUa-9y3uNIJBi0b-svOg&usqp=CAU",
      masterId: "63ae8131cb64891f59b87864",
    },
    {
      closetName: "Jay's Closet",
      closetOwner: "Jay Patel",
      closetImg: jayCloset,
      masterId: "63ae8159cb64891f59b87fb0",
    },
  ];
  //   const [image, setImage] = useState("")
  //   const [bannerImage, setBannerImage] = useState("")
  //   const { status, userInfo, error,profile } = useSelector(state => state.user)
  //   const { setupStatus, userFullInfo, setupError } = useSelector(state => state.closet)

  // const toastOption = {
  //     position: "top-right",
  //     autoClose: 2500,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  // };

  return (
    <>
      <ConfettiNew />
      {spinner ? (
        // <div style={{ height: "60vh", width: "100%" }}>
        <Spinner />
      ) : (
        // </div>
        // </div>
        <div
          className="closest_setup_container"
          style={{ position: "relative" }}
        >
          {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

          <h1
            className="section_title"
            style={{ paddingTop: "40px", marginTop: 0 }}
          >
            Congratulations {userDetails.user.userFName}! Your closet setup is
            now complete!
          </h1>

          {userDetails &&
          userDetails.user &&
          userDetails.user.membership &&
          userDetails.user.membership !== "trial" ? (
            <>
              <p className="paragraph_title"> Start earning hangers!</p>
              <div style={{ textAlign: "center" }}>
                <Link to="/add-items">
                  <button
                    className="btn dark-btn setup-complete-button"
                    type="submit"
                  >
                    Add Items
                  </button>
                </Link>
                {/* <button className='btn dark-btn setup-complete-button' type="submit" >Refer a Friend</button> */}
              </div>
            </>
          ) : (
            <div className="list-styles">
              <br />
              <br />
              <div className="inside">
                <p className="background-text" style={{ fontSize: "18px" }}>
                  As a free guest of Closest Closet, you can:
                </p>
                <ul>
                  <li>Browse and shop for items not marked "Members Only"</li>
                  <li>
                    Purchase hanger credits for $7 each at checkout to buy
                    desired items
                  </li>
                </ul>
                <br />
                <p className="background-text" style={{ fontSize: "18px" }}>
                  Important Limitations:
                </p>
                <ul>
                  <li>
                    Newly posted items are exclusive to paid members for the
                    first 30 days
                  </li>
                </ul>
                <br />
                <p className="background-text" style={{ fontSize: "18px" }}>
                  Upgrade to Paid Membership:
                </p>
                <div>
                  To enjoy full access and benefits, upgrade to a paid
                  membership anytime from the{" "}
                  <Link
                    to={"/user/membership"}
                    className="background-text"
                    style={{
                      borderBottom: "1px solid var(--background-text)",
                    }}
                  >
                    <u>
                      My Closet <BsChevronRight /> My Membership page.
                    </u>
                  </Link>
                </div>
                <br />
                <p className="background-text" style={{ fontSize: "18px" }}>
                  Paid Memberships Advantages:
                </p>
                <ul>
                  <li>Unlimited transactions with no fee</li>
                  <li>
                    Earn hanger credits by posting items to your virtual closet
                  </li>
                  <li>
                    Shop other members closets with the hanger credits earned
                  </li>
                  <li>Discounted price on hanger credits if your are short</li>
                  <li>
                    Exclusive access to Member's Only items for the first 30
                    days
                  </li>
                  <li>Friend referral program access:</li>
                  <ul>
                    <li>
                      {" "}
                      5 free credits for the member + $5 subscription credit off
                      the next payment.
                    </li>
                    <li style={{ marginBottom: ".5rem" }}>
                      {" "}
                      Referrals get 5 bonus hanger credits.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          )}

          <p className="paragraph_title">Manage Your Closet</p>
          <div style={{ textAlign: "center" }}>
            <Link to="/user/my-closet">
              <button className="btn dark-btn setup-complete-button">
                My Closet
              </button>
            </Link>
          </div>

          <p className="paragraph_title">Start Shopping!</p>
          <div style={{ textAlign: "center" }}>
            <Link to="/shop">
              <button
                className="btn dark-btn setup-complete-button"
                type="submit"
              >
                Shop Now
              </button>
            </Link>
          </div>

          <h1 className="section_title" style={{ paddingTop: "75px" }}>
            Explore Similar Closets
          </h1>

          <div>
            <div className="similarClosets" style={{ paddingBottom: 0 }}>
              {topClosets &&
                topClosets.slice(0, 2).map((closet) => (
                  <Link
                    to={`/closet/${closet.closetName}`}
                    state={{ id: closet.masterId }}
                    style={{
                      color: "#F26654",
                      margin: "0 1rem",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="UsersContainer"
                      style={{ paddingBottom: 40 }}
                    >
                      <img
                        className="closetUser"
                        src={closet.closetImage || closetImg}
                        alt="Closet User"
                      ></img>
                      <p
                        style={{
                          color: "#f26654",
                          textTransform: "capitalize",
                        }}
                      >
                        {closet.closetName}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SetupClosestComplete;
