import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  orderstatus: "idle",
  orderInfo: [],
  error: null,
  boughtHistoryStatusMessage:null
}

export const getOrders = createAsyncThunk(
  "product/getorders",
  async ({token,search=""}, { rejectWithValue }) => {
    try {
      let s = ''
      if(search){
        s = `?search=${search}`
      }
      const { data } = await axios.get(
        `/api/checkout/getorders${s}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const seenBoughtOrders = createAsyncThunk(
  "product/seenBoughtOrders",
  async ({token,ordersId}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/seenboughtorders`,
        {
          ordersId
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    reseto(state,action){
        state.orderInfo = []
        state.orderstatus="idle"
        state.boughtHistoryStatusMessage=null;
    }
  },
  extraReducers: {
    [getOrders.pending]: (state, action) => {
      state.orderstatus = "loading"
    },
    [getOrders.fulfilled]: (state, action) => {
      state.orderstatus = "succeeded"
      state.orderInfo = action.payload.orderInfo
    },
    [getOrders.rejected]: (state, action) => {
      state.orderstatus = "failed"
      state.error = action.payload.message
    },
    [seenBoughtOrders.pending]: (state, action) => {
      state.orderstatus = "loading"
    },
    [seenBoughtOrders.fulfilled]: (state, action) => {
      state.orderstatus = "succeeded"
      state.boughtHistoryStatusMessage = action.payload.message
      // state.orderInfo = action.payload.orderInfo
    },
    [seenBoughtOrders.rejected]: (state, action) => {
      state.orderstatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { reseto } = OrdersSlice.actions
export default OrdersSlice.reducer 