import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import "./ConfettiNew.css";

const ConfettiNew = () => {
  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className={`confetti-container ${showConfetti ? "show" : "hide"}`}>
      <Confetti
        wind={0.01}
        gravity={0.15}
        numberOfPieces={400}
        width={window.innerWidth}
        height={2 * window.innerHeight}
        colors={["#f47f72", "#f6998e", "#924c44", "#f58c80", "#492622"]}
        recycle={showConfetti}
      />
    </div>
  );
};

export default ConfettiNew;
