import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserInfo } from "../components/StateSlices/getuserSlice";
import { addItem, resetItem } from "../components/StateSlices/additemSlice";
import { addBrand, resetBrand } from "../components/StateSlices/brandSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import axios from "axios";
import Select, { components } from "react-select";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  addCMSContent,
  resetCMS,
  getCMSContent,
  updateCMSContent,
} from "../components/StateSlices/cmsSlice";

import "react-toastify/dist/ReactToastify.css";
import "./AddItems.css";
import "./AddCMS.css";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const editorConfiguration = {
  contentsLangDirection: "ltr",
  toolbar: [
    "sourceEditing",
    "heading",
    "|",
    "bold",
    "italic",
    "underline",
    "fontFamily",
    "fontBackgroundColor",
    "fontColor",
    "fontSize",
    "|",
    "horizontalLine",
    "link",
    "alignment",
    "outdent",
    "indent",
    "numberedList",
    "bulletedList",
    "|",
    "imageUpload",
    "blockQuote",
    "insertTable",
    "mediaEmbed",
    "undo",
    "redo",
    "findAndReplace",
    "selectAll",
  ],
  mediaEmbed: {
    previewsInData: true,
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "3px",
  borderBottom: "2px solid #F26654",
};

const groupLabelStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#F26654",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const EditCMS = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const { itemStatus, itemInfo, itemError } = useSelector((state) => state.add);
  const { cmsStatus, cmsInfo, cmsError, cmsFetchStatus, cmsMessage } =
    useSelector((state) => state.cms);
  const {
    brandStatus,
    brandInfo,
    brandError,
    categoryInfo,
    signature,
    timestamp,
    sizeInfo,
  } = useSelector((state) => state.brand);

  const [feature, setFeature] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [brandName, setBrandName] = useState("");
  const [brandErrorDisplay, setBrandErrorDisplay] = useState("");
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [colors, setColors] = useState([]);
  const formikRef = useRef();
  const [colorError, setColorError] = useState("");

  const [dragActive, setDragActive] = React.useState(false);
  const [currentCat, setCurrentCat] = useState("");
  const [sizeOptions, setSizeOptions] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [cmsContent, setCmsContent] = useState("");
  const [editorCont, setEditorCont] = useState();

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    window.scrollTo(0, 0);
    if (token && location.state && location.state.id) {
      setSpinner(true);
      dispatch(getCMSContent({ token: token, id: location.state.id }));
      dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
    } else {
      navigate("/404", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (cmsInfo) {
      setCmsContent(cmsInfo.pageContent);
      formik.setFieldValue("pageTitle", cmsInfo.pageName);
      formik.setFieldValue("pageURL", cmsInfo.pageURL);
      formik.setFieldValue("bannerText", cmsInfo.bannerText);
      setImages([cmsInfo.pageBanner]);
      setSpinner(false);
    }
  }, [cmsInfo]);

  const [spinner, setSpinner] = useState(false);
  const [visibleChecked, visibleSetChecked] = useState(false);

  const [images, setImages] = useState([]);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const hangerToastOption = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const formik = useFormik({
    innerRef: { formikRef },
    initialValues: {
      pageTitle: "",
      pageURL: "",
      bannerText: "",
    },
    validationSchema: Yup.object({
      pageTitle: Yup.string().required("Please enter Page Title"),
      pageURL: Yup.string().required("Please enter Page URL"),
      //   bannerText: Yup.string().required("Please select Brand "),
      //   category: Yup.array().min(1, "Please select Category"),
      //   size: Yup.string().required("Please select the Size"),
      //   itemPrice: Yup.string()
      //     .required("Please enter Item Price")
      //     .typeError("Please enter numeric value (0,1,2,...)"),
      //   condition: Yup.string().required("Please enter Item Condition"),
      //   quantity: Yup.number().min(1).required("Please enter valid Quantity"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);

      let token = localStorage.getItem("clttoken");

      console.log(
        editorCont.plugins._plugins.get("SourceEditing").isSourceEditingMode
      );
      if (
        editorCont.plugins._plugins.get("SourceEditing").isSourceEditingMode
      ) {
        toast.error(
          "Please click on Source again to go back to normal editing mode and then submit",
          toastOption
        );
        return false;
      }

      //   const images = [];
      if (images.length == 0) {
        toast.error("Please upload the Page Banner Image", toastOption);
        return false;
      }
      setSpinner(true);

      const uploadImageToCloudinary = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const dataUrl = reader.result;
            const formData = new FormData();
            formData.append("file", dataUrl);
            formData.append("upload_preset", "closet-closest");
            formData.append("cloud_name", "closet-closest");
            formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
            formData.append("signature", signature);
            formData.append("timestamp", timestamp);
            formData.append("api_key", "738997887579849");

            axios
              .post(
                "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
                formData,
                {
                  headers: { "X-Requested-With": "XMLHttpRequest" },
                }
              )
              .then((response) => {
                const { url } = response.data;
                resolve(url);
              })
              .catch((error) => {
                console.error("Error uploading image to Cloudinary:", error);
                reject(error);
              });
          };

          reader.readAsDataURL(blob);
        });
      };

      const replaceImages = async (htmlString) => {
        const regex = /<img.*?src="data:image\/(.*?);base64,([^"]+)".*?>/g;

        const matches = [];
        let match;
        while ((match = regex.exec(htmlString)) !== null) {
          const imageFormat = match[1];
          const base64Image = match[2];
          const blob = await convertBase64ToBlob(base64Image);
          const cloudinaryUrlPromise = uploadImageToCloudinary(blob)
            .then((cloudinaryUrl) => {
              htmlString = htmlString.replace(
                `data:image/${imageFormat};base64,${base64Image}`,
                cloudinaryUrl
              );
            })
            .catch((error) => {
              console.error("Error uploading and replacing image:", error);
            });
          matches.push(cloudinaryUrlPromise);
        }

        await Promise.all(matches);
        return htmlString;
      };

      const convertBase64ToBlob = (base64Image) => {
        return new Promise((resolve, reject) => {
          const byteCharacters = atob(base64Image);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/png" });
          resolve(blob);
        });
      };

      // Upload images and replace Base64 images with Cloudinary URLs
      const uploadAndReplaceImages = async () => {
        try {
          const updatedHTMLString = await replaceImages(cmsContent);
          console.log("Updated HTML string:", updatedHTMLString);
          postDetails(
            values,
            setSubmitting,
            setValues,
            token,
            updatedHTMLString
          );
        } catch (error) {
          console.error("Error uploading and replacing images:", error);
        }
      };

      uploadAndReplaceImages();
      //   postDetails(values, setSubmitting, setValues, token);

      return false;
      if (token) {
        if (colors.length <= 0 && !values.personalColor) {
          setColorError("Please Select Colors");
          return false;
        }
        setSpinner(true);
        setColorError("");
        postDetails(values, setSubmitting, setValues, token);
      } else {
        navigate("/");
      }

      return false;
    },
  });

  if (cmsMessage) {
    toast.success("Page Updated Successfully", toastOption);
    dispatch(resetCMS());
    navigate("/admin/cms");
  }

  const postDetails = (values, setSubmitting, setValues, token, cmsContent) => {
    let payload = { ...values, htmlContent: cmsContent };
    // console.log(colors);
    //return false;
    const uploaders = images.map(async (file, index) => {
      // Initial FormData
      if (typeof file !== "string") {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "closet-closest");
        formData.append("cloud_name", "closet-closest");
        formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
        formData.append("signature", signature);
        formData.append("timestamp", timestamp);
        formData.append("api_key", "738997887579849");

        return axios
          .post(
            "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
            formData,
            {
              headers: { "X-Requested-With": "XMLHttpRequest" },
            }
          )
          .then((response) => {
            const data = response.data;
            const fileURL = data.secure_url; // You should store this URL for future references in your app
            payload = { ...payload, bannerImage: fileURL };
          })
          .catch((e) => {
            console.log(e);
            throw new Error("Something went Wrong!!!!");
          });
      } else {
        payload = { ...payload, bannerImage: file };
      }
    });

    axios
      .all(uploaders)
      .then(() => {
        setValues(payload);
        dispatch(
          updateCMSContent({ token, values: payload, id: location.state.id })
        );
        setSubmitting(false);
      })
      .catch((e) => {
        toast.error("Something Went Wrong Please Try Again", toastOption);
        dispatch(
          addBrand({ token, values: { brandName }, requestType: "get" })
        );
      });
  };

  const removeImg = (id) => {
    if (feature === id) setFeature(0);
    let updatedImg = images.filter((img, index) => index !== id);
    setImages([...updatedImg]);
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImages([...e.dataTransfer.files]);
    }
  };

  return (
    <div className="closest_setup_container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <div className="closest_setup">
        <h1 className="section_title">Edit Page</h1>
        <br></br>
        <br></br>
        <form onSubmit={formik.handleSubmit}>
          <div className="closest_setup_form">
            {images.length > 0 ? (
              <div className="preview_image">
                <div className="item_title">
                  Image Preview - Please Select the Banner Photo
                </div>
                <div className="preview_image_container">
                  <div
                    className="img_name_cont"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      margin: "10px 0px",
                    }}
                  >
                    {images.map((image, index) => {
                      return (
                        <div className={`img_icon_cont`} key={index}>
                          <div className="img_name">
                            <div
                              className="cross"
                              onClick={() => removeImg(index)}
                            >
                              X
                            </div>
                            <img
                              className={`${
                                feature === index ? "feature" : ""
                              }`}
                              onClick={() => setFeature(index)}
                              src={
                                typeof image === "string"
                                  ? image
                                  : URL.createObjectURL(image)
                              }
                              alt={image.name}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="img_upload_container">
              <div className="file_icon_container">
                <div className="item_title">Take or Choose Photos</div>
                <label
                  htmlFor="closet_icon"
                  className={`file_upload ${dragActive ? "drag-active" : ""}`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="icon_container add_icon_container">
                    <i class="fas fa-regular fa-camera"></i>
                    <p className="item_title" style={{}}>
                      {" "}
                      or drag them in
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="closet_icon"
                  hidden
                  onChange={(e) => {
                    console.log(URL.createObjectURL(e.target.files[0]));
                    setImages([...e.target.files]);
                  }}
                />
              </div>
            </div>

            <br></br>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Page Title
              </label>
              <input
                type="text"
                id="pageTitle"
                name="pageTitle"
                onChange={formik.handleChange}
                value={formik.values.pageTitle}
              />
              {formik.touched.pageTitle && formik.errors.pageTitle ? (
                <small className="form-text text-danger">
                  {formik.errors.pageTitle}
                </small>
              ) : null}
            </div>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Page URL
              </label>
              <input
                type="text"
                id="pageURL"
                name="pageURL"
                onChange={formik.handleChange}
                value={formik.values.pageURL}
              />
              {formik.touched.pageURL && formik.errors.pageURL ? (
                <small className="form-text text-danger">
                  {formik.errors.pageURL}
                </small>
              ) : null}
            </div>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Banner Text
              </label>
              <input
                type="text"
                id="bannerText"
                name="bannerText"
                onChange={formik.handleChange}
                value={formik.values.bannerText}
              />
            </div>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Page Content
              </label>
              <CKEditor
                disableWatchdog={true}
                editor={Editor}
                config={editorConfiguration}
                data={cmsContent}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                  setEditorCont(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  console.log(editor.sourceEditing);
                  setCmsContent(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor, event);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor, event);
                }}
              />
            </div>

            <button
              className="btn dark-btn"
              type="submit"
              disabled={cmsStatus === "loading" ? true : false}
            >
              {cmsStatus === "loading" ? "Please Wait" : "EDIT PAGE"}
            </button>

            {/* <div className="personal_info_container">
              <div className="select_container">
                <p className="newBrand saveDraft">
                  <a href="javascript:void();">or Save Draft</a>
                </p>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const MultiValueContainer = (value) => {
  let ct =
    value.data && value.data["value"].slice(value.data["value"].length - 1);
  return (
    value.data && (
      <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
        <div>
          {ct === "M"
            ? "🧔‍♂️"
            : ct === "W"
            ? "👩"
            : ct === "B"
            ? "👦"
            : ct === "G"
            ? "👧"
            : "⭐️"}
        </div>
        <components.MultiValueContainer {...value} />
      </div>
    )
  );
};

export default EditCMS;
