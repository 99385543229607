import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PurchaseTableAccordion"; // Import your CSS file
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { HiOutlineChat } from "react-icons/hi";
import Pagination from "../Pagination/Pagination";

const OrderRow = ({ order, handleFulfill, checkoutId }) => {
  const [collapsed, setCollapsed] = useState(order.collapse);
  const [contentHeight, setContentHeight] = useState(0);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    window.scrollTo(0, 480);
  }, []);

  const contentRef = React.useRef(null);

  React.useLayoutEffect(() => {
    setContentHeight(contentRef.current.scrollHeight);
  }, [collapsed]);

  return (
    <>
      <tr
        className="order-row table-accordion"
        onClick={toggleCollapse}
        style={{ fontWeight: collapsed ? "normal" : 700, cursor: "pointer" }}
      >
        <th scope="row">{order.items[0].orderId}</th>

        <td>
          {new Date(order.items[0].checkoutTime).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) +
            " - " +
            new Date(order.items[0].checkoutTime).toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            })}
        </td>
        <td>
          {order.items.reduce((sum, item) => sum + parseInt(item.price), 0)}
        </td>
        <td style={{ cursor: "pointer", textAlign: "right" }}>
          {collapsed ? (
            <span>
              {order.items.length} &nbsp;{" "}
              <FaChevronRight
                style={{
                  paddingTop: "6px",
                  // position: "relative",
                  // left: "5rem",
                }}
              />
            </span>
          ) : (
            <span>
              {" "}
              {order.items.length} &nbsp;{" "}
              <FaChevronDown
                style={{
                  paddingTop: "6px",
                  // position: "relative",
                  // left: "5rem",
                }}
              />
            </span>
          )}
        </td>
      </tr>
      <tr className={`product-row ${collapsed ? "collapsed" : "no-collapsed"}`}>
        <td colSpan="4" style={{ padding: 0, border: "none" }}>
          <div
            ref={contentRef}
            style={{
              height: collapsed ? "0" : `${contentHeight}px`,
              transition: "height 0.3s ease-out",
              overflow: "hidden",
            }}
          >
            <table style={{ borderBottom: "none", width: "100%" }}>
              <thead>
                <tr
                  style={{
                    background: "var(--peel-orange-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                >
                  <th className=" white-text h6">Image</th>
                  <th className=" white-text h6">Product Name</th>
                  <th className=" white-text h6">Product Brand</th>
                  <th className=" white-text h6">Price</th>
                  <th className=" white-text h6">PaidBy</th>
                  <th className=" white-text h6">Chat</th>
                  <th className="h6 white-text">Action</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((product) => (
                  <tr
                    key={product.id}
                    className={product.read === false ? "blink-bg" : ""}
                  >
                    <td
                      className="h6 table-img"
                      style={{
                        width: "100px",
                        height: "120px",
                        transition: ".2s ease",
                        position:
                          window.innerWidth < 767 && !collapsed
                            ? "absolute"
                            : "inherit",
                      }}
                    >
                      <img
                        width={"100%"}
                        src={product.productImage[0]}
                        alt={product.name}
                      />
                    </td>
                    <td className="h6">
                      <Link
                        to={`/purchased/${encodeURIComponent(
                          product.brand
                        )}-${encodeURIComponent(product.name)}`}
                        state={{ id: product.id, pId: product.productId }}
                        style={{ color: "#F26654", lineBreak: "anywhere" }}
                      >
                        {product.name}
                      </Link>
                    </td>
                    <td className="h6">{product.brand}</td>
                    <td className="h6">{product.price}</td>
                    <td className="h6">{product.paidBy}</td>
                    <td className="h6 ">
                      <Link
                        to={`/chat-support`}
                        state={{ sellerEmail: product.userEmail }}
                      >
                        <HiOutlineChat color={"var(--primary-color)"} />
                      </Link>
                    </td>
                    <td className="h6 ">
                      <button
                        className="btn"
                        onClick={() =>
                          handleFulfill(product.productId, checkoutId)
                        }
                        disabled={product.fulfilledBuyer ? true : false}
                        style={
                          product.fulfilledBuyer
                            ? { padding: "6px 20px" }
                            : { padding: "6px 20px", background: "#f2998f" }
                        }
                      >
                        {product.fulfilledBuyer
                          ? "FULFILLED!"
                          : "Mark Fulfilled"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

const PurchaseTableAccordion = ({ orderInfo, handleFulfill }) => {
  const [thresholdPage, setThresholdPage] = useState(0);
  const [thresholdActive, setThresholdActive] = useState(true);
  //consolele.log(orderInfo);
  const groupedData = orderInfo.reduce((groups, item) => {
    const { checkoutId } = item;
    if (groups[checkoutId]) {
      groups[checkoutId].push(item);
    } else {
      groups[checkoutId] = [item];
    }
    return groups;
  }, {});

  //consolele.log(groupedData);

  // Convert the grouped data into an array of objects
  const result = Object.keys(groupedData).map((checkoutId) => {
    const items = groupedData[checkoutId];
    const collapse = !items.some((item) => item.read === false);
    return {
      checkoutId,
      collapse,
      items,
    };
  });

  //consolele.log(result);

  return (
    <>
      <table className="responsive-table">
        <thead>
          <tr>
            <th scope="col" style={{ minWidth: "200px" }}>
              Order ID
            </th>
            <th scope="col" style={{ minWidth: "300px" }}>
              Date - Time
            </th>
            <th scope="col" style={{ minWidth: "200px" }}>
              Total Price
            </th>
            <th scope="col" style={{ textAlign: "right" }}>
              Total Products
            </th>
          </tr>
        </thead>
        <tbody>
          {result.map((order) => (
            <OrderRow
              key={order.checkoutId}
              order={order}
              handleFulfill={handleFulfill}
              checkoutId={order.checkoutId}
            />
          ))}
        </tbody>
      </table>
      <Pagination
        pageCount={Math.ceil(result.length / 10)}
        pageNumber={thresholdPage}
        setPageNumber={setThresholdPage}
        // setProductInfo={setOrderDetails}
        thresholdActive={thresholdActive}
      />
    </>
  );
};

export default PurchaseTableAccordion;
