import React, { useState, useRef, useEffect } from "react";
import "./UploadImage.css";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

const UploadImage = () => {
  const [isUseCamera, setUseCamera] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [result, setResult] = useState(null);
  const refPhoto = useRef(null);
  const refCanvas = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 300, height: 100 });
  const [imageSize, setImageSize] = useState({ width: 300, height: 100 });

  const handleTakePhoto = async (dataUri) => {
    if (dataUri) {
      setPhoto(dataUri);
      // const imageSize = await getImageSize(dataUri);
      setImageSize(imageSize);
      setUseCamera(false);
    }
  };

  const handleResetPhoto = () => {
    setPhoto(false);
    setResult(null);
    const canvas = refCanvas.current;
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    setPhoto(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0]);
  };

  useEffect(() => {
    if (refPhoto?.current) {
      const { offsetWidth, offsetHeight } = refPhoto.current;
      setCanvasSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [photo]);

  return (
    <div className="upload-wrapper">
      {!isUseCamera && !photo && (
        <div className="big-screen-upload">
          <input
            type="file"
            id="fileUploadScreen"
            name="fileUploadScreen"
            onChange={(e) => handleFileChange(e)}
          />
        </div>
      )}
      <div className="mobile-screen-upload">
        <nav className="circular-menu">
          {!isUseCamera && !photo && (
            <>
              <input
                className="circular-menu__button"
                id="circular-menu"
                type="checkbox"
                defaultChecked="checked"
              />
              <label className="circular-menu__icon" htmlFor="circular-menu">
                <div className="hamburger hamburger-bar"></div>
                <div className="hamburger hamburger-bar"></div>
                <div className="hamburger hamburger-bar"></div>
              </label>
            </>
          )}
          {!photo &&
            (isUseCamera ? (
              <>
                <Camera
                  onTakePhoto={(dataUri) => {
                    handleTakePhoto(dataUri);
                  }}
                  isMaxResolution={true}
                  sizeFactor={1}
                />
                <div
                  className="camera-close-btn"
                  onClick={() => setUseCamera(false)}
                >
                  {" "}
                  Close
                </div>
              </>
            ) : (
              <>
                <label className="circular-menu__item" htmlFor="fileUpload">
                  <i className="fa fa-file"></i>
                </label>
                <input
                  type="file"
                  id="fileUpload"
                  name="fileUpload"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                />

                <label
                  onClick={() => setUseCamera(true)}
                  className="circular-menu__item"
                >
                  <i className="fa fa-camera"></i>
                </label>
              </>
            ))}
        </nav>
      </div>

      {/* PREVIEW PANE */}
      {photo && (
        <div className="preview-pane">
          <h3 style={{ textAlign: "center" }}>Preview-Pane</h3>
          <img
            ref={refPhoto}
            src={photo}
            width="300"
            alt="preview-panel-photo"
          />
          {photo && (
            <button
              onClick={handleResetPhoto}
              class="btn"
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                marginTop: ".5rem",
              }}
            >
              Change Photo
            </button>
          )}
          {isUseCamera && (
            <button
              onClick={() => setUseCamera(false)}
              class="btn"
              style={{
                textAlign: "center",
                display: "flex",
                margin: "auto",
                marginTop: ".5rem",
              }}
            >
              Cancel Camera
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadImage;
