import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  shippingStatus: "idle",
  shippingInfo: null,
  shippingError: null,
  salesTax:null,
}
export const getShippingPrice = createAsyncThunk(
  "shipping/price",
  async ({shippingData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/checkout/shippingprice",
        shippingData,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getSalesTax = createAsyncThunk(
  "shipping/tax",
  async ({shippingData,token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/tax/${id}`,
        shippingData,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const shippingPriceSlice = createSlice({
  name: "shippingprice",
  initialState,
  reducers: {
    shippingReset(state,action){
      state.shippingError=null
      state.shippingInfo=null
      state.salesTax = null
    },
    taxReset(state,action){
      state.shippingError=null
      state.salesTax = null
    }
  },
  extraReducers: {
    [getShippingPrice.pending]: (state, action) => {
      state.shippingStatus = "loading"
    },
    [getShippingPrice.fulfilled]: (state, action) => {
      state.shippingStatus = "succeeded"
      state.shippingInfo = action.payload.info
      state.salesTax = action.payload.tax
    },
    [getShippingPrice.rejected]: (state, action) => {
      state.shippingError = "failed"
      state.shippingInfo=[];
      state.error = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
    [getSalesTax.pending]: (state, action) => {
      state.shippingStatus = "loading"
    },
    [getSalesTax.fulfilled]: (state, action) => {
      state.shippingStatus = "succeeded"
      // state.shippingInfo = action.payload.info
      state.salesTax = action.payload.tax
    },
    [getSalesTax.rejected]: (state, action) => {
      state.shippingError = "failed"
      // state.shippingInfo=[];
      state.error = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { shippingReset, taxReset } = shippingPriceSlice.actions

export default shippingPriceSlice.reducer 