import React from "react";
import "./PageLocation.css";
import { Link, useLocation } from "react-router-dom";

const PageLocation = ({ productDetails }) => {
  const location = useLocation();
  const breadcrumbList = [
    { description: "Home", url: "/" },
    { description: "Shop", url: "/shop" },
    {
      description: productDetails?.productName,
      url: window.location.pathname + "?product=" + productDetails._id,
    },
  ];
  return (
    <>
      <ol
        style={{ listStyle: "none", display: "flex", padding: 0 }}
        itemScope={true}
        itemType="http://schema.org/BreadcrumbList"
        className="breadcrumbs-container"
      >
        {breadcrumbList.map((breadcrumb, i) => (
          <>
            <li
              className="page_location"
              key={i}
              itemProp="itemListElement"
              itemScope={true}
              itemType="http://schema.org/ListItem"
            >
              <a
                href={breadcrumb.url}
                itemScope={true}
                itemType="http://schema.org/Thing"
                itemProp="item"
                itemID={breadcrumb.url}
              >
                <span itemProp="name">{breadcrumb.description}</span>
              </a>
              <meta itemProp="position" content={i.toString()} />
            </li>
            {i < breadcrumbList.length - 1 ? <span>&nbsp;&gt;&nbsp;</span> : ""}
          </>
        ))}
      </ol>
    </>
  );
};

export default PageLocation;
