import React, { useEffect } from "react";
import "./PricingTable.css";
import Spinner from "../../assets/images/spinner.gif";
import { Link, useNavigate } from "react-router-dom";
const PricingTable = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      navigate("/user/membership");
    } else {
      navigate("/signup");
    }
  };
  return (
    <>
      <br />
      <h2
        className="large-text bold-text text-center background-text"
        style={{ margin: "1rem" }}
      >
        Less Money, More Choices, Better Wardrobe
      </h2>
      <div className="pricing-background" style={{ marginTop: "2rem" }}>
        <div className="container">
          {/* <div className="panel "> */}
          <div className="pricing-plan">
            <div style={{ textAlign: "center" }}>
              <img src={Spinner} alt="spinner" className="pricing-img" />
            </div>

            <h3
              className="pricing-header h3 grey-text header-1"
              style={{ marginBottom: "3rem" }}
            >
              Guest
            </h3>
            {/* <br /> */}
            {/* <small>&nbsp;</small> */}
            <ul className="pricing-features" style={{ marginTop: "1rem" }}>
              <li className="pricing-features-item h6 bold-text">
                Shop non member exclusive items
              </li>
              <li className="pricing-features-item h6 bold-text">
                Buy hanger credits with cash at checkout at full price
              </li>
            </ul>
            <span
              className="pricing-price h6"
              style={{ textTransform: "capitalize", fontSize: "24px" }}
            >
              Limited Access
            </span>
            <button className="pricing-button">
              <Link to={"/shop"}>Shop Now</Link>
            </button>
          </div>
          <div className="pricing-plan featured">
            <div style={{ textAlign: "center" }}>
              <img src={Spinner} alt="spinner-2" className="pricing-img" />
            </div>
            <h3 className="pricing-header h3 grey-text">Monthly Membership</h3>
            <small>RECOMMENDED</small>
            <ul className="pricing-features">
              <li className="pricing-features-item h6 bold-text">
                Unlimited transactions, no fee per item
              </li>
              <li className="pricing-features-item h6 bold-text">
                Earn hanger credits for every item in your closest
              </li>
              <li className="pricing-features-item h6 bold-text">
                Shop other closets with your hanger credits
              </li>
              <li className="pricing-features-item h6 bold-text">
                Buyers pay for shipping <br />
                (local pickup is encouraged)
              </li>
              <li className="pricing-features-item h6 bold-text">
                Discounted price on hanger credits if you are short
              </li>
            </ul>
            <div className="pricing-price">
              $20 <span className="h6 bold-text grey-text">/&nbsp;Month</span>{" "}
            </div>
            <button
              onClick={handleNavigation}
              className="pricing-button is-featured"
            >
              Join Now
            </button>
          </div>

          <div className="pricing-plan">
            <div style={{ textAlign: "center" }}>
              <img src={Spinner} alt="spinner-3" className="pricing-img" />
            </div>
            <h3 className="pricing-header h3 grey-text">Yearly Membership</h3>

            <small
              style={{
                background: "var(--primary-color)",
                padding: ".5rem 1rem",
                color: "var(--white-color)",
                borderRadius: "5px",
              }}
            >
              Save 33&nbsp;%
            </small>
            <br />
            <ul className="pricing-features">
              <li className="pricing-features-item h6 bold-text">
                Unlimited transactions, no fee per item
              </li>
              <li className="pricing-features-item h6 bold-text">
                Earn hanger credits for every item in your closest
              </li>
              <li className="pricing-features-item h6 bold-text">
                Shop other closets with your hanger credits
              </li>
              <li className="pricing-features-item h6 bold-text">
                Buyers pay for shipping
                <br /> (local pickup is encouraged)
              </li>
              <li className="pricing-features-item h6 bold-text">
                Discounted price on hanger credits if you are short
              </li>
            </ul>
            <div className="pricing-price">
              $160 <span className="h6 bold-text grey-text">/&nbsp;Year</span>{" "}
            </div>
            <button onClick={handleNavigation} className="pricing-button">
              Join Now
            </button>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default PricingTable;
