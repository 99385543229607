import React from "react";
import "./MyClosetSidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "../StateSlices/getuserSlice";
const MyClosetSidebar = ({ getUrl }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="my-closet-sidebar-con">
      <div className="my-closet-sidebar-wrapper">
        <ul className="h6">
          <Link to={"account"}>
            <li
              className={`${
                location.pathname.indexOf("account") > -1 ? "active-link" : ""
              }`}
            >
              My Profile
            </li>
          </Link>
          <Link to={"my-closet"}>
            <li
              className={`${
                location.pathname.indexOf("my-closet") > -1 ? "active-link" : ""
              }`}
            >
              My Closet
            </li>
          </Link>
          <Link to={"hanger"}>
            <li
              className={`${
                location.pathname.indexOf("hanger") > -1 ? "active-link" : ""
              }`}
            >
              Hanger
            </li>
          </Link>
          <Link to={"purchases"}>
            <li
              className={`${
                location.pathname.indexOf("purchases") > -1 ? "active-link" : ""
              }`}
            >
              My Purchases
            </li>
          </Link>
          <Link to={"orders"}>
            <li
              className={`${
                location.pathname.indexOf("orders") > -1 ? "active-link" : ""
              }`}
            >
              My Orders
            </li>
          </Link>
          <Link to={"membership"}>
            <li
              className={`${
                location.pathname.indexOf("membership") > -1
                  ? "active-link"
                  : ""
              }`}
            >
              My Membership
            </li>
          </Link>
          <Link to={"edit-profile"}>
            <li
              className={`${
                location.pathname.indexOf("edit-profile") > -1
                  ? "active-link"
                  : ""
              }`}
            >
              Edit My Closet
            </li>
          </Link>
          <>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => getUrl()}
              className={`${
                location.pathname.indexOf("referrals") > -1 ? "active-link" : ""
              }`}
            >
              Referral Link
            </li>
          </>

          <li
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: "var(--primary-color)",
            }}
            onClick={() => {
              localStorage.removeItem("clttoken");
              dispatch(reset());
              navigate("/", { state: { stopAudio: "value" } });
            }}
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyClosetSidebar;
