import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  likeStatus: "idle",
  likeMessage: "",
  likeError: null,
}

export const handleLike = createAsyncThunk(
  "like/handleLike",
  async ({id,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/handlelike/${id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const handleLikeSlice = createSlice({
  name: "like",
  initialState,
  reducers: {
    resetLikeMessage(state,action){
        state.likeMessage = ""
        state.likeError=null
        state.likeStatus="idle"
      }
  },
  extraReducers: {
    [handleLike.pending]: (state, action) => {
      state.likeStatus = "loading"
    },
    [handleLike.fulfilled]: (state, action) => {
      state.likeStatus = "succeeded"
      state.likeMessage = action.payload.message
    },
    [handleLike.rejected]: (state, action) => {
      state.likeStatus = "failed"
      state.likeError = action.payload.message
    },
  },
})

export const { resetLikeMessage } = handleLikeSlice.actions
export default handleLikeSlice.reducer 