import React from "react";

const ChatCardCustom = ({
  activeChat,
  chats,
  userInfo,
  setActiveChat,
  renderChatHeader,
}) => {
  if (chats) {
    var chatss = Object.values(chats);
  }
  //   //console.log(activeChat);
  return (
    <>
      <div
        class="ce-chat-list-column"
        style={{
          boxSizing: "border-box",
          minHeight: "1px",
          position: "relative",
          paddingLeft: "0px",
          paddingRight: "0px",
          width: "25%",
          flex: "0 0 25%",
          maxWidth: "25%",
          marginLeft: "0%",
          right: "auto",
          left: "auto",
          height: "80vh",
        }}
      >
        <div
          class="ce-chat-list"
          style={{
            height: "100%",
            maxHeight: "100vh",
            overflow: "hidden scroll",
            borderRight: "1px solid rgb(175, 175, 175)",
            backgroundColor: "white",
          }}
        >
          <div
            class="ce-chats-container"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              borderRadius: "0px 0px 24px 24px",
            }}
          >
            <div>
              <div
                class="ce-chat-form-container"
                style={{
                  padding: "16px 14px",
                  backgroundColor: "white",
                  marginLeft: "0px",
                }}
              >
                <div>
                  <div
                    style={{
                      height: "0px",
                      background: "var(--primary-color)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        position: "relative",
                        top: "4px",
                        width: "calc(100% - 48px)",
                        background: "var(--primary-color)",
                      }}
                    >
                      My Chats
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {chatss &&
              chatss.map((chat, index) => {
                if (index == 3)
                  //console.log(chat);
                  var otherPerson = chat.people.find(
                    (person) =>
                      person.person.username !==
                      userInfo.userDetailsId.userEmail
                  );
                if (chat.last_message.created !== "") {
                  var lastMsgDate = new Date(
                    chat.last_message.created
                  ).getDate();
                  var lastMsgMonth = new Date(
                    chat.last_message.created
                  ).toLocaleString("default", { month: "short" });
                  // //console.log(lastMsgDate);
                }
                return (
                  <div>
                    <span
                      style={{
                        display: "inline-block",
                        backfaceVisibility: "hidden",
                        transform: "translate(0px, 0px) rotate(0deg) scale(1)",
                        width: "-webkit-fill-available",
                        background: `${
                          activeChat === chat.id
                            ? "rgb(217,217,217) !important"
                            : "#fff7f7 !important"
                        }`,
                      }}
                      onClick={() => {
                        setActiveChat(chat.id);
                        renderChatHeader(
                          otherPerson.person.first_name +
                            "_" +
                            otherPerson.person.last_name
                        );
                      }}
                    >
                      <div
                        class={`ce-chat-card ${
                          chat.id === activeChat
                            ? "ce-active-chat-card"
                            : "false"
                        }`}
                        style={{
                          padding: "16px 16px 12px",
                          cursor: "pointer",
                          backgroundColor: `${
                            activeChat === chat.id
                              ? "rgb(217,217,217) !important"
                              : "#fff !important"
                          }`,
                          border: "0px solid white",
                        }}
                      >
                        <div
                          class="ce-chat-title-text"
                          id={`ce-chat-card-title-${otherPerson.person.username}`}
                          style={{
                            fontWeight: "500",
                            paddingBottom: "4px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            background: `${
                              activeChat === chat.id
                                ? "rgb(217,217,217) !important"
                                : "#fff !important"
                            }`,
                          }}
                        >
                          <div
                            style={{
                              overflowX: "hidden",
                              display: "inline-block",
                            }}
                          >
                            {otherPerson.person.first_name +
                              "_" +
                              otherPerson.person.last_name}
                          </div>
                        </div>
                        <div class="ce-chat-subtitle" style={{ width: "100%" }}>
                          <div
                            class="ce-chat-subtitle-text ce-chat-subtitle-message"
                            style={{
                              width: "75%",
                              color: " rgb(153, 153, 153)",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              display: "inline-block",
                            }}
                          >
                            {chat.last_message && chat.last_message.text !== ""
                              ? chat.last_message.text.replace(/<[^>]+>/g, "")
                              : ""}
                          </div>
                          <div
                            class="ce-chat-subtitle-text ce-chat-subtitle-date"
                            style={{
                              width: "25%",
                              color: "rgb(153, 153, 153)",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              display: "inline-block",
                              textAlign: "right",
                            }}
                          >
                            {chat.last_message.created !== ""
                              ? lastMsgDate + " " + lastMsgMonth
                              : ""}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
    // <>
    //   {chats &&

    //     Object.keys(chats).map((chat) => {
    //       //console.log(chats[chat]);

    //       var otherPerson = chats[chat].people.find(function (person) {
    //         // //console.log(person.person.username);
    //         if (person.person.username) {
    //           return (
    //             userInfo.userDetailsId.userEmail !== person.person.username
    //           );
    //         }
    //       });

    //       //console.log(otherPerson);

    //   return (
    //     <div>
    //       <span
    //         style={{
    //           display: "inline-block",
    //           backfaceVisibility: "hidden",
    //           transform: "translate(0px, 0px) rotate(0deg) scale(1)",
    //           width: "-webkit-fill-available",
    //         }}
    //       >
    //         <div
    //           class="ce-chat-card ce-active-chat-card"
    //           style={{
    //             padding: "16px 16px 12px",
    //             cursor: "pointer",
    //             backgroundColor: "rgb(217, 217, 217)",
    //             border: "0px solid white",
    //             borderRadius: "12px",
    //           }}
    //         >
    //           <div
    //             class="ce-chat-title-text"
    //             id={`ce-chat-card-title-${otherPerson.username}`}
    //             style={{
    //               fontWeight: "500",
    //               paddingBottom: "4px",
    //               whiteSpace: "nowrap",
    //               overflow: "hidden",
    //             }}
    //           >
    //             <div
    //               style={{ overflowX: "hidden", display: "inline-block" }}
    //             >
    //               {otherPerson.first_name + "_" + otherPerson.last_name}
    //             </div>
    //           </div>
    //           <div class="ce-chat-subtitle" style={{ width: "100%" }}>
    //             <div
    //               class="ce-chat-subtitle-text ce-chat-subtitle-message"
    //               style={{
    //                 width: "75%",
    //                 color: " rgb(153, 153, 153)",
    //                 fontSize: "14px",
    //                 whiteSpace: "nowrap",
    //                 overflow: "hidden",
    //                 display: "inline-block",
    //               }}
    //             >
    //               Okay
    //             </div>
    //             <div
    //               class="ce-chat-subtitle-text ce-chat-subtitle-date"
    //               style={{
    //                 width: "25%",
    //                 color: "rgb(153, 153, 153)",
    //                 fontSize: "14px",
    //                 whiteSpace: "nowrap",
    //                 overflow: "hidden",
    //                 display: "inline-block",
    //                 textAlign: "right",
    //               }}
    //             >
    //               Feb 22
    //             </div>
    //           </div>
    //         </div>
    //       </span>
    //     </div>
    //   );
    //     })}
    // </>
  );
};

export default ChatCardCustom;
