import React, { useState } from "react";
import hanger from "../../assets/images/hanger.svg";
import "./ProductDescription.css";
import productLogo from "../../assets/images/GAP_Logo.png";
import { Link } from "react-router-dom";
import { FaShoppingBag } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import { BsChatDotsFill, BsHeart, BsShareFill } from "react-icons/bs";
import { GoReport } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { productreport, resetReport } from "../StateSlices/productReportSlice";
import StarRating from "../StarRating/StarRating";
import swal from "sweetalert";
import { PiCoatHangerFill } from "react-icons/pi";

const ProductDescription = ({
  productInfo,
  userInfo,
  pid,
  AddToCart,
  setCartVal,
  cartVal,
  toast,
  toastOption,
  closetInfo,
}) => {
  //consolele.log(productInfo, closetInfo);
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const [rating, setRating] = useState(
    productInfo?.productRating?.find(
      (ratingObj) => ratingObj.userId === userInfo?._id
    )?.rating || 0
  );

  const [isReported, setIsReported] = useState(
    productInfo?.productReported?.indexOf(userInfo?._id) == -1 ? false : true
  );

  const avgRating =
    productInfo?.productRating?.reduce(
      (sum, ratingObj) => sum + ratingObj.rating,
      0
    ) / productInfo?.productRating?.length;

  const { reportInfo, reportStatus, reportError } = useSelector(
    (state) => state.productReport
  );

  const dispatch = useDispatch();
  const addtoSessionCart = () => {
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    cart.push(pid);
    sessionStorage.setItem("closetcart", JSON.stringify(cart));
    setCartVal(cart);
  };

  const reportItem = () => {
    swal({
      title: isReported ? "Unreport Item" : "Report Item!!!",
      text: isReported
        ? "Do you want to withdraw the report?"
        : "Do you want to report this Item to Admin?",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //consolele.log(`Please Report it. ${productInfo._id}`);
        let token = localStorage.getItem("clttoken");
        // setLoading(true);
        if (token) {
          dispatch(productreport({ id: productInfo._id, token: token }));
        }
      }
    });
    // if (isReported) {
    //   {
    //     toast.success("Product Unreported Successfully!", toastOption);
    //   }
    // } else {
    //   {
    //     toast.success("Product Reported Successfully!", toastOption);
    //   }
    // }
  };

  if (reportInfo) {
    if (reportInfo === "Item Reported") {
      setIsReported(true);
      toast.success("Product Reported Successfully!", toastOption);
    } else {
      setIsReported(false);
      toast.success("Product Unreported Successfully!", toastOption);
    }
    dispatch(resetReport());
  }

  return (
    productInfo && (
      <article className="product_description_container">
        <header>
          <h1
            className="product_title h3 bold-text"
            style={{ fontSize: "26px", textAlign: "left" }}
          >
            {productInfo.productName}{" "}
            {(new Date().getTime() -
              new Date(productInfo.createdAt).getTime()) /
              (1000 * 3600 * 24) <=
              30 && (
              <>
                <br />
                <span className="members-only-tag small-text">
                  Members Only
                </span>
              </>
            )}
          </h1>
        </header>
        <div className="brand">
          {/* <img
            style={{ height: "30px" }}
            src={productLogo}
            alt="product icon"
          /> */}
          {/* <a href="https://clearbit.com">Logos provided by Clearbit</a> */}
        </div>
        <section className="product_meta_container" style={{ padding: 0 }}>
          <div className="product_meta_data">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: ".3rem" }}>
                {" "}
                <PiCoatHangerFill size={"1.8em"} color="var(--primary-color)" />
              </div>
              <div className="product_price">&nbsp;x&nbsp;</div>
              <div className="product_price">{productInfo.productHanger} </div>
            </div>

            <h2 className="h4 grey-text" style={{ marginBottom: "1rem" }}>
              {productInfo.productDesc}
            </h2>
            <dl className="product_info h6">
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  <strong className="background-text">Size: &nbsp;</strong>
                </dt>
                <dd>
                  {productInfo.productStats && productInfo.productStats.size}
                </dd>
              </div>

              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">Category: &nbsp;</strong>
                </dt>
                <dd>
                  {productInfo.productStats &&
                    productInfo.productStats.category
                      .toString()
                      .replaceAll(",", ", ")}
                </dd>
              </div>
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">Brand: &nbsp;</strong>
                </dt>
                <dd>
                  {productInfo.productStats && productInfo.productStats.brand}
                </dd>
              </div>
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">Colors: &nbsp;</strong>
                </dt>
                <dd style={{ textTransform: "capitalize" }}>
                  {" "}
                  {productInfo.productStats &&
                    productInfo.productStats.color.join(", ")}
                </dd>
              </div>
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">
                    Original Price: &nbsp;
                  </strong>
                </dt>
                <dd> {productInfo.productPrice}</dd>
              </div>
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">
                    Current Condition: &nbsp;
                  </strong>
                </dt>
                <dd>
                  {productInfo.productStats &&
                    productInfo.productStats.condition}
                </dd>
              </div>
              <div className="product_info h6" style={{ display: "flex" }}>
                <dt>
                  {" "}
                  <strong className="background-text">
                    Date Posted: &nbsp;
                  </strong>
                </dt>
                <dd>
                  {
                    (new Date(productInfo.createdAt).getMonth() + 1)
                      .toString()
                      .padStart(2, "0") +
                      "-" +
                      new Date(productInfo.createdAt)
                        .getDate()
                        .toString()
                        .padStart(2, "0") +
                      "-" +
                      new Date(productInfo.createdAt).getFullYear()
                    //+ "-" +
                    // new Date(productInfo.createdAt).toLocaleTimeString()
                  }
                </dd>
              </div>
            </dl>
            {/* <div className="product_description h6">
              {productInfo.productDesc}
            </div>
            <div className="product_info h6">
              <div>
                <strong>Size:</strong>{" "}
                {productInfo.productStats && productInfo.productStats.size}
              </div>
              <div>
                <strong>Category:</strong>{" "}
                {productInfo.productStats &&
                  productInfo.productStats.category
                    .toString()
                    .replaceAll(",", ", ")}
              </div>
              <div>
                <strong>Brand:</strong>{" "}
                {productInfo.productStats && productInfo.productStats.brand}
              </div>
              <div>
                <strong>Colors:</strong>{" "}
                {productInfo.productStats &&
                  productInfo.productStats.color.join(", ")}
              </div>
              <div>
                <strong>Original Price: </strong> &#36;
                {productInfo.productPrice}
              </div>
              <div>
                <strong>Current Condition:</strong>{" "}
                {productInfo.productStats && productInfo.productStats.condition}
              </div>
              <div>
                <strong>Date Posted:</strong>{" "}
                {
                  new Date(productInfo.createdAt).toLocaleDateString("en-GB")
                  //+ "-" +
                  // new Date(productInfo.createdAt).toLocaleTimeString()
                }
              </div>
              
            </div> */}
          </div>
          <div className="product_actions">
            {userInfo &&
              userInfo.role === "admin" &&
              userInfo.totalProduct.some((el) => el == pid) && (
                <div>
                  {userInfo.userProductsId.cartId.some(
                    (cid) => cid === productInfo._id
                  ) ? (
                    <Link to={"/checkout"}>
                      <button className="btn">
                        Go to Shopping Bag &nbsp; <FaShoppingBag />
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="btn h4"
                      onClick={() => {
                        AddToCart(productInfo._id);
                      }}
                      style={{ width: "100%" }}
                    >
                      Add to bag &nbsp;
                      <FaShoppingBag />
                    </button>
                  )}
                </div>
              )}

            {userInfo &&
            userInfo.userProductsId &&
            !userInfo.totalProduct.some((el) => el == pid) ? (
              <>
                {userInfo.userProductsId.cartId.some(
                  (cid) => cid === productInfo._id
                ) ? (
                  <Link to={"/checkout"}>
                    <button className="btn">
                      Go to Shopping Bag &nbsp; <FaShoppingBag />
                    </button>
                  </Link>
                ) : (
                  <button
                    className="btn h4"
                    onClick={() => {
                      // //consolele.log(
                      //   new Date(new Date().setDate(new Date().getDate() + 30))
                      // );
                      //consolele.log(new Date(productInfo.createdAt));
                      if (
                        new Date().getTime() <=
                        new Date(
                          new Date(productInfo.createdAt).setDate(
                            new Date(productInfo.createdAt).getDate() + 30
                          )
                        ).getTime()
                      ) {
                        if (
                          !userInfo ||
                          new Date(
                            userInfo.userDetailsId.membershipExpiration
                          ).getTime() <= new Date().getTime()
                        ) {
                          toast.error(
                            "You need to be a member to add this item to your cart",
                            toastOption
                          );
                          return false;
                        }
                      }
                      AddToCart(productInfo._id);
                    }}
                    style={{ width: "100%" }}
                  >
                    Add to bag &nbsp;
                    <FaShoppingBag />
                  </button>
                )}

                {/* <div
                  style={{ cursor: "no-drop", color: "grey" }}
                  title="Coming Soon"
                  onClick={(event) => event.preventDefault()}
                >
                  <i className="fas fa-heart"></i> Like & Save for Later
                </div>
                <div
                  style={{ cursor: "no-drop", color: "grey" }}
                  title="Coming Soon"
                  onClick={(event) => event.preventDefault()}
                >
                  <i className="fas fa-share-alt"></i> Share with friends
                </div>
                <div
                  style={{ cursor: "no-drop", color: "grey" }}
                  title="Coming Soon"
                  onClick={(event) => event.preventDefault()}
                >
                  <i className="fas fa-question-circle"></i> Ask a question to
                  owner
                </div> */}
                {/* <br />
                <br /> */}
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {/* <div className="product-chat-btn" title="Chat with Owner">
                    <BsHeart size={"1.5em"} />
                  </div> */}
                  <div className="product-chat-btn" title="Chat with Owner">
                    <Link
                      to={"/chat-support"}
                      state={{
                        sellerEmail: closetInfo && closetInfo.userEmail,
                      }}
                    >
                      <BsChatDotsFill size={"1.5em"} />
                    </Link>
                  </div>
                  <div className="product-chat-btn" title="Share with Friends">
                    <BsShareFill
                      size="1.5em"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(window.location.href)
                          .then(() => {
                            toast.success(
                              "URL copied successfully!",
                              toastOption
                            );
                          })
                          .catch((err) => {
                            toast.error("Failed to copy URL", toastOption);
                          });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid var(--primary-color)",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <small className="h5 background-text">Rate Product </small>
                  <br />
                  <StarRating
                    rating={rating}
                    onRatingChange={handleRatingChange}
                    productInfo={productInfo}
                  />

                  {/* <h6
                    style={{
                      textAlign: "right",
                      marginRight: "2rem",
                      marginTop: "0px",
                    }}
                  >
                    {avgRating ? avgRating : 0} (
                    <span>{productInfo.productRating?.length}</span>)
                  </h6> */}
                </div>

                <button
                  style={
                    reportStatus === "loading"
                      ? {
                          fontSize: "14px",
                          cursor: "not-allowed",
                          width: "100%",
                        }
                      : {
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "100%",
                        }
                  }
                  disabled={reportStatus === "loading" ? true : false}
                  className="h4 btn product-report-btn"
                  title="Report an item for taking any action against the seller"
                  onClick={(event) => {
                    reportItem();
                  }}
                >
                  {reportStatus === "loading" ? (
                    <>
                      <span>
                        {/* <GoReport
                        size={"1rem"}
                        style={{ position: "relative", top: "5px" }}
                      />{" "} */}
                        {isReported ? "Unreporting..." : "Reporting..."}
                      </span>

                      <MdReport size={"1.2em"} style={{ display: "block" }} />
                    </>
                  ) : isReported ? (
                    <>
                      <span>
                        {/* <GoReport
                        size={"1rem"}
                        style={{ position: "relative", top: "5px" }}
                      />{" "} */}
                        Unreport
                      </span>
                      <MdReport size={"1.2em"} style={{ display: "block" }} />
                    </>
                  ) : (
                    <>
                      <span>
                        {/* <GoReport
                        size={"1rem"}
                        style={{ position: "relative", top: "5px" }}
                      />{" "} */}
                        Report an Item
                      </span>

                      <MdReport size={"1.2em"} style={{ display: "block" }} />
                    </>
                  )}
                </button>
              </>
            ) : userInfo &&
              userInfo.userProductsId &&
              userInfo.totalProduct.some((el) => el == pid) ? (
              <button className="btn">
                <Link
                  to={`/editproduct/${encodeURIComponent(
                    productInfo.productStats && productInfo.productStats.brand
                  )}-${encodeURIComponent(productInfo.productName)}`}
                  state={{ id: pid }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Edit Item
                </Link>
              </button>
            ) : (
              <>
                {cartVal.includes(pid) ? (
                  <Link to={"/checkout"}>
                    <button className="btn h4">
                      Go to Shopping Bag &nbsp; <FaShoppingBag />
                    </button>
                  </Link>
                ) : (
                  <button
                    className="btn h4"
                    onClick={() => {
                      // //consolele.log(
                      //   new Date(new Date().setDate(new Date().getDate() + 30))
                      // );
                      //consolele.log(new Date(productInfo.createdAt));
                      if (
                        new Date().getTime() <=
                        new Date(
                          new Date(productInfo.createdAt).setDate(
                            new Date(productInfo.createdAt).getDate() + 30
                          )
                        ).getTime()
                      ) {
                        console.log(userInfo)
                        if (
                          !userInfo ||
                          new Date(
                            userInfo.userDetailsId.membershipExpiration
                          ).getTime() <= new Date().getTime()
                        ) {
                          toast.error(
                            "You need to be a member to add this item to your cart",
                            toastOption
                          );
                          return false;
                        }
                      }
                      addtoSessionCart();
                    }}
                    style={{ width: "100%" }}
                  >
                    Add to Bag &nbsp; <FaShoppingBag />
                  </button>
                )}
              </>
            )}
          </div>
        </section>
      </article>
    )
  );
};

export default ProductDescription;
