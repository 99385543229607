import React, { useEffect, useState } from "react";
import "./Hangers.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoClosetItem from "../assets/images/others_closet_empty.svg";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import { getOrders } from "../components/StateSlices/getOrderSlice";
import {
  getSoldProducts,
  seenSoldOrders,
  reseto,
  resetSold,
} from "../components/StateSlices/getSoldProductsSlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import OrderTable from "../components/OrderTable/OrderTable";
import Collapse, { Panel } from "rc-collapse";
import OrderTableAccordion from "../components/OrderTable/OrderTableAccordion";
import {
  fulfilled,
  resetFulfill,
} from "../components/StateSlices/fulfillSlice";
import { Helmet } from "react-helmet";

const Orders = () => {
  const [orderHistory, setOrderHistory] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(false);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { fulFillError, fulFillStatus, fulFillInfo } = useSelector(
    (state) => state.fulfilled
  );
  const { orderstatus, orderInfo, orderHistoryStatusMessage } = useSelector(
    (state) => state.soldorders
  );
  const [orderDetails, setOrderDetails] = useState([]);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = window.location.href;

    document.head.appendChild(canonicalLink);
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getSoldProducts({ token }));
      window.scrollTo(0, 480);
      setSpinner(true);
    } else {
      navigate("/login", { replace: true });
    }
    return () => {
      dispatch(resetSold());
    };
  }, []);

  const handleFulfill = (pId, cId) => {
    swal({
      title: "Mark this item Fulfilled?",
      text: "Are you sure you have dispatched this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(fulfilled({ token, pId, cId, userType: "fulfilledSeller" }));
        setSpinner(true);
      }
    });
  };

  useEffect(() => {
    if (fulFillInfo === "fulfilled") {
      toast.success("Item Sucessfully marked as Fulfilled!", toastOption);
      dispatch(resetFulfill());
      let token = localStorage.getItem("clttoken");
      dispatch(getSoldProducts({ token }));
    }
  }, [fulFillInfo]);

  useEffect(() => {
    if (userInfo && orderInfo.length > 0 && orderstatus === "succeeded") {
      // setOrderHistory([...purchaseproduct])
      console.log(orderInfo);
      setOrderDetails(orderInfo);
      dispatch(resetSold());
      setSpinner(false);
    } else if (orderInfo.length === 0 && orderstatus === "succeeded") {
      setSpinner(false);
    }
  }, [orderInfo, userInfo]);

  useEffect(() => {
    if (orderDetails.length > 0) {
      if (userInfo && userInfo.unreadSoldCount > 0) {
        let token = localStorage.getItem("clttoken");
        // get an array of  _id for which seen is false in orderInfo
        let ids = orderDetails
          .filter((item) => item.read === false)
          .map((item) => item._id);
        dispatch(seenSoldOrders({ token, ordersId: ids }));
      }
    }
  }, [orderDetails]);

  if (orderHistoryStatusMessage == "Succesfully Seen Orders History!!!") {
    dispatch(reseto());
    let token = localStorage.getItem("clttoken");

    dispatch(getuserInfo({ token: token }));
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for analyzing the orders of products to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for analyzing the orders of products to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for analyzing the orders of products to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <div
        className="page-container"
        style={{ position: "relative", minHeight: "520px" }}
      >
        {spinner ? (
          <Spinner />
        ) : orderDetails && orderDetails.length > 0 ? (
          <div className="hangers_container">
            <div className="orders">
              {/* <h1
                className="section_title bold-text"
                style={{ textAlign: "center", margin: "2rem auto" }}
              >
                Order History
              </h1> */}
              {/* <OrderTable orderInfo={orderInfo} /> */}
              <OrderTableAccordion
                orderInfo={orderDetails}
                handleFulfill={handleFulfill}
              />
            </div>
          </div>
        ) : (
          <div className="no_item">
            <h1
              className="h1 text-center"
              style={{ marginTop: "1rem", color: "var(--background-color)" }}
            >
              You haven’t had any orders yet
            </h1>
            <br />
            <button className="btn">
              <Link to={"/add-items"}>ADD MORE ITEMS</Link>
            </button>
            <br />
            <img
              style={{ marginTop: "4rem" }}
              src={NoClosetItem}
              alt="no-closet-icon"
            />
            {/* <div className="h5 text-center" style={{ marginTop: "1rem" }}>
        <Link className="add_item" to="../add-items">
          Click Here
        </Link>{" "}
        to Add Item in your Closet
      </div> */}
          </div>
        )}

        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      </div>
    </>
  );
};

export default Orders;
