import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import { closetSetup } from "../components/StateSlices/closestsetupSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import { BsFillBagCheckFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import danielle from "../assets/images/danielle.png";
import "./SetupClosest.css";
import "./OrderConfirmation.css";
import { checkout } from "../components/StateSlices/checkoutSlice";
import { getProduct } from "../components/StateSlices/getproductSlice";
import ConfettiNew from "../components/Confetti/ConfettiNew";

const OrderConfirmation = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  //dispatch(reset());
  const { pstatus, productInfo, images, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );
  const { userInfo } = useSelector((state) => state.user);
  const [productDetailInfo, setProductDetailInfo] = useState([]);

  //   const [image, setImage] = useState("")
  //   const [bannerImage, setBannerImage] = useState("")
  //   const { status, userInfo, error,profile } = useSelector(state => state.user)
  //   const { setupStatus, userFullInfo, setupError } = useSelector(state => state.closet)

  // const toastOption = {
  //     position: "top-right",
  //     autoClose: 2500,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  // };
  console.log("closetINFFFFO", closetInfo);
  console.log("productINNNFO", state);
  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
    } else {
      //navigate("/login");
    }
    if (state && state.products) {
      let used = 0;
      let userHanger = Math.abs(state.price.hanger - state.shortHanger);
      // group state.products by ownerEmail

      let groupedProducts = state.products.reduce((r, a) => {
        if (!r[a.productId.ownerEmail.userEmail]) {
          r[a.productId.ownerEmail.userEmail] = [];
        }

        let hangerUsed = 0;
        if (userHanger <= a.productId.productHanger) {
          hangerUsed = a.productId.productHanger - userHanger;
          userHanger = 0;
        } else {
          hangerUsed = 0;
          userHanger = userHanger - a.productId.productHanger;
        }

        const productWithHanger = { ...a, hangerUsed };
        r[a.productId.ownerEmail.userEmail].push(productWithHanger);

        return r;
      }, {});
      console.log("groupedProducts", Object.values(groupedProducts));
      setProductDetailInfo(Object.values(groupedProducts));
    }
    // console.log(state);
  }, [state]);
  return (
    <div className="closest_setup_container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <ConfettiNew />
      <div style={{ padding: "2rem 0" }}>
        <div className="success-checkmark">
          <BsFillBagCheckFill size={"5rem"} color={"green"} />
        </div>
        <br />
        <h1 className="section_title">
          Congratulations! Your Order has been placed Successfully!
        </h1>
        <div className="main-con">
          <br />
          <h4 className="bold-text cartId primary-text">
            ORDER ID : {state && state.orderId}
          </h4>
          <br />
          <h2 className="cart-prod-details-heading">Product Details</h2>
          <div className="order-cart-con">
            <div
              className="product-details-con order-product-header-sec"
              style={{ borderBottom: "1px solid #ddd" }}
            >
              <div className="img-con">
                <h3>Product Image</h3>
              </div>
              <div className="product-desc">
                <h3>Product Name</h3>
              </div>
              <div className="product-price">
                <h3 style={{ marginLeft: ".5rem" }}>Price Details</h3>
              </div>
            </div>
            {state &&
              productDetailInfo.length > 0 &&
              productDetailInfo.map((pd, key) => (
                <div className="order-product-group-con">
                  {/* <div className="order-product-group-index">{key + 1}</div> */}
                  {pd.map((product, index) => (
                    <div className="product-details-con" key={key}>
                      <div className="img-con">
                        <img
                          src={product.productId.productImage[0]}
                          alt={product.productId.productName}
                        />
                      </div>
                      <div className="product-desc">
                        <Link
                          to={`/purchased/${product.productId.productStats.brand}-${product.productId.productName}`}
                          state={{
                            id: product._id,
                            pId: product.productId._id,
                          }}
                        >
                          <h4 className="bold-text primary-text">
                            {product.productId.productName}
                          </h4>
                        </Link>
                        <h5>Brand : {product.productId.productStats.brand}</h5>
                        <h5>Size : {product.productId.productStats.size}</h5>
                        <h5>
                          Condition : {product.productId.productStats.condition}
                        </h5>
                        <h5>
                          Closet : {product.productId.ownerEmail.closetName}
                        </h5>
                      </div>
                      <div className="product-price">
                        <table style={{ width: "100%" }}>
                          <tr className="">
                            <th
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                            >
                              Price Paid{" "}
                            </th>
                            <td
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              ${" "}
                              {Number(
                                Number(product.hangerPurchased) *
                                  (state.hangerPurchased != 0
                                    ? state.price.price /
                                      Number(state.hangerPurchased)
                                    : 0)
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr className="">
                            <th
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              Sales Tax{" "}
                            </th>
                            <td
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              ${" "}
                              {product.paidBy === "Money"
                                ? Number(
                                    (Math.abs(
                                      state.price.salesTax.amount -
                                        state.price.salesTax.shippingTax
                                    ) /
                                      state.shortHanger) *
                                      product.hangerPurchased
                                  ).toFixed(2)
                                : "0"}
                            </td>
                          </tr>
                          <tr className="">
                            <th
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              Hanger Credit Used
                            </th>
                            <td
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              {product.productId.productHanger}
                            </td>
                          </tr>
                          <tr className="">
                            <th
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              Hanger Credit Purchased
                            </th>
                            <td
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                maxWidth: "50px",
                              }}
                            >
                              {product.hangerPurchased}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ))}
                  <hr style={{ marginLeft: "1rem", marginRight: "1rem" }} />
                  <div
                    className="shipping-info-par-con"
                    style={{
                      marginLeft: "1rem",
                      marginRight: "1rem",
                      display: "flex",
                    }}
                  >
                    <div className="shipping-info-div1">
                      {" "}
                      <h3>Shipping Cost Details</h3>
                      &nbsp;
                    </div>
                    <div
                      className="shipping-info-div2"
                      style={{ display: "flex", flexBasis: "30%" }}
                    >
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              // maxWidth: "50px",
                            }}
                          >
                            Shipping Cost
                          </th>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              maxWidth: "40px",
                            }}
                          >
                            $
                            {Number(
                              state.price.shipping[pd[0].productId._id][0]
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              maxWidth: "50px",
                            }}
                          >
                            Shipping Tax
                          </th>
                          <td
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              maxWidth: "50px",
                            }}
                          >
                            $
                            {state.price.shipping[pd[0].productId._id][0] > 0
                              ? (
                                  (state.price.shipping[
                                    pd[0].productId._id
                                  ][0] *
                                    state.price.salesTax.percent) /
                                  100
                                ).toFixed(2)
                              : 0}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  {/* <div
                  className="shipping-info-par-con"
                  style={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    display: "flex",
                  }}
                >
                  <div className="shipping-info-div1">
                    {" "}
                    <h3 className="h3">Total Cost</h3>
                    &nbsp;
                  </div>
                  <div
                    className="shipping-info-div2"
                    style={{ display: "flex", flexBasis: "30%" }}
                  >
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            maxWidth: "50px",
                          }}
                        >
                          Total
                        </th>
                        <td
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            maxWidth: "50px",
                          }}
                        >
                          $
                          {Number(
                            state.price.shipping[pd[0].productId._id][0]
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div> */}
                  {userInfo && (
                    <div
                      className="order-chat-btn-con"
                      style={{ marginTop: "2rem", flexBasis: "100%" }}
                    >
                      <Link
                        to={`/chat-support`}
                        state={{
                          sellerEmail: pd[0].productId.ownerEmail.userEmail,
                        }}
                      >
                        <button
                          className="btn bold-text"
                          style={{
                            width: "100%",
                            borderRadius: "3px",
                            textTransform: "uppercase",
                          }}
                        >
                          Chat with Seller
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <br />
          <h2 className="cart-prod-details-heading">Shipping Details</h2>
          <br />
          <div
            className="shipping-details-con"
            style={{ margin: "0rem 2.5rem", textAlign: "left" }}
          >
            <h5>
              {" "}
              <strong> Address :</strong> {state.shippingAddress}{" "}
            </h5>
            <h5>
              {" "}
              <strong> City :</strong> {state.shippingCity}{" "}
            </h5>
            <h5>
              {" "}
              <strong> State :</strong> {state.shippingState}{" "}
            </h5>
            <h5>
              {" "}
              <strong> Zip Code :</strong> {state.shippingZipCode}{" "}
            </h5>
          </div>
        </div>

        {userInfo ? (
          <>
            {/* {state.products.length == 1 && (
            <>
              <p className="paragraph_title">
                Start Chatting with User for any Queries!
              </p>
              <div style={{ textAlign: "center" }}>
                <Link
                  to={`/chat-support-ind/${closetInfo.userEmail}/${state.products[0].productId._id}`}
                >
                  <button
                    className="btn dark-btn setup-complete-button"
                    type="submit"
                  >
                    Chat with User
                  </button>
                </Link>
              </div>
            </>
          )} */}
            <p className="paragraph_title">
              You can refer Order History to track your Order!
            </p>
            <div style={{ textAlign: "center" }}>
              <Link to="/user/purchases">
                <button
                  className="btn dark-btn setup-complete-button"
                  type="submit"
                >
                  Order History
                </button>
              </Link>
              <Link to="/user/hanger">
                <button
                  className="btn dark-btn setup-complete-button"
                  type="submit"
                >
                  Hanger History
                </button>
              </Link>
            </div>

            <p className="paragraph_title">or START SHOPPING!</p>
            <div style={{ textAlign: "center" }}>
              <Link to="/shop">
                <button
                  className="btn dark-btn setup-complete-button"
                  type="submit"
                >
                  Show All Products
                </button>
              </Link>
            </div>
          </>
        ) : (
          <>
            {userInfo ? (
              <>
                <p className="paragraph_title">
                  Check your order history or hanger credit balance here:
                </p>
                <div style={{ textAlign: "center" }}>
                  <Link to="/user/orders">
                    <button
                      className="btn dark-btn setup-complete-button"
                      type="button"
                    >
                      Order History
                    </button>
                  </Link>
                  <Link to="/user/hanger">
                    <button
                      className="btn dark-btn setup-complete-button"
                      type="button"
                    >
                      Hanger History
                    </button>
                  </Link>
                </div>
                <p className="paragraph_title">OR</p>
                <p className="paragraph_title">
                  Shop for other fabulous finds here:
                </p>
                <div style={{ textAlign: "center" }}>
                  <Link to="/shop">
                    <button
                      className="btn dark-btn setup-complete-button"
                      type="button"
                    >
                      Shop
                    </button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <p className="paragraph_title">
                  Sign up for a free guest account in order to track your order
                  or chat with member to arrange pickup:
                </p>
                <div style={{ textAlign: "center" }}>
                  <Link to="/signup">
                    <button
                      className="btn dark-btn setup-complete-button"
                      type="button"
                    >
                      Sign Up
                    </button>
                  </Link>
                </div>
                <p className="paragraph_title">OR</p>
                <p className="paragraph_title">
                  Sign in here if you already have a guest account:
                </p>
                <div style={{ textAlign: "center" }}>
                  <Link to="/login">
                    <button
                      className="btn dark-btn setup-complete-button"
                      type="button"
                    >
                      Login
                    </button>
                  </Link>
                </div>
              </>
            )}
          </>
        )}
        {/* <h1 className="section_title" style={{ paddingTop: "75px" }}>
        Similar Products
      </h1> */}

        {/* <div>
                <div className='similarClosets'>
                    <div className='UsersContainer'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>

                    <div className='UsersContainer'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>

                    <div className='UsersContainer'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>

                    <div className='UsersContainer UsersContainerHidden'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>

                    <div className='UsersContainer UsersContainerHidden'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>

                    <div className='UsersContainer UsersContainerHidden'>
                        <img className='closetUser' src={danielle} alt="Closet User"></img>
                        <div className='addFriendContainer'>
                            <button className='addFriend' onClick={"javascript;"}>+</button>
                        </div>
                        <p style={{paddingTop:"30px", color: "#f26654"}}>{User_Name}</p>
                    </div>
                </div>
            </div> */}
      </div>
    </div>
  );
};

export default OrderConfirmation;
