import React, { useState } from "react";
import "./SmallHeaderMobile.css";
import logo from "../../assets/images/CCfinallogo_boxed.png";
import hanger from "../../assets/images/hangerMobile.svg";
import bag from "../../assets/images/bagMobile.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../StateSlices/getuserSlice";
import { getSearchResult } from "../StateSlices/searchSlice";
import { BiCog } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { HiOutlineChat, HiOutlineShoppingBag } from "react-icons/hi";
import { PiCoatHangerFill } from "react-icons/pi";
import { IoMdAddCircleOutline } from "react-icons/io";

const SmallHeaderMobile = ({ cart }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { searchinfo, searchstatus } = useSelector((state) => state.search);
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  // //console.log(userInfo);
  const logout = () => {
    localStorage.removeItem("clttoken");
    dispatch(reset());
    navigate("/");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    //console.log(searchQuery);
    dispatch(getSearchResult(searchQuery));
    if (searchinfo) navigate(`/search/${searchQuery}`);
  };

  //console.log(userInfo);

  return (
    <div>
      <div className="small_header">
        <div
          className="sidebar"
          style={sidebar ? { width: "250px" } : { width: "0px" }}
        >
          <div
            className="closebtn"
            onClick={() => setSidebar(false)}
            style={{ minHeight: "60px" }}
          >
            &times;
          </div>
          <form className="searchbar_container" onSubmit={handleSearch}>
            <input
              type="text"
              className="input_search"
              value={searchQuery}
              // onClick={() => setSearchActive(true)}
              onChange={(e) => setSearchQuery(e.target.value)}
              // onBlur={() => setSearchActive(false)}
              // onMouseUp={() => sear}
            />
            <button className="input_search" onClick={() => setSidebar(false)}>
              <i className="fas fa-search fa-lg"></i>
            </button>
          </form>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {/* <a href="./feed" className="nav_link">Feed</a> */}
          <Link
            to={`/shop`}
            style={{ textDecoration: "none" }}
            onClick={() => setSidebar(false)}
            className="nav_link"
          >
            Shop
          </Link>
          {/* <a href="javascript:void(0)" className="nav_link">Shop Closets</a> */}
          <Link
            to={`/user/my-closet`}
            style={{ textDecoration: "none" }}
            onClick={() => setSidebar(false)}
            className="nav_link"
          >
            My Closet
          </Link>
        </div>
        <div className="menu_container">
          <div className="left_container">
            <span onClick={() => setSidebar(true)}>
              {/* &#9776; */}
              <FiMenu size={"1.1em"} />
            </span>
          </div>
          <Link
            to={`/`}
            style={{ textDecoration: "none", textAlign: "center" }}
          >
            <img
              src={logo}
              style={{ height: "50px" }}
              alt="Closest Closet Logo"
            />
          </Link>
          <div className="profile_icon_container profile_menu icon_container">
            {/* <Link to={`/login`} style={{textDecoration:"none"}} className="icon_container"><i className="fas fa-user-circle fa-xs"></i></Link> */}
            {userInfo && userInfo.userDetailsId ? (
              <div className="dropdown_icon">
                <img
                  className="user_logo_header"
                  src={
                    userInfo.userDetailsId.userIcon
                      ? userInfo.userDetailsId.userIcon
                      : logo
                  }
                  alt="user-icon"
                />
                <div className="dropdown">
                  <Link
                    to={`/user/profile`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className="dropdown_menu">Profile</div>
                  </Link>
                  <div className="dropdown_menu" onClick={logout}>
                    Logout
                  </div>
                </div>
              </div>
            ) : (
              <Link to="./login" className="icon_container">
                {" "}
                <i className="far fa-user-circle "></i>
              </Link>
            )}
          </div>
        </div>
        <div>
          <div className="small_icon_container">
            <Link
              to={`/chat-support`}
              style={{
                textDecoration: "none",
                color: "var(--white-color)",
                display: "flex",
                justifyContent: "center",
              }}
              className="icon_container"
            >
              <button type="button" class="header-icon-button">
                <HiOutlineChat
                  color="var(--white-color)"
                  style={{ cursor: "pointer" }}
                  size={28}
                />
              </button>
            </Link>
            <Link
              to={`/add-items`}
              style={{
                textDecoration: "none",
                color: "var(--white-color)",
                display: "flex",
                justifyContent: "center",
              }}
              className="icon_container"
            >
              <button type="button" class="header-icon-button">
                <IoMdAddCircleOutline
                  color="var(--white-color)"
                  size={28}
                  cursor={"pointer"}
                />
              </button>
            </Link>
            {userInfo && userInfo.userDetailsId ? (
              <>
                <Link
                  to={`/user/hanger`}
                  style={{
                    textDecoration: "none",
                    color: "var(--white-color)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="icon_container"
                >
                  <button type="button" class="header-icon-button">
                    <PiCoatHangerFill
                      style={{ marginTop: "5px" }}
                      size={28}
                      color="var(--white-color)"
                      cursor={"pointer"}
                    />
                    <span class="icon-button__badge">
                      {userInfo.userHanger}
                    </span>
                  </button>
                </Link>
                {userInfo && userInfo.userDetailsId ? (
                  <>
                    <Link
                      to={`/checkout`}
                      style={{ textDecoration: "none", display: "flex" }}
                      className="icon_container icon_con_2"
                      title="Checkout"
                    >
                      <button type="button" class="header-icon-button">
                        <HiOutlineShoppingBag
                          style={{ cursor: "pointer" }}
                          size={28}
                          color="var(--white-color)"
                        />
                        {userInfo.userProductsId.cartId.length > 0 && (
                          <span class="icon-button__badge">
                            {userInfo.userProductsId.cartId.length}
                          </span>
                        )}
                      </button>
                    </Link>
                  </>
                ) : (
                  <Link
                    to={`/checkout`}
                    style={{ textDecoration: "none", display: "flex" }}
                    className="icon_container icon_con_2"
                    title="Checkout"
                  >
                    <button type="button" class="header-icon-button">
                      <HiOutlineShoppingBag
                        style={{ cursor: "pointer" }}
                        size={28}
                        color="var(--white-color)"
                      />
                      {cart.length > 0 && (
                        <span class="icon-button__badge">{cart.length}</span>
                      )}
                    </button>
                  </Link>
                )}
                {userInfo && userInfo.role === "admin" ? (
                  <>
                    <Link
                      to={`/admin/users`}
                      style={{ textDecoration: "none", display: "flex" }}
                      className="icon_container icon_con_2"
                      title="Admin Panel"
                    >
                      <button type="button" class="header-icon-button-small">
                        <BiCog
                          size={28}
                          color="var(--white-color)"
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <div className="small_icon_container">
            <Link to={`/`} style={{textDecoration:"none"}} className="icon_container"><i className="far fa-bell "></i></Link>
            <Link to={`/add-items`} style={{textDecoration:"none"}} className="icon_container"><i class="fas fa-plus-circle"></i></Link>
            <Link to={`/hanger`} style={{textDecoration:"none"}} className="icon_container"><img style={{height:"21px"}} src={hanger} alt="hanger icon"/>
            <span className="badge">3</span></Link>
            <Link to={`/checkout`} style={{textDecoration:"none"}} className="icon_container"><img style={{height:"21px"}} src={bag} alt="hanger icon"/><span className="badge-bag" style={{left:"50%",top:"44%",transform:"translate(-50%,-50%)"}}>2</span></Link>
        </div> */}
    </div>
  );
};

export default SmallHeaderMobile;
