import React, { useRef, useState } from "react";
import "./LoginHomeCarousel.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import loginCar1 from "../../assets/images/login-car-1.png";
import loginCar2 from "../../assets/images/login-car-2.png";
import loginCar3 from "../../assets/images/login-car-3.png";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const CustomSlide = ({ contentt, index, currentSlide }) => {
  // console.log("login home carousel", contentt, index, currentSlide);
  const classNames = `slide ${index === currentSlide ? "current" : "upcoming"}`;
  return (
    <div>
      <div style={{ position: "relative" }} className={`login-car-wrapper`}>
        <>
          <div className={`login-car-carousel-content ${classNames}`}>
            <h1
              className={`main-title bold-text ${classNames}`}
              style={{ textTransform: "uppercase", margin: ".5rem" }}
            >
              {contentt.heading1}
            </h1>
            <h4
              style={{ color: "#eee", margin: ".5rem auto", width: "80%" }}
              className={classNames}
            >
              {contentt.heading5}
            </h4>
            <div
              className={`login-car-carousel-buttons ${classNames} `}
              style={{ width: "75%" }}
            >
              {contentt.buttons &&
                contentt.buttons.map((btn, ind) => (
                  <Link to={"/" + btn.url} key={ind}>
                    <button className="login-car-btn">{btn.btn}</button>
                  </Link>
                ))}
            </div>
          </div>
        </>

        <div className={`login-car-carousel-image-container`}>
          <img
            src={contentt.fileimage}
            alt="Carousel 1"
            className="login-car-carousel-image"
          />
        </div>
      </div>
    </div>
  );
};
const LeftArrow = ({ slider, setArrowClicked }) => {
  return (
    <div
      class="login-car-arrow-left"
      onClick={() => {
        setArrowClicked("left");
        slider.current.slickPrev();
      }}
    >
      <BsArrowLeftCircleFill size={"2.6em"} style={{ cursor: "pointer" }} />
    </div>
  );
};
const RightArrow = ({ slider, setArrowClicked }) => {
  return (
    <div
      class="login-car-arrow-right"
      onClick={() => {
        setArrowClicked("right");
        slider.current.slickNext();
      }}
    >
      <BsArrowRightCircleFill size={"2.6em"} style={{ cursor: "pointer" }} />
    </div>
  );
};

const LoginHomeCarousel = ({ content }) => {
  const [contentt, setcontenttt] = useState(content || []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [arrowClicked, setArrowClicked] = useState(null);

  const slider = useRef();
  // console.log("Logged In Home Carousel ", contentt);
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    interval: 5000,
    pauseOnFocus: false,
    pauseOnHover: true,
    dots: false,
    fade: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease-in",
    prevArrow: <LeftArrow slider={slider} setArrowClicked={setArrowClicked} />,
    nextArrow: <RightArrow slider={slider} setArrowClicked={setArrowClicked} />,
    afterChange: (next) => {
      if (next !== currentSlide && arrowClicked === "right")
        setCurrentSlide((prevSlide) => (prevSlide + 1) % contentt.length);
      else if (next !== currentSlide && arrowClicked === "left")
        setCurrentSlide(
          (prevSlide) => (prevSlide - 1 + contentt.length) % contentt.length
        );
      else {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % contentt.length);
      }
    },
  };

  return (
    <>
      {contentt && (
        <div className="login-car-carousel-container">
          <Slider {...settings} ref={slider}>
            {contentt &&
              contentt.map((data, index) => {
                return (
                  <CustomSlide
                    key={index}
                    contentt={data}
                    index={index}
                    currentSlide={currentSlide}
                  />
                );
              })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default LoginHomeCarousel;
