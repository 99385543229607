import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  profileStatus: "idle",
  userUpdatedInfo: null,
  profileError: null,
};

export const editProfile = createAsyncThunk(
  "profile/editprofile",
  async ({ token, values, id = "" }, { rejectWithValue }) => {
    try {
      let s = "/api/users/editprofile";
      if (id) {
        s += `?id=${id}`;
      }
      const { data } = await axios.put(`${s}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const editProfileSlice = createSlice({
  name: "editprofile",
  initialState,
  reducers: {
    resetProfileEdit(state, action) {
      state.profileStatus = "idle";
      state.userUpdatedInfo = null;
      state.profileError = null;
    },
  },
  extraReducers: {
    [editProfile.pending]: (state, action) => {
      state.profileStatus = "loading";
    },
    [editProfile.fulfilled]: (state, action) => {
      state.profileStatus = "succeeded";
      state.userUpdatedInfo = action.payload.message;
    },
    [editProfile.rejected]: (state, action) => {
      state.profileStatus = "failed";
      console.log(action);
      state.profileError = action.payload;
    },
  },
});

export const { resetProfileEdit } = editProfileSlice.actions;
export default editProfileSlice.reducer;
