import React from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import "./Pagination.css";

const Pagination = ({
  pageCount,
  pageNumber,
  setPageNumber,
  thresholdActive = true,
  noMarginLeft = false,
  noMarginTop = false,
  myClosetPagination = false,
  myClosetType = null,
  otherUnchangedCount = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  //consolele.log(location.search);
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (myClosetPagination) {
      // Determine which pages to use for active and archive
      const activePage =
        myClosetType === "active"
          ? selectedPage + 1
          : new URLSearchParams(window.location.search).get("activepage") || 1;

      const archivePage =
        myClosetType === "archive"
          ? selectedPage + 1
          : new URLSearchParams(window.location.search).get("archivepage") || 1;

      // Check if the current query parameters match the intended values to prevent unnecessary state updates
      const currentParams = new URLSearchParams(window.location.search);
      const currentActivePage = currentParams.get("activepage") || "1";
      const currentArchivePage = currentParams.get("archivepage") || "1";

      if (
        String(activePage) !== currentActivePage ||
        String(archivePage) !== currentArchivePage
      ) {
        setPageNumber(selectedPage);
        // navigate(
        //   `${location.pathname}?activepage=${activePage}&archivepage=${archivePage}`,
        //   {
        //     replace: true,
        //     state: { id: location.state ? location.state.id : "" },
        //   }
        // );
        // navigate(
        //   `/user/my-closet?activepage=${activePage}&archivepage=${archivePage}`,
        //   { replace: true,  state: { id: location.state ? location.state.id : "" } }
        // );
        const newUrl = `${location.pathname}?activepage=${activePage}&archivepage=${archivePage}`;
        window.history.replaceState(
          {
            ...window.history.state,
            id: location.state ? location.state.id : "",
          },
          "",
          newUrl
        );
        // window.scrollTo(0, 0);
      }
    } else {
      if (thresholdActive) {
        // Only update if the page has changed to avoid re-rendering
        const currentPage =
          new URLSearchParams(window.location.search).get("page") || "1";
        if (currentPage !== String(selectedPage + 1)) {
          setPageNumber(selectedPage);
          sessionStorage.setItem("closetshoppage", selectedPage);

          // Update the URL without causing unnecessary re-renders
          navigate(`${location.pathname}?page=${selectedPage + 1}`, {
            replace: true,
            state: { id: location.state ? location.state.id : "" },
          });
          window.scrollTo(0, 0);
        }
      }
    }
    //  else {
    //       if (thresholdActive) {
    //         setPageNumber(data.selected);
    //         sessionStorage.setItem("closetshoppage", data.selected);
    //         // Generic case for other paginations
    //         navigate(`${location.pathname}?page=${selectedPage + 1}`, {
    //           replace: true,
    //           state: { id: location.state ? location.state.id : "" },
    //         });
    //         window.scrollTo(0, 0);
    //       }
    //     }
  };
  return (
    <div
      className="pagination-con"
      style={{ marginTop: noMarginTop && "-3rem" }}
    >
      <ReactPaginate
        nextLabel={"NEXT"}
        previousLabel={"<<"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={
          noMarginLeft ? "pagination-no-margin-left" : "pagination"
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item-prev"
        previousLinkClassName="page-link-prev"
        nextClassName="page-item-next"
        nextLinkClassName="page-link-next"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        initialPage={
          location.search.slice(-1) && location.search.indexOf("page") > -1
            ? location.search.slice(location.search.indexOf("=") + 1) - 1
            : 0
        }
        forcePage={
          location.search.slice(-1) && location.search.indexOf("page") > -1
            ? location.search.slice(location.search.indexOf("=") + 1) - 1
            : 0
        }
      />
    </div>
  );
};

export default Pagination;
