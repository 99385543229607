import React, { useEffect, useState } from "react";
import "./Header2.css";
import "../../pages/Home.css";
import { MdContentCopy } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import hanger from "../../assets/images/hanger.svg";
import Modal from "react-modal";
import CryptoJS from "crypto-js";
import { RiUserSharedLine } from "react-icons/ri";
import { referLink, resetItem } from "../StateSlices/referLinkSlice";
import { PiCoatHangerFill } from "react-icons/pi";
import { IoMdAddCircleOutline } from "react-icons/io";
import ScrollAnimation from "react-animate-on-scroll";

const Header2 = () => {
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );

  const [isReferModalActive, setIsReferModalActive] = useState(false);
  const [refUrl, setRefUrl] = useState("");
  //consolele.log("header2", userInfo, profile);
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://closestcloset.com/signup?referral=" + encodeURIComponent(referId)
    );
    setIsReferModalActive((prev) => !prev);
  }

  const getUrl = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(referLink({ token: token }));
    }
  };

  const generateRefUrl = () => {
    let uId = userInfo._id.toString();
    const key = CryptoJS.enc.Utf8.parse("ccRefKey");
    const iv = CryptoJS.enc.Utf8.parse("YourInitialVector");

    const encrypted = CryptoJS.AES.encrypt(uId, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    setRefUrl(
      "https://closestcloset.com/signup?referral=" +
        encodeURIComponent(encrypted.toString())
    );
    setIsReferModalActive((prev) => !prev);
  };
  return (
    <>
      {/* <ToastContainer
        position="bottom-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal
        ariaHideApp={false}
        isOpen={isReferModalActive}
        onRequestClose={() => setIsReferModalActive(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 9.5px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
        </div>

        <div className="terms-modal-con">
          <div
            className="terms-modal-wrapper"
            style={{ marginTop: 0, padding: "1.5rem 0", paddingTop: ".5rem" }}
          >
            <h3 className="primary-text" style={{ marginBottom: ".5rem" }}>
              Refer to your friends
            </h3>
            <h6 className="background-text">
              Members earn 5 bonus hanger credits + $5 off their next
              subscription fee on each paid member referral!
            </h6>
            <div class="reference-section">
              <input type="text" id="reference-input" value={refUrl} disabled />
              <button
                id="copy-button"
                onClick={() => {
                  // refUrl.select();
                  // refUrl.setSelectionRange(0, 99999); // For mobile devices
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(refUrl);
                  toast.success(
                    "Referral URL copied successfully!",
                    toastOption
                  );
                }}
              >
                <MdContentCopy />
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ScrollAnimation animateOnce={true} animateIn="fadeInZoom">
        <div className="header2">
          <div
            className="header site-width"
            style={{
              padding: "0 35px",
              paddingRight: "30px",
              minHeight: "50px",
            }}
          >
            <div className="header_left">
              {/* <Link to="./feed" onClick={(event)=>event.preventDefault()}  title="Coming Soon" style={{cursor:"not-allowed"}} className="nav_link">Feed</Link> */}

              <div class="shop-dropdown">
                <Link to={"/shop"}>
                  <span className="nav_link" style={{ cursor: "pointer" }}>
                    Shop
                  </span>
                </Link>
                <div class="shop-dropdown-content">
                  <Link to={"/shop"} state={{ categoryName: "Women" }}>
                    <div className="dropdown-menu">Women</div>
                  </Link>
                  <Link to={"/shop"} state={{ categoryName: "Men" }}>
                    <div className="dropdown-menu">Men</div>
                  </Link>
                  <Link to={"/shop"} state={{ categoryName: "Girl" }}>
                    <div className="dropdown-menu">Girls</div>
                  </Link>
                  <Link to={"/shop"} state={{ categoryName: "Boy" }}>
                    <div className="dropdown-menu">Boys</div>
                  </Link>
                  <Link to={"/shop"} state={{ categoryName: "Other" }}>
                    <div className="dropdown-menu">Others</div>
                  </Link>
                </div>
              </div>
              {!userInfo && (
                <Link to="/why-join-closest-closet" className="nav_link">
                  Why Join Closest Closet?
                </Link>
              )}
              {/* <a href="#" className="nav_link">Shop Closets</a> */}
              {userInfo && userInfo.userDetailsId ? (
                <Link to="/user/my-closet" className="nav_link">
                  My Closet
                </Link>
              ) : (
                ""
              )}
              {/* <Link to="/leaderboard" className="nav_link">
                  Leaderboard
                </Link> */}
            </div>

            <div className="header_right">
              {userInfo && userInfo.userDetailsId?.membership !== "trial" && (
                <>
                  <Link
                    to="./add-items"
                    className="icon_container icon_con_2 tooltip"
                  >
                    <button type="button" class="header-icon-button">
                      <IoMdAddCircleOutline
                        color="var(--primary-color)"
                        size={32}
                        cursor={"pointer"}
                      />
                    </button>
                    <span class="tooltiptext h6">Add Items!</span>
                  </Link>
                </>
              )}
              {userInfo && userInfo.userDetailsId && (
                <>
                  <Link
                    to={`/user/hanger`}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                    }}
                    className="icon_container tooltip icon_con_2"
                  >
                    <button type="button" class="header-icon-button">
                      <PiCoatHangerFill
                        style={{ marginTop: "5px" }}
                        size={32}
                        color="var(--primary-color)"
                        cursor={"pointer"}
                      />
                      <span class="icon-button__badge">
                        {userInfo.userHanger}
                      </span>
                    </button>
                    <span class="tooltiptext h6" style={{zIndex:"999"}}>
                      Hanger Credits : {userInfo.userHanger}
                    </span>
                  </Link>
                  <div
                    onClick={() => {
                      getUrl();
                    }}
                    className="icon_container tooltip icon_con_2"
                    style={{
                      minWidth: "66px",
                      minHeight: "50px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <RiUserSharedLine size={30} />
                    <span class="tooltiptext h6">Refer a friend!</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </>
  );
};

export default Header2;
