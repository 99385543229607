import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import notfoundimg from "../assets/images/404.jpg";
import "./NotFound.css";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: window.location.href,
          },
        ]}
      >
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="not_found_container">
        <img
          src={notfoundimg}
          alt="notfoundimg"
          style={{ width: "100%", height: "100%" }}
        />
        <div className="img_text">
          <h1>404 Lost In Closet</h1>
          <Link to="/shop">
            <button className="btn btn-primary">Get back to Shopping</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
