import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { getSearchResult } from "../StateSlices/searchSlice";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import "./SearchResults.css";
const SearchResults = () => {
  const dispatch = useDispatch();
  const { searchinfo } = useSelector((state) => state.search);
  // const { status, userInfo, profile } = useSelector((state) => state.user);
  const [spinner, setSpinner] = useState(true);
  const [closets, setClosets] = useState([]);
  let { query } = useParams();
  useEffect(() => {
    setSpinner(true);
    dispatch(getSearchResult(query));
  }, [query]);

  useEffect(() => {
    if (searchinfo && searchinfo.products) {
      setTimeout(() => setSpinner(false), 1500);
      //consolele.log(searchinfo);
      const uniqueNames = searchinfo.products.reduce((uniqueSet, item) => {
        if (
          !uniqueSet.some((uniqueItem) => uniqueItem.masterId === item.masterId)
        ) {
          uniqueSet.push({
            masterId: item.masterId,
            closetName: item.closetName,
            bannerImage: item.bannerImage,
          });
        }
        return uniqueSet;
      }, []);
      setClosets(uniqueNames);
    }
  }, [searchinfo]);
  // const dres = {
  //   products: [
  //     {
  //       productStats: {
  //         color: ["purple"],
  //         size: "Small",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Women", "Activewear"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b42c96c40141e2d8957d15",
  //       productName: "Nike purple pullover size small",
  //       productPrice: "70",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679470559/sjnwzbpxsphul3mdqkpx.webp",
  //       ],
  //       productDesc: "Fast",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T13:24:38.012Z",
  //       updatedAt: "2023-03-24T04:31:29.874Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["blue"],
  //         size: "XL",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Men", "Activewear"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b427704a66a25c4c314442",
  //       productName: "Nike mens XL three pullover",
  //       productPrice: "70",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679470726/chsze5b57gdmah0jzyhw.webp",
  //       ],
  //       productDesc:
  //         "Dark blue with faded white horizontal stripes and three quarter zip",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T13:02:40.835Z",
  //       updatedAt: "2023-03-24T04:31:26.158Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["grey"],
  //         size: "XL",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Women", "Activewear", "Men", "Swimwear"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b4115153c48c5f2963d978",
  //       productName: "Nike grey swimsuit size xl",
  //       productPrice: "35",
  //       productShipping: "true",
  //       productHanger: 1,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472223/soevu4dwrkhpqxftrr5x.webp",
  //       ],
  //       productDesc: "Short swim trunks Nike xl",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T11:28:17.091Z",
  //       updatedAt: "2023-03-24T04:28:37.688Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["navy blue"],
  //         size: "Small",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Women", "Sweatshirts"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b42d77c40141e2d8957d99",
  //       productName: "Nike blue zipper hoodie size small",
  //       productPrice: "70",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679468533/ya1oygnqh11zvjnq9i6t.webp",
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679468533/f2zxnndbnt6umhwh32ea.webp",
  //       ],
  //       productDesc: "Super comfy zip hoodie",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T13:28:23.395Z",
  //       updatedAt: "2023-03-24T04:31:32.735Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["blue"],
  //         size: "Shirt / Top - Medium",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Women", "Tops"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b41a376f394907a1dde08e",
  //       productName: "Nike LeBron T-shirt size medium",
  //       productPrice: "35",
  //       productShipping: "true",
  //       productHanger: 1,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679471739/wmsyvrav3pqn1mb7tdua.webp",
  //       ],
  //       productDesc: "Nike LeBron T-shirt",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T12:06:15.207Z",
  //       updatedAt: "2023-03-24T04:28:48.453Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["grey"],
  //         size: "Large",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Men", "Activewear", "Shorts"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b4114e53c48c5f2963d974",
  //       productName: "Nike dry fit shorts size large",
  //       productPrice: "35",
  //       productShipping: "true",
  //       productHanger: 1,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472226/pdx0cwmzmivafuoxhn9s.webp",
  //       ],
  //       productDesc: "Grey shorts",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T11:28:14.637Z",
  //       updatedAt: "2023-03-24T04:28:37.523Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["grey"],
  //         size: "Kids - 6",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Boy", "Pants"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b406c2f2a923a4f9547207",
  //       productName: "Nike dry fit boys pants 6M",
  //       productPrice: "35",
  //       productShipping: "true",
  //       productHanger: 1,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472952/b5ytyino5jdb0zbnwbw3.webp",
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472952/x6y1arw0mdvcgqx9ko9s.webp",
  //       ],
  //       productDesc: "Nike dry fit boys pants.",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T10:43:14.537Z",
  //       updatedAt: "2023-03-24T04:28:27.322Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["white", "navy", "blue"],
  //         size: "Medium",
  //         brand: "Nike",
  //         condition: "Like New",
  //         category: ["Women", "Activewear", "Men", "Activewear"],
  //       },
  //       _id: "64a9a7591e72c8109dc8a5c0",
  //       productName: "Nike Blue Track Pants Size Medium #11030",
  //       productPrice: "45",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1688840023/hz3mmypdixkxijb7nt6q.webp",
  //       ],
  //       productDesc:
  //         "Has zipper pockets and a thin white stripe on both pant legs",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       productRating: [],
  //       productComment: [],
  //       createdAt: "2023-07-08T18:13:45.204Z",
  //       updatedAt: "2023-07-08T18:13:45.204Z",
  //       __v: 0,
  //     },
  //     {
  //       productStats: {
  //         color: ["grey"],
  //         size: "Medium",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Men", "Activewear", "Shorts"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b4112753c48c5f2963d96c",
  //       productName: "Nike Basketball Shorts Medium Spartans size medium",
  //       productPrice: "35",
  //       productShipping: "true",
  //       productHanger: 1,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472232/cahcdinvs8dhtwtu95hf.webp",
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679472232/bjopyuixh50aht0mtbyb.webp",
  //       ],
  //       productDesc: "Dri fit Nike shorts",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T11:27:35.417Z",
  //       updatedAt: "2023-03-24T04:28:37.203Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["blue"],
  //         size: "Other",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Men", "Pants"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b3fbe17104840224aecd4c",
  //       productName: "Nike tearaway warmup pants blue size xl",
  //       productPrice: "70",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679474174/phfc9ecwbld8kmotiuh6.webp",
  //       ],
  //       productDesc: "Blue Nike tearaway warmup pants. Size XL",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T09:56:49.966Z",
  //       updatedAt: "2023-03-24T04:30:24.508Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //     {
  //       productStats: {
  //         color: ["black"],
  //         size: "XL",
  //         brand: "Nike",
  //         condition: "Good",
  //         category: ["Women", "Activewear"],
  //       },
  //       productArchive: false,
  //       adminBan: false,
  //       productReported: [],
  //       productLikes: [],
  //       _id: "63b43b90308730de9d214e3c",
  //       productName: "Nike Dry fit long sleeve top size xl",
  //       productPrice: "70",
  //       productShipping: "true",
  //       productHanger: 2,
  //       productStock: 1,
  //       productImage: [
  //         "https://res.cloudinary.com/closet-closest/image/upload/v1679466007/ozmswoerq5vumsxurzho.webp",
  //       ],
  //       productDesc: "Nike Dry fit long sleeve top w/ scrunch collar",
  //       productSpamCount: 0,
  //       productTargetId: [],
  //       productRating: [],
  //       createdAt: "2023-01-03T14:28:32.996Z",
  //       updatedAt: "2023-03-24T04:32:09.322Z",
  //       __v: 0,
  //       productComment: [],
  //     },
  //   ],
  // };

  return (
    <>
      <div style={{ position: "relative", minHeight: "39vh" }}>
        <br />
        <br />
        {spinner == true ? (
          <Spinner />
        ) : (
          <>
            <div style={{ width: "100%", margin: "auto" }}></div>
            {/* {searchinfo.users && (
            <h3 style={{ margin: "1rem 5rem", borderBottom: "1px solid #000" }}>
              Users
            </h3>
          )} */}
            {/* <div className="sr-con">
            {searchinfo.users && userInfo && searchinfo.users.length > 0 ? (
              <>
                {searchinfo.users.map(
                  (user, index) =>
                    user.userFName &&
                    user.userLName && (
                      <div className="sr-card">
                        <div className="sr-card-user">
                          <Link
                            to={
                              userInfo && userInfo._id == user._id
                                ? "/my-closet"
                                : `/closet/${user.closetName}`
                            }
                            state={{ id: user.userMasterId[0]._id }}
                          >
                            <h3 style={{ marginBottom: ".5rem" }}>
                              {user.userFName + " " + user.userLName}
                            </h3>
                            <h6 style={{ marginBottom: "1rem" }}>
                              {user.closetName}
                            </h6>
                          </Link>
                        </div>
                      </div>
                    )
                )}
              </>
            ) : (
              <h3>No Users Found</h3>
            )}
          </div>
          <br />
          <br />
          <br /> */}

            {searchinfo.products && (
              <>
                <div
                  style={{
                    display: "flex",
                    margin: "1rem 1.5rem",
                    color: "var(--background-color)",
                  }}
                >
                  <div
                    style={{
                      margin: ".5rem",
                      borderBottom: "1px solid var(--grey-color)",
                      width: "70%",
                      textAlign: "center",
                    }}
                    className="h3 bold-text"
                  >
                    Products
                  </div>

                  <div
                    style={{
                      margin: ".5rem",
                      borderBottom: "1px solid var(--grey-color)",
                      width: "30%",
                      textAlign: "center",
                    }}
                    className="h3 bold-text"
                  >
                    Closets
                  </div>
                </div>
              </>
            )}

            {/* <div className="sr-con">
              {searchinfo.products && searchinfo.products.length > 0 ? (
                searchinfo.products.map((product, index) => (
                  <div className="sr-card">
                    <div className="sr-card-img">
                      <img src={product.productImage[0]} />
                    </div>
                    <div className="sr-card-desc">
                      <h3>{product.productName}</h3>
                      <p>{product.productStats.brand}</p>
                      <Link
                        to={{
                          pathname: `/product/${encodeURIComponent(
                            product.productStats.brand
                          )}-${encodeURIComponent(product.productName)}`,
                          search: `?product=${product._id}`,
                        }}
                        state={{ id: product._id }}
                        style={{ textDecoration: "none" }}
                      >
                        <button className="btn">View in Detail</button>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <h3>No Products Found</h3>
              )}
            </div> */}
            <div className="search-main-con">
              <div className="sr-con">
                {searchinfo.products && searchinfo.products.length > 0 ? (
                  searchinfo.products.map((product, index) => (
                    <Link
                      to={{
                        pathname: `/product/${encodeURIComponent(
                          product.productStats.brand
                        )}-${encodeURIComponent(product.productName)}/${product._id}`,
                        // search: `?product=${product._id}`,
                      }}
                      state={{ id: product._id }}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="sr-card">
                        <div className="sr-card-img">
                          <img
                            src={product.productImage[0]}
                            alt={product.productName}
                          />
                        </div>
                        <div className="sr-card-desc">
                          <h5 className="background-text">
                            {product.productName}
                          </h5>
                          <p>{product.closetName}</p>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <h3>No Products Found</h3>
                )}
              </div>
              <div className="sr-con2">
                {searchinfo.products &&
                searchinfo.products.length > 0 &&
                closets ? (
                  closets.map((closet, index) => (
                    <div className="closet-wrapper">
                      <div className="closet-img-wrapper">
                        <img
                          alt={"closet_image"}
                          className="closet-img"
                          src={
                            closet.closetImage ||
                            "https://www.instyle.com/thmb/ciGuAwe4dfMdkFBuaGwnavsRSaw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/closet-lead-2000-5b8bd03a1a6044aeb146a92b5e52d24c.jpg"
                          }
                        />
                      </div>
                      <Link
                        to={{
                          pathname: `/closet/${closet.closetName}`,
                          search: `?closet=${closet.masterId}`,
                        }}
                      >
                        <div
                          className="h5 bold-text"
                          style={{ marginTop: ".5rem" }}
                        >
                          {closet.closetName}
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <h3>No Closets Found</h3>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchResults;
