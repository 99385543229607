import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  coupenStatus: "idle",
  coupenInfo: null,
  coupenMessage: null,
  coupenError: null,
}

export const addCoupen = createAsyncThunk(
  "coupen/add",
  async ({token,values}, { rejectWithValue }) => {
    try {
            const { data } = await axios.post(
                "/api/coupen/add",values,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
              )
              return data
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getCoupen = createAsyncThunk(
    "coupen/getAll",
    async ({token,values}, { rejectWithValue }) => {
      try {
              const { data } = await axios.get(
                  `/api/coupen/getall?status=${values.status}`,
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  }
                )
                return data
        
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

  export const deleteCoupen = createAsyncThunk(
    "coupen/delete",
    async ({token,values}, { rejectWithValue, thunkAPI, dispatch }) => {
      try {
              const { data } = await axios.delete(
                  `/api/coupen/delete/${values.coupenCode}`,
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  }
                )
                // await dispatch(getCoupen({ token, values:{status:"active"}}));
                return data
        
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

export const coupenSlice = createSlice({
  name: "coupen",
  initialState,
  reducers: {
    resetCoupen(state,action){
      state.coupenStatus ="idle"
      state.coupenInfo = null
      state.coupenError = null
      state.coupenMessage = null
    }
  },
  extraReducers: {
    [addCoupen.pending]: (state, action) => {
      state.coupenStatus = "loading"
    },
    [addCoupen.fulfilled]: (state, action) => {
      state.coupenStatus = "succeeded"
      state.coupenMessage = action.payload.message
    },
    [addCoupen.rejected]: (state, action) => {
      state.coupenStatus = "failed";
      state.coupenError = action.payload.message
    },
    [getCoupen.pending]: (state, action) => {
      state.coupenStatus = "loading"
    },
    [getCoupen.fulfilled]: (state, action) => {
      state.coupenStatus = "succeeded"
      state.coupenInfo = action.payload.coupenInfo
    },
    [getCoupen.rejected]: (state, action) => {
      state.coupenStatus = "failed";
      state.coupenError = action.payload.message
    },
    [deleteCoupen.pending]: (state, action) => {
      state.coupenStatus = "loading"
    },
    [deleteCoupen.fulfilled]: (state, action) => {
      state.coupenStatus = "succeeded"
      state.coupenMessage = action.payload.message
    },
    [deleteCoupen.rejected]: (state, action) => {
      state.coupenStatus = "failed";
      state.coupenError = action.payload.message
    },
  },
})

export const { resetCoupen } = coupenSlice.actions
export default coupenSlice.reducer 