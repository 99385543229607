import React, { useEffect, useState } from "react";
import "./Hangers.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import { getOrders } from "../components/StateSlices/getOrderSlice";
import {
  getHangerHistory,
  seenHangerHistory,
  resethangerHistory,
} from "../components/StateSlices/hangerHistorySlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Collapse, { Panel } from "rc-collapse";

import "react-toastify/dist/ReactToastify.css";
import HangerTable from "../components/HangerTable/HangerTable";

const Hangers = () => {
  const [hangerHistoryInfo, setHangerHistoryInfo] = useState([]);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(false);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { hangerHistoryStatus, hangerHistoryDT, hangerHistoryStatusMessage } =
    useSelector((state) => state.hangerhistory);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getHangerHistory({ token }));
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 480);
      setSpinner(true);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  let lastDeletedId;

  useEffect(() => {
    if (profile) {
      console.log(profile);
      if (profile !== "complete") {
        navigate("/setup-closest");
      }
      if (!userInfo.userDetailsId.verified) {
        navigate("/confirmation?error=AccountNotVerified", { state: userInfo });
      }
    }
  }, [profile]);

  useEffect(() => {
    console.log(hangerHistoryStatus);
    if (
      userInfo &&
      (hangerHistoryDT.length > 0 || hangerHistoryStatus === "succeeded")
    ) {
      console.log(hangerHistoryDT);

      let finalP = [];
      let purchasedProduct = hangerHistoryDT.hangerHistory.map((order) => {
        finalP.push({
          id: order._id ? order._id : "",
          eventName: order.hangerEvent,
          productName: order.eventId ? order.eventId.productName : "",
          brand: order.eventId ? order.eventId.productStats.brand : "",
          pid: order.eventId ? order.eventId._id : "",
          cid: order.checkoutId ? order.checkoutId : "",
          hanger: order.hangerNumber.toString(),
          read: order.read,
          checkoutTime: order.time,
        });
        return true;
      });

      let tmp = [...finalP];
      tmp.sort(
        (a, b) =>
          new Date(b.checkoutTime).getTime() -
          new Date(a.checkoutTime).getTime()
      );
      console.log(tmp);

      setHangerHistoryInfo([...tmp]);
      setSpinner(false);

      if (userInfo && userInfo.hangerUnreadCount > 0) {
        let token = localStorage.getItem("clttoken");

        dispatch(seenHangerHistory({ token }));
      }
    }
  }, [profile, hangerHistoryDT]);

  if (hangerHistoryStatusMessage == "Succesfully Seen Hanger History!!!") {
    dispatch(resethangerHistory());
    let token = localStorage.getItem("clttoken");

    dispatch(getuserInfo({ token: token }));
  }

  const deletekey = (id) => {
    lastDeletedId = id;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(deleteItem({ token, id }));
      }
    });
  };

  if (deleteInfo) {
    console.log(deleteInfo);
    dispatch(resetDelete());
    toast.success("Product Successfully Archived", toastOption);
    setTimeout(() => {
      setSpinner(false);

      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
        window.scrollTo(0, 0);
      } else {
        navigate("/login", { replace: true });
      }
    }, 5000);
  }

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      {spinner ? (
        <div style={{ width: "100%", height: "520px" }}>
          <Spinner />
        </div>
      ) : (
        <div className="">
          <div className="hangers_container">
            {/* <h1 className="section_title bold-text">Hanger Credits History</h1> */}
            {userInfo && userInfo.userDetailsId.membership === "trial" && (
              <div
                className="section_title"
                style={{ fontSize: "20px", marginBottom: "2rem" }}
              >
                <Link
                  to="/user/membership"
                  style={{ color: "#F26654", textDecoration: "underline" }}
                >
                  Become a Member
                </Link>{" "}
                to use Hanger Credits
              </div>
            )}

            <HangerTable
              hangerHistory={hangerHistoryInfo}
              userInfo={userInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Hangers;
