import React, { useState, useEffect, useRef } from "react";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import Founder from "../components/Founder/Founder";
import MembershipSelect from "../components/MembershipSelect/MembershipSelect";
import SignupForm from "../components/SignupForm/SignupForm";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, reset } from "../components/StateSlices/registerSlice";
import {
  verifyCoupenCode,
  resetcoupen,
} from "../components/StateSlices/verifyCoupenSlice";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import * as Yup from "yup";
import TagManager from "react-gtm-module";

import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const statesList = [
  {
    State: "Alabama",
    Abbreviation: "AL",
  },
  {
    State: "Alaska",
    Abbreviation: "AK",
  },
  {
    State: "Arizona",
    Abbreviation: "AZ",
  },
  {
    State: "Arkansas",
    Abbreviation: "AR",
  },
  {
    State: "California",
    Abbreviation: "CA",
  },
  {
    State: "Colorado",
    Abbreviation: "CO",
  },
  {
    State: "Connecticut",
    Abbreviation: "CT",
  },
  {
    State: "Delaware",
    Abbreviation: "DE",
  },
  {
    State: "District of Columbia",
    Abbreviation: "DC",
  },
  {
    State: "Florida",
    Abbreviation: "FL",
  },
  {
    State: "Georgia",
    Abbreviation: "GA",
  },
  {
    State: "Hawaii",
    Abbreviation: "HI",
  },
  {
    State: "Idaho",
    Abbreviation: "ID",
  },
  {
    State: "Illinois",
    Abbreviation: "IL",
  },
  {
    State: "Indiana",
    Abbreviation: "IN",
  },
  {
    State: "Iowa",
    Abbreviation: "IA",
  },
  {
    State: "Kansas",
    Abbreviation: "KS",
  },
  {
    State: "Kentucky",
    Abbreviation: "KY",
  },
  {
    State: "Louisiana",
    Abbreviation: "LA",
  },
  {
    State: "Maine",
    Abbreviation: "ME",
  },
  {
    State: "Montana",
    Abbreviation: "MT",
  },
  {
    State: "Nebraska",
    Abbreviation: "NE",
  },
  {
    State: "Nevada",
    Abbreviation: "NV",
  },
  {
    State: "New Hampshire",
    Abbreviation: "NH",
  },
  {
    State: "New Jersey",
    Abbreviation: "NJ",
  },
  {
    State: "New Mexico",
    Abbreviation: "NM",
  },
  {
    State: "New York",
    Abbreviation: "NY",
  },
  {
    State: "North Carolina",
    Abbreviation: "NC",
  },
  {
    State: "North Dakota",
    Abbreviation: "ND",
  },
  {
    State: "Ohio",
    Abbreviation: "OH",
  },
  {
    State: "Oklahoma",
    Abbreviation: "OK",
  },
  {
    State: "Oregon",
    Abbreviation: "OR",
  },
  {
    State: "Maryland",
    Abbreviation: "MD",
  },
  {
    State: "Massachusetts",
    Abbreviation: "MA",
  },
  {
    State: "Michigan",
    Abbreviation: "MI",
  },
  {
    State: "Minnesota",
    Abbreviation: "MN",
  },
  {
    State: "Mississippi",
    Abbreviation: "MS",
  },
  {
    State: "Missouri",
    Abbreviation: "MO",
  },
  {
    State: "Pennsylvania",
    Abbreviation: "PA",
  },
  {
    State: "Rhode Island",
    Abbreviation: "RI",
  },
  {
    State: "South Carolina",
    Abbreviation: "SC",
  },
  {
    State: "South Dakota",
    Abbreviation: "SD",
  },
  {
    State: "Tennessee",
    Abbreviation: "TN",
  },
  {
    State: "Texas",
    Abbreviation: "TX",
  },
  {
    State: "Utah",
    Abbreviation: "UT",
  },
  {
    State: "Vermont",
    Abbreviation: "VT",
  },
  {
    State: "Virginia",
    Abbreviation: "VA",
  },
  {
    State: "Washington",
    Abbreviation: "WA",
  },
  {
    State: "West Virginia",
    Abbreviation: "WV",
  },
  {
    State: "Wisconsin",
    Abbreviation: "WI",
  },
  {
    State: "Wyoming",
    Abbreviation: "WY",
  },
];

let sList = statesList.map((stateItem) => {
  return {
    label: `${stateItem.State}, ${stateItem.Abbreviation}`,
    value: stateItem.State,
  };
});

const Signup = () => {
  const [membership, setMembership] = useState("monthly");
  const [tc, setTc] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [provider, setProvider] = useState("email");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userRegistered, error, userDetails, amountPaid, referredBy } =
    useSelector((state) => state.register);
  const { coupenStatus, coupenInfo, coupenError, coupenDetail } = useSelector(
    (state) => state.verifycoupen
  );
  const formRef = useRef();
  const [id, setId] = useState("");
  const [discount, setDiscount] = useState(0);
  const [coupen, setCoupen] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [disableCoupen, setDisableCoupen] = useState(false);
  const [coupenApplied, setCoupenApplied] = useState(null);
  const [stateSelection, setStateSelection] = useState("");

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      membership: sessionStorage.getItem("subscriptionPack") || "monthly",
    },
    onSubmit: (values) => {
      if (tc) {
        if (formik.values.password === formik.values.confirmPassword) {
          console.log(values);
          setSpinner(true);
          dispatch(registerUser(values));
        } else {
          toast.error("Password Doesn't Match", toastOption);
        }
      } else {
        toast.error("Please Agree Terms & Conditions", toastOption);
      }
      return false;
    },
  });

  if (userRegistered) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Signup Conversion",
      action: "Successful Verification",
      label: "Signup Conversion",
      items: [
        {
          item_id: userDetails.membership,
          item_name: `$${
            membership === "monthly" ? 20 : membership === "trial" ? 0 : 160
          } for ${userDetails.membership} Subscription`,
          affiliation: referredBy,
          coupon: coupenApplied,
          currency: "USD",
          discount: discount,
          index: 0,
          price:
            membership === "monthly" ? 20 : membership === "trial" ? 0 : 160,
          quantity: 1,
        },
      ],
      affiliation: referredBy,
      coupon: coupenApplied,
      userEmail: userDetails.userEmail,
      userId: userDetails._id,
      userMembership: userDetails.membership,
      subscriptionPaid: amountPaid,
    });
    TagManager.dataLayer({ dataLayer: window.dataLayer });
    toast.success("User Succesfully Registered", toastOption);

    dispatch(reset());
    setTimeout(() => {
      if (provider === "email" && userDetails.membership !== "trial") {
        navigate("/confirmation", { state: userDetails });
      } else if (provider === "email" && userDetails.membership === "trial") {
        navigate("/guest-confirmation", { state: userDetails });
      } else {
        navigate("/setup-closest", { state: userDetails });
      }
    }, 5000);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  useEffect(() => {
    if (window.innerWidth < 640) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const checkCoupen = (coupenCode) => {
    if (coupenCode) {
      setSpinner(true);
      console.log(coupenCode);
      dispatch(verifyCoupenCode({ coupenCode }));
    } else {
      toast.error("Please Enter Coupon Code", toastOption);
    }
  };

  if (coupenInfo) {
    console.log(coupenInfo, coupenDetail);
    if (coupenInfo.amount_off) {
      if (
        (coupenDetail && coupenDetail.subscriptionType === "annual") ||
        coupenInfo.name.toLowerCase().indexOf("year") > -1
      ) {
        if (membership === "annualy") {
          setDiscount(coupenInfo.amount_off / 100);
          setDiscountPercent(0);
          toast.success("Coupon Code Applied", toastOption);
          setDisableCoupen(true);
          setCoupenApplied(coupenInfo.id);
        } else {
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      } else if (
        (coupenDetail && coupenDetail.subscriptionType === "monthly") ||
        coupenInfo.name.toLowerCase().indexOf("month") > -1
      ) {
        if (membership === "monthly") {
          setDiscount(coupenInfo.amount_off / 100);
          setDiscountPercent(0);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      }
    } else if (coupenInfo.percent_off) {
      if (
        (coupenDetail && coupenDetail.subscriptionType === "annual") ||
        coupenInfo.name.toLowerCase().indexOf("year") > -1
      ) {
        if (membership === "annualy") {
          setDiscount((160 * coupenInfo.percent_off) / 100);
          setDiscountPercent(coupenInfo.percent_off);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          console.log("FF");
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      } else if (
        (coupenDetail && coupenDetail.subscriptionType === "monthly") ||
        coupenInfo.name.toLowerCase().indexOf("month") > -1
      ) {
        if (membership === "monthly") {
          setDiscount((20 * coupenInfo.percent_off) / 100);
          setDiscountPercent(coupenInfo.percent_off);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          console.log("FF");
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      }
    }
    // setDiscount(coupenInfo)

    dispatch(resetcoupen());
    setSpinner(false);
  }

  if (coupenError) {
    toast.error(coupenError, toastOption);
    dispatch(resetcoupen());
    setDiscount(0);
    setDiscountPercent(0);
    setCoupen("");
    setCoupenApplied(null);
    setSpinner(false);
  }

  useEffect(() => {
    setDiscount(0);
    setDiscountPercent(0);
    setCoupen("");
    setCoupenApplied(null);
  }, [membership]);

  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: window.location.href,
          },
        ]}
      >
        <meta
          name="description"
          content="Join our community today! Sign up for exclusive access to deals, updates, and more. It's quick, easy, and free."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Closest Closet" />
        <meta
          property="og:description"
          content="Create an infinite closet by using your own items as currency. Every member earns one hanger for every item they post which they can use to buy other items."
        />
        <meta property="og:url" content="https://closestcloset.com" />

        <meta
          property="og:image"
          content="https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
        />
        <meta property="og:site_name" content="ClosestCloset" />
        <meta property="og:locale" content="en_US" />
        {/* <!-- Twitter --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@closestcloset" />
        <meta name="twitter:title" content="Closest Closet" />
        <meta property="twitter:url" content="https://closestcloset.com" />
        <meta
          name="twitter:description"
          content="Create an infinite closet by using your own items as currency. Every member earns one hanger for every item they post which they can use to buy other items."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
        />
      </Helmet>
      <div
        className="page-container"
        style={{ position: "relative", fontFamily: "LATO" }}
      >
        {spinner ? <Spinner /> : ""}
        <div className="signup">
          {/* <ToastContainer
          position="bottom-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
          <Founder />
          <div className="signup_right">
            <MembershipSelect
              membership={membership}
              formik={formik}
              setMembership={setMembership}
            />
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                address: "",
                city: "",
                state: "",
                zipCode: "",
                membership:
                  sessionStorage.getItem("subscriptionPack") || "monthly",
              }}
              initialErrors={{
                firstName: "Please enter your First Name",
                lastName: "Please enter your Last Name",
                address: "Please enter street address",
                city: "Please enter city",
                state: "Please enter state",
                zipCode: "Please enter zip code",
                email: "Enter Valid Email",
                password: "Please enter your Email",
                confirmPassword: "Please reenter your Password",
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .trim()
                  .required("Please enter your First Name"),
                lastName: Yup.string()
                  .trim()
                  .required("Please enter your Last Name"),
                email: Yup.string()
                  .email("Enter Valid Email")
                  .required("Please enter your Email"),
                address: Yup.string()
                  .trim()
                  .required("Please enter street address"),
                city: Yup.string().trim().required("Please enter city"),
                state: Yup.string().trim().required("Please enter state"),
                zipCode: Yup.string().trim().required("Please enter zip code"),
                password: Yup.string().required("Please enter your Password"),
                confirmPassword: Yup.string().required(
                  "Please reenter your Password"
                ),
              })}
              onSubmit={(values, actions) => {
                setSpinner(true);
                if (tc) {
                  if (values.password === values.confirmPassword) {
                    let payload = {};
                    console.log(values);
                    payload = { ...values };
                    payload.token = id;
                    payload.membership = membership;
                    payload.provider = provider;
                    payload.coupen = coupen;
                    const urlParams = new URLSearchParams(
                      window.location.search
                    );
                    if (urlParams.get("referral")) {
                      payload.referral = decodeURIComponent(
                        urlParams.get("referral")
                      );
                      console.log(payload.referral);
                    }

                    dispatch(registerUser(payload));
                  } else {
                    setSpinner(false);
                    toast.error("Password Doesn't Match", toastOption);
                  }
                } else {
                  setSpinner(false);
                  toast.error("Please Agree Terms & Conditions", toastOption);
                }
                return false;
              }}
              innerRef={formRef}
              render={(props) => (
                <SignupForm
                  setTc={setTc}
                  tc={tc}
                  status={status}
                  formRef={formRef}
                  membership={membership}
                  setId={setId}
                  {...props}
                  setProvider={setProvider}
                  provider={provider}
                  checkCoupen={checkCoupen}
                  discount={discount}
                  discountPercent={discountPercent}
                  setCoupen={setCoupen}
                  coupen={coupen}
                  disableCoupen={disableCoupen}
                  setDisableCoupen={setDisableCoupen}
                  setDiscount={setDiscount}
                  setCoupenApplied={setCoupenApplied}
                  setDiscountPercent={setDiscountPercent}
                  spinner={spinner}
                  setSpinner={setSpinner}
                  ScrollToFieldError={ScrollToFieldError}
                  stateSelection={stateSelection}
                  setStateSelection={setStateSelection}
                  sList={sList}
                  statesList={statesList}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
