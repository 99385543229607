import React from "react";
import {
  ElementsConsumer,
  CardNumberElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import CardSection from "./CardSection";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class MembershipPayment extends React.Component {
  handleSubmit = async (event) => {
    this.props.setSpinner(true);
    event.preventDefault();
    const toastOption = {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      toast.error(result.error.message, toastOption);
      //console.log(result.error.message);
      this.props.setSpinner(false);
    } else {
      //console.log(result.token);
      //this.props.submitForm();
      //console.log(this.props);
      this.props.submitForm(result.token);
    }
  };

  render() {
    return (
      <div>
        <form>
          <CardSection />
          <button
            type="button"
            onClick={this.handleSubmit}
            className="btn dark-btn"
            disabled={!this.props.stripe || this.props.spinner === true}
            style={{
              margin: "20px 0px",
              fontWeight: "bold",
              fontFamily: "LATO",
              fontSize: "16px",
            }}
          >
            {this.props.cardUpdate
              ? "Update My Card"
              : !this.props.stripe || this.props.spinner === true
              ? "Please Wait"
              : "Upgrade Membership"}
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm({
  submitForm,
  cardUpdate,
  spinner,
  setSpinner,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <MembershipPayment
          stripe={stripe}
          elements={elements}
          submitForm={submitForm}
          cardUpdate={cardUpdate}
          spinner={spinner}
          setSpinner={setSpinner}
        />
      )}
    </ElementsConsumer>
  );
}
