import React, { useEffect, useState } from "react";
import "./CheckoutDetails.css"; // Import the CSS file for styling
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import hanger from "../../../assets/images/hanger.svg";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutStatusChange,
  orderStatusChange,
  resetFulfill,
} from "../../../components/StateSlices/fulfillSlice";
import Spinner from "../../../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const CheckoutDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve modalData from localStorage when component mounts
    const storedModalData = localStorage.getItem("adminOrderModalData");

    if (storedModalData) {
      const parsedModalData = JSON.parse(storedModalData);
      setState(parsedModalData);

      // Remove modalData from localStorage after retrieving
      // localStorage.removeItem("adminOrderModalData");
    }
  }, []);

  const [state, setState] = useState(location?.state?.modalData);
  console.log("checoutPage State", state);

  const [loading, setLoading] = useState(false);

  const { fulFillError, fulFillStatus, fulFillInfo } = useSelector(
    (state) => state.fulfilled
  );

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleFulfill = (pId, cId, status, e) => {
    swal({
      title: `Mark this item ${status}`,
      text:
        status == "not fulfilled"
          ? `Are you sure this item was not received by the user?`
          : status == "fulfilled"
          ? "Are you sure this item was received by the user?"
          : status == "cancelled"
          ? "Are you sure this item was cancelled by the user?"
          : "Are you sure this item is missing from the store?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(checkoutStatusChange({ token, pId, cId, status }));
        setLoading(true);

        // let temp = orderDetails.map((ord) => {
        //   if (ord._id === cId) {
        //     return {
        //       ...ord,
        //       products: ord.products.map((prod) => {
        //         if (prod._id === pId) {
        //           return {
        //             ...prod,
        //             params: prod.params.map((param, index) => {
        //               if (index === 0) {
        //                 // Update only the first element in the params array
        //                 return {
        //                   ...param,
        //                   status: status,
        //                 };
        //               }
        //               return param;
        //             }),
        //           };
        //         }
        //         return prod;
        //       }),
        //     };
        //   }
        //   return ord;
        // });

        // setOrderDetails(temp);

        let temp2 = state.products.map((prod) => {
          if (prod._id === pId) {
            return {
              ...prod,
              params: prod.params.map((param, index) => {
                if (index === 0) {
                  // Update only the first element in the params array
                  return {
                    ...param,
                    status: status,
                  };
                }
                return param;
              }),
            };
          }
          return prod;
        });

        setState((prevModalData) => ({
          ...prevModalData,
          products: temp2,
        }));
      } else {
        let previousProdInfo = state?.products.filter(
          (prod) => prod._id == pId
        );
        e.target.value = previousProdInfo[0].params[0].status
          ? previousProdInfo[0].params[0].status
          : "not fulfilled";
      }
    });
  };

  const handleComplete = (cId) => {
    swal({
      title: `Mark Order Complete `,
      text: "Do you want to mark this complete? (Customer has picked up the order or it has been shipped)",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(orderStatusChange({ token, cId }));
        setLoading(false);
        // set
      } 
    });
  }

  useEffect(() => {
    if (fulFillInfo === "fulfilled") {
      toast.success("Item Sucessfully marked as Fulfilled!", toastOption);
      setLoading(false);

      dispatch(resetFulfill());
      // let token = localStorage.getItem("clttoken");
      // dispatch(
      //   getCheckoutOrders({
      //     token,
      //     p: pageNumber,
      //     search: orderSearchVal,
      //   })
      // );
    }else if(fulFillInfo === "orderfulfilled"){
      toast.success(`Ordered Item Status Sucessfully Updated!`, toastOption);
      setLoading(false);
      dispatch(resetFulfill());

      let temp2 = state.products.map((prod) => {

          return {
            ...prod,
            params: prod.params.map((param, index) => {
              if (index === 0) {
                // Update only the first element in the params array
                return {
                  ...param,
                  status: "fulfilled",
                  fulfilled:true,
                };
              }
              return param;
            }),
          };
      });

      setState((prevModalData) => ({
        ...prevModalData,
        products: temp2,
      }));
      
    }
  }, [fulFillInfo]);

  useEffect(() => {
    if (state) {
      let sellerPresent = false;
      state.products.map((prod) => {
        if (prod.sellerDetail) {
          sellerPresent = true;
        }
      });
      if (!sellerPresent) {
        let productsId = state.products.map((p) => {
          let userProdDetail = state.userInfo.filter((prodDetail) => {
            return prodDetail.productsId.includes(p._id);
          });
          let userMaster = state.usermasterDetail.filter((masterDetail) => {
            return masterDetail.userProductsId == userProdDetail[0]._id;
          });
          let sellerDetail = state.sellerInfo.filter((sellInfo) => {
            return sellInfo._id == userMaster[0].userDetailsId;
          });
          return {
            ...p,
            sellerDetail: sellerDetail[0],
          };
        });
        setState((prevState) => {
          return {
            ...prevState,
            products: productsId,
          };
        });
      }
    }
  }, [state]);

  return (
    <div className="checkout-container">
      {loading && <Spinner />}
      {state && (
        <>
          <h2 className="checkout-heading background-text h2 bold-text">
            Checkout Details
          </h2>

          <div className="checkout-container-2">
            <div className="chkout-col-65">
              <h3>Product Details</h3>
              <hr style={{ width: "100%" }} />
              <div className="product-list">
                {state?.products.map((prod, ind) => {
                  console.log(prod);
                  return (
                    <>
                      <div
                        class="product checkout-details-product"
                        key={ind}
                        style={{ position: "relative" }}
                      >
                        <div class="product-img checkout-details-product-image">
                          <LazyLoadImage
                            src={
                              prod.productImage[0]
                                ? prod.productImage[0]
                                : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                            }
                            alt="FP1"
                            effect="blur"
                            style={{
                              width: "150px",
                              minHeight: "150px",
                              maxHeight: "150px",
                              borderRadius: "5px",
                            }}
                          />
                          {/* <img src="http://placehold.it/500x500" /> */}
                        </div>
                        <div
                          class="product-content checkout-details-product-content"
                          style={{ margin: "0 1rem" }}
                        >
                          <h3 className="background-text">
                            {prod.productName}
                          </h3>
                          <div
                            className="product-details-row"
                            style={{
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "1rem",
                            }}
                          >
                            <div className="col">
                              <em
                                style={{
                                  margin: "8px 0",
                                  color: "var(--dark-grey-color)",
                                }}
                              >
                                {prod.closetName}
                              </em>
                              <hr className="product-hr" />
                              <div className="bold-text h6">
                                <span class="product-text price">
                                  $&nbsp;{prod.productPrice}
                                </span>
                                <span
                                  style={{
                                    margin: "auto 1rem",
                                    color: "var(--background-color)",
                                  }}
                                >
                                  |
                                </span>
                                <span class="product-text genre">
                                  <img
                                    style={{ height: "14px" }}
                                    src={hanger}
                                    alt="hanger icon"
                                  />{" "}
                                  {prod.productHanger}
                                </span>
                              </div>
                              <div
                                className="bold-text h6"
                                style={{ marginTop: "10px" }}
                              >
                                {/* <button className="btn" type="button" onClick={()=>handleFulfill(prod._id,modalData?._id)} disabled={prod.params[0].fulfilled?true:false} style={prod.params[0].fulfilled?{padding:"6px 20px"}:{padding:"6px 20px",background:"#f2998f"}}>
                                  {
                                    prod.params[0].fulfilled?"FulFilled!":"FulFilled?"
                                  }
                        
                            </button> */}
                                <select
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    handleFulfill(
                                      prod._id,
                                      state?._id,
                                      e.target.value,
                                      e
                                    );
                                  }}
                                  style={{
                                    padding: "8px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <option
                                    value="not fulfilled"
                                    selected={
                                      prod.params[0].status &&
                                      prod.params[0].status == "not fulfilled"
                                        ? true
                                        : false
                                    }
                                  >
                                    Not Fulfilled
                                  </option>
                                  <option
                                    value="fulfilled"
                                    selected={
                                      prod.params[0].status &&
                                      prod.params[0].status == "fulfilled"
                                        ? true
                                        : false
                                    }
                                  >
                                    Fulfilled
                                  </option>
                                  <option
                                    value="cancelled"
                                    selected={
                                      prod.params[0].status &&
                                      prod.params[0].status == "cancelled"
                                        ? true
                                        : false
                                    }
                                  >
                                    Cancelled
                                  </option>
                                  <option
                                    value="missing"
                                    selected={
                                      prod.params[0].status &&
                                      prod.params[0].status == "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    Missing
                                  </option>
                                </select>
                                {/* <Select

                              options={orderStatusOption}
                              onChange={(selectedOption) => {
                                
                              }}
                              //value={[{label:"notFullfilled",value:1}]}
                              styles={{
                                ...customStyles,
                              }}
                              placeholder="Order Status"
                            ></Select> */}
                              </div>
                            </div>
                            <div className="col">
                              <div className="h6">
                                <span className="bold-text background-text">
                                  Brand :{" "}
                                </span>
                                <span>{prod.productStats?.brand}</span>
                              </div>

                              <div className="h6">
                                <span className="bold-text background-text">
                                  Category :{" "}
                                </span>
                                <span>{prod.productStats?.category + ","}</span>
                              </div>

                              <div className="h6">
                                <span className="bold-text background-text">
                                  Condition :{" "}
                                </span>
                                <span>{prod.productStats?.condition}</span>
                              </div>

                              <div className="h6">
                                <span className="bold-text background-text">
                                  Stock :{" "}
                                </span>
                                <span>{prod.productStock}</span>
                              </div>

                              <div className="h6">
                                <span className="bold-text background-text">
                                  Size :{" "}
                                </span>{" "}
                                <span>{prod.productStats?.size}</span>
                              </div>
                            </div>
                          </div>
                          <div className="product-details-row product-details-row-2">
                            <div className="col" style={{ margin: 0 }}>
                              <div className="h6">
                                <span className="bold-text background-text">
                                  Shipping Method :{" "}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {prod.params[0]?.shippingMethod}
                                </span>
                              </div>
                              {prod.params[0]?.shippingMethod !== "free" && (
                                <div className="h6">
                                  <span className="bold-text background-text">
                                    Shipping Label :{" "}
                                  </span>
                                  <a href={prod.params[0]?.trackingUrl}>
                                    <span style={{ wordBreak: "break-all" }}>
                                      Tracking Link
                                    </span>
                                  </a>
                                </div>
                              )}
                            </div>
                            <div className="col" style={{ margin: 0 }}>
                              <div className="h6">
                                <span className="bold-text background-text">
                                  Seller Name :{" "}
                                </span>
                                <span>
                                  {prod.sellerDetail?.userFName +
                                    " " +
                                    prod.sellerDetail?.userLName}
                                </span>
                              </div>
                              <div className="h6">
                                <span className="bold-text background-text">
                                  Closet Name :{" "}
                                </span>
                                <span>{prod.sellerDetail?.closetName}</span>
                              </div>
                              <div className="h6">
                                <span className="bold-text background-text">
                                  Email Address :{" "}
                                </span>
                                <span>{prod.sellerDetail?.userEmail}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="product-operations"
                            style={{ position: "absolute", right: 0, top: 0 }}
                          >
                            <div
                              className="action_icon"
                              style={{
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              <Link
                                to={`/product/${encodeURIComponent(
                                  prod.productStats.brand
                                )}-${encodeURIComponent(prod.productName)}/${
                                  prod._id
                                }`}
                                state={{ id: prod._id }}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                              >
                                <div className="btn">
                                  <i class="fas fa-eye"></i>
                                </div>
                              </Link>
                              {/* <Link
                            to={`/editproduct/${encodeURIComponent(
                              prod.productStats.brand
                            )}-${encodeURIComponent(prod.productName)}`}
                            state={{ id: prod._id,from:"admin" }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="btn">
                              <i class="fas fa-pen"></i>
                            </div>
                          </Link> */}
                              {/* <Link to={"/my-closet"}> */}

                              {/* </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
            <div className="chkout-col-35">
              <div>
                <h3>Order Details</h3>
                <hr style={{ width: "100%" }} />
                <div className="content-con" style={{ textAlign: "left" }}>
                  <p
                    className="h6"
                    style={{ textAlign: "left", textTransform: "capitalize" }}
                  >
                    <strong>Ordered Id:</strong> &nbsp;
                    {state.orderId}
                  </p>
                  <p
                    className="h6"
                    style={{ textAlign: "left", textTransform: "capitalize" }}
                  >
                    <strong>Ordered By:</strong> &nbsp;
                    {state.orderdBy}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Total Hanger Used:</strong> &nbsp;
                    {state.hangerPaid}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Hanger Paid(By Money):</strong> &nbsp;
                    {state.hangerPurchased}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Price Paid:</strong> &nbsp;
                    {state.pricePaid}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Checkout Date:</strong> &nbsp;
                    {new Date(state.checkoutTime).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Checkout Time:</strong>{" "}
                    {new Date(state.checkoutTime).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    })}
                  </p>
                  <p
                    className="h6"
                    style={{ textAlign: "left", textTransform: "capitalize" }}
                  >
                    <strong>Order Status:</strong> &nbsp;
                    
                      {state.products.every((product) =>
                        product.params.every((param) =>
                          // param.status == "fulfilled"
                          ["fulfilled","cancelled","missing"].includes(param.status) && param.fulfilled == false
                        )
                      )?(
                        <>
                          <span>On Hold / Processed</span>
                          <button className="btn h6" style={{marginLeft:"8px"}} type="button" onClick={(e)=>{
                            
                            handleComplete(state._id)
                          }}>Mark Order Complete</button>
                        </>
                      ):state.products.every((product) =>
                        product.params.some((param) =>
                          param.fulfilled == false
                          // ["fulfilled"].includes(param.status)
                        )
                      )
                        ? "Not FullFilled":
                      "FullFilled"}
                  </p>
                </div>
              </div>
              <div>
                <h3>Shipping Details</h3>
                <hr style={{ width: "100%" }} />
                <div className="content-con" style={{ textAlign: "left" }}>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>Address:</strong> &nbsp;
                    {state.shippingAddress}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>City:</strong> &nbsp;
                    {state.shippingCity}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>State:</strong> &nbsp;
                    {state.shippingState}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong>ZipCode:</strong> &nbsp;
                    {state.shippingZipCode}
                  </p>
                </div>
              </div>
              <div>
                <h3>User Details</h3>
                <hr style={{ width: "100%" }} />
                <div className="content-con" style={{ textAlign: "left" }}>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong> Name:</strong> &nbsp;
                    {state.name}
                  </p>
                  <p className="h6" style={{ textAlign: "left" }}>
                    <strong> Email:</strong> &nbsp;
                    {state.userEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutDetails;
