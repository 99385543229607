import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  topClosetsStatus: "idle",
  topClosetsInfo: null,
  topClosetsError: null,
};

export const getTopCloset = createAsyncThunk(
  "static/getTopCloset",
  async ({ values }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/static/getTopClosets`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatetopClosetsSlice = createSlice({
  name: "topClosets",
  initialState,
  reducers: {
    resettopClosets(state, action) {
      state.updateMsg = null;
    },
  },
  extraReducers: {
    [getTopCloset.pending]: (state, action) => {
      state.topClosetsStatus = "loading";
    },
    [getTopCloset.fulfilled]: (state, action) => {
      state.topClosetsStatus = "succeeded";
      state.topClosetsInfo = action.payload.data;
    },
    [getTopCloset.rejected]: (state, action) => {
      state.topClosetsStatus = "failed";
      state.topClosetsError = action.payload;
    },
  },
});

export const { resettopClosets } = updatetopClosetsSlice.actions;
export default updatetopClosetsSlice.reducer;
