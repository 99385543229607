import React from "react";
import Products from "../Products/Products";
import Users from "../Users/Users";
import "./RightSection.css";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import Tabs from "../Tabs/Tabs";
import {
  usersTab,
  productsTab,
  coupensTab,
  ordersTab,
} from "../Tabs/TabsContent";
import Orders from "../Orders/Orders";
import CheckoutDetails from "../CheckoutDetails/CheckoutDetails";
import Brands from "../../../components/Brands/Brands";
import Category from "../../../components/Category/Category";
import Sizes from "../../../components/Sizes/Sizes";
import AddCMS from "../../../pages/AddCMS";
import CMS from "../../pages/CMS";
import EditCMS from "../../../pages/EditCMS";
import NotFound from "../../../pages/NotFound";
import StaticCMS from "../../../pages/StaticCMS";
import Coupens from "../Coupens/CoupensCreation";
import ImageResizer from "../ImageResizer/ImageResizer";
import Analytics from "../Analytics/Analytics";
import SubscriptionTab from "../SubscriptionTab/SubscriptionTab";

// import ViewProduct from "../ViewProduct/ViewProduct";
const RightSection = ({ isOpen, currentTab }) => {
  return (
    <div
      className={isOpen ? "adm-right-section" : "adm-right-section collapsed"}
    >
      <Routes>
        <Route path="/users" element={<Users tab="active" />} />
        <Route path="/products" element={<Tabs tabs={productsTab} />} />
        <Route path="/orders" element={<Tabs tabs={ordersTab} />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/category" element={<Category />} />
        <Route path="/sizes" element={<Sizes />} />
        <Route path="/checkout/:checkoutId" element={<CheckoutDetails />} />
        <Route path="/addcms" element={<AddCMS />} />
        <Route path="/cms" element={<CMS />} />
        <Route path="/editcms" element={<EditCMS />} />
        <Route path="/static" element={<StaticCMS />} />
        <Route path="/coupons" element={<Tabs tabs={coupensTab} />} />
        <Route path="/imageresize" element={<ImageResizer />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/subscriptions" element={<SubscriptionTab />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {/* {currentTab == "users" && <Users />}
      {currentTab == "products" && <Products />} */}
      {/* {currentTab == "viewProduct" && <ViewProduct />} */}
    </div>
  );
};

export default RightSection;
