import MyPurchases from "../../../components/MyPurchases/MyPurchases";
import MyCloset from "../../../pages/MyCloset";
import CoupensCreation from "../Coupens/CoupensCreation";
import CoupensDisplay from "../Coupens/CoupensDisplay";
import Products from "../Products/Products";
import Users from "../Users/Users";
import Orders from "../Orders/Orders";

export const usersTab = [
  {
    label: "Active Users",
    content: <Users tab="active" />,
  },
  // {
  //   label: "Banned Users",
  //   content: <Users tab="ban" />,
  // },
  // {
  //   label: "Paid Users",
  //   content: <Users tab="active" type="paid" />,
  // },
  // {
  //   label: "Free Users",
  //   content: <Users tab="active" type="free" />,
  // },
  // {
  //   label: "Paused Users",
  //   content: <Users tab="pause" />,
  // },
];

export const productsTab = [
  {
    label: "Active Products",
    content: <Products tab="active" />,
  },
  {
    label: "Banned Products",
    content: <Products tab="ban" />,
  },
  {
    label: "Reported Products",
    content: <Products tab="report" />,
  },
  {
    label: "Purchased Products",
    content: <Products tab="purchased" />,
  },
];

export const coupensTab = [
  {
    label: "Create New Coupon",
    content: <CoupensCreation tab="create" />,
  },
  {
    label: "Active Coupons",
    content: <CoupensDisplay tab="active" />,
  },
  {
    label: "Expired Coupons",
    content: <CoupensDisplay tab="expired" />,
  },
];

export const ordersTab = [
  {
    label: "Open Orders",
    content: <Orders tab={"open"} />,
  },
  {
    label: "Completed Orders",
    content: <Orders tab={"completed"} />,
  },
];
