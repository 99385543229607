import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendVerification,
  reset,
} from "../components/StateSlices/resendVerificationSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import danielle from "../assets/images/danielle.png";
import "./SetupClosest.css";

const Confirmation = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [resend, setResend] = useState(false);
  const User_Name = "Danielle Osborn";
  const { status, rmessage, error } = useSelector((state) => state.resend);
  //   const [image, setImage] = useState("")
  //   const [bannerImage, setBannerImage] = useState("")
  //   const { status, userInfo, error,profile } = useSelector(state => state.user)
  //   const { setupStatus, userFullInfo, setupError } = useSelector(state => state.closet)
  const userDetailsState = location.state;

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  useEffect(() => {
    if (!userDetailsState || userDetailsState === null) {
      navigate("/404");
    }
    let search = location.search;
    if (search) {
      let qry = new URLSearchParams(search);
      let error = qry.get("error");
      if (error === "AccountNotVerified") {
        setMessage("Account Not Verified");
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your Email Address"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      dispatch(resendVerification(values));
      setSpinner(true);
      return false;
    },
  });

  if (rmessage) {
    dispatch(reset());
    toast.success("Email Sent Successfully", toastOption);
    setSpinner(false);
    setResend(false);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  return (
    <div
      className="closest_setup_container"
      style={{ position: "relative", minHeight: "39vh" }}
    >
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <h1 className="section_title bold-text">
        {resend ? "Resend Verification Email" : "Welcome to Closest Closet"}
      </h1>

      {resend ? (
        <div className="closest_setup">
          {/* <p className="paragraph_title">Resend Verification Email</p> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="closest_setup_form">
              <div className="input_container">
                <label htmlFor="email_address">Email Address*</label>
                <input
                  type="email"
                  id="email_address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <small className="form-text text-danger">
                    {formik.errors.email}
                  </small>
                ) : null}
              </div>
              <button
                className="btn dark-btn"
                type="submit"
                disabled={status === "loading" ? true : false}
              >
                {status === "loading" ? "Please Wait" : "Resend Email"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <>
          {message ? (
            <p className="paragraph_title" style={{ color: "red" }}>
              {message}
            </p>
          ) : (
            ""
          )}
          <p className="paragraph_title">Verify Your Email Address</p>
          <div style={{ textAlign: "center" }}>
            Please check your email and verify your account to acess all
            features of Closest Closet.
          </div>
          <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
            <p className="paragraph_title">
              e Verification Email ? <br /> Please check your spam folder or{" "}
              <a
                href="javascript:void()"
                style={{ color: "#F26654" }}
                onClick={() => setResend(true)}
              >
                Click Here
              </a>{" "}
              to resend
            </p>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default Confirmation;
