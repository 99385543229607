import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
// import {
//   editProfile,
//   resetProfileEdit,
// } from "../components/StateSlices/editprofileSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
// import Spinner from "../components/Spinner/Spinner";

import "react-toastify/dist/ReactToastify.css";
import "./AdminEditProfile.css";
import axios from "axios";
import Select from "react-select";
import CryptoJS from "crypto-js";
import {
  editProfile,
  resetProfileEdit,
} from "../../../components/StateSlices/editprofileSlice";
import Spinner from "../../../components/Spinner/Spinner";
import {
  getuserInfoForAdmin,
  reset,
} from "../../../components/StateSlices/getUserSliceForAdmin";
import {
  getUsers,
  resetUpdateStatus,
} from "../../../components/StateSlices/adminSlice";

const AdminEditProfile = ({
  userId,
  setIsEditProfileModal,
  setAllUsersInfoState,
  pageNumber,
  tab,
  userSearchVal,
  type,
}) => {
  //   const [membership,setMembership] = useState("free")
  //   const [tc, setTc] = useState(false)
  // jaypatel3215447@gmail.com
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const { status, userInfoForAdmin, error, profileForAdmin } = useSelector(
    (state) => state.userForAdmin
  );
  const { profileStatus, userUpdatedInfo, profileError } = useSelector(
    (state) => state.editprofile
  );
  const [dragActive, setDragActive] = useState(false);
  const [dragActive2, setDragActive2] = useState(false);
  const [shirtSizeOptions, setShirtSizeOptions] = useState([]);
  const [shoeSizeOptions, setShoeSizeOptions] = useState([]);
  const [dressSizeOptions, setDressSizeOptions] = useState([]);
  const [stateSelection, setStateSelection] = useState("");
  const [gender, setGender] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [pantSize, setPantSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [waistSize, setWaistSize] = useState("");

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      description: "",
      iconUrl: "",
      bannerUrl: "",
      gender: "",
      shirtSize: "",
      dressSize: "",
      shoeSize: "",
      closetName: "",
      waistSize: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your First Name"),
      lastName: Yup.string().required("Please enter your Last Name"),
      phone: Yup.number()
        .typeError("Enter a valid zipcode")
        .required("Please enter your phone number"),
      address: Yup.string().required("Please enter your street address"),
      city: Yup.string().required("Please enter your city"),
      state: Yup.string().required("Please select your state"),
      zipCode: Yup.number()
        .typeError("Enter a valid zipcode")
        .max(99950, "Please enter valid zipcode")
        .required("Please enter your zipcode"),
      gender: Yup.string().required("Please select your gender"),
      shirtSize: Yup.string().required("Please select the shirt size"),
      dressSize: Yup.string().required("Please select the dress size"),
      shoeSize: Yup.string().required("Please select the shoe size"),
      closetName: Yup.string().required("Please enter Name of the Closet"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      window.scrollTo(0, 480);
      let token = localStorage.getItem("clttoken");
      if (token) {
        setSpinner(true);
        postDetails(values, setSubmitting, setValues, token);
      } else {
        navigate("/");
      }

      return false;
    },
  });
  const location = useLocation();
  const handleCloseEditProfileModal = () => {
    const updatedUserInfo = {
      closetName: userUpdatedInfo?.user?.closetName,
      userFName: userUpdatedInfo?.user?.userFName,
      userLName: userUpdatedInfo?.user?.userLName,
      email: userUpdatedInfo?.user?.userEmail,
    };
    // setAllUsersInfoState((prevState) =>
    //   prevState.map((user) =>
    //     user._id === userUpdatedInfo._id
    //       ? { ...user, ...updatedUserInfo }
    //       : user
    //   )
    // );
    // dispatch(resetUpdateStatus());
    const token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        getUsers({
          token,
          p: pageNumber,
          search: userSearchVal,
          status: tab,
          type: type,
        })
      );
      setIsEditProfileModal(false);
    }
  };

  if (userUpdatedInfo) {
    toast.success("User Profile Updated", toastOption);
    dispatch(resetProfileEdit());
    handleCloseEditProfileModal();
  }

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      setSpinner(true);
      console.log("userId", userId);
      dispatch(getuserInfoForAdmin({ token: token, id: userId }));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (profileForAdmin && userInfoForAdmin) {
      if (!userInfoForAdmin.userDetailsId.verified) {
        // navigate("/confirmation?error=AccountNotVerified", {
        //   state: userInfoForAdmin,
        // });
        toast.error("User is not verified", toastOption);
        setIsEditProfileModal(false);
      } else {
        // if (profileForAdmin === "Incomplete") {
        //   toast.info("User Profile is Incomplete", toastOption);
        // }
        formik.setFieldValue(
          "firstName",
          userInfoForAdmin.userDetailsId.userFName
        );
        formik.setFieldValue(
          "lastName",
          userInfoForAdmin.userDetailsId.userLName
        );
        formik.setFieldValue("phone", userInfoForAdmin.userDetailsId.userPhone);
        formik.setFieldValue("email", userInfoForAdmin.userDetailsId.userEmail);
        formik.setFieldValue(
          "address",
          userInfoForAdmin.userDetailsId.userAddress
        );
        formik.setFieldValue("city", userInfoForAdmin.userDetailsId.userCity);
        formik.setFieldValue("state", userInfoForAdmin.userDetailsId.userState);
        formik.setFieldValue(
          "zipCode",
          userInfoForAdmin.userDetailsId.userZipCode
        );
        formik.setFieldValue(
          "description",
          userInfoForAdmin.userDetailsId.userDesc
        );
        formik.setFieldValue(
          "gender",
          userInfoForAdmin.userDetailsId.userGender
        );
        formik.setFieldValue(
          "shirtSize",
          userInfoForAdmin.userDetailsId.userShirtSize
        );
        formik.setFieldValue(
          "dressSize",
          userInfoForAdmin.userDetailsId.userDressSize
        );
        formik.setFieldValue(
          "shoeSize",
          userInfoForAdmin.userDetailsId.userShoeSize
        );
        formik.setFieldValue(
          "closetName",
          userInfoForAdmin.userDetailsId.closetName
        );
        setImage(userInfoForAdmin.userDetailsId.userIcon);
        setBannerImage(userInfoForAdmin.userDetailsId.userBanner);
        setSpinner(false);
        dispatch(reset());
        //   }
      }
    }
  }, [profileForAdmin]);

  useEffect(() => {
    if (profileError) {
      toast.error(
        "Profile Error: Something Went Wrong Please Try Again",
        toastOption
      );
      setSpinner(false);
    }
  }, [profileError]);

  const postDetails = (values, setSubmitting, setValues, token) => {
    let payload = { ...values };
    const timestamp = Math.round(new Date().getTime() / 1000);
    const params = {
      timestamp: timestamp,
      transformation: "f_webp,q_70,c_scale,w_1000",
      upload_preset: "closet-closest",
      // add any additional parameters here, such as transformation options
    };
    const signature = CryptoJS.SHA1(
      `timestamp=${params.timestamp}&transformation=${
        params.transformation
      }&upload_preset=${params.upload_preset}${"5Tr2RNxBz-S8UBp-Y9c9SSo2S04"}`
    ).toString();

    // const signature = cl.utils.api_sign_request({
    //     timestamp: timestamp,

    //     }, "5Tr2RNxBz-S8UBp-Y9c9SSo2S04");

    const uploader = [0, 1].map((index) => {
      let data = new FormData();

      data.append("upload_preset", "closet-closest");
      data.append("cloud_name", "closet-closest");
      data.append("transformation", "f_webp,q_70,c_scale,w_1000");
      data.append("signature", signature);
      data.append("timestamp", timestamp);
      data.append("api_key", "738997887579849");

      if (index === 0) {
        if (typeof image !== "string") {
          data.append("file", image);

          console.log(image);
          return axios
            .post(
              "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
              data,
              {
                headers: { "X-Requested-With": "XMLHttpRequest" },
              }
            )
            .then((response) => {
              const data = response.data;
              const fileURL = data.secure_url;
              payload = { ...payload, iconUrl: fileURL };
              setValues(payload);
            })
            .catch((e) => {
              console.log(e);
              throw new Error("Something went wrong");
            });
        } else {
          payload = { ...payload, iconUrl: image };
        }
      } else {
        if (typeof bannerImage !== "string") {
          data.append("file", bannerImage);
          return axios
            .post(
              "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
              bannerImage,
              {
                headers: { "X-Requested-With": "XMLHttpRequest" },
              }
            )
            .then((response) => {
              const data = response.data;
              const fileURL = data.secure_url;
              payload = { ...payload, bannerUrl: fileURL };
              setValues(payload);
            })
            .catch((e) => {
              console.log(e);
              throw new Error("Something went wrong");
            });
        } else {
          payload = { ...payload, bannerUrl: bannerImage };
        }
      }
    });
    axios
      .all(uploader)
      .then(() => {
        setValues(payload);
        console.log(payload);
        dispatch(editProfile({ token, values: payload, id: userId }));
        setSubmitting(false);
      })
      .catch((e) => {
        toast.error("Something Went Wrong Please Try Again", toastOption);
        setSpinner(false);
      });
    return;

    // fetch("https://api.cloudinary.com/v1_1/closet-closest/image/upload",{
    //     method:"post",
    //     body:data
    // })
    // .then(res=>res.json())
    // .then(async data=>{
    //     console.log(data.url);
    //     const payload = {...values, iconUrl: data.url }
    //     setValues(payload)
    //     console.log(payload);
    //     let temp = {...payload};
    //     data = new FormData()
    //     data.append('file',bannerImage)
    //     data.append('upload_preset',"closet-closest")
    //     data.append("cloud_name","closet-closest")

    //     fetch("https://api.cloudinary.com/v1_1/closet-closest/image/upload",{
    //         method:"post",
    //         body:data
    //     })
    //     .then(res=>res.json())
    //     .then(async data=>{

    //         console.log(data.url);
    //         const payload = {...temp, bannerUrl: data.url }
    //         setValues(payload)
    //         console.log(payload);
    //         dispatch(closetSetup({token,values:payload}))
    //         setSubmitting(false);

    //         return;
    //     })
    //     .catch(e=>{
    //         console.log(e)
    //     })
    //     return;
    // })
    // .catch(e=>{
    //     console.log(e)
    // })
  };
  const handleDrag = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      if (type == "icon") {
        setDragActive(true);
      } else {
        setDragActive2(true);
      }
    } else if (e.type === "dragleave") {
      setDragActive(false);
      setDragActive2(false);
    }
  };

  const handleDrop = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setDragActive2(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (type == "icon") {
        setImage(e.dataTransfer.files[0]);
      } else {
        setBannerImage(e.dataTransfer.files[0]);
      }
    }
  };

  const optionMenShirtSize = [
    {
      value: "Shirt - XXS",
      label: "Shirt - XXS",
    },
    {
      value: "Shirt - XS",
      label: "Shirt - XS",
    },
    {
      value: "Shirt - Small",
      label: "Shirt - Small",
    },
    {
      value: "Shirt - Medium",
      label: "Shirt - Medium",
    },
    {
      value: "Shirt - Large",
      label: "Shirt - Large",
    },
    {
      value: "Shirt - XL",
      label: "Shirt - XL",
    },
    {
      value: "Shirt - 2X",
      label: "Shirt - 2X",
    },
    {
      value: "Shirt - 3X",
      label: "Shirt - 3X",
    },
    {
      value: "Shirt - 4X",
      label: "Shirt - 4X",
    },
    {
      value: "Shirt - Neck 14.5",
      label: "Shirt - Neck 14.5",
    },
    {
      value: "Shirt - Neck 15",
      label: "Shirt - Neck 15",
    },
    {
      value: "Shirt - Neck 15.5",
      label: "Shirt - Neck 15.5",
    },
    {
      value: "Shirt - Neck 16",
      label: "Shirt - Neck 16",
    },
    {
      value: "Shirt - Neck 16.5",
      label: "Shirt - Neck 16.5",
    },
    {
      value: "Shirt - Neck 17",
      label: "Shirt - Neck 17",
    },
    {
      value: "Shirt - Neck 17.5",
      label: "Shirt - Neck 17.5",
    },
    {
      value: "Shirt - Neck 18",
      label: "Shirt - Neck 18",
    },
  ];

  const optionMenDressSize = [
    {
      value: "Pants - 1",
      label: "Pants - 1",
    },
    {
      value: "Pants - 3",
      label: "Pants - 3",
    },
    {
      value: "Pants - 5",
      label: "Pants - 5",
    },
    {
      value: "Pants - 7",
      label: "Pants - 7",
    },
    {
      value: "Pants -09",
      label: "Pants -09",
    },
    {
      value: "Pants - 11",
      label: "Pants - 11",
    },
    {
      value: "Pants - 13",
      label: "Pants - 13",
    },
    {
      value: "Pants - 15",
      label: "Pants - 15",
    },
    {
      value: "Pants - 22",
      label: "Pants - 22",
    },
    {
      value: "Pants - 23",
      label: "Pants - 23",
    },
    {
      value: "Pants - 24",
      label: "Pants - 24",
    },
    {
      value: "Pants - 25",
      label: "Pants - 25",
    },
    {
      value: "Pants - 26",
      label: "Pants - 26",
    },
    {
      value: "Pants - 27",
      label: "Pants - 27",
    },
    {
      value: "Pants - 28",
      label: "Pants - 28",
    },
    {
      value: "Pants - 29",
      label: "Pants - 29",
    },
    {
      value: "Pants - 30",
      label: "Pants - 30",
    },
    {
      value: "Pants - 31",
      label: "Pants - 31",
    },
    {
      value: "Pants - 32",
      label: "Pants - 32",
    },
    {
      value: "Pants - 33",
      label: "Pants - 33",
    },
    {
      value: "Pants - 34",
      label: "Pants - 34",
    },
    {
      value: "Pants - 35",
      label: "Pants - 35",
    },
    {
      value: "Pants - 37",
      label: "Pants - 37",
    },
    {
      value: "Pants - 39",
      label: "Pants - 39",
    },
    {
      value: "Pants - 41",
      label: "Pants - 41",
    },
    {
      value: "Pants - 43",
      label: "Pants - 43",
    },
  ];

  const optionMenShoeSize = [
    { value: "Shoes - 8.5", label: "Shoes - 8.5" },
    { value: "Shoes - 9", label: "Shoes - 9" },
    { value: "Shoes - 9.5", label: "Shoes - 9.5" },
    { value: "Shoes - 10.5", label: "Shoes - 10.5" },
    { value: "Shoes - 10", label: "Shoes - 10" },
    { value: "Shoes - 11", label: "Shoes - 11" },
    { value: "Shoes - 11.5", label: "Shoes - 11.5" },
    { value: "Shoes - 12", label: "Shoes - 12" },
    { value: "Shoes - 12.5", label: "Shoes - 12.5" },
    { value: "Shoes - 13", label: "Shoes - 13" },
    { value: "Shoes - 13.5", label: "Shoes - 13.5" },
    { value: "Shoes - 14", label: "Shoes - 14" },
    { value: "Shoes - 14.5", label: "Shoes - 14.5" },
    { value: "Shoes - 15", label: "Shoes - 15" },
    { value: "Shoes - 15.5", label: "Shoes - 15.5" },
    { value: "Shoes - 16", label: "Shoes - 16" },
  ];

  const optionWomenShoeSize = [
    { value: "Shoes - 5", label: "Shoes - 5" },
    { value: "Shoes - 5.5", label: "Shoes - 5.5" },
    { value: "Shoes - 6", label: "Shoes - 6" },
    { value: "Shoes - 6.5", label: "Shoes - 6.5" },
    { value: "Shoes - 7", label: "Shoes - 7" },
    { value: "Shoes - 7.5", label: "Shoes - 7.5" },
    { value: "Shoes - 8", label: "Shoes - 8" },
    { value: "Shoes - 8.5", label: "Shoes - 8.5" },
    { value: "XShoes - 9", label: "Shoes - 9" },
    { value: "Shoes - 9.5", label: "Shoes - 9.5" },
    { value: "Shoes - 10.5", label: "Shoes - 10.5" },
    { value: "Shoes - 10", label: "Shoes - 10" },
    { value: "Shoes - 11", label: "Shoes - 11" },
    { value: "Shoes - 11.5", label: "Shoes - 11.5" },
    { value: "Shoes - 12", label: "Shoes - 12" },
    { value: "Shoes - 12.5", label: "Shoes - 12.5" },
    { value: "Shoes - 13", label: "Shoes - 13" },
    { value: "Shoes - 13.5", label: "Shoes - 13.5" },
    { value: "Shoes - 14", label: "Shoes - 14" },
    { value: "Shoes - 14.5", label: "Shoes - 14.5" },
    { value: "Shoes - 15", label: "Shoes - 15" },
    { value: "Shoes - 15.5", label: "Shoes - 15.5" },
    { value: "Shoes - 16", label: "Shoes - 16" },
  ];

  const optionWomenShirtSize = [
    { value: "Shirt / Top - XXS", label: "Shirt / Top - XXS" },
    { value: "Shirt / Top - XS", label: "Shirt / Top - XS" },
    { value: "Shirt / Top - Small", label: "Shirt / Top - Small" },
    { value: "Shirt / Top - Medium", label: "Shirt / Top - Medium" },
    { value: "Shirt / Top - Large", label: "Shirt / Top - Large" },
    { value: "Shirt / Top - XL", label: "Shirt / Top - XL" },
    { value: "Shirt / Top - 2X", label: "Shirt / Top - 2X" },
    { value: "Shirt /Top - 3X", label: "Shirt /Top - 3X" },
    { value: "Shirt /Top - 4X", label: "Shirt /Top - 4X" },
  ];

  const optionWomenDressSize = [
    { value: "Dress - 0", label: "Dress - 0" },
    { value: "Dress - 2", label: "Dress - 2" },
    { value: "Dress - 4", label: "Dress - 4" },
    { value: "Dress - 6", label: "Dress - 6" },
    { value: "Dress - 8", label: "Dress - 8" },
    { value: "Dress - 10", label: "Dress - 10" },
    { value: "Dress - 12", label: "Dress - 12" },
    { value: "Dress - 14", label: "Dress - 14" },
    { value: "Dress - 16", label: "Dress - 16" },
    { value: "Dress - 18", label: "Dress - 18" },
    { value: "Dress - 20", label: "Dress - 20" },
    { value: "Dress - 22", label: "Dress - 22" },
    { value: "Dress - 24", label: "Dress - 24" },
    { value: "Dress - 26", label: "Dress - 26" },
    { value: "Dress - 28", label: "Dress - 28" },
    { value: "Dress - 30", label: "Dress - 30" },
    { value: "Dress - 32", label: "Dress - 32" },
    { value: "Dress - 34", label: "Dress - 34" },
    { value: "Dress - 36", label: "Dress - 36" },
  ];

  const optionWomenWaistSize = [
    { value: "Waist - 0", label: "Waist - 0" },
    { value: "Waist - 2", label: "Waist - 2" },
    { value: "Waist - 4", label: "Waist - 4" },
    { value: "Waist - 6", label: "Waist - 6" },
    { value: "Waist - 8", label: "Waist - 8" },
    { value: "Waist -10", label: "Waist -10" },
    { value: "Waist - 12", label: "Waist - 12" },
    { value: "Waist - 14", label: "Waist - 14" },
    { value: "Waist - 16", label: "Waist - 16" },
    { value: "Waist - 23", label: "Waist - 23" },
    { value: "Waist - 24", label: "Waist - 24" },
    { value: "Waist - 25", label: "Waist - 25" },
    { value: "Waist - 26", label: "Waist - 26" },
    { value: "Waist - 27", label: "Waist - 27" },
    { value: "Waist - 28", label: "Waist - 28" },
    { value: "Waist - 29", label: "Waist - 29" },
    { value: "Waist - 30", label: "Waist - 30" },
    { value: "Waist - 31", label: "Waist - 31" },
    { value: "Waist - 32", label: "Waist - 32" },
    { value: "Waist - 33", label: "Waist - 33" },
    { value: "Waist - 34", label: "Waist - 34" },
    { value: "Waist - 35", label: "Waist - 35" },
    { value: "Waist - 36", label: "Waist - 36" },
    { value: "Waist - 38", label: "Waist - 38" },
    { value: "Waist - 40", label: "Waist - 40" },
    { value: "Waist - 42", label: "Waist - 42" },
    { value: "Waist - 44", label: "Waist - 44" },
  ];

  const statesList = [
    {
      State: "Alabama",
      Abbreviation: "AL",
    },
    {
      State: "Alaska",
      Abbreviation: "AK",
    },
    {
      State: "Arizona",
      Abbreviation: "AZ",
    },
    {
      State: "Arkansas",
      Abbreviation: "AR",
    },
    {
      State: "California",
      Abbreviation: "CA",
    },
    {
      State: "Colorado",
      Abbreviation: "CO",
    },
    {
      State: "Connecticut",
      Abbreviation: "CT",
    },
    {
      State: "Delaware",
      Abbreviation: "DE",
    },
    {
      State: "District of Columbia",
      Abbreviation: "DC",
    },
    {
      State: "Florida",
      Abbreviation: "FL",
    },
    {
      State: "Georgia",
      Abbreviation: "GA",
    },
    {
      State: "Hawaii",
      Abbreviation: "HI",
    },
    {
      State: "Idaho",
      Abbreviation: "ID",
    },
    {
      State: "Illinois",
      Abbreviation: "IL",
    },
    {
      State: "Indiana",
      Abbreviation: "IN",
    },
    {
      State: "Iowa",
      Abbreviation: "IA",
    },
    {
      State: "Kansas",
      Abbreviation: "KS",
    },
    {
      State: "Kentucky",
      Abbreviation: "KY",
    },
    {
      State: "Louisiana",
      Abbreviation: "LA",
    },
    {
      State: "Maine",
      Abbreviation: "ME",
    },
    {
      State: "Montana",
      Abbreviation: "MT",
    },
    {
      State: "Nebraska",
      Abbreviation: "NE",
    },
    {
      State: "Nevada",
      Abbreviation: "NV",
    },
    {
      State: "New Hampshire",
      Abbreviation: "NH",
    },
    {
      State: "New Jersey",
      Abbreviation: "NJ",
    },
    {
      State: "New Mexico",
      Abbreviation: "NM",
    },
    {
      State: "New York",
      Abbreviation: "NY",
    },
    {
      State: "North Carolina",
      Abbreviation: "NC",
    },
    {
      State: "North Dakota",
      Abbreviation: "ND",
    },
    {
      State: "Ohio",
      Abbreviation: "OH",
    },
    {
      State: "Oklahoma",
      Abbreviation: "OK",
    },
    {
      State: "Oregon",
      Abbreviation: "OR",
    },
    {
      State: "Maryland",
      Abbreviation: "MD",
    },
    {
      State: "Massachusetts",
      Abbreviation: "MA",
    },
    {
      State: "Michigan",
      Abbreviation: "MI",
    },
    {
      State: "Minnesota",
      Abbreviation: "MN",
    },
    {
      State: "Mississippi",
      Abbreviation: "MS",
    },
    {
      State: "Missouri",
      Abbreviation: "MO",
    },
    {
      State: "Pennsylvania",
      Abbreviation: "PA",
    },
    {
      State: "Rhode Island",
      Abbreviation: "RI",
    },
    {
      State: "South Carolina",
      Abbreviation: "SC",
    },
    {
      State: "South Dakota",
      Abbreviation: "SD",
    },
    {
      State: "Tennessee",
      Abbreviation: "TN",
    },
    {
      State: "Texas",
      Abbreviation: "TX",
    },
    {
      State: "Utah",
      Abbreviation: "UT",
    },
    {
      State: "Vermont",
      Abbreviation: "VT",
    },
    {
      State: "Virginia",
      Abbreviation: "VA",
    },
    {
      State: "Washington",
      Abbreviation: "WA",
    },
    {
      State: "West Virginia",
      Abbreviation: "WV",
    },
    {
      State: "Wisconsin",
      Abbreviation: "WI",
    },
    {
      State: "Wyoming",
      Abbreviation: "WY",
    },
  ];

  let sList = statesList.map((stateItem) => {
    return {
      label: `${stateItem.State}, ${stateItem.Abbreviation}`,
      value: stateItem.State,
    };
  });

  let genderList = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Non-Binary", label: "Non-Binary" },
  ];

  useEffect(() => {
    console.log(formik.values.gender);
    if (formik.values.gender !== "Select Gender") {
      if (formik.values.gender === "Male") {
        setShirtSizeOptions(optionMenShirtSize);
        setShoeSizeOptions(optionMenShoeSize);
        setDressSizeOptions(optionMenDressSize);
      } else if (formik.values.gender === "Female") {
        setShirtSizeOptions(optionWomenShirtSize);
        setShoeSizeOptions(optionWomenShoeSize);
        setDressSizeOptions(optionWomenDressSize);
      } else if (formik.values.gender === "Non-Binary") {
        setShirtSizeOptions([...optionMenShirtSize, ...optionWomenShirtSize]);
        setShoeSizeOptions([...optionMenShoeSize, ...optionWomenShoeSize]);
        setDressSizeOptions([...optionMenDressSize, ...optionWomenDressSize]);
      }
    } else {
      setShirtSizeOptions([]);
      setShoeSizeOptions([]);
      setDressSizeOptions([]);
    }
  }, [formik.values.gender]);

  return (
    <>
      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="closest_setup_container" style={{ position: "relative" }}>
        {spinner ? (
          <div style={{ width: "100%", height: "520px" }}>
            <Spinner />
          </div>
        ) : (
          <div
            className="closest_setup"
            style={{ width: "100%", marginTop: 0 }}
          >
            <h3 className="background-text">Edit User Profile</h3>
            <hr />
            {/* <h1 className="section_title bold-text">Edit Profile</h1> */}
            <form style={{ marginTop: 0 }} onSubmit={formik.handleSubmit}>
              <div className="closest_setup_form">
                <div className="personal_info_container">
                  <div className="input_container">
                    <label htmlFor="first_name">First Name*</label>
                    <input
                      type="text"
                      id="first_name"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <small className="form-text text-danger">
                        {formik.errors.firstName}
                      </small>
                    ) : null}
                  </div>
                  <div className="input_container">
                    <label htmlFor="last_name">Last Name*</label>
                    <input
                      type="text"
                      id="last_name"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <small className="form-text text-danger">
                        {formik.errors.lastName}
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className="input_container">
                  <label htmlFor="email_address">
                    Email Address*{" "}
                    {/* {userInfo.userDetailsId.userEmail !== formik.values.email && (
                  <span
                    style={{
                      color: "red",
                      float: "right",
                      borderBottom: "1px solid red",
                      cursor: "pointer",
                    }}
                  >
                    Verify Email
                  </span>
                )} */}
                  </label>
                  <input
                    type="email"
                    id="email_address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    // disabled
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="phone">Closet Phone Number*</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <small className="form-text text-danger">
                      {formik.errors.phone}
                    </small>
                  ) : null}
                </div>
                <div className="input_container">
                  <label htmlFor="closetName">Closet Name*</label>
                  <input
                    type="text"
                    id="closetName"
                    name="closetName"
                    onChange={formik.handleChange}
                    value={formik.values.closetName}
                  />
                  {formik.touched.closetName && formik.errors.closetName ? (
                    <small className="form-text text-danger">
                      {formik.errors.closetName}
                    </small>
                  ) : null}
                </div>
                <div className="input_container">
                  <label htmlFor="address">Street Address*</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <small className="form-text text-danger">
                      {formik.errors.address}
                    </small>
                  ) : null}
                </div>
                <div className="personal_info_container">
                  <div className="input_container">
                    <label htmlFor="city">City*</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <small className="form-text text-danger">
                        {formik.errors.city}
                      </small>
                    ) : null}
                  </div>

                  <div className="select_container">
                    <label htmlFor="state">State*</label>
                    <Select
                      options={sList}
                      id="state"
                      name="state"
                      isSearchable={true}
                      onChange={(selectedOption) => {
                        setStateSelection(selectedOption);
                        formik.setFieldValue("state", selectedOption.value);
                      }}
                      value={stateSelection}
                      placeholder={formik.values.state}
                    >
                      {/* <option selected>Select State</option>
                  {statesList.map((item, index) => {
                    return (
                      <option value={item.State} key={index}>
                        {item.State + ", " + item.Abbreviation}
                      </option>
                    );
                  })} */}
                    </Select>
                    {formik.touched.state && formik.errors.state ? (
                      <small className="form-text text-danger">
                        {formik.errors.state}
                      </small>
                    ) : null}
                  </div>
                  <div className="input_container">
                    <label htmlFor="zipCode">Zip Code*</label>
                    <input
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      onChange={formik.handleChange}
                      value={formik.values.zipCode}
                    />
                    {formik.touched.zipCode && formik.errors.zipCode ? (
                      <small className="form-text text-danger">
                        {formik.errors.zipCode}
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className="text_container">
                  <label htmlFor="description">Closet Description</label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  ></textarea>
                </div>

                <div className="img_upload_container">
                  <div className="file_icon_container">
                    {image ? (
                      <div className="preview_image">
                        <div className="item_title">Icon Preview</div>
                        <div className="preview_image_container">
                          <div
                            className="img_name_cont"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              margin: "10px 0px",
                            }}
                          >
                            <div className={`img_icon_cont`}>
                              <div className="img_name">
                                {/* <div className='cross' onClick={()=>removeImg(index)}>X</div> */}
                                <img
                                  src={
                                    typeof image === "string"
                                      ? image
                                      : URL.createObjectURL(image)
                                  }
                                  alt={image.name}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>Closet Icon</div>
                    <label
                      htmlFor="closet_icon"
                      className={`file_upload ${
                        dragActive ? "drag-active" : ""
                      }`}
                      onDragEnter={(e) => handleDrag(e, "icon")}
                      onDragLeave={(e) => handleDrag(e, "icon")}
                      onDragOver={(e) => handleDrag(e, "icon")}
                      onDrop={(e) => handleDrop(e, "icon")}
                    >
                      <div className="icon_container add_icon_container">
                        <i class="fas fa-regular fa-camera"></i>
                        <p className="item_title" style={{}}>
                          {" "}
                          or drag them in
                        </p>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="closet_icon"
                      hidden
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                  <div className="file_icon_container">
                    {bannerImage ? (
                      <div className="preview_image">
                        <div className="item_title">Banner Preview</div>
                        <div className="preview_image_container">
                          <div
                            className="img_name_cont"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              margin: "10px 0px",
                            }}
                          >
                            <div className={`img_icon_cont`}>
                              <div className="img_name">
                                {/* <div className='cross' onClick={()=>removeImg(index)}>X</div> */}
                                <img
                                  src={
                                    typeof bannerImage === "string"
                                      ? bannerImage
                                      : URL.createObjectURL(bannerImage)
                                  }
                                  alt={bannerImage.name}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>Closet Banner Image</div>
                    <label
                      htmlFor="closet_banner"
                      className={`file_upload ${
                        dragActive2 ? "drag-active" : ""
                      }`}
                      onDragEnter={(e) => handleDrag(e, "banner")}
                      onDragLeave={(e) => handleDrag(e, "banner")}
                      onDragOver={(e) => handleDrag(e, "banner")}
                      onDrop={(e) => handleDrop(e, "banner")}
                    >
                      <div className="icon_container add_icon_container">
                        <i class="fas fa-regular fa-camera"></i>
                        <p className="item_title" style={{}}>
                          {" "}
                          or drag them in
                        </p>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="closet_banner"
                      hidden
                      onChange={(e) => setBannerImage(e.target.files[0])}
                    />
                  </div>
                </div>
                <h2 className="section_title" style={{ marginTop: "30px" }}>
                  Your Closet/Shopper Profile
                </h2>
                <div className="select_container">
                  <label htmlFor="gender">Gender*</label>
                  <Select
                    id="gender"
                    name="gender"
                    defaultValue={formik.values.gender}
                    options={genderList.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    onChange={(selectedOption) => {
                      setGender(selectedOption);
                      formik.setFieldValue("gender", selectedOption.value);
                    }}
                    value={gender}
                    isSearchable={true}
                    placeholder={formik.values.gender}
                  ></Select>
                  {formik.touched.gender && formik.errors.gender ? (
                    <small className="form-text text-danger">
                      {formik.errors.gender}
                    </small>
                  ) : null}
                </div>
                <div className="personal_info_container">
                  <div className="select_container">
                    <label htmlFor="shirtSize">Shirt Size*</label>
                    <Select
                      id="shirtSize"
                      name="shirtSize"
                      options={shirtSizeOptions}
                      onChange={(selectedOption) => {
                        setShirtSize(selectedOption);
                        formik.setFieldValue("shirtSize", selectedOption.value);
                      }}
                      value={shirtSize}
                      defaultValue={formik.values.shirtSize}
                      placeholder={formik.values.shirtSize}
                    ></Select>
                    {formik.touched.shirtSize && formik.errors.shirtSize ? (
                      <small className="form-text text-danger">
                        {formik.errors.shirtSize}
                      </small>
                    ) : null}
                  </div>
                  <div className="select_container">
                    <label htmlFor="dressSize">
                      {formik.values.gender === "Male" ? "Pants" : "Dress"}{" "}
                      Size*
                    </label>
                    <Select
                      id="dressSize"
                      name="dressSize"
                      options={dressSizeOptions}
                      onChange={(selectedOption) => {
                        setPantSize(selectedOption);
                        formik.setFieldValue("dressSize", selectedOption.value);
                      }}
                      value={pantSize}
                      defaultValue={formik.values.dressSize}
                      placeholder={formik.values.dressSize}
                    ></Select>
                    {formik.touched.dressSize && formik.errors.dressSize ? (
                      <small className="form-text text-danger">
                        {formik.errors.dressSize}
                      </small>
                    ) : null}
                  </div>

                  {formik.values.gender === "Female" && (
                    <div className="select_container">
                      <label htmlFor="waistSize">Waist Size*</label>
                      <Select
                        name="waistSize"
                        id="waistSize"
                        options={optionWomenWaistSize}
                        onChange={(selectedOption) => {
                          setWaistSize(selectedOption);
                          formik.setFieldValue(
                            "waistSize",
                            selectedOption.value
                          );
                        }}
                        value={waistSize}
                        placeholder={"Select Size"}
                      ></Select>
                      {formik.touched.waistSize && formik.errors.waistSize ? (
                        <small className="form-text text-danger">
                          {formik.errors.waistSize}
                        </small>
                      ) : null}
                    </div>
                  )}
                  <div className="select_container">
                    <label htmlFor="shoeSize">Shoe Size*</label>
                    <Select
                      name="shoeSize"
                      id="shoeSize"
                      options={shoeSizeOptions}
                      onChange={(selectedOption) => {
                        setShoeSize(selectedOption);
                        formik.setFieldValue("shoeSize", selectedOption.value);
                      }}
                      value={shoeSize}
                      defaultValue={formik.values.shoeSize}
                      placeholder={formik.values.shoeSize}
                    ></Select>
                    {formik.touched.shoeSize && formik.errors.shoeSize ? (
                      <small className="form-text text-danger">
                        {formik.errors.shoeSize}
                      </small>
                    ) : null}
                  </div>
                </div>
                <button
                  className="btn dark-btn"
                  type="submit"
                  disabled={profileStatus === "loading" ? true : false}
                >
                  {profileStatus === "loading"
                    ? "Please Wait"
                    : "Update Profile Info"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminEditProfile;
