import React, { useState } from "react";
import "./Header.css";
import ToggleSidebar from "../ToggleSidebar/ToggleSidebar";

function Header({ handleToggle }) {
  const [isMobile, setIsMobile] = useState(false);

  // Listen to window resize event and toggle isMobile state accordingly
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth <= 1050); // Adjust breakpoint as needed
  });

  // Render the header component
  return (
    <>
      {isMobile ? (
        // Render header as part of the sidebar on mobile
        <div className="sidebar-header">
          <h1 className="logo bold-text">Admin Panel</h1>
          <ToggleSidebar onToggle={handleToggle} />
        </div>
      ) : (
        // Render header as a standalone component on desktop
        <div className="sidebar-header">
          <h1 className="logo bold-text">Admin Panel</h1>
          <ToggleSidebar onToggle={handleToggle} />
        </div>
      )}
    </>
  );
}

export default Header;
