import { useEffect, useState } from "react";
import shortid from "shortid";
import "./Carousel.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addBrand } from "../../StateSlices/brandSlice";
import { updateStaticContent } from "../../StateSlices/staticContentSlice";
import { BsDownload, BsTrashFill } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { FaMinusSquare, FaPlusSquare, FaTrash } from "react-icons/fa";
import Spinner from "../../Spinner/Spinner";
import swal from "sweetalert";

const Carousel = ({ staticState, setStaticState }) => {
  const [saveAction, setSaveAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLabelChange = (imageIndex, buttonIndex, label, contentType) => {
    // console.log(imageIndex, buttonIndex, label);
    if (contentType === "modify") {
      SetFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
            return index === buttonIndex ? { ...button, btn: label } : button;
          }),
        };
        return updatedFiles;
      });
      console.log(Files[imageIndex].buttons[buttonIndex]);
    }
    if (contentType === "new") {
      SetSelectedFile((prevFiles) => {
        const updatedFiles = [...prevFiles];
        const currentButtons = updatedFiles[imageIndex].buttons || [];
        if (currentButtons.length === 0) {
          updatedFiles[imageIndex] = {
            ...updatedFiles[imageIndex],
            buttons: [{ btn: label }],
          };
        } else {
          // If the buttons array is not empty, append the new item
          updatedFiles[imageIndex] = {
            ...updatedFiles[imageIndex],
            buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
              return index === buttonIndex ? { ...button, btn: label } : button;
            }),
          };
        }
        return updatedFiles;
      });
    }
  };

  const handleLinkChange = (imageIndex, buttonIndex, link, contentType) => {
    if (contentType === "modify") {
      SetFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
            return index === buttonIndex ? { ...button, url: link } : button;
          }),
        };
        return updatedFiles;
      });
      console.log(Files[imageIndex].buttons[buttonIndex]);
    }
    if (contentType === "new") {
      SetSelectedFile((prevFiles) => {
        const updatedFiles = [...prevFiles];
        const currentButtons = updatedFiles[imageIndex].buttons || [];
        if (currentButtons.length === 0) {
          updatedFiles[imageIndex] = {
            ...updatedFiles[imageIndex],
            buttons: [{ url: link }],
          };
        } else {
          // If the buttons array is not empty, append the new item
          updatedFiles[imageIndex] = {
            ...updatedFiles[imageIndex],
            buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
              return index === buttonIndex ? { ...button, url: link } : button;
            }),
          };
        }
        return updatedFiles;
      });
    }
  };

  const handleAddButton = (imageIndex, contentType) => {
    if (contentType === "modify") {
      SetFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        const currentButtons = updatedFiles[imageIndex].buttons || [];

        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: [...currentButtons, { url: "", btn: "" }],
        };

        return updatedFiles;
      });
    }
    if (contentType === "new") {
      SetSelectedFile((prevFiles) => {
        const updatedFiles = [...prevFiles];
        const currentButtons = updatedFiles[imageIndex].buttons || [];

        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: [...currentButtons, { url: "", btn: "" }],
        };

        return updatedFiles;
      });
    }
  };

  const handleRemoveButton = (imageIndex, buttonIndex, contentType) => {
    if (contentType === "modify") {
      SetFiles((prevFiles) => {
        return prevFiles.map((file, i) => {
          if (i === imageIndex) {
            const updatedButtons = file.buttons.filter(
              (btn, j) => j !== buttonIndex
            );
            return { ...file, buttons: updatedButtons };
          }
          return file;
        });
      });
    }
    if (contentType === "new") {
      SetSelectedFile((prevFiles) => {
        return prevFiles.map((file, i) => {
          if (i === imageIndex) {
            const updatedButtons = file.buttons.filter(
              (btn, j) => j !== buttonIndex
            );
            return { ...file, buttons: updatedButtons };
          }
          return file;
        });
      });
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        addBrand({ token, values: { brandName: "" }, requestType: "get" })
      );
    }
  }, []);

  useEffect(() => {
    if (saveAction) {
      if (initialFiles !== Files || selectedfile > 0) {
        const token = localStorage.getItem("clttoken");
        // console.log("final files", Files);
        if (token) {
          const sortedFiles = [...Files].sort(
            (a, b) => parseInt(a.ind) - parseInt(b.ind)
          );
          setLoading(true);
          dispatch(
            updateStaticContent({
              token,
              values: {
                section: "loggedInHomePageCarousel",
                data: sortedFiles,
              },
              requestType: "post",
            })
          );
          setSaveAction(false);
          setInitialFiles(sortedFiles);
          // setLoading(false);
        }
      }
    }
  }, [saveAction === true]);

  useEffect(() => {
    SetFiles(staticState);
    setInitialFiles(staticState);
    setLoading(false);
  }, [staticState]);

  const handleSave = async () => {
    try {
      await FileUploadSubmit();
      if (Files) {
        setSaveAction(true);
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };
  const {
    brandStatus,
    brandInfo,
    brandError,
    categoryInfo,
    signature,
    timestamp,
    sizeInfo,
  } = useSelector((state) => state.brand);
  const [selectedfile, SetSelectedFile] = useState([]);
  const [initialFiles, setInitialFiles] = useState(staticState || []);
  const [Files, SetFiles] = useState(staticState || []);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: shortid.generate(),
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
              datetime:
                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(e.target.files[i].size),
              heading1: "Heading",
              heading5: "Description",
              buttons: [{ btn: "", url: "" }],
              ind: Files ? i + 1 + Files.length : i + 1,
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    swal({
      title: `Delete Slide`,
      text: `Are you sure you want to delete this Slide?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        const result = selectedfile.filter((data) => data.id !== id);
        if (result && result.length > 0) {
          SetSelectedFile(result);
        } else {
          SetSelectedFile([]);
        }
      }
    });
  };

  const FileUploadSubmit = async () => {
    try {
      if (selectedfile.length > 0) {
        setLoading(true);
        // Use Promise.all to wait for all file uploads to complete
        await Promise.all(
          selectedfile.map(async (fille) => {
            const formData = new FormData();
            formData.append("file", fille.fileimage);
            formData.append("upload_preset", "closet-closest");
            formData.append("cloud_name", "closet-closest");
            formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
            formData.append("signature", signature);
            formData.append("timestamp", timestamp);
            formData.append("api_key", "738997887579849");

            // Use await to wait for the axios.post to complete
            const response = await axios.post(
              "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
              formData,
              {
                headers: { "X-Requested-With": "XMLHttpRequest" },
              }
            );

            const data = response.data;
            const fileURL = data.secure_url;
            fille.fileimage = fileURL;

            // Use SetFiles in a way that ensures the state is updated correctly
            SetFiles((preValue) => {
              return [...preValue, fille];
            });
          })
        );

        // Reset selected files after successful upload
        SetSelectedFile([]);
      } else {
        if (initialFiles === Files) {
          alert("Please add/modify the sections");
          return;
        }
      }
    } catch (error) {
      // If an error occurs during file upload, propagate the error
      throw error;
    }
  };

  const DeleteFile = async (id) => {
    swal({
      title: `Delete Existing Slide`,
      text: `Are you sure you want to delete existing slide?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        const result = Files.filter((data) => data.id !== id);
        SetFiles(result);
        setTimeout(() => {
          setSaveAction(true);
        }, 200);
      }
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="static-carousel-con ">
            <div className="fileupload-view">
              <div className="row justify-content-center m-0">
                <div className="" style={{ width: "100%" }}>
                  <div className="card mt-5">
                    <div className="card-body">
                      <div className="kb-data-box">
                        <div className="kb-modal-data-title">
                          <div
                            className="kb-data-title"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h3 className="background-text">
                              Carousel Content
                            </h3>
                            {/* {initialFiles === Files ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className="btn"
                                onClick={handleSave}
                                style={{ marginLeft: "1rem" }}
                              >
                                Save & Publish Content
                              </button>
                            )} */}
                          </div>
                        </div>
                        <form>
                          <div className="kb-file-upload">
                            <div className="file-upload-box">
                              <input
                                type="file"
                                accept="image/*"
                                id="fileupload"
                                className="file-upload-input"
                                onChange={InputChange}
                                multiple
                              />
                              <span>
                                Drag and drop or{" "}
                                <span className="file-link">
                                  Choose your files
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="kb-attach-box mb-3">
                            {selectedfile.map((data, i) => {
                              const {
                                id,
                                filename,
                                filetype,
                                fileimage,
                                datetime,
                                filesize,
                                heading1,
                                heading5,
                                buttons = [{ btn: "", url: "" }],
                                ind,
                              } = data;
                              const adjustedIndex =
                                Files.length > 0 ? i + Files.length : i;

                              return (
                                <div className="file-atc-box" key={id}>
                                  {filename &&
                                  filename.match(
                                    /.(jpg|jpeg|png|gif|svg)$/i
                                  ) ? (
                                    <div className="file-image">
                                      {" "}
                                      <img src={fileimage} alt={filename} />
                                    </div>
                                  ) : (
                                    <div className="file-image">
                                      <i className="far fa-file-alt"></i>
                                    </div>
                                  )}
                                  <div className="file-detail">
                                    <h6>{filename}</h6>
                                    <p></p>
                                    <p>
                                      <span>Size : {filesize}</span>
                                      <span style={{ marginLeft: ".5rem" }}>
                                        Modified Time : {datetime}
                                      </span>
                                    </p>
                                    <p>
                                      <span>
                                        Index
                                        :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </span>
                                      <input
                                        className="st-login-input"
                                        type="text"
                                        value={ind}
                                        onChange={(e) =>
                                          SetSelectedFile((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[i] = {
                                              ...prevContent[i],
                                              ind: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                      ></input>
                                    </p>
                                    <p>
                                      <span>
                                        Heading :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </span>
                                      <input
                                        type="text"
                                        className="st-login-input"
                                        style={{ width: "300px" }}
                                        value={heading1}
                                        onChange={(e) =>
                                          SetSelectedFile((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[i] = {
                                              ...prevContent[i],
                                              heading1: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                      ></input>
                                    </p>
                                    <p>
                                      <span>Description: </span>
                                      <input
                                        className="st-login-input"
                                        type="text"
                                        value={heading5}
                                        onChange={(e) =>
                                          SetSelectedFile((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[i] = {
                                              ...prevContent[i],
                                              heading5: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                      ></input>
                                    </p>

                                    <p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>Button(s) :&nbsp;</div>
                                        <div>
                                          {buttons.length < 3 && (
                                            <button
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleAddButton(i, "new");
                                              }}
                                              style={{
                                                background: "transparent",
                                                border: "none",
                                                // padding: "5px",
                                              }}
                                            >
                                              <FaPlusSquare
                                                size={"1.5em"}
                                                color="var(--background-color)"
                                                style={{
                                                  background: "transparent",
                                                  paddingTop: "3px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      {buttons &&
                                        buttons.length > 0 &&
                                        buttons.map((btn, btnIndex) => (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <input
                                                className="static-cms-btn-label"
                                                type="text"
                                                style={{
                                                  marginLeft: "5px",
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid var(--background-color)",
                                                  borderRadius: 0,
                                                }}
                                                name="label"
                                                placeholder="Button Label"
                                                value={btn.btn}
                                                onChange={(e) =>
                                                  handleLabelChange(
                                                    i,
                                                    btnIndex,
                                                    e.target.value,
                                                    "new"
                                                  )
                                                }
                                              />
                                              <input
                                                className="static-cms-btn-link"
                                                style={{
                                                  marginLeft: "5px",
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid var(--background-color)",
                                                  borderRadius: 0,
                                                }}
                                                type="text"
                                                name="link"
                                                placeholder="Hyperlink"
                                                value={btn.url}
                                                onChange={(e) =>
                                                  handleLinkChange(
                                                    i,
                                                    btnIndex,
                                                    e.target.value,
                                                    "new"
                                                  )
                                                }
                                              />
                                              <button
                                                onClick={() =>
                                                  handleRemoveButton(
                                                    i,
                                                    btnIndex,
                                                    "new"
                                                  )
                                                }
                                                style={{
                                                  background: "transparent",
                                                  border: "none",
                                                }}
                                              >
                                                <IoMdCloseCircle
                                                  size={"1.4em"}
                                                  color="var(--background-color)"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </button>
                                            </div>
                                          </>
                                        ))}
                                    </p>
                                    <div
                                      className="file-actions"
                                      style={{ marginTop: "2rem" }}
                                    >
                                      <button
                                        type="button"
                                        className="file-action-btn"
                                        onClick={() => DeleteSelectFile(id)}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={handleSave}
                                        style={{
                                          marginLeft: "1rem",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        Save & Publish
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </form>
                        {Files.length > 0 ? (
                          <div className="kb-attach-box">
                            <hr />
                            <br />
                            <h3
                              style={{ marginBottom: ".2rem" }}
                              className="background-text"
                            >
                              Live Content
                            </h3>
                            {Files.map((data, index) => {
                              const {
                                id,
                                filename,
                                filetype,
                                fileimage,
                                datetime,
                                filesize,
                                heading1,
                                heading5,
                                ind,
                                buttons,
                              } = data;
                              return (
                                <div className="file-atc-box" key={index}>
                                  {filename &&
                                  filename.match(
                                    /.(jpg|jpeg|png|gif|svg)$/i
                                  ) ? (
                                    <div className="file-image">
                                      {" "}
                                      <img src={fileimage} alt={filename} />
                                      <div className="small-text">
                                        {filename}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="file-image">
                                      {/* <i className="far fa-file-alt"></i> */}

                                      <img src={fileimage} alt={filename} />
                                      <h6>{filename}</h6>
                                    </div>
                                  )}
                                  <div className="file-detail">
                                    <p>
                                      <span>Size : {filesize}</span>
                                      <span style={{ marginLeft: ".5rem" }}>
                                        Modified Time : {datetime}
                                      </span>
                                    </p>
                                    <p>
                                      <span className="bold-text">Index :</span>
                                      <input
                                        type="text"
                                        value={ind}
                                        onChange={(e) =>
                                          SetFiles((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[index] = {
                                              ...prevContent[index],
                                              ind: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          borderBottom:
                                            "1px solid var(--background-color)",
                                          fontSize: "14px",
                                        }}
                                      ></input>
                                    </p>
                                    <p>
                                      <span className="bold-text">
                                        Heading :
                                      </span>
                                      <input
                                        type="text"
                                        value={heading1}
                                        onChange={(e) =>
                                          SetFiles((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[index] = {
                                              ...prevContent[index],
                                              heading1: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          borderBottom:
                                            "1px solid var(--background-color)",
                                          fontSize: "14px",
                                        }}
                                      ></input>
                                    </p>
                                    <p>
                                      <span className="bold-text">
                                        Description :
                                      </span>
                                      <input
                                        type="text"
                                        value={heading5}
                                        onChange={(e) =>
                                          SetFiles((prev) => {
                                            const prevContent = [...prev];
                                            prevContent[index] = {
                                              ...prevContent[index],
                                              heading5: e.target.value,
                                            };
                                            return prevContent;
                                          })
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          borderBottom:
                                            "1px solid var(--background-color)",
                                          fontSize: "14px",
                                        }}
                                      ></input>
                                    </p>
                                    <p>
                                      <div style={{ display: "flex" }}>
                                        <span className="bold-text">
                                          Button(s):
                                        </span>
                                        {/* Button to add new button */}
                                        {buttons.length < 3 && (
                                          <button
                                            onClick={() =>
                                              handleAddButton(index, "modify")
                                            }
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                            }}
                                          >
                                            <FaPlusSquare
                                              size={"1rem"}
                                              color="var(--background-color)"
                                              cursor={"pointer"}
                                            />
                                          </button>
                                        )}
                                      </div>
                                      {buttons.map((button, buttonIndex) => (
                                        <div
                                          key={buttonIndex}
                                          style={{
                                            marginTop: "5px",
                                            display: "flex",
                                          }}
                                        >
                                          <input
                                            className="static-cms-btn-label"
                                            type="text"
                                            placeholder="Label"
                                            value={button.btn}
                                            onChange={(e) =>
                                              handleLabelChange(
                                                index,
                                                buttonIndex,
                                                e.target.value,
                                                "modify"
                                              )
                                            }
                                            style={{
                                              border: "none",
                                              borderRadius: 0,
                                              borderBottom:
                                                "1px solid var(--background-color)",
                                              fontSize: "14px",
                                            }}
                                          />
                                          <input
                                            className="static-cms-btn-link"
                                            type="text"
                                            placeholder="Link"
                                            value={button.url}
                                            onChange={(e) =>
                                              handleLinkChange(
                                                index,
                                                buttonIndex,
                                                e.target.value,
                                                "modify"
                                              )
                                            }
                                            style={{
                                              borderRadius: 0,
                                              border: "none",
                                              borderBottom:
                                                "1px solid var(--background-color)",
                                              marginLeft: "1rem",
                                              fontSize: "14px",
                                            }}
                                          />
                                          <button
                                            onClick={() =>
                                              handleRemoveButton(
                                                index,
                                                buttonIndex,
                                                "modify"
                                              )
                                            }
                                            style={{
                                              background: "transparent",
                                              fontSize: "14px",
                                              border: "none",
                                            }}
                                          >
                                            <IoMdCloseCircle
                                              size={"1.1rem"}
                                              color="var(--background-color)"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </button>
                                        </div>
                                      ))}
                                    </p>

                                    <div className="file-actions">
                                      <button
                                        className="file-action-btn"
                                        onClick={() => DeleteFile(id)}
                                      >
                                        <BsTrashFill size={"1.2em"} />
                                      </button>
                                      <a
                                        href={fileimage}
                                        className="file-action-btn"
                                        download={filename}
                                        target="_blank"
                                      >
                                        <BsDownload size={"1.2em"} />
                                      </a>
                                      {initialFiles[index] !== Files[index] && (
                                        <button
                                          type="button"
                                          className="btn"
                                          onClick={handleSave}
                                          style={{
                                            marginLeft: "1rem",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Save & Publish
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Carousel;
