import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  videoUploadStatus: "idle",
  videoUploadInfo: null,
  videoUploadError: null,
};

export const videoUploadMulter = createAsyncThunk(
  "statis/videoUpload",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post("/api/static/videoUpload", formData);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const videoUploadMulterSlice = createSlice({
  name: "videoUpload",
  initialState,
  reducers: {
    resetItem(state, action) {
      state.videoUploadInfo = null;
      state.videoUploadStatus = "idle";
      state.videoUploadError = null;
    },
  },
  extraReducers: {
    [videoUploadMulter.pending]: (state, action) => {
      state.videoUploadStatus = "loading";
    },
    [videoUploadMulter.fulfilled]: (state, action) => {
      state.videoUploadStatus = "succeeded";
      state.videoUploadInfo = action.payload;
    },
    [videoUploadMulter.rejected]: (state, action) => {
      state.videoUploadStatus = "failed";
      state.videoUploadError = action.payload;
    },
  },
});

export const { resetItem } = videoUploadMulterSlice.actions;
export default videoUploadMulterSlice.reducer;
