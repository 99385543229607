import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  staticContentStatus: "idle",
  staticContentInfo: null,
  staticContentInfoAdmin: null,
  staticContentError: null,
  updateMsg: null,
};

export const updateStaticContent = createAsyncThunk(
  "static/staticContent",
  async (
    { token, values, requestType },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    console.log(token, values, requestType, "k2ll");
    try {
      if (requestType === "post") {
        console.log("k2l post");
        const { data } = await axios.post(
          "/api/static/updateStaticContent",
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      }
      //  else {
      //   try {
      //     const { data } = await axios.get(
      //       `/api/static/getStaticContent/${values.section}`,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //       }
      //     );
      //     return data;
      //   } catch (err) {
      //     return rejectWithValue(err.response.data);
      //   }
      // }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaticContent = createAsyncThunk(
  "static/getStaticContent",
  async ({ values }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/static/getStaticContent/${values.section}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStaticContent = createAsyncThunk(
  "static/getAllStaticContent",
  async ({ values }, { rejectWithValue }) => {
    console.log("came here ", values);
    try {
      const { data } = await axios.get(`/api/static/getAllStaticContent`);
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStaticContentSlice = createSlice({
  name: "staticContent",
  initialState,
  reducers: {
    resetstaticContent(state, action) {
      state.updateMsg = null;
    },
  },
  extraReducers: {
    [updateStaticContent.pending]: (state, action) => {
      state.staticContentStatus = "loading";
    },
    [updateStaticContent.fulfilled]: (state, action) => {
      state.staticContentStatus = "succeeded";
      // state.staticContentInfoAdmin = action.payload.staticContent;
      state.updateMsg = action.payload.message;
    },
    [updateStaticContent.rejected]: (state, action) => {
      state.staticContentStatus = "failed";
      state.staticContentError = action.payload;
    },
    [getStaticContent.pending]: (state, action) => {
      state.staticContentStatus = "loading";
    },
    [getStaticContent.fulfilled]: (state, action) => {
      state.staticContentStatus = "succeeded";
      // state.staticContentInfo = action.payload.staticContent;
      state.staticContentInfoAdmin = action.payload.staticContent;
    },
    [getStaticContent.rejected]: (state, action) => {
      state.staticContentStatus = "failed";
      state.staticContentError = action.payload;
    },
    [getAllStaticContent.pending]: (state, action) => {
      state.staticContentStatus = "loading";
    },
    [getAllStaticContent.fulfilled]: (state, action) => {
      state.staticContentStatus = "succeeded";
      state.staticContentInfo = action.payload.staticContent;
    },
    [getAllStaticContent.rejected]: (state, action) => {
      state.staticContentStatus = "failed";
      state.staticContentError = action.payload;
    },
  },
});

export const { resetstaticContent } = updateStaticContentSlice.actions;
export default updateStaticContentSlice.reducer;
