import React, { useMemo, useState } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import Pagination from "../../../components/Pagination/Pagination";
import { AgGridReact } from "ag-grid-react";

const OrderAnalytics = ({
  loading,
  data,
  analyticsCount,
  pageNumber,
  setPageNumber,
}) => {
  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const capitalize = (params) => {
    return params.value.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "LATO" },
  }));
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Date of order",
      field: "checkout.checkoutTime",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Order ID",
      field: "checkout.orderId",
    },
    {
      headerName: "Buyer Email",
      field: "BuyerInfo.userEmail",
    },
    {
      headerName: "Seller Email",
      field: "sellerInfo.userEmail",
    },
    {
      headerName: "Shipping Method",
      field: "checkout.products.shippingMethod",
    },
    {
      headerName: "Hangers Paid (without the bought ones)",
      field: "checkout.productDetail.productHanger",
      valueFormatter: (params) => params && params.value,
    },
    {
      headerName: "Total Order Hangers Paid (without the bought ones)",
      field: "checkout.hangerPaid",
      valueFormatter: (params) => params && params.value,
    },
    {
      headerName: "Hangers Bought",
      field: "checkout.products.hangerPurchased",
      valueFormatter: (params) => params && params.value,
    },
    {
      headerName: "Fulfilled",
      field: "checkout.products.fulfilled",
      valueFormatter: (params) => params && params.value,
    },
    {
      headerName: "Ordered By (user/admin)",
      field: "checkout.orderdBy",
      valueFormatter: (params) => params && params.value,
    },
  ]);
  return (
    <div className="analytics-con" style={{ marginTop: "1rem" }}>
      {loading && data.length == 0 ? (
        <div style={{ borderRadius: "10px", overflow: "hidden" }}>
          <Spinner1 />
        </div>
      ) : (
        <>
          <div className="ag-theme-quartz" style={{ height: 900 }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={data}
              columnDefs={colDefs}
            />
          </div>
          <Pagination
            pageCount={Math.ceil(analyticsCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default OrderAnalytics;
