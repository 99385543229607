import React from "react";
import spinner from "../../assets/images/spinner.gif";
import "./Spinner1.css";

const Spinner1 = (props) => {
  return (
    <div
      className="spinner_container1"
      style={props && props.minHeight && { minHeight: props.minHeight }}
    >
      <img src={spinner} alt="spinner1" />
    </div>
  );
};

export default Spinner1;
