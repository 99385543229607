import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import {
  forgotPassword,
  reset,
} from "../components/StateSlices/forgotpasswordSlice";

import "react-toastify/dist/ReactToastify.css";
import "./SetupClosest.css";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const { status, message, error } = useSelector((state) => state.forgot);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your Email Address"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      dispatch(forgotPassword(values));
      setSpinner(true);
      return false;
    },
  });

  if (message) {
    dispatch(reset());
    toast.success("Email Sent Successfully", toastOption);
    setSpinner(false);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: window.location.href,
          },
        ]}
      >
        <meta
          name="description"
          content="Reset your password quickly and securely. Enter your email to receive a password reset link. Regain access to your account in just a few simple steps."
        />
      </Helmet>

      <div
        className="closest_setup_container"
        style={{ position: "relative", height: "39vh" }}
      >
        {spinner ? <Spinner /> : ""}
        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
        <div className="closest_setup">
          <h1 className="section_title bold-text">Forgot Password</h1>
          <h5 className="h4" style={{ textAlign: "center" }}>
            Enter your email address where you will receive password reset link.
          </h5>
          <form onSubmit={formik.handleSubmit}>
            <div className="closest_setup_form">
              <div className="input_container">
                <label htmlFor="email_address">Email Address*</label>
                <input
                  type="email"
                  id="email_address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <small className="form-text text-danger">
                    {formik.errors.email}
                  </small>
                ) : null}
              </div>
              <button
                className="btn dark-btn"
                type="submit"
                disabled={status === "loading" ? true : false}
              >
                {status === "loading" ? "Please Wait" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
