import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  cstatus: "idle",
  message: "",
  error: null,
}

export const addtoCart = createAsyncThunk(
  "cart/addtoCart",
  async ({id,token,arr=false}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/addtocart`, {id:id,arr},
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const AddToCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCartMessage(state,action){
        state.message = ""
        state.cstatus="idle"
      }
  },
  extraReducers: {
    [addtoCart.pending]: (state, action) => {
      state.cstatus = "loading"
    },
    [addtoCart.fulfilled]: (state, action) => {
      state.cstatus = "succeeded"
      state.message = action.payload.message
    },
    [addtoCart.rejected]: (state, action) => {
      state.cstatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { resetCartMessage } = AddToCartSlice.actions
export default AddToCartSlice.reducer 