import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  coupenStatus: "idle",
  coupenInfo: null,
  coupenError: null,
  coupenDetail:null
};

export const verifyCoupenCode = createAsyncThunk(
  "coupen/verifycoupen",
  async ({ coupenCode }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/users/verifycoupen/${coupenCode}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verifyCoupenSlice = createSlice({
  name: "verifycoupen",
  initialState,
  reducers: {
    resetcoupen(state, action) {
      state.coupenInfo = null;
      state.coupenStatus = "idle";
      state.coupenError = null;
      state.coupenDetail = null
    },
  },
  extraReducers: {
    [verifyCoupenCode.pending]: (state, action) => {
      state.coupenStatus = "loading";
    },
    [verifyCoupenCode.fulfilled]: (state, action) => {
      state.coupenStatus = "succeeded";
      state.coupenInfo = action.payload.coupon;
      state.coupenDetail = action.payload.coupenInfo
    },
    [verifyCoupenCode.rejected]: (state, action) => {
      state.coupenStatus = "failed";
      state.coupenError = action.payload.message;
    },
  },
});

export const { resetcoupen } = verifyCoupenSlice.actions;
export default verifyCoupenSlice.reducer;
