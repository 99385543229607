import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  status: "idle",
  rmessage: null,
  error: null,
}
export const resendVerification = createAsyncThunk(
  "resend/verification",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/resendverification",
        email
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const resendVerificationSlice = createSlice({
  name: "resend",
  initialState,
  reducers: {
    reset(state,action){
      state.error=null
      state.rmessage=null
    }
  },
  extraReducers: {
    [resendVerification.pending]: (state, action) => {
      state.status = "loading"
    },
    [resendVerification.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.rmessage = action.payload.message
    },
    [resendVerification.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { reset } = resendVerificationSlice.actions

export default resendVerificationSlice.reducer 