import React from "react";
import "./CleanYourCloset.css";
import tip2 from "../assets/images/tip2.png";
import { useNavigate } from "react-router-dom";

const CleanYourCloset = () => {
  const navigate = useNavigate();

  return (
    <div className="page-container clean-closet-container">
      <h1 className="section_title bold-text">
        Closest Closet Guidelines – Quality Items Only
      </h1>
      <div className="clean-closet-intro">
        <div className="intro-text">
          Your Closest Closet reputation and membership status depend on the
          authenticity of the items you add to your closet and your reliability
          as the seller. Follow these tips to ensure a successful experience and
          gain followers.
        </div>
        <div className="intro-img">
          <img src={tip2} alt="Quality Item Intro" />
        </div>
      </div>
      <div className="clean-closet-section">
        <h1>Make An Impact While Upcycling</h1>
        <div>
          Marie Kondo and “The Life-Changing Magic of Tidying Up” have taken the
          art of seasonal closet cleaning to the next level. Marie has inspired
          millions of men and women to take back their lives by tackling their
          closets. What a cathartic process it is to honor and release items
          that are no longer serving you. But where do those items go? Donation
          centers are overflowing and while dropping off a few bags might make
          you feel pretty good, it doesn’t give you a return on your investment.
          Only 10% of items donated to charities or thrift stores actually get
          sold. The rest end up in landfills or flood markets in developing
          countries like Haiti where they are bought by the bundle and hurt the
          local trade industry.
        </div>
      </div>
      <div className="clean-closet-section">
        <h1>Closest Closet Recommended Steps For Tidying Up</h1>
        <div>
          CC will turn your donated items into hangers that can be redeemed for
          pieces you do actually need in your closet, instead of another cute,
          summer dress that will only be worn once.
        </div>
        <div>
          <ol>
            <li>
              Take a good look at the contents of your closet and drawers.
            </li>
            <li>Pull out pieces that haven’t seen the light of day.</li>
            <li>
              Try them on and ask yourself these questions: does it fit? does it
              make me feel good? does it make me look good?
            </li>
            <li>
              If it doesn’t fit do yourself a favor and get rid of it. You
              aren’t giving up on wearing the amazing sequined dress you wore
              your senior year of college, you are honoring that amazing dress
              by offering it a new home.
            </li>
            <li>
              Have you ever walked out of your house and then realized halfway
              through your morning how much you hate what you are wearing? If it
              doesn’t make you feel good then it shouldn’t be within your grasp.
              Get it out of your closet and into someone else’s.
            </li>
            <li>
              Stand in front of a full length mirror and be critical. Not of
              your beautiful body and all its creases and curves, but of what
              you are wearing. Does it highlight your best assets and hide the
              imperfections? If not let it find its way into another closet.
            </li>
          </ol>
        </div>
      </div>
      <div className="actn-btn">
        <button className="btn dark-btn" onClick={() => navigate("/add-items")}>
          + Add Items
        </button>
        <button className="btn dark-btn" onClick={() => navigate("/shop")}>
          Shop Closet Here
        </button>
      </div>
    </div>
  );
};

export default CleanYourCloset;
