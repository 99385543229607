import React, { useState } from "react";
import {
  ElementsConsumer,
  CardNumberElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import CardSection from "./CardSection";
import { ToastContainer, toast } from "react-toastify";
import stripeBadge1 from "../../assets/images/stripe-badge-grey-1.png";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

class CheckoutForm extends React.Component {
  openPopup = () => {
    const width = window.innerWidth * 0.75;
    const height = window.innerHeight * 0.75;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popupWindow = window.open(
      "https://closestcloset.com/terms-conditions",
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    // Add event listener to close the popup window when the component unmounts
    window.addEventListener("beforeunload", () => {
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    });
  };
  handleSubmit = async (event) => {
    const errorKeys = Object.keys(this.props.errors);
    //console.log(errorKeys)
    if (errorKeys.length === 0) {
      this.props.setSpinner(true);
      event.preventDefault();
      const toastOption = {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        toast.error(result.error.message, toastOption);
        //console.log(result.error.message);
        this.props.setSpinner(false);
      } else {
        //console.log(result.token);
        //this.props.submitForm();
        //console.log(this.props);
        if (this.props.formRef.current) {
          this.props.setId(result.token);
          this.props.formRef.current.handleSubmit();
        }
      }
    } else {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  render() {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          style={{
            overlay: {
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              maxWidth: "80%",
              maxHeight: "auto",
              margin: "auto",
              position: "static",
            },
          }}
          contentLabel="Example Modal"
        >
          <div
            className="close_container"
            style={{ display: "flex", justifyContent: "end" }}
          >
            {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 8px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
          </div>

          <div className="terms-modal-con">
            <div className="terms-modal-wrapper">
              <h3>Terms and Conditions</h3>
              <p style={{ textAlign: "left" }} className="h5">
                Closest Closet, LLC. (“Closest Closet,” “we,” “us,” and/or
                “our”) provides its services (described below) to you through
                its mobile applications and other software made available by
                Closest Closet (“Software”) and its website located at{" "}
                <a
                  href="https://www.ClosestCloset.com"
                  style={{ color: "#F26654", lineBreak: "anywhere" }}
                >
                  https://www.ClosestCloset.com
                </a>{" "}
                (the “Site”) (collectively, such services, Software and the
                Site, collectively, the “Service(s)”), subject to the following
                Terms of Service (as amended from time to time, these “Terms of
                Service”). We reserve the right, at our sole discretion, to
                change or modify portions of these Terms of Service at any time
                without further notice. You should periodically visit this page
                to review the current Terms of Service so you are aware of any
                revision to which you are bound. If we do this, we will post the
                changes to these Terms of Service on this page and will indicate
                at the top of this page the date these terms were last revised.
                We will also notify you, either through the Services user
                interface, in an email notification or through other reasonable
                means. Any such changes will become effective for existing users
                no earlier than ten (10) days after they are posted, except that
                changes addressing new functions of the Services or changes made
                for legal reasons will be effective immediately. Your continued
                use of the Service after the date any such changes become
                effective constitutes your acceptance of the new Terms of
                Service. If you do not agree to abide by these or any future
                Terms of Service, do not use or access (or continue to use or
                access) the Service.
              </p>
              <br />
              <strong>Hanger Price will be set by CC*</strong>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <form>
          <CardSection />

          <div className="aggrement_container" style={{ marginTop: "21px" }}>
            <input
              type="checkbox"
              className="tc_input"
              style={{ marginLeft: ".5rem" }}
              onChange={() => this.props.setTc((prev) => !prev)}
              checked={this.props.tc}
            />
            <label>
              I agree to{" "}
              {/* <Link
                to={`/terms-conditions`}
                style={{ textDecoration: "none" }}
                className="tc"
                target={"_blank"}
              > */}
              <span
                className="tc"
                // onClick={() => this.setState({ modalIsOpen: true })}
                onClick={this.openPopup}
                style={{ cursor: "pointer" }}
              >
                Terms and Conditions
              </span>
              {/* </Link> */}
            </label>
          </div>
          <button
            type="button"
            onClick={this.handleSubmit}
            className="btn dark-btn"
            disabled={!this.props.stripe || this.props.spinner === true}
          >
            {!this.props.stripe || this.props.spinner === true
              ? "Please Wait"
              : "Become a Member"}
          </button>
          <div
            style={{
              marginTop: "1.5rem",
              background: "#e1e2e5",
              textAlign: "center",
            }}
          >
            <img
              src={stripeBadge1}
              width="65%"
              style={{ borderRadius: "5px" }}
              alt="stripe guaranteed badge"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm({
  setTc,
  submitForm,
  formRef,
  setId,
  spinner,
  setSpinner,
  errors,
  tc,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          setTc={setTc}
          tc={tc}
          submitForm={submitForm}
          formRef={formRef}
          setId={setId}
          spinner={spinner}
          setSpinner={setSpinner}
          errors={errors}
        />
      )}
    </ElementsConsumer>
  );
}
