import React, { useEffect } from "react";
import "./FeaturedProducts.css";
import FP1 from "../../assets/images/FP1.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import hanger from "../../assets/images/hanger.svg";
import { Grid } from "@splidejs/splide-extension-grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import loadImgGif from "../../assets/images/load-img.gif";
import { PiCoatHangerFill } from "react-icons/pi";

const FeaturedProducts = ({ newsetItem, title }) => {
  //consolele.log(Grid);
  return (
    <div className="featured_products_container">
      <h2 className="section_title large-text bold-text heading text-center">
        {title}
      </h2>
      <div
        // style={{
        //   width: "75%",
        //   marginLeft: "20px",
        //   marginRight: "10px",
        // }}
        className="shop-items-con"
        style={{ marginTop: "2rem" }}
      >
        <div
        // style={{
        //   display: "flex",
        //   flexWrap: "wrap",
        //   justifyContent: "center",
        // }}
        // className="shop-items-con-wrapper"
        >
          <Splide
            options={{
              type: "loop",
              perPage: 4,
              rewind: true,
              grid: {
                // You can define rows/cols instead of dimensions.
                rows: 1,
              },
              easing: "cubic-bezier(0.25, 1, 0.5, 1)",
              speed: 2000,
              rewindSpeed: 1500,
              arrows: false,
              pagination: true,
              drag: "free",
              classes: {
                pagination: "splide__pagination pagination_container",
                page: "splide__pagination__page page_container",
              },
              breakpoints: {
                1150: {
                  perPage: 3,
                },
                850: {
                  perPage: 2,
                },
                570: {
                  perPage: 1,
                },
              },
            }}
            Extensions={{ Grid }}
          >
            {newsetItem.length > 0 &&
              newsetItem
                .slice(0, Math.floor(newsetItem.length / 4) * 4)
                .map((item, index) => {
                  return (
                    <SplideSlide key={index}>
                      <Link
                        to={{
                          pathname: `/product/${encodeURIComponent(
                            item.productStats.brand
                          )}-${encodeURIComponent(item.productName)}/${item._id}`,
                          // search: `?product=${item._id}`,
                        }}
                        state={{ id: item._id }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="shop-item-card"
                          style={{
                            width: "250px",
                            margin: "0 auto",
                            height: "inherit",
                            marginBottom: "1rem",
                          }}
                        >
                          <div
                            className="card_image"
                            style={{ height: "270px" }}
                          >
                            <LazyLoadImage
                              src={
                                item.productImage[0]
                                  ? item.productImage[0]
                                  : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                              }
                              alt="FP1"
                              effect="blur"
                              placeholderSrc={loadImgGif}
                              // style={{ height: "150px" }}
                            />
                            {/* <div className="action_icon">
                        <Link
                          to={{
                            pathname: `/product/${encodeURIComponent(
                              item.productStats.brand
                            )}-${encodeURIComponent(item.productName)}`,
                            search: `?product=${item._id}`,
                          }}
                          state={{ id: item._id }}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="btn">View</div>
                        </Link>
                        <div className="btn"><i class="fas fa-heart"></i></div>
                        <div className="btn"><i class="fas fa-search-plus"></i></div>
                      </div> */}
                          </div>
                          <div className="card_text">
                            <div className="product_title h6">
                              {item.productName}
                            </div>
                            <hr
                              style={{
                                margin: "1rem .2rem",
                                borderStyle: "solid",
                                color: "#ddd",
                              }}
                            />
                            <div className="product_price h6 bold-text">
                              {item.productHanger}
                              <PiCoatHangerFill
                                style={{ marginLeft: "3px" }}
                                size={"1.3em"}
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SplideSlide>
                  );
                })}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
