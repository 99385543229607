import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  analyticsStatus: "idle",
  analyticsInfo: null,
  analyticsError: null,
  analyticsCount: 0,
};

export const userAnalytics = createAsyncThunk(
  "analytics/user",
  async ({ token, p }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/analytics/user?pageNumber=${p}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const productAnalytics = createAsyncThunk(
  "analytics/product",
  async ({ token, p }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/analytics/product?pageNumber=${p}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const orderAnalytics = createAsyncThunk(
  "analytics/order",
  async ({ token, p }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/analytics/order?pageNumber=${p}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const overallAnalytics = createAsyncThunk(
  "analytics/overall",
  async ({ token, search="all" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/analytics/overall/${search}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetanalytics(state, action) {
      state.analyticsError = null;
      state.analyticsInfo = null;
      //   state.analyticsCount = 0;
    },
  },
  extraReducers: {
    [userAnalytics.pending]: (state, action) => {
      state.analyticsStatus = "loading";
    },
    [userAnalytics.fulfilled]: (state, action) => {
      state.analyticsStatus = "succeeded";
      state.analyticsInfo = action.payload.users;
      state.analyticsCount = action.payload.length[0].totalDocuments;
    },
    [userAnalytics.rejected]: (state, action) => {
      state.analyticsStatus = "failed";
      state.analyticsError = action.payload.message;
    },

    [productAnalytics.pending]: (state, action) => {
      state.analyticsStatus = "loading";
    },
    [productAnalytics.fulfilled]: (state, action) => {
      state.analyticsStatus = "succeeded";
      state.analyticsInfo = action.payload.products;
      state.analyticsCount = action.payload.length[0].totalDocuments;
    },
    [productAnalytics.rejected]: (state, action) => {
      state.analyticsStatus = "failed";
      state.analyticsError = action.payload.message;
    },

    [orderAnalytics.pending]: (state, action) => {
      state.analyticsStatus = "loading";
    },
    [orderAnalytics.fulfilled]: (state, action) => {
      state.analyticsStatus = "succeeded";
      state.analyticsInfo = action.payload.orders;
      state.analyticsCount = action.payload.length[0].totalDocuments;
    },
    [orderAnalytics.rejected]: (state, action) => {
      state.analyticsStatus = "failed";
      state.analyticsError = action.payload.message;
    },

    [overallAnalytics.pending]: (state, action) => {
      state.analyticsStatus = "loading";
    },
    [overallAnalytics.fulfilled]: (state, action) => {
      state.analyticsStatus = "succeeded";
      state.analyticsInfo = action.payload.result;
      // state.analyticsCount = action.payload.length[0].totalDocuments;
    },
    [overallAnalytics.rejected]: (state, action) => {
      state.analyticsStatus = "failed";
      state.analyticsError = action.payload.message;
    },
  },
});

export const { resetanalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;
