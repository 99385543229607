import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./SubscriptionTab.css";
// import ProductAnalytics from "./ProductAnalytics";
// import OrderAnalytics from "./OrderAnalytics";
import { useDispatch, useSelector } from "react-redux";
import {
  orderAnalytics,
  overallAnalytics,
  productAnalytics,
  resetanalytics,
  userAnalytics,
} from "../../../components/StateSlices/analyticsSlice";
import { useLocation } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { getAnalyticsDataExport } from "../../../components/StateSlices/analyticsDataExportSlice";
import { toast } from "react-toastify";
import SubscriptionAnalytics from "./SubscriptionAnalytics";
import { resetSubscription, subscriptionList } from "../../../components/StateSlices/adminSlice";
// import OverallAnalytics from "./OverallAnalytics";



const toastOption = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#F26654" : "inherit",
    width: "100%",
    marginTop: ".5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#F26654",
    },
    fontSize: "14px",
    borderRadius: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    marginTop: "3px",
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "#F26654" : "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isSelected ? "#F26654" : "rgba(242, 102, 84, 0.5)",
      color: "#fff",
      borderRadius: 5,
    },
    borderRadius: 5,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 31,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
};

const SubscriptionTab = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userSearchVal, setUserSearchVal] = useState("")

  const {
    allUserStatus,
    allUsersInfo,
    subscriptionData
  } = useSelector((state) => state.admin);

  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );


  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
        dispatch(subscriptionList({ token: token, page: pageNumber }));
    }
  }, []);

  useEffect(()=>{
    if(subscriptionData){
        setData(subscriptionData)
        dispatch(resetSubscription())
        setLoading(false)
    }
  },[subscriptionData])


  return (
    <div className="analytics-con">
      <div className="analytics-wrapper">
        

          <SubscriptionAnalytics
            loading={loading}
            data={data}
            
            pageNumber={[pageNumber]}
            setPageNumber={setPageNumber}
          />
        
      </div>
    </div>
  );
};

export default SubscriptionTab;
