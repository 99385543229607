import React, { useEffect } from "react";
import { useState } from "react";
import "./ProductTabs.css";
import Moment from "react-moment";
import { updateComment } from "../StateSlices/commentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hanger from "../../assets/images/hanger.svg";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { TbHanger } from "react-icons/tb";
import {
  addClosetComment,
  deleteClosetComment,
  getClosetComments,
  resetComment,
  updateClosetComment,
} from "../StateSlices/closetCommentSlice";
import Spinner1 from "../Spinner1/Spinner1";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PiCoatHangerFill } from "react-icons/pi";
import loadImgGif from "../../assets/images/load-img.gif";

const ProductTabs = ({
  closetInfo,
  userInfo,
  productInfo,
  postComment,
  removeComment,
  smallLoading,
  closProduct,
  getMoreProductsFromCloset,
}) => {
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [tab, setTab] = useState("first");
  const [commentBody, setCommentBody] = useState("");
  const [isEditActive, setIsEditActive] = useState({});
  const { commentInfo, commentStatus, commentMessage } = useSelector(
    (state) => state.closetcomment
  );
  const [closetCommentBody, setClosetCommentBody] = useState("");
  const [isClosetEditActive, setIsClosetEditActive] = useState({});

  // if (commentStatus == "succeeded") {
  //   dispatch(resetComment());
  //   setLoading(false);
  //   toast.success("Comment Updated Successfully", toastOption);
  //   dispatch(getClosetComments(closetInfo._id));
  // }
  useEffect(() => {
    getMoreProductsFromCloset(closetInfo.masterId);
  }, []);
  useEffect(() => {
    dispatch(
      getClosetComments({
        token: localStorage.getItem("clttoken"),
        id: closetInfo._id,
      })
    );
  }, [tab === "second"]); //yeh barabar hai?? hau
  const postClosetComment = (comment) => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      let data = {
        userId: closetInfo._id,
        comment: closetCommentBody,
      };
      dispatch(addClosetComment({ token, values: data, id: closetInfo._id }));
    } else {
      navigate("/login");
    }

    // setLoading(true);
  };
  const handleCommentUpdate = (cId) => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        updateComment({
          token: token,
          id: productInfo._id,
          commentId: cId,
          comment: isEditActive[cId],
        })
      );
    }
  };

  const removeSpace = (url) => {
    return url.replace(/[\s#\/]+/g, "-");
  };

  const handleClosetCommentUpdate = (cId) => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        updateClosetComment({
          token: token,
          id: closetInfo._id,
          commentId: cId,
          comment: isClosetEditActive[cId],
        })
      );
    }
  };

  const removeClosetComment = (id) => {
    swal({
      title: "Are you sure?",
      text: "This Comment will be Deleted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //consolele.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        // setLoading(true);
        dispatch(
          deleteClosetComment({ token, id: closetInfo._id, commentId: id })
        );
      }
    });
  };

  if (
    commentMessage === "Comment Deleted Succesfully" ||
    commentMessage === "Comment Updated Succesfully" ||
    commentMessage === "Comment Added Succesfully"
  ) {
    dispatch(resetComment());
    toast.success("Comment Updated Successfully", toastOption);
    dispatch(
      getClosetComments({
        token: localStorage.getItem("clttoken"),
        id: closetInfo._id,
      })
    );
    //ab test kar
  }

  return (
    <>
      <div className="product_tabs_container">
        <div className="tab">
          <button
            className={`tablinks ${tab === "first" ? "active" : ""}`}
            onClick={() => setTab("first")}
          >
            Item Comments
          </button>
          <button
            className={`tablinks ${tab === "second" ? "active" : ""}`}
            onClick={() => {
              setTab("second");
            }}
          >
            Closet Reviews
          </button>
          {/* <button
          className={`tablinks ${tab === "third" ? "active" : ""}`}
          onClick={() => {
            setTab("third");
            getMoreProductsFromCloset(closetInfo.masterId);
          }}
        >
          More Item from {closetInfo.closetName}
        </button> */}
        </div>

        <div
          style={tab == "first" ? { display: "block" } : { display: "none" }}
          className="tabcontent"
        >
          {/* <CommentSection /> */}
          {/* <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}> */}
          <textarea
            className="comment_textarea"
            placeholder="Write a comment..."
            rows={3}
            // disabled={true}
            style={{
              padding: "1rem",
              fontSize: "16px",
              fontFamily: "LATO",
            }}
            value={closetCommentBody}
            onChange={(e) => {
              setCommentBody(e.target.value);
              setClosetCommentBody(e.target.value);
            }}
          />
          <div className="button_container">
            <button
              className="btn"
              style={{ textAlign: "right" }}
              onClick={() => {
                postComment(commentBody);
                setCommentBody("");
                setClosetCommentBody("");
              }}
            >
              Post Comment
            </button>
          </div>

          {/* </div> */}
          <div className="comment_container">
            {productInfo &&
            productInfo.productComment &&
            productInfo.productComment.length === 0 ? (
              <div className="no_comment">
                No comments yet. Be the first to comment
              </div>
            ) : (
              <div>
                {productInfo && productInfo.productComment && (
                  <>
                    <div
                      className="h4"
                      style={{
                        borderBottom: "2px solid var(--grey-color)",
                        marginBottom: "20px",
                      }}
                    >
                      {" "}
                      {productInfo.productComment.length}{" "}
                      {productInfo.productComment.length > 1
                        ? "Comments"
                        : "Comment"}
                    </div>
                    {productInfo.productComment.map((item, index) => (
                      <div className="comment">
                        <div className="comment_user">
                          <div className="comment_user_image">
                            <img
                              src={
                                item.userId.userDetailsId.userIcon
                                  ? item.userId.userDetailsId.userIcon
                                  : "https://www.instyle.com/thmb/ciGuAwe4dfMdkFBuaGwnavsRSaw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/closet-lead-2000-5b8bd03a1a6044aeb146a92b5e52d24c.jpg"
                              }
                              alt="user_icon"
                            />
                          </div>
                        </div>
                        <div className="content">
                          <div
                            className="h6 background-text bold-text"
                            style={{
                              fontWeight: "700",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.userId.userDetailsId.userFName}{" "}
                            <span
                              className="small-text"
                              style={{ fontWeight: "normal", color: "#aaa" }}
                            >
                              - <Moment fromNow>{item.createdAt}</Moment>
                            </span>
                          </div>

                          <div
                            className="comment_content h5 grey-text"
                            style={{
                              transition: "all .5s ease-in",
                              position: "relative",
                              width: "85%",
                            }}
                          >
                            {isEditActive && isEditActive[item._id] ? (
                              <>
                                <input
                                  className="cmt-edit-input"
                                  autoFocus={true}
                                  type="text"
                                  value={isEditActive[item._id].value}
                                  onChange={(e) => {
                                    setIsEditActive((prev) => {
                                      return {
                                        ...prev,
                                        [item._id]: e.target.value,
                                      };
                                    });
                                  }}
                                />
                                <span
                                  className="cmt-save"
                                  onClick={() => handleCommentUpdate(item._id)}
                                >
                                  <i
                                    class="fas fa-check"
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "var(--background-color)",
                                      position: "absolute",
                                      right: "0",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {item.comment}
                                {item.userId._id === userInfo?._id && (
                                  <span style={{ padding: "0 1rem" }}>
                                    <i
                                      onClick={() => {
                                        setIsEditActive(() => {
                                          return {
                                            [item._id]: {
                                              value: item.comment,
                                            },
                                          };
                                        });
                                      }}
                                      class="fas fa-pen"
                                      style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/* {//consolele.log(userInfo)} */}
                        {(userInfo?.role === "admin" ||
                          userInfo?._id === item.userId._id) && (
                          <div
                            className="delete_comment"
                            onClick={() => removeComment(item._id)}
                          >
                            <i
                              class="fas fa-trash"
                              style={{ fontSize: "14px" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={tab == "second" ? { display: "block" } : { display: "none" }}
          className="tabcontent"
        >
          <textarea
            className="comment_textarea"
            placeholder="Write a comment..."
            rows={3}
            // disabled={true}
            style={{
              padding: "1rem",
              fontSize: "16px",
              fontFamily: "LATO",
            }}
            value={closetCommentBody}
            onChange={(e) => setClosetCommentBody(e.target.value)}
          />
          <div className="button_container">
            <button
              className="btn"
              style={{ textAlign: "right" }}
              onClick={() => {
                postClosetComment(commentBody);
                setClosetCommentBody("");
              }}
            >
              Post Comment
            </button>
          </div>

          {/* </div> */}
          <div className="comment_container">
            {commentInfo &&
            commentInfo.comments &&
            commentInfo.comments.length === 0 ? (
              <div className="no_comment">
                No comments yet. Be the first to comment
              </div>
            ) : (
              <div>
                {commentInfo && commentInfo.comments && (
                  <>
                    <div
                      className="h4"
                      style={{
                        borderBottom: "2px solid var(--grey-color)",
                        marginBottom: "20px",
                      }}
                    >
                      {" "}
                      {commentInfo.comments.length}{" "}
                      {commentInfo.comments.length > 1 ? "Comments" : "Comment"}
                    </div>
                    {commentInfo.comments.map((item, index) => (
                      <div className="comment">
                        <div className="comment_user">
                          <div className="comment_user_image">
                            <img
                              src={
                                item.userId?.userDetailsId.userIcon
                                  ? item.userId.userDetailsId.userIcon
                                  : "https://www.instyle.com/thmb/ciGuAwe4dfMdkFBuaGwnavsRSaw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/closet-lead-2000-5b8bd03a1a6044aeb146a92b5e52d24c.jpg"
                              }
                              alt="user_icon"
                            />
                          </div>
                        </div>
                        <div className="content">
                          <div
                            className="h6 background-text bold-text"
                            style={{
                              fontWeight: "700",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.userId.userDetailsId.userFName}{" "}
                            <span
                              className="small-text"
                              style={{ fontWeight: "normal", color: "#aaa" }}
                            >
                              - <Moment fromNow>{item.createdAt}</Moment>
                            </span>
                          </div>

                          <div
                            className="comment_content h5 grey-text"
                            style={{
                              transition: "all .5s ease-in",
                              position: "relative",
                              width: "85%",
                            }}
                          >
                            {isClosetEditActive &&
                            isClosetEditActive[item._id] ? (
                              <>
                                <input
                                  className="cmt-edit-input"
                                  autoFocus={true}
                                  type="text"
                                  value={isClosetEditActive[item._id].value}
                                  onChange={(e) => {
                                    setIsClosetEditActive((prev) => {
                                      return {
                                        ...prev,
                                        [item._id]: e.target.value,
                                      };
                                    });
                                  }}
                                />
                                <span
                                  className="cmt-save"
                                  onClick={() =>
                                    handleClosetCommentUpdate(item._id)
                                  }
                                >
                                  <i
                                    class="fas fa-check"
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "var(--background-color)",
                                      position: "absolute",
                                      right: "0",
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              <>
                                {item.comment}
                                {item.userId._id === userInfo?._id && (
                                  <span style={{ padding: "0 1rem" }}>
                                    <i
                                      onClick={() => {
                                        setIsClosetEditActive(() => {
                                          return {
                                            [item._id]: {
                                              value: item.comment,
                                            },
                                          };
                                        });
                                      }}
                                      class="fas fa-pen"
                                      style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {(userInfo?.role === "admin" ||
                          userInfo?._id === item.userId._id) && (
                          <div
                            className="delete_comment"
                            onClick={() => removeClosetComment(item._id)}
                          >
                            <i
                              class="fas fa-trash"
                              style={{ fontSize: "14px" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* <div
        style={tab == "third" ? { display: "block" } : { display: "none" }}
        className="tabcontent"
      >
        <div className="cs">
          <div class="container-cs">
            {smallLoading ? (
              <Spinner1 minHeight="50vh" />
            ) : (
              <>
                {closProduct.map((pInfo, index) => {
                  return (
                    <div
                      class="item"
                      key={pInfo._id}
                      style={{
                        borderRadius: "5px",
                        maxWidth: "220px",
                        maxHeight: "auto",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "220px",
                          maxHeight: "auto",
                        }}
                      >
                        <img
                          src={pInfo.productImage[0]}
                          alt="Image 1"
                          style={{ borderRadius: "5px 5px 0 0 " }}
                        />
                      </div>
                      <Link
                        to={{
                          pathname: `/product/${encodeURIComponent(
                            removeSpace(pInfo.productStats.brand)
                          )}-${encodeURIComponent(removeSpace(pInfo.productName))}`,
                          search: `?product=${pInfo._id}`,
                        }}
                        state={{ id: pInfo._id }}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="closet-card-text">
                          <div
                            className="h6"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {pInfo.productName}
                          </div>
                          <hr
                            style={{
                              borderStyle: "solid",
                              borderColor: "#ddd",
                            }}
                          />
                          <div>
                            {pInfo.productHanger}&nbsp;
                            <TbHanger />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
                <div
                  style={{ position: "absolute", top: "45%", right: "3.5rem" }}
                >
                  <Link
                    to={
                      userInfo && userInfo._id == closetInfo.masterId
                        ? { pathname: "/my-closet" }
                        : {
                            pathname: `/closet/${closetInfo.closetName}`,
                            search: `?closet=${closetInfo.masterId}`,
                          }
                    }
                    state={{ id: closetInfo.masterId }}
                    style={{ color: "#F26654" }}
                  >
                    <BsFillArrowRightCircleFill
                      size={"1.8em"}
                      style={{
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                    />{" "}
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div> */}
      </div>

      <h3
        className="h3 bold-text text-center background-text"
        style={{ marginTop: "4rem", fontSize: "28px" }}
      >
        Other items from {closetInfo.closetName}
      </h3>

      {smallLoading ? (
        <Spinner1 minHeight="50vh" />
      ) : (
        <>
          <div className="shop-items-con other-closet-items-con">
            {closProduct.map((item, index) => {
              console.log("kk2lk", item);
              return (
                <Link
                  to={{
                    pathname: `/product/${encodeURIComponent(
                      removeSpace(item.productStats.brand)
                    )}-${encodeURIComponent(removeSpace(item.productName))}/${
                      item._id
                    }`,
                    // search: `?product=${item._id}`,
                  }}
                  state={{ id: item._id }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="shop-item-card"
                    style={{
                      width: "250px",
                      height: "inherit",
                      margin: "1rem",
                    }}
                  >
                    <div className="card_image" style={{ height: "270px" }}>
                      <LazyLoadImage
                        src={
                          item.productImage[0]
                            ? item.productImage[0]
                            : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                        }
                        alt="FP1"
                        effect="blur"
                        placeholderSrc={loadImgGif}
                        // style={{ height: "150px" }}
                      />
                      {/* <div className="action_icon">
                        <Link
                          to={{
                            pathname: `/product/${encodeURIComponent(
                              item.productStats.brand
                            )}-${encodeURIComponent(item.productName)}`,
                            search: `?product=${item._id}`,
                          }}
                          state={{ id: item._id }}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="btn">View</div>
                        </Link>
                        <div className="btn"><i class="fas fa-heart"></i></div>
                        <div className="btn"><i class="fas fa-search-plus"></i></div>
                      </div> */}
                    </div>
                    <div className="card_text">
                      <div className="product_title h6">{item.productName}</div>
                      <hr
                        style={{
                          margin: "1rem .2rem",
                          borderStyle: "solid",
                          color: "#ddd",
                        }}
                      />
                      <div className="product_price h6 bold-text">
                        {item.productHanger}
                        <PiCoatHangerFill
                          style={{ marginLeft: "3px" }}
                          size={"1.3em"}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
            {/* <div style={{ position: "absolute", top: "45%", right: "3.5rem" }}>
              <Link
                to={
                  userInfo && userInfo._id == closetInfo.masterId
                    ? { pathname: "/my-closet" }
                    : {
                        pathname: `/closet/${closetInfo.closetName}`,
                        search: `?closet=${closetInfo.masterId}`,
                      }
                }
                state={{ id: closetInfo.masterId }}
                style={{ color: "#F26654" }}
              >
                <BsFillArrowRightCircleFill
                  size={"1.8em"}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                  }}
                />{" "}
              </Link>
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "4rem",
            }}
          >
            <Link
              to={
                userInfo && userInfo._id == closetInfo.masterId
                  ? { pathname: "/user/my-closet" }
                  : {
                      pathname: `/closet/${closetInfo.closetName}`,
                      search: `?closet=${closetInfo.masterId}`,
                    }
              }
              state={{ id: closetInfo.masterId }}
              style={{ color: "#F26654" }}
            >
              <button
                className="btn"
                style={{ textAlign: "center", margin: "auto" }}
              >
                More from {closetInfo.closetName}
              </button>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default ProductTabs;
