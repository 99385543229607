import React from "react";
import { Routes, Route } from "react-router-dom";
import Purchases from "../../pages/Purchases";
import EditProfile from "../../pages/EditProfile";
import Membership from "../../pages/Membership";
import Orders from "../../pages/Orders";
import NotFound from "../../pages/NotFound";
import MyCloset from "../../pages/MyCloset";
import Hangers from "../../pages/Hangers";
import Profile from "../../pages/Profile";

const MyClosetRightSection = () => {
  return (
    <Routes>
      <Route path="/account" element={<Profile />} />
      <Route path="/my-closet" element={<MyCloset />} />
      <Route path="/hanger" element={<Hangers />} />
      <Route path="/purchases" element={<Purchases />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default MyClosetRightSection;
