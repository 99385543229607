import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import {
  BsFillEmojiHeartEyesFill,
  BsEmojiLaughingFill,
  BsEmojiSmileFill,
} from "react-icons/bs";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useDispatch, useSelector } from "react-redux";
import { getStaticContent } from "../StateSlices/staticContentSlice";
// import "@splidejs/react-splide/css";

const Testimonials = ({ content, title, subtitle, showOnlyThree = false }) => {
  // if (content) {
  // }
  const [data, setData] = useState({});
  useEffect(() => {
    if (content) {
      setData(content);
    }
  }, [content]);
  const dispatch = useDispatch();
  // const { staticContentInfo } = useSelector((state) => state.staticContent);
  // const [data1, setData1] = useState(null);
  const [loading, setLoading] = useState(false);
  //consolele.log("From TEstimonials Content ", content);
  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     getStaticContent({
  //       values: { section: "testimonials" },
  //       requestType: "get",
  //     })
  //   );
  // }, []);
  // useEffect(() => {
  //   if (staticContentInfo) {
  //     setData1(staticContentInfo);
  //     //consolele.log("k2l ", data1);

  //     setLoading(false);
  //   }
  // }, [staticContentInfo]);
  // const data = [
  //   {
  //     icon: <BsEmojiLaughingFill />,
  //     name: "Tina",
  //     city: "",
  //     desc: "What a wonderful place for making clothing sustainable! I have donated and used points to get some beautiful dresses, tops and even a brand new bathing suit with the tags still on!",
  //   },
  //   {
  //     icon: <BsEmojiLaughingFill />,
  //     name: "Mandy",
  //     city: "",
  //     desc: "What a delight! Stop spending money on mass-produced new clothes, go second-hand, and curate a unique wardrobe full of pieces that no one else will have! It's good for the earth and it's good for humanity! Win-win-win!",
  //   },
  //   {
  //     icon: <BsEmojiLaughingFill />,
  //     name: "Elizabeth",
  //     city: "",
  //     desc: "My experience with Closest Closet has been amazing! Danielle is a visionary and giving members of the community a really fun way to shop that's economic but also ecologically responsible. The inventory is very good quality and are current styles. Plus the unlimited ability to swap clothes is incredible and addicting!",
  //   },
  //   {
  //     icon: <BsEmojiLaughingFill />,
  //     name: "Micaela",
  //     city: "",
  //     desc: "Awesome! I have been able to rid my closet of things I just was hanging onto for unexplainable reasons, and get my husband and I some new items to wear to work. It's fun and easy to find something new to try, and if you don't like it...simply bring it back in and try something new!",
  //   },
  // ];
  return (
    <>
      <br />
      <h2 className="large-text bold-text text-center background-text">
        {title ? title : "Testimonials"}
      </h2>
      {subtitle && (
        <h3 className="h3 lato-desc text-center background-text">{subtitle}</h3>
      )}
      <div className="testimonials-con">
        {/* {data.map((item, index) => (
          <figure class="snip1533">
            <figcaption>
              <blockquote>
                <p>{item.desc}</p>
              </blockquote>
              <h4> - {item.name}</h4>
              <h4>{item.city}</h4>
            </figcaption>
          </figure>
        ))} */}
        {loading
          ? ""
          : data &&
            data.cards &&
            (showOnlyThree
              ? data.cards.slice(0, 3).map((item, index) => (
                  <figure class="snip1533" key={index}>
                    <figcaption>
                      <blockquote>
                        <p>{item.desc}</p>
                      </blockquote>
                      <h4> - {item.title}</h4>
                      {/* <h4>{item.city}</h4> */}
                    </figcaption>
                  </figure>
                ))
              : data.cards.map((item, index) => (
                  <figure class="snip1533" key={index}>
                    <figcaption>
                      <blockquote>
                        <p>{item.desc}</p>
                      </blockquote>
                      <h4> - {item.title}</h4>
                      {/* <h4>{item.city}</h4> */}
                    </figcaption>
                  </figure>
                )))}
      </div>
      <br />
    </>
  );
};

export default Testimonials;
