import React from "react";
import { ShimmerPostList, ShimmerPostItem, ShimmerSectionHeader, ShimmerTitle, ShimmerText } from "react-shimmer-effects";
import "./Shimmer.css";

const ShimmerCloset = () => {
  return (
    <>
    <ShimmerTitle line={1} gap={10} variant="primary"  className={"shimmer-title"}/>
    <ShimmerText line={1} gap={10} className={"shimmer-subtitle"}/>
    <div className="shimmer-con">
      {[...Array(10)].map((item, index) => (
        <ShimmerPostItem
          key={index}
          card
          title
          imageType="thumbnail"
          imageWidth={"250px"}
          imageHeight={"250px"}
          contentCenter
        />
      ))}
    </div>
      <br/>
    </>
  );
};

export default ShimmerCloset;
