import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getuserInfo } from "../StateSlices/getuserSlice";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  AmazonLoginButton,
} from "react-social-login-buttons";
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import googleOneTap  from "google-one-tap";
import axios from "axios";
// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
const REDIRECT_URI = "https://localhost:3000/login";

const SignupForm = ({
  setTc,
  status,
  formRef,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  setId,
  membership,
  setFieldValue,
  setProvider,
  provider,
  setPEmail,
  setSpinner
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, error, profile } = useSelector((state) => state.user);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // dispatch(getuserInfo({token:token}))
      // window.scrollTo(0,0)
      navigate("/user/my-closet");
    } else {
      // navigate("/feed")
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (profile) {
      //consolele.log(profile);
      if (profile !== "complete") {
        navigate("/setup-closest");
      }
    }
  }, [profile]);

  useGoogleOneTapLogin({
    onError: error => {
      console.log(error)
    },
    onSuccess: (response) => {
      setProvider('google');
      setPEmail(response.email);
      handleSubmit();
    },
    googleAccountConfigs: {
      client_id: '979214090074-cmcbqh2e53bjam8oba2104pp3hh3c4q2.apps.googleusercontent.com'// Your google client id here !!!
    },
  });

  return (
    <div className="signupform_container">
      <div className="social_signup">
        <h1
          className="section_title bold-text"
          style={{
            marginTop: "-80px",
            paddingBottom: "20px",
          }}
        >
          Login
        </h1>
        {/* <div className="social_btn_container"> */}
          {/* <button className='btn dark-btn google-btn social-btn-width'><i class="fab fa-google"></i>Continue With <strong>Google</strong></button> */}
          <LoginSocialGoogle
            client_id={
              process.env.REACT_APP_GG_APP_ID ||
              "979214090074-cmcbqh2e53bjam8oba2104pp3hh3c4q2.apps.googleusercontent.com"
            }
            // onLoginStart={onLoginStart}
            redirect_uri={REDIRECT_URI}
            // scope="openid profile email access_token"
            discoveryDocs="claims_supported"
            access_type="online"
          isOnlyGetToken
          typeResponse="accessToken"
            onResolve={({ provider, data }) => {
              console.log(provider);
              console.log(data);
              axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`, {
                headers: {
                    Authorization: `Bearer ${data.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                setSpinner(true)
                console.log(res.data);
                
                  setPEmail(res.data.email);
                // formikRef.current.setFieldValue("password", "google1234");
                // formikRef.current.setFieldValue("confirmPassword", "google1234");
                setProvider("google");
                
                // formikRef.current.setErrors({});
                // formikRef.current.touched({});
                setTimeout(()=>{
                    handleSubmit();
                },1000)
                
                // submitForm();
                // console.log(formikRef.current)
            })
            .catch((err) => console.log(err));
            // setProvider(provider);
            // setPEmail(data.email);
            // handleSubmit();
            }}
            onReject={(err) => {
              //consolele.log(err);
            }}
          >
            <GoogleLoginButton
              style={{
                minWidth: "100%",
                boxShadow: "none",
                background: "#f1f1f1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "'Lato', sans-serif",
              }}
              activeStyle={{ background: "#eee" }}
            />
          </LoginSocialGoogle>
          {/* <div style={{ margin: "1rem" }}></div> */}
          {/* <button className='btn dark-btn facebook-btn social-btn-width'><i class="fab fa-facebook-f"></i>Continue With <strong>Facebook</strong></button> */}
          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || "1356304748373309"}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            // onLoginStart={onLoginStart}
            // onLogoutSuccess={onLogoutSuccess}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              //consolele.log(provider);
              //consolele.log(data);
              setProvider(provider);
              setPEmail(data.email);
              handleSubmit();
            }}
            onReject={(err) => {
              //consolele.log(err);
            }}
          >
            <FacebookLoginButton
              style={{
                minWidth: "100%",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "'Lato', sans-serif",
              }}
            />
          </LoginSocialFacebook>
          {/* <button className='btn dark-btn microsoft-btn social-btn-width'><i class="fab fa-microsoft"></i>Continue With <strong>Microsoft</strong></button> */}
          {/* <LoginSocialAmazon
            client_id={process.env.REACT_APP_AMAZON_APP_ID || ""}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              //consolele.log(provider);
              //consolele.log(data);
            }}
            onReject={(err) => {
              //consolele.log(err);
            }}
            // onLoginStart={onLoginStart}
          >
            <AmazonLoginButton
              style={{ minWidth: "100%", boxShadow: "none" }}
            />
          </LoginSocialAmazon> */}
        {/* </div> */}
      </div>
      <div className="email_signup">
        <div
          className="section_title h2 bold-text"
          style={{
            padding: "15px 0",
          }}
        >
          OR
        </div>
        <form onSubmit={handleSubmit}>
          <div className="email_signup_form">
            <div className="input_container background-text">
              <label htmlFor="email_address">Email Address:</label>
              <input
                type="email"
                id="email_address"
                name="email"
                onChange={handleChange}
                value={values.email}
                required
              />
            </div>
            <div className="input_container background-text">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                value={values.password}
                required
              />
            </div>
            <div
              className="input_container"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <input
                type="checkbox"
                className="tc_input"
                name="terms"
                style={{ transform: "scale(1)", borderRadius: ".1rem" }}
                onChange={() => setTc((prev) => !prev)}
              />
              <label htmlFor="terms" style={{ marginTop: "5px" }}>
                Remember me
              </label>
            </div>
            <button
              className="btn dark-btn"
              disabled={status === "loading" ? true : false}
              style={{ marginTop: "40px" }}
            >
              {status === "loading" ? "Please Wait" : "Login"}
            </button>
            <div className="loginOptionsContainer">
              <h3 className="loginOptions">
                Not a Member?{" "}
                <Link
                  to="../signup"
                  style={{
                    color: "#F26654",
                    borderBottom: "2px solid var(--peel-orange-color)",
                  }}
                >
                  <span
                    style={{
                      color: "#F26654",
                      borderBottom: "2px solid var(--peel-orange-color)",
                    }}
                  >
                    Click here
                  </span>
                </Link>
              </h3>
              <h3 className="loginOptions">
                Forgot your Password?{" "}
                <Link
                  to="/forgotpassword"
                  style={{
                    color: "#F26654",
                    borderBottom: "2px solid var(--peel-orange-color)",
                  }}
                >
                  <span
                    style={{
                      color: "#F26654",
                      borderBottom: "2px solid var(--peel-orange-color)",
                    }}
                  >
                    Click here
                  </span>
                </Link>
              </h3>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;