import React from "react";
import { ShimmerPostList, ShimmerPostItem } from "react-shimmer-effects";
import "./Shimmer.css";

const Shimmer = () => {
  return (
    <div className="shimmer-con">
      {[...Array(8)].map((item, index) => (
        <ShimmerPostItem
          key={index}
          card
          title
          imageType="thumbnail"
          imageWidth={"250px"}
          imageHeight={"250px"}
          contentCenter
        />
      ))}
    </div>
  );
};

export default Shimmer;
