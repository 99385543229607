import React, { useEffect, useState } from "react";
import "./Product.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addtoCart,
  resetCartMessage,
} from "../components/StateSlices/addtocartSlice";
import { getProduct, reset } from "../components/StateSlices/getproductSlice";
import PageLocation from "../components/PageLocation/PageLocation";
import ProductDescription from "../components/ProductDescription/ProductDescription";
import product from "../assets/images/product.png";
import ProductCloset from "../components/ProductCloset/ProductCloset";
import ProductTabs from "../components/ProductTabs/ProductTabs";
import Spinner1 from "../components/Spinner1/Spinner1";
import { getuserInfo } from "../components/StateSlices/getuserSlice";
import {
  getSoldedProduct,
  preset,
} from "../components/StateSlices/getSoldedSlice";
import PurchasedDescription from "../components/PurchasedDescription/PurchasedDescription";
import SupportEngine from "../components/ChatSupport/SupportEngine";
import InnerImageZoom from "react-inner-image-zoom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Solded = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { cstatus, message } = useSelector((state) => state.cart);
  // console.log("userINFO", userInfo);
  // console.log("profile", profile);
  const { pstatus, productInfo, images, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );
  const { purchasestatus, purchaseInfo } = useSelector((state) => state.solded);
  console.log("productINFO", closetInfo, productInfo);

  useEffect(() => {
    console.log(location.state.pId)
    if (productInfo && productInfo._id !== location.state.pId) {
      dispatch(reset());
      dispatch(getProduct(location.state.pId));
    }else{
      dispatch(getProduct(location.state.pId));
    }

    // if(productInfo !== {}){
    //     productInfo.productImage.map((val)=>{
    //         setImages(prevState => {
    //             return [...prevState,{original:val,thumbnail:val}]
    //         })
    //     })
    // }
  }, []);

  useEffect(() => {
    if (pstatus == "loading" || pstatus == "idle") {
      console.log(pstatus);
      setLoading(true);
    } else {
      console.log(location.state.pId)
      if (productInfo && productInfo._id === location.state.pId) {
        console.log("Here");
        let token = localStorage.getItem("clttoken");
        console.log(token);
        dispatch(getSoldedProduct({ id: location.state.id, token: token }));
      }
      setLoading(false);
    }
  }, [pstatus]);

  useEffect(() => {
    if (purchasestatus == "loading" || purchasestatus == "idle") {
      console.log(purchasestatus);
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [purchasestatus]);

  // useEffect(()=>{

  // },[productInfo])

  // const images = [
  //     {
  //       original: product,
  //       thumbnail: product,
  //       originalHeight:"100%",
  //     },
  //     {
  //         original: product,
  //         thumbnail: product,
  //         originalHeight:"100%",

  //     },
  //     {
  //         original: product,
  //         thumbnail: product,
  //         originalHeight:"100%",

  //     },
  //   ];

  const AddToCart = (id) => {
    console.log(id);
    let token = localStorage.getItem("clttoken");
    setLoading(true);
    dispatch(addtoCart({ token, id }));
  };

  if (message) {
    console.log(message);
    dispatch(resetCartMessage());
    setLoading(false);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({token:token}));
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className="page-container"
      style={{ position: "relative", margin: "40px 60px 0px 60px" }}
    >
      {loading || !userInfo || !closetInfo ? (
        <Spinner1 />
      ) : (
        <>
          {console.log(userInfo.userDetailsId.userEmail, closetInfo.userEmail)}
          <PageLocation />
          <div className="product_section">
              <div className="product_image_gallery">
                <Slider {...settings}>
                  {/* {console.log(images)} */}
                  {images.map((img, index) => (
                    <InnerImageZoom
                      src={img.original}
                      //   fullscreenOnMobile={true}
                      hideCloseButton={true}
                      hasSpacer={true}
                    />
                  ))}
                </Slider>
              </div>
            <div className="product_stats">
              <PurchasedDescription
                productInfo={productInfo}
                userInfo={userInfo}
                pid={location.state.pId}
                AddToCart={AddToCart}
                purchaseInfo={purchaseInfo}
              />
            </div>
          </div>
          <ProductCloset closetInfo={closetInfo} userInfo={userInfo}/>
          {/* {userInfo && closetInfo && (
            <SupportEngine
              productId={closetInfo._id}
              sender={{
                email: userInfo.userDetailsId.userEmail,
                userName: `${userInfo.userDetailsId.userFName}_${userInfo.userDetailsId.userLName}`,
              }}
              receiver={{
                email: `${closetInfo.userEmail}`,
                userName: `${closetInfo.userFName}_${closetInfo.userLName}`,
              }}
            />
          )} */}
        </>
      )}
    </div>
  );
};

export default Solded;
