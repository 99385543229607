import React from "react";
import {
  ElementsConsumer,
  CardElement,
  CardNumberElement,
  Elements,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import CardSection from "./CardSection";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

class CheckoutSection extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const errorKeys = Object.keys(this.props.errors);
    //console.log(errorKeys)
    if (errorKeys.length === 0) {
      this.props.setSpinner(true);
      const toastOption = {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        toast.error(result.error.message, toastOption);
        //console.log(result.error.message);
        this.props.setSpinner(false);
      } else {
        //console.log(result.token);
        this.props.setId(result.token);
        this.props.submitForm();
        return;
      }
    } else {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  render() {
    return (
      <div>
        <form>
          <CardSection />
          <button
            style={{
              margin: "20px 0px",
              fontWeight: "bold",
              fontFamily: "LATO",
              fontSize: "16px",
            }}
            type="button"
            onClick={this.handleSubmit}
            title={
              this.props.addressDone === false
                ? "Please Update Address First"
                : ""
            }
            className={`btn dark-btn ${
              this.props.addressDone === false ? "dis-btn" : ""
            }`}
            disabled={
              !this.props.stripe ||
              this.props.spinner === true ||
              !this.props.addressDone
            }
          >
            {!this.props.stripe || this.props.spinner === true
              ? "Please Wait"
              : "Submit Order"}
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutSection({
  submitForm,
  formRef,
  setId,
  spinner,
  setSpinner,
  errors,
  addressDone,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutSection
          stripe={stripe}
          elements={elements}
          submitForm={submitForm}
          formRef={formRef}
          setId={setId}
          spinner={spinner}
          setSpinner={setSpinner}
          errors={errors}
          addressDone={addressDone}
        />
      )}
    </ElementsConsumer>
  );
}
