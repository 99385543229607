import React, { createRef, useEffect, useRef, useState } from "react";
import repeatImg from "../assets/images/repeatImg.png";
import "./LogoutPageNew.css";
import ccIntroVideo2 from "../assets/videos/cc-intro-video-2.mp4";
import ccBrandsVideo from "../assets/videos/cc-brands.mp4";
import ccNotWearing from "../assets/videos/cc-not-wearing.mp4";
import ccThrift from "../assets/videos/cc-thrift.mp4";
import ccEnvironment from "../assets/videos/cc-environment.mp4";
import ccThankyouSignup from "../assets/videos/cc-thankyou-signup.mp4";
import ccMembership from "../assets/videos/cc-membership.mp4";
import { Link } from "react-router-dom";
import Marquee from "../components/HomeComponents/Marquee";
import Testimonials from "../components/HomeComponents/Testimonials";
import ScrollAnimation from "react-animate-on-scroll";
import { FaCirclePlay } from "react-icons/fa6";
import { GoMute, GoUnmute } from "react-icons/go";

const LogoutPageNew = ({ testimonialsContent }) => {
  const videoRef1 = useRef();
  const videoRef2 = useRef();
  const videoRef3 = useRef();
  const videoRef4 = useRef();
  const videoRef5 = useRef();
  const videoRef6 = useRef();
  const videoRef7 = useRef();
  const [membership, setMembership] = useState("monthly");
  // const [videoIndex, setVideoIndex] = useState(0);

  const handleValue = (value) => {
    sessionStorage.setItem("subscriptionPack", value);
  };
  useEffect(() => {
    sessionStorage.setItem("subscriptionPack", "monthly");
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const refs = [
  //       videoRef1,
  //       videoRef2,
  //       videoRef3,
  //       videoRef4,
  //       videoRef5,
  //       videoRef6,
  //       videoRef7,
  //     ];
  //     let isInViewport = false;
  //     refs.forEach((refInd, ind) => {
  //       const sectionRect = refInd.current.getBoundingClientRect();

  //       if (sectionRect) {
  //         isInViewport =
  //           sectionRect.top >= 0 && sectionRect.bottom <= window.innerHeight;
  //         if (isInViewport) {
  //           console.log(refInd, "in view port");
  //         }
  //         if (
  //           sectionRect.top >= 0 &&
  //           sectionRect.bottom <= window.innerHeight
  //         ) {
  //           // console.log("ind", ind, ref);
  //           refs.forEach((reff, indd) => {
  //             if (indd !== ind) {
  //               reff.current.pause();
  //             }
  //           });
  //           setVideoIndex(ind + 1);
  //           refInd.current.play();
  //           // ref.current.currentTime = 0;
  //         }
  //       }
  //     });
  //   };
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  const VideoComponent = ({ layout, vref, vidInd, vidSrc }) => {
    const [pauseCurrentVideoIndex, setPauseCurrentVideoIndex] = useState(
      vidInd === 1 ? false : true
    );
    const [muteCurrentVideoIndex, setMuteCurrentVideoIndex] = useState(false);
    return (
      <div
        className={`${layout} video-wrapper`}
        style={{ position: "relative" }}
      >
        {pauseCurrentVideoIndex && (
          <div
            className="video-play-btn"
            onClick={() => {
              if (vref.current.paused) {
                // console.log("played from button");
                vref.current.play();
                setPauseCurrentVideoIndex(false);
              } else {
                // console.log("paused from button");
                setPauseCurrentVideoIndex(true);
                vref.current.pause();
              }
            }}
            style={{
              display: pauseCurrentVideoIndex ? "flex" : "none",
              top: "45%",
              right: "45%",

              zIndex: 999,
            }}
          >
            <FaCirclePlay size={"3em"} color="var(--primary-color)" />
            <span class="dot_animation"></span>
          </div>
        )}
        <div>
          <div
            onClick={() => {
              if (vref.current.paused) {
                // console.log("played from surface");
                vref.current.play();
                setPauseCurrentVideoIndex(false);
              } else {
                // console.log("paused from surface");
                setPauseCurrentVideoIndex(true);
                vref.current.pause();
              }
            }}
          >
            <video
              ref={vref}
              width="100%"
              height="100%"
              preload="auto"
              autoPlay={vidInd === 1 ? true : false}
              style={{ borderRadius: "5px" }}
            >
              <source src={vidSrc}></source>
            </video>
          </div>
          {!pauseCurrentVideoIndex && (
            <div
              id="muteUnmuteBtn"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                cursor: "pointer",
                background: "#fff",
                borderRadius: "50%",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "8px",
                display: "flex",
                zIndex: 1,
              }}
              onClick={() => {
                if (vref.current.muted) {
                  setMuteCurrentVideoIndex(false);
                  vref.current.muted = false;
                } else {
                  setMuteCurrentVideoIndex(true);
                  vref.current.muted = true;
                }
              }}
            >
              {muteCurrentVideoIndex ? (
                <GoMute color="var(--primary-color)" />
              ) : (
                <GoUnmute color="var(--primary-color)" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
      <div className="gen-home-container site-width">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <div className="left text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                A Closest Closet membership saves money, time & the environment
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                ❤️ Learn why it will be your favorite fashion subscription ❤️
              </h3>
              <h4
                lassName="h4"
                style={{ color: "var(--secondary-color)", margin: "10px 0" }}
              >
                HANG → SHOP → WEAR
              </h4>
              <img
                src={repeatImg}
                style={{ width: "200px", marginBotton: "1rem" }}
                alt="img"
              />
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
            <VideoComponent
              layout={"right"}
              vidInd={1}
              vidSrc={ccIntroVideo2}
              vref={videoRef1}
            />
          </section>
        </ScrollAnimation>

        {/* SECTION 2 */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <VideoComponent
              layout={"left"}
              vidInd={2}
              vidSrc={ccMembership}
              vref={videoRef2}
            />
            <div className="right text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                Members shop for FREE, using their own items as currency
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                Members (& their families) turn their unloved clothing +
                accessories into credits they use to shop other members items
              </h3>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </ScrollAnimation>

        {/* SECTION 3 */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <div className="left text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                Shop a wide variety of top brands
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                Clothing & Accessories in all categories from GAP to Vera Wang
              </h3>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
            <VideoComponent
              layout={"right"}
              vidInd={3}
              vidSrc={ccBrandsVideo}
              vref={videoRef3}
            />
          </section>
        </ScrollAnimation>
      </div>

      {/* SECTION 4 */}
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <section className="sec" style={{ maxWidth: "100%" }}>
          <Marquee />
        </section>
      </ScrollAnimation>
      <div className="gen-home-container site-width">
        {/* SECTION 5 */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section style={{ marginBottom: "4rem" }}>
            <Testimonials
              content={testimonialsContent}
              showOnlyThree="true"
              title="Members Swap On Average 8 Items Per Month"
              subtitle="(that’s on average a $300 value vs. buying 8 items @ other secondhand marketplaces)"
            />
            <div style={{ width: "50%", margin: "auto" }}>
              <Link to={"/signup"}>
                <button
                  className="btn bold-text"
                  style={{
                    margin: "1.5rem 0",
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                >
                  Become a Member
                </button>
              </Link>
            </div>
          </section>
        </ScrollAnimation>

        {/* SECTION 6 */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <div className="left text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                What % of your closet are you not wearing?
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                Full closet but nothing to wear, we know. <br />
                Closest Closet has you covered in all the latest trends
              </h3>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
            <VideoComponent
              layout={"right"}
              vidInd={4}
              vidSrc={ccNotWearing}
              vref={videoRef4}
            />
          </section>
        </ScrollAnimation>

        {/* SECTION 7*/}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <VideoComponent
              layout={"left"}
              vidInd={5}
              vidSrc={ccThrift}
              vref={videoRef5}
            />
            <div className="right text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                Love to thrift?
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                With hundreds of new items added per week, you never know what
                you’ll find!
              </h3>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </ScrollAnimation>

        {/* SECTION 8 */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <div className="left text-content">
              <h2 className="large-text lato-heading bold-text background-text">
                Help Make An Impact On The Environment
              </h2>
              <h3 className="h3 lato-desc" style={{ margin: "15px 0" }}>
                Fashion has become the #2 polluter in the world. Help reduce
                it’s impact by buying secondhand items
              </h3>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
            <VideoComponent
              layout={"right"}
              vidInd={6}
              vidSrc={ccEnvironment}
              vref={videoRef6}
            />
          </section>
        </ScrollAnimation>

        {/* SECTION 9*/}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <section className="sec">
            <VideoComponent
              layout={"left"}
              vidInd={7}
              vidSrc={ccThankyouSignup}
              vref={videoRef7}
            />
            <div className="right text-content" style={{ maxWidth: "none" }}>
              <h1 className="section_title bold-text">
                Step 1: Choose Membership
              </h1>
              <div className="membership_card_container">
                <div
                  className={`${
                    membership === "trial" ? `membership_select` : ``
                  } membership_card`}
                  onClick={() => {
                    setMembership("trial");
                    handleValue("trial");
                  }}
                >
                  <div className="membership_title h3">$0 Guest</div>
                  <div className="membership_desc">
                    Unlimited transactions with minimal fees
                  </div>
                  <div className="membership_feature">
                    <ul>
                      <li>
                        <span className="bullet"></span>
                        <span>Shop non member exclusive Items</span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Buy hanger credits with cash at checkout at full price
                        </span>
                      </li>
                    </ul>
                  </div>
                  <button className="btn dark-btn">
                    {membership == "trial"
                      ? "Guest Plan Selected"
                      : "Select Guest"}
                  </button>
                </div>
                <div
                  className={`${
                    membership === "monthly" ? `membership_select` : ``
                  } membership_card`}
                  onClick={() => {
                    setMembership("monthly");
                    handleValue("monthly");
                  }}
                >
                  <div className="membership_title h3">$20 Monthly</div>
                  <div className="membership_desc">
                    Unlimited transactions with no fee
                  </div>
                  <div className="membership_feature">
                    <ul>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Earn hanger credits for every item in your closet
                        </span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>Shop other closets w/ your hanger credits</span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Buyers pay for shipping (local pickup is encouraged)
                        </span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>Unlimited transactions, no fee per item</span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Discounted price on hanger credits if you are short
                        </span>
                      </li>
                    </ul>
                  </div>
                  <button className="btn dark-btn">
                    {membership == "monthly"
                      ? "Monthly Selected"
                      : "Select Monthly"}
                  </button>
                </div>
                <div
                  className={`${
                    membership === "annualy" ? `membership_select` : ``
                  } membership_card`}
                  onClick={() => {
                    setMembership("annualy");
                    handleValue("annualy");
                  }}
                >
                  <div className="membership_title h3">$160 Annually</div>
                  <div className="membership_desc">
                    Unlimited transactions with no fee to help make the biggest
                    impact on the environment while saving 33%!
                  </div>
                  <div className="membership_feature">
                    <ul>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Earn hanger credits for every item in your closet
                        </span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>Shop other closets w/ your hanger credits</span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Buyers pay for shipping (local pickup is encouraged)
                        </span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>Unlimited transactions, no fee per item</span>
                      </li>
                      <li>
                        <span className="bullet"></span>
                        <span>
                          Discounted price on hanger credits if you are short
                        </span>
                      </li>
                    </ul>
                  </div>
                  <button className="btn dark-btn">
                    {membership == "annualy"
                      ? "Annualy Selected"
                      : "Select Annualy"}
                  </button>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Link to={"/signup"}>
                  <button
                    className="btn bold-text"
                    style={{
                      margin: "1.5rem 0",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    Become a Member
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    </ScrollAnimation>
  );
};

export default LogoutPageNew;
