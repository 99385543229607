import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  fulFillStatus: "idle",
  fulFillInfo: null,
  fulFillError: null,
};

export const fulfilled = createAsyncThunk(
  "checkout/fulfilled",
  async ({ token, pId, cId,userType }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/fulfilled/${pId}/${cId}`,
        {
            userType
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkoutStatusChange = createAsyncThunk(
  "checkout/status",
  async ({ token, pId, cId,status }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/status/${pId}/${cId}`,
        {
            status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const orderStatusChange = createAsyncThunk(
  "checkout/orderStatusChange",
  async ({ token, cId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/orderstatus/${cId}`,
        {
            
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fulfillSlice = createSlice({
  name: "fulfilled",
  initialState,
  reducers: {
    resetFulfill(state, action) {
      state.fulFillInfo = null;
      state.fulFillStatus = "idle";
      state.fulFillError = null;
    },
  },
  extraReducers: {
    [fulfilled.pending]: (state, action) => {
      state.fulFillStatus = "loading";
    },
    [fulfilled.fulfilled]: (state, action) => {
      state.fulFillStatus = "succeeded";
      state.fulFillInfo = action.payload.message;
    },
    [fulfilled.rejected]: (state, action) => {
      state.fulFillStatus = "failed";
      state.fulFillError = action.payload.message;
    },
    [checkoutStatusChange.pending]: (state, action) => {
      state.fulFillStatus = "loading";
    },
    [checkoutStatusChange.fulfilled]: (state, action) => {
      state.fulFillStatus = "succeeded";
      state.fulFillInfo = action.payload.message;
    },
    [checkoutStatusChange.rejected]: (state, action) => {
      state.fulFillStatus = "failed";
      state.fulFillError = action.payload.message;
    },
    [orderStatusChange.pending]: (state, action) => {
      state.fulFillStatus = "loading";
    },
    [orderStatusChange.fulfilled]: (state, action) => {
      state.fulFillStatus = "succeeded";
      state.fulFillInfo = action.payload.message;
    },
    [orderStatusChange.rejected]: (state, action) => {
      state.fulFillStatus = "failed";
      state.fulFillError = action.payload.message;
    },
  },
});

export const { resetFulfill } = fulfillSlice.actions;
export default fulfillSlice.reducer;
