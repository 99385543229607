import React, { useMemo, useState } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import Pagination from "../../../components/Pagination/Pagination";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
const UserAnalytics = ({
  loading,
  data,
  analyticsCount,
  pageNumber,
  setPageNumber,
}) => {
  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const capitalize = (params) => {
    if (params !== "")
      return params.value.charAt(0).toUpperCase() + params.value.slice(1);
    return "";
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "LATO" },
  }));

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Role",
      field: "role",
      // valueFormatter: (params) => capitalize(params),
    },
    {
      headerName: "Closet Name",
      field: "closetName",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone Number",
      field: "phone",
    },
    {
      headerName: "Closet Description",
      field: "closetDesc",
    },
    {
      headerName: "Date Created",
      field: "created",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Membership Status",
      field: "memberships",
      valueFormatter: (params) => {
        // console.log(params)
        return params.value.membershipHistory.length > 0
          ? params.value.membershipHistory[
              params.value.membershipHistory.length - 1
            ].type === "Cancelled"
            ? "Cancelled"
            : params.value.membershipHistory[
                params.value.membershipHistory.length - 1
              ].type === "trial"
            ? "Free"
            : "Active"
          : "Free";
      },
    },
    {
      headerName: "Membership type",
      field: "membership",
      // valueFormatter: (params) => capitalize(params),
    },
    {
      headerName: "Card Flagged (Hover for detail)",
      field: "charges",
      tooltipField: "failure_message",
    },
    // {
    //   headerName: "Membership status",
    //   field: "membershipStatus",
    // },
    {
      headerName: "Member expiration date",
      field: "membershipExp",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Address City",
      field: "city",
    },
    {
      headerName: "Address State",
      field: "state",
    },
    {
      headerName: "Adress Zip Code",
      field: "zipCode",
    },
    {
      headerName: "Gender",
      field: "gender",
    },
    {
      headerName: "Number of Items/Products in closet",
      field: "userProducts.productsId.length",
    },
    {
      headerName: "Item value",
      field: "totalProductValue",
    },
    {
      headerName: "Number of purchases",
      field: "userProducts.purchaseId.length",
    },
    {
      headerName: "Number of orders",
      field: "checkoutInfo.length",
    },
    {
      headerName: "Number of favorites",
      field: "userProducts.favouritesId.length",
    },
    {
      headerName: "Number of friends",
      field: "",
    },
    {
      headerName: "Number of chats",
      field: "",
    },
    {
      headerName: "Hanger Credits",
      field: "userHanger",
    },
    {
      headerName: "Subscription Money",
      field: "subscriptionMoney",
    },
    {
      headerName: "Last Login",
      field: "userLastActivated",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Referrals",
      field: "referrals.length",
    },
    {
      headerName: "Last purchase date",
      field: "latestCheckoutTime",
      valueFormatter: (params) => {
        let lastSubTime;
        if (params.data.memberships.membershipHistory.length > 0) {
          const filteredData = params.data.memberships.membershipHistory.filter(
            (item) => parseFloat(item.money) > 0
          );

          if (filteredData.length > 0) {
            lastSubTime = filteredData[filteredData.length - 1].time;
          }
        }
        // return the value which is greater from params and lastSubTime, params and lastSubTime is in string format and also can be undefined
        return lastSubTime && params.value
          ? new Date(lastSubTime).getTime() > new Date(params.value).getTime()
            ? getDate({ value: lastSubTime })
            : getDate({ value: params.value })
          : lastSubTime
          ? getDate({ value: lastSubTime })
          : params.value
          ? getDate(params)
          : "";
        // return params.value?getDate(params):""
      },
    },
    {
      headerName: "Last chat date",
      field: "latestChatDate",
      valueFormatter: (params) => (params.value ? getDate(params) : ""),
    },
    {
      headerName: "Last profile update date",
      field: "lastUpdateDate",
      valueFormatter: (params) => getDate(params),
    },
  ]);

  return (
    <div className="analytics-con" style={{ marginTop: "1rem" }}>
      {loading && data.length == 0 ? (
        <div style={{ borderRadius: "10px", overflow: "hidden" }}>
          <Spinner1 />
        </div>
      ) : (
        <>
          <div className="ag-theme-quartz" style={{ height: 900 }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={data}
              columnDefs={colDefs}
            />
          </div>
          <Pagination
            pageCount={Math.ceil(analyticsCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default UserAnalytics;
