import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./OrderTableAccordion.css"; // Import your CSS file
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { HiOutlineChat } from "react-icons/hi";
import OrderTable from "./OrderTable";
import Pagination from "../Pagination/Pagination";

const OrderRow = ({ order, handleFulfill }) => {
  // //consolele.log(order);
  const [collapsed, setCollapsed] = useState(order.collapse);
  const [contentHeight, setContentHeight] = useState(0);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const contentRef = React.useRef(null);

  React.useLayoutEffect(() => {
    setContentHeight(contentRef.current.scrollHeight);
  }, [collapsed]);

  return (
    <>
      <tr
        className="order-row table-accordion"
        onClick={toggleCollapse}
        style={{ fontWeight: collapsed ? "normal" : 700, cursor: "pointer" }}
      >
        {/* <td>{order.items[0].checkoutInfo.orderId}</td> */}
        <td>{order.items[0].buyerName}</td>
        <td>{order.buyerEmail}</td>
        <td style={{ cursor: "pointer", textAlign: "right" }}>
          {collapsed ? (
            <span>
              <span style={{ minWidth: "0" }}>{order.items.length}</span>{" "}
              &nbsp;&nbsp;{" "}
              <FaChevronRight
                style={{
                  paddingTop: "6px",
                  // position: "relative",
                  // left: "5rem",
                }}
              />
            </span>
          ) : (
            <span>
              <span style={{ minWidth: "0" }}>{order.items.length}</span>{" "}
              &nbsp;&nbsp;{" "}
              <FaChevronDown
                style={{
                  paddingTop: "6px",
                  // position: "relative",
                  // left: "5rem",
                }}
              />
            </span>
          )}
        </td>
      </tr>
      <tr className={`product-row ${collapsed ? "collapsed" : "no-collapsed"}`}>
        <td
          colSpan="4"
          style={{ padding: 0, border: "none" }}
          // style={collapsed ? { display: "none" } : { display: "block" }}
        >
          <div
            ref={contentRef}
            style={{
              height: collapsed ? "0" : `${contentHeight}px`,
              transition: "height 0.3s ease-out",
              overflow: !collapsed ? "hidden" : "auto",
              background: "rgb(255 217 213 / 5%)",
              border: "none",
            }}
          >
            <table style={{ borderBottom: "none", width: "100%" }}>
              <thead>
                <tr
                  style={{
                    background: "var(--peel-orange-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                >
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Order
                  </th>
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Image
                  </th>
                  <th
                    className="h6 bold-tex white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Product Name
                  </th>
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Product Brand
                  </th>
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Date - Time
                  </th>
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Chat
                  </th>
                  <th
                    className="h6 bold-text white-text"
                    style={{ fontWeight: 700 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((product) => {
                  //consolele.log(product)
                  return (
                    <tr
                      key={product.id}
                      className={product.read === false ? "blink-bg" : ""}
                    >
                      <td className="h6" style={{}}>
                        {product.checkoutInfo.orderId}
                      </td>
                      <td
                        className="h6 table-img"
                        style={{
                          width: "100px",
                          height: "120px",
                          transition: ".2s ease",
                          position:
                            window.innerWidth < 767 && !collapsed
                              ? "absolute"
                              : "inherit",
                        }}
                      >
                        <img
                          alt={product.productName}
                          width={"100%"}
                          src={product.checkoutInfo.productInfo.productImage[0]}
                        />
                      </td>
                      {/* {//consolele.log(product)} */}
                      <td className="h6" style={{}}>
                        <Link
                          to={`/purchased/${encodeURIComponent(
                            product.brandName
                          )}-${encodeURIComponent(product.productName)}`}
                          state={{ id: product.id, pId: product.productId }}
                          style={{ color: "#F26654", lineBreak: "anywhere" }}
                        >
                          {product.productName + product._id}
                        </Link>
                      </td>
                      <td className="h6">{product.brandName}</td>
                      <td className="h6">
                        {new Date(product.checkoutTime).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        ) +
                          " - " +
                          new Date(product.checkoutTime).toLocaleTimeString(
                            "en-US",
                            { hour: "numeric", minute: "numeric" }
                          )}
                      </td>
                      <td className="h6 ">
                        <Link
                          to={`/chat-support`}
                          state={{ sellerEmail: product.buyerEmail }}
                        >
                          <HiOutlineChat
                            fontSize={"1.4rem"}
                            color={"var(--primary-color)"}
                          />
                        </Link>
                      </td>
                      <td className="h6 ">
                        <button
                          className="btn"
                          onClick={() =>
                            handleFulfill(
                              product.checkoutInfo.products.productId,
                              product.checkoutInfo._id
                            )
                          }
                          disabled={
                            product.checkoutInfo.products.fulfilledSeller
                              ? true
                              : false
                          }
                          style={
                            product.checkoutInfo.products.fulfilledSeller
                              ? { padding: "6px 20px" }
                              : { padding: "6px 20px", background: "#f2998f" }
                          }
                        >
                          {product.checkoutInfo.products.fulfilledSeller
                            ? "FULFILLED"
                            : "Mark Fulfilled"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

const OrderTableAccordion = ({ orderInfo, handleFulfill }) => {
  const [thresholdPage, setThresholdPage] = useState(0);
  const [thresholdActive, setThresholdActive] = useState(true);
  // //consolele.log(orderInfo);
  const groupedData = orderInfo.reduce((result, item) => {
    const key = item.buyerEmail;
    // + item.checkoutTime;

    if (!result[key]) {
      result[key] = {
        buyerEmail: item.buyerEmail,
        // checkoutTime: item.checkoutTime,
        items: [],
        collapse: true,
      };
    }

    if (item.read === false) {
      // //consolele.log("Working")
      // //consolele.log(result[key])
      result[key].collapse = false;
    }

    result[key].items.push(item);

    return result;
  }, {});

  const groupedArray = Object.values(groupedData);

  //consolele.log(groupedArray);

  return (
    <>
      <table className="responsive-table">
        {/* <caption>Your Orders</caption> */}
        <thead>
          <tr>
            {/* <th scope="col">Order ID</th> */}
            <th scope="col">Buyer Name</th>
            <th scope="col" style={{ minWidth: "0" }}>
              Buyer Email
            </th>
            {/* <th>Checkout Info</th> */}
            <th scope="col" style={{ textAlign: "right" }}>
              Total Products
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedArray.map((order) => (
            <OrderRow
              key={order.checkoutId}
              order={order}
              handleFulfill={handleFulfill}
            />
          ))}
        </tbody>
      </table>
      {/* <Pagination
        pageCount={Math.ceil(groupedArray.length / 10)}
        pageNumber={thresholdPage}
        setPageNumber={setThresholdPage}
        // setProductInfo={setOrderDetails}
        thresholdActive={thresholdActive}
      /> */}
    </>
  );
};

export default OrderTableAccordion;
