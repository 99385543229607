import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import {
  resetPassword,
  reset,
} from "../components/StateSlices/resetpasswordSlice";

import "react-toastify/dist/ReactToastify.css";
import "./SetupClosest.css";

const ResetPassword = () => {
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const { status, message, error } = useSelector((state) => state.reset);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter Password"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      let payload = { ...values };
      payload.token = token;
      dispatch(resetPassword(payload));
      setSpinner(true);
      return false;
    },
  });

  if (message) {
    dispatch(reset());
    toast.success("Password Reset Succesfully", toastOption);
    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 5000);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  return (
    <div
      className="closest_setup_container"
      style={{ position: "relative", height: "39vh" }}
    >
      {spinner ? <Spinner /> : ""}
      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      <div className="closest_setup">
        <h1 className="section_title">Reset Password</h1>
        <div style={{ textAlign: "center" }}>Enter your New Password.</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="closest_setup_form">
            <div className="input_container">
              <label for="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <small className="form-text text-danger">
                  {formik.errors.password}
                </small>
              ) : null}
            </div>
            <div className="input_container">
              <label for="confirm_password">Confirm Password:</label>
              <input
                type="password"
                id="confirm_password"
                name="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <small className="form-text text-danger">
                  {formik.errors.confirmPassword}
                </small>
              ) : null}
            </div>
            <button
              className="btn dark-btn"
              type="submit"
              disabled={status === "loading" ? true : false}
            >
              {status === "loading" ? "Please Wait" : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
