import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  leaderboardResults: null,
  error: null,
};

export const getLeaderboardResults = createAsyncThunk(
  "static/getLeaderboardResults",
  async ({  }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/static/leaderboard`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getLeaderboardResultsSlice = createSlice({
  name: "getleaderboardresults",
  initialState,
  reducers: {
    reset(state, action) {
      state.error = null;
      state.leaderboardResults = null;
    },
    resetStatus(state, action) {
      state.status = "idle";
    },
  },
  extraReducers: {
    [getLeaderboardResults.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [getLeaderboardResults.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.leaderboardResults = action.payload.data;
    },
    [getLeaderboardResults.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reset, resetStatus } = getLeaderboardResultsSlice.actions;
export default getLeaderboardResultsSlice.reducer;
