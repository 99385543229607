import React, { useEffect, useState } from "react";
import "./Shop.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import SortItem from "../components/SortItem/SortItem";
import ShopItem from "../components/ShopItem/ShopItem";
import Spinner from "../components/Spinner/Spinner";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getallProducts,
  reset,
  resetError,
} from "../components/StateSlices/getproductsSlice";
import Pagination from "../components/Pagination/Pagination";
import { Helmet } from "react-helmet";

const Shop = ({ setCartVal, cartVal }) => {
  const navigate = useNavigate();
  const llocation = useLocation();

  const [scrollPage, setScrollPage] = useState(1);
  const [brandFilter, setBrandFilter] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [sizeFilter, setSizeFilter] = useState([]);
  const [conditionFilter, setConditionFilter] = useState([]);
  const [closetFilter, setClosetFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [hangerFilter, setHangerFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [location, setLocation] = useState("");
  const [distance, setDistance] = useState("");
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(true);
  const [switchTrigger, setSwitchTrigger] = useState(false);
  const { status, products, error, productCount, filters } = useSelector(
    (state) => state.product
  );
  const [productInfo, setProductInfo] = useState([]);
  const [sort, setSort] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [exclusiveFilter, setExclusiveFilter] = useState({
    value: "All",
    label: "All",
    first: true,
  });
  const [filterQuery, setFilterQuery] = useState("");
  const [initalLoad, setInitalLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(
    llocation.search.slice(-1)
      ? llocation.search.slice(llocation.search.indexOf("=") + 1) - 1
      : 0
  );
  const [sortFilter, setSortFilter] = useState("");
  const [resetFilt, setResetFilt] = useState(false);
  const [catValue, setCatValue] = useState("");
  const [pinCode, setPincode] = useState(null);
  const [memberChecked, setMemberChecked] = useState(false);
  const [nonMemberChecked, setNonMemberChecked] = useState(false);
  const [thresholdPage, setThresholdPage] = useState(0);
  const [thresholdActive, setThresholdActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pageChange, setPageChange] = useState(false);
  const [running, setRunning] = useState(false);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    console.log("JIIJ");
    if (llocation.state) {
      if (llocation.state.categoryName) {
        setCatValue((prev) => !prev);
      }
    }
  }, [llocation?.state?.categoryName]);

  useEffect(() => {
    // let token = localStorage.getItem("clttoken")
    // if(token){
    setSpinner(true);
    setProductInfo([]);
    dispatch(reset());
    console.log(llocation.state);
    console.log(filterQuery);

    let filterState = sessionStorage.getItem("closetshopfilter");
    let locationFilter = sessionStorage.getItem("closetshopzipfilter");
    console.log(locationFilter);
    if (locationFilter) {
      let locationArr = JSON.parse(locationFilter);
      if (locationArr.location) {
        setLocation(locationArr.location);
        setPincode(locationArr.location);
      }
      if (locationArr.location && locationArr.distance) {
        // console.log("ref","running")
        setDistance({
          label: locationArr.distance,
          value: locationArr.distance,
        });
      } else {
        locationFilter = "";
      }
    }

    if (llocation.state) {
      if (llocation.state.brandName) {
        if (!filterState) {
          let brandFil = {
            brand: [llocation.state.brandName],
            color: [],
            size: [],
            condition: [],
            closet: [],
            category: [],
            hanger: [],
            exclusive: "false",
            search: "",
            sort: "",
          };
          filterState = JSON.stringify(brandFil);
        } else {
          let brandFil = {
            ...JSON.parse(filterState),
            brand: [llocation.state.brandName],
          };
          filterState = JSON.stringify(brandFil);
        }
      } else if (llocation.state.categoryName) {
        if (!filterState) {
          let categoryFil = {
            brand: [],
            color: [],
            size: [],
            condition: [],
            closet: [],
            category: [llocation.state.categoryName],
            hanger: [],
            exclusive: "false",
            search: "",
            sort: "",
          };
          filterState = JSON.stringify(categoryFil);
        } else {
          let categoryFil = {
            ...JSON.parse(filterState),
            category: [llocation.state.categoryName],
          };
          filterState = JSON.stringify(categoryFil);
        }
      }
    }

    let pageno = sessionStorage.getItem("closetshoppage");
    if (!filterState || (filterState === filterQuery && !locationFilter)) {
      if (llocation.search.slice(-1)) {
        console.log(
          "NEXT PGGGGGG",
          llocation.search.slice(llocation.search.indexOf("=") + 1) - 1
        );
        //setThresholdPage()

        setThresholdPage(
          llocation.search.slice(llocation.search.indexOf("=") + 1) - 1
        );
        setScrollPage(
          (llocation.search.slice(llocation.search.indexOf("=") + 1) - 1) * 5 +
            1
        );
        dispatch(
          getallProducts(
            `p=${
              (llocation.search.slice(llocation.search.indexOf("=") + 1) - 1) *
              5
            }&filter=${encodeURIComponent(filterQuery)}`
          )
        );
      } else {
        console.log("NEXT PGGGGGG");
        //setScrollPage(((llocation.search.slice(llocation.search.indexOf("=") + 1) - 1)*5)+1)
        dispatch(
          getallProducts(
            `p=${pageNumber * 5}&filter=${encodeURIComponent(filterQuery)}`
          )
        );
      }
    } else {
      let filterArr = JSON.parse(filterState);
      console.log(filterArr);
      setBrandFilter(
        filterArr.brand.map((item) => {
          return { label: item, value: item };
        })
      );
      setColorFilter(
        filterArr.color.map((item) => {
          return { label: item, value: item };
        })
      );
      setSizeFilter(
        filterArr.size.map((item) => {
          return { label: item, value: item };
        })
      );
      setConditionFilter(
        filterArr.condition.map((item) => {
          return { label: item, value: item };
        })
      );
      setClosetFilter(
        filterArr.closet.map((item) => {
          return { label: item, value: item };
        })
      );
      setCategoryFilter(
        filterArr.category.map((item) => {
          return { label: item, value: item };
        })
      );
      setHangerFilter(
        filterArr.hanger.map((item) => {
          return { label: item, value: item };
        })
      );
      setExclusiveFilter(
        filterArr.exclusive === "MeM"
          ? { value: "MeM", label: "MeM", first: false, saved: true }
          : filterArr.exclusive === "NoNM"
          ? { value: "NoNM", label: "NoNM", first: false, saved: true }
          : { value: "All", label: "All", first: false, saved: true }
      );
      if (filterArr.exclusive === "MeM" || filterArr.exclusive === "All") {
        setMemberChecked(true);
      }
      if (filterArr.exclusive === "NoNM" || filterArr.exclusive === "All") {
        console.log("Thissss");
        setNonMemberChecked(true);
      }
      setSearchFilter(filterArr.search);
      setProductSearch(filterArr.search);
      setSortFilter(filterArr.sort);
      console.log(pageno);
      console.log(pageNumber);
      if (pageno && Number(pageno) > 0 && Number(pageno) != pageNumber) {
        console.log("Firing");
        // navigate(`/shop?page=${Number(pageno) + 1}`, { replace: true });
        setPageNumber(pageno);
      }
      // setFilterQuery(filterState);
    }

    //window.scrollTo(0,0)
    // }else{
    //     navigate("/login")
    // }
    return () => {
      dispatch(reset());
    };
  }, [pageNumber, catValue]);

  useEffect(() => {
    setProductInfo([]);
    console.log(filterQuery);
    setSpinner(true);
    if (filterQuery) {
      window.scrollTo(0, 0);
      if (pageNumber === 0) {
        let x = "";
        if (
          Number(location) > 0 &&
          location.toString().length == 5 &&
          distance
        ) {
          x = `&location=${location}&distance=${distance.value}`;
        }
        if (scrollPage > 1) {
          console.log("k2l", scrollPage, thresholdPage);
        } else {
          console.log("ref", refresh);
          setRefresh(false);
          dispatch(
            getallProducts(
              `p=${scrollPage - 1}&filter=${encodeURIComponent(
                filterQuery
              )}${x}`
            )
          );
        }
      } else {
        console.log("Comming");
        setPageNumber(0);
      }
    }
    //dispatch(getallProducts(`p=0&filter=${encodeURIComponent(filterQuery)}`));
  }, [filterQuery]);

  // useEffect(() => {
  //   setDistance("");
  //   // if (location.length === 5) {
  //   //   setSpinner(true);
  //   //   dispatch(getallProducts(`location=${location}&distance=&p=${llocation.search.slice(llocation.search.indexOf("=")+1) - 1}`));
  //   // }
  // }, [location]);

  useEffect(() => {
    console.log(location);
    console.log("SDDSASD");
    if (
      Number(location) > 0 &&
      location.toString().length == 5 &&
      distance &&
      filterQuery &&
      !running
    ) {
      setProductInfo([]);
      setRunning(true);
      setSpinner(true);
      sessionStorage.setItem(
        "closetshopzipfilter",
        JSON.stringify({
          location: location,
          distance: distance.value,
        })
      );
      console.log("ref", "falsing");
      setRefresh(false);
      dispatch(
        getallProducts(
          `location=${location}&distance=${
            distance.value
          }&filter=${encodeURIComponent(filterQuery)}&p=${0}`
        )
      );
    } else if (resetFilt) {
      setRunning(true);
      setResetFilt(false);
      setSpinner(true);
      setRefresh(false);
      dispatch(
        getallProducts(`filter=${encodeURIComponent(filterQuery)}&p=${0}`)
      );
    }
  }, [distance]);

  useEffect(() => {
    if (products.length > 0 || status === "succeeded") {
      setProductInfo((prev) => [...prev, ...products]);
      setRefresh(false);
      dispatch(reset());
      setSpinner(false);
      setInitalLoad(false);
      setRunning(false);
    }

    // dispatch(reset())
  }, [products]);

  if (error) {
    setSpinner(false);
    dispatch(resetError());
  }

  useEffect(() => {
    console.log(resetFilt);
    if (
      brandFilter.length > 0 ||
      colorFilter.length > 0 ||
      sizeFilter.length > 0 ||
      conditionFilter.length > 0 ||
      closetFilter.length > 0 ||
      categoryFilter.length > 0 ||
      hangerFilter.length > 0 ||
      searchFilter ||
      sortFilter ||
      filterQuery ||
      !exclusiveFilter.first
    ) {
      // window.removeEventListener("scroll", handleScroll);
      setInitalLoad(true);
      console.log("chale");
      // setSpinner(true);
      //setPageNumber(0);
      if (switchTrigger) {
        setRefresh(true);
        // window.removeEventListener("scroll", handleScroll);
        console.log("Chnnnn k3l");
        setSwitchTrigger(false);
        sessionStorage.setItem("closetshoppage", 0);
        setScrollPage(1);
        setThresholdPage(0);
        setThresholdActive(false);
        navigate(`/shop?page=1`, { replace: true });
      }

      let filterObj = {
        brand:
          brandFilter.length > 0
            ? brandFilter.map((item) => `${item.value}`)
            : [],
        color:
          colorFilter.length > 0 ? colorFilter.map((item) => item.value) : [],
        size: sizeFilter.length > 0 ? sizeFilter.map((item) => item.value) : [],
        condition:
          conditionFilter.length > 0
            ? conditionFilter.map((item) => item.value)
            : [],
        closet:
          closetFilter.length > 0 ? closetFilter.map((item) => item.value) : [],
        category:
          categoryFilter.length > 0
            ? categoryFilter.map((item) => item.label)
            : [],
        hanger:
          hangerFilter.length > 0 ? hangerFilter.map((item) => item.value) : [],
        exclusive: exclusiveFilter.value,
        search: searchFilter,
        sort: sortFilter,
      };
      console.log(categoryFilter);
      sessionStorage.setItem("closetshopfilter", JSON.stringify(filterObj));
      setFilterQuery(JSON.stringify(filterObj));
    }
  }, [
    sizeFilter,
    categoryFilter,
    colorFilter,
    brandFilter,
    hangerFilter,
    conditionFilter,
    closetFilter,
    searchFilter,
    sortFilter,
    exclusiveFilter,
  ]);

  const handleSort = (sort) => {
    console.log(sort);
    setSortFilter(sort);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(productSearch);
    setSearchFilter(productSearch);
    // let newProducts = products.filter((product) =>
    //   product.productName.toLowerCase().includes(productSearch.toLowerCase())
    // );
    // setProductInfo(newProducts);
  };

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      console.log("ref", refresh);
      if (20 * (scrollPage % 5) == 0) {
        // setThresholdPage((prev) => prev + 1);
        // setScrollPage((prev) => prev + 1);
        // setProductInfo([]);
        setThresholdActive(true);
      } else if (!spinner && productInfo.length < productCount && !refresh) {
        console.log("YESSSSS");
        setThresholdActive(false);
        let location, distance;
        let closetshopzipfilter = sessionStorage.getItem("closetshopzipfilter");
        if (closetshopzipfilter) {
          closetshopzipfilter = JSON.parse(closetshopzipfilter);
          console.log(closetshopzipfilter);
          location = closetshopzipfilter.location;
          distance = closetshopzipfilter.distance;
        }
        if (location && dispatch) {
          dispatch(
            getallProducts(
              `p=${scrollPage}&filter=${encodeURIComponent(
                filterQuery
              )}&location=${location}&distance=${distance}`
            )
          );
        } else {
          dispatch(
            getallProducts(
              `p=${scrollPage}&filter=${encodeURIComponent(filterQuery)}`
            )
          );
        }
        setSpinner(true);
        setScrollPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    console.log("Runned");
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [spinner, refresh]);

  useEffect(() => {
    console.log("NEXT PAGE ", thresholdPage);

    if (thresholdActive) {
      // setPageChange(true)
      // setThresholdPage((prev) => prev + 1);
      setSpinner(true);
      setProductInfo([]);
      setThresholdActive(false);
      let location, distance;
      let closetshopzipfilter = sessionStorage.getItem("closetshopzipfilter");
      if (closetshopzipfilter) {
        closetshopzipfilter = JSON.parse(closetshopzipfilter);
        console.log(closetshopzipfilter);
        location = closetshopzipfilter.location;
        distance = closetshopzipfilter.distance;
      }
      if (location && dispatch) {
        dispatch(
          getallProducts(
            `p=${thresholdPage * 5}&filter=${encodeURIComponent(
              filterQuery
            )}&location=${location}&distance=${distance}`
          )
        );
      } else {
        dispatch(
          getallProducts(
            `p=${thresholdPage * 5}&filter=${encodeURIComponent(filterQuery)}`
          )
        );
      }

      setScrollPage((prev) => thresholdPage * 5 + 1);
    }
  }, [thresholdPage]);

  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: window.location.href,
          },
        ]}
      ></Helmet>
      <Helmet>
        <meta
          name="description"
          content="Discover a curated selection of stylish clothing for every occasion. Browse our collection of high-quality tops, bottoms, dresses, and accessories. Find your perfect look today!"
        />
      </Helmet>
      <div className="page-container" style={{ position: "relative" }}>
        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        <div className="my-closet">
          <div className="shop-left">
            <SortItem
              products={productInfo}
              setBrandFilter={setBrandFilter}
              setColorFilter={setColorFilter}
              setSizeFilter={setSizeFilter}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              brandFilter={brandFilter}
              colorFilter={colorFilter}
              sizeFilter={sizeFilter}
              location={location}
              setDistance={setDistance}
              setLocation={setLocation}
              distance={distance}
              setProductSearch={setProductSearch}
              handleSearch={handleSearch}
              productSearch={productSearch}
              conditionFilter={conditionFilter}
              setConditionFilter={setConditionFilter}
              setClosetFilter={setClosetFilter}
              closetFilter={closetFilter}
              setExclusiveFilter={setExclusiveFilter}
              exclusiveFilter={exclusiveFilter}
              setHangerFilter={setHangerFilter}
              hangerFilter={hangerFilter}
              filters={filters}
              setFilterQuery={setFilterQuery}
              setPageNumber={setPageNumber}
              setSortFilter={setSortFilter}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              setSwitchTrigger={setSwitchTrigger}
              toast={toast}
              toastOption={toastOption}
              setResetFilt={setResetFilt}
              setPincode={setPincode}
              pinCode={pinCode}
              setNonMemberChecked={setNonMemberChecked}
              nonMemberChecked={nonMemberChecked}
              setMemberChecked={setMemberChecked}
              memberChecked={memberChecked}
              setRefresh={setRefresh}
            />
          </div>
          <div className="shop-right">
            <ShopItem
              setCartVal={setCartVal}
              cartVal={cartVal}
              products={productInfo}
              brandFilter={brandFilter}
              colorFilter={colorFilter}
              sizeFilter={sizeFilter}
              categoryFilter={categoryFilter}
              spinner={spinner}
              setSort={setSort}
              sort={sort}
              handleSort={handleSort}
              conditionFilter={conditionFilter}
              closetFilter={closetFilter}
              exclusiveFilter={exclusiveFilter}
              hangerFilter={hangerFilter}
              pageNumber={pageNumber}
              productCount={productCount}
              pstatus={initalLoad}
            />
          </div>
        </div>
        {productInfo.length >= 100 && (
          <Pagination
            pageCount={Math.ceil(productCount / 100)}
            pageNumber={thresholdPage}
            setPageNumber={setThresholdPage}
            setProductInfo={setProductInfo}
            thresholdActive={thresholdActive}
          />
        )}
      </div>
    </>
  );
};

export default Shop;
