import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const DynamicTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const title = extractTitleFromPath(location.pathname);
    console.log(location.pathname);
    // Update the title in the <title> tag
    // document.title = title.length < 20 ? "Closest Closet " + title : title;
    if (location.pathname.includes("admin")) {
      document.title = "Closest Closet Admin | " + title;
    } else {
      document.title = "Closest Closet | " + title;
    }

    const staticCMSPages = [
      "privacy",
      "how it works",
      "terms conditions",
      "store",
      "wgn around town ana belaval",
      "faq",
      "clean your closet",
      "checkout",
    ];
    const staticCMSMetaDescMappings = {
      privacy:
        "Review our privacy policy and terms of service to understand how we handle your data and use of our services. Learn about your rights and our commitment to protecting your information.",
      howItWorks:
        "Discover how our platform works and learn about our process step-by-step. Explore features, benefits, and get started with ease.",
      termsAndConditions:
        "Explore our terms and conditions to understand the rules and guidelines governing the use of our services. Learn about your rights, responsibilities, and the terms of our agreement.",
      store:
        "Explore our virtual store for a wide selection of products. Shop online for fashion. Discover great deals and convenient shopping from the comfort of your home.",
      wgnAnaBelaval:
        "Closest Closet was featured on WGN's Morning News Around Town Segment w/ Ana Belaval on January 3rd, 2024.",
      faq: "Find answers to commonly asked questions about our products and services. Explore our FAQ page for helpful information on ordering, shipping, returns, and more.",
      cleanYourCloset:
        "Your Closest Closet reputation and membership status depend on the authenticity of the items you add to your closet and your reliability as the seller.",
      checkout:
        "Complete your purchase securely and efficiently. Our checkout page offers a seamless process for finalizing your order. Enter payment details and confirm delivery to complete your shopping experience.",
      default: "This page is from Closest Closet Website",
    };
    console.log("TITLEE", title);
    if (staticCMSPages.includes(title.toLowerCase())) {
      console.log("TITLEEE STATIC ", title);
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "description";
      switch (title) {
        case "privacy":
          newMetaTag.content = staticCMSMetaDescMappings["privacy"];
          break;
        case "how-it-works":
          newMetaTag.content = staticCMSMetaDescMappings["howItWorks"];
          break;
        case "terms-conditions":
          newMetaTag.content = staticCMSMetaDescMappings["termsAndConditions"];
          break;
        case "store":
          newMetaTag.content = staticCMSMetaDescMappings["store"];
          break;
        case "wgn-around-town-ana-belaval":
          newMetaTag.content = staticCMSMetaDescMappings["wgnAnaBelaval"];
          break;
        case "faq":
          newMetaTag.content = staticCMSMetaDescMappings["faq"];
          break;
        case "clean-your-closet":
          newMetaTag.content = staticCMSMetaDescMappings["cleanYourCloset"];
          break;
        case "checkout":
          newMetaTag.content = staticCMSMetaDescMappings["checkout"];
          break;
        default:
          newMetaTag.content = staticCMSMetaDescMappings["default"];
          break;
      }

      // Append the new meta element to the document head
      document.head.appendChild(newMetaTag);

      // Clean up function to remove the added meta tag when component unmounts
      return () => {
        document.head.removeChild(newMetaTag);
      };
    }
  }, [location]);

  const extractTitleFromPath = (pathname) => {
    const pathParts = pathname.split("/").filter((part) => part.trim() !== "");
    if (pathParts.length > 0) {
      return decodeURIComponent(
        pathParts[pathParts.length - 1]
          .replace(/-/g, " ")
          .replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          )
      );
    }
    return "Home";
  };

  return null;
  // return (
  //   <Helmet>
  //     <title>{title}</title>
  //   </Helmet>
  // );
};

export default DynamicTitleUpdater;
