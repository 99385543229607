import React from "react";
import LandingSection from "../components/HomeComponents/LandingSection";
import Marquee from "../components/HomeComponents/Marquee";
import PricingTable from "../components/HomeComponents/PricingTable";
import GeneralHomeCarousel from "../components/HomeComponents/GeneralHomeCarousel";
import InformationSection from "../components/HomeComponents/InformationSection";
import Testimonials from "../components/HomeComponents/Testimonials";
import FutureOfFashion from "../components/HomeComponents/FutureOfFashion";
import VideoSection from "../components/HomeComponents/VideoSection";
import ScrollAnimation from "react-animate-on-scroll";
const GeneralHomePage = ({ content }) => {
  return (
    <>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <LandingSection
          content={content && content.loggedOutHomePageCarousel}
        />
      </ScrollAnimation>
      <br />
      <br />
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <FutureOfFashion />
      </ScrollAnimation>
      {/* <GeneralHomeCarousel /> */}
      <br />
      <br />
      <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
        <Marquee />
      </ScrollAnimation>
      <br />
      <br />
      <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
        <InformationSection />
      </ScrollAnimation>
      <br />
      <br />
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <VideoSection />
      </ScrollAnimation>
      <br />
      <br />
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <PricingTable />
      </ScrollAnimation>
      <br />
      <br />
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Testimonials content={content && content.testimonialsContent} />
      </ScrollAnimation>
    </>
  );
};

export default GeneralHomePage;
