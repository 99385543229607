import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetUpdateStatus,
  cancelSubscription,
  pauseSubscription,
  getBrands,
  updateBrand,
  resetBrandError,
  deleteBrand,
  getCategory,
  updateCategory,
  deleteCategory,
  addCategory,
  getSizes,
  updateSize,
  deleteSize,
  addSize,
} from "../../components/StateSlices/adminSlice";
import { addBrand, resetBrand } from "../../components/StateSlices/brandSlice";

import Spinner1 from "../../components/Spinner1/Spinner1";
import "./Sizes.css";
import Modal from "react-modal";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { FaPen, FaUndo } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";

import { MdOutlineCancel, MdSubscriptions } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "../../components/Pagination/Pagination";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "3px",
  borderBottom: "2px solid #F26654",
};

const groupLabelStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#F26654",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

function Sizes({ tab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const {
    allUserStatus,
    allUsersInfo,
    allUserError,
    updateUserStatus,
    totalCount,
    allBrandInfo,
    brandErrorDisplay,
    allCategoryInfo,
    allSizeInfo,
  } = useSelector((state) => state.admin);

  const { brandInfo, categoryInfo } = useSelector((state) => state.brand);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("clttoken");
  //   if (token) {
  //     dispatch(getUsers({ token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/404", { replace: true });
  //   }
  // }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [subModalIsOpen, setSubModalIsOpen] = useState(null);
  const [role, setRole] = useState(null);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [currentId, setCurrentId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [userSearchVal, setUserSearchVal] = useState(null);
  const [pauseMonth, setPauseMonth] = useState({});
  const [currentSizes, setCurrentSizes] = useState(null);
  const [parentCategory, setParentCategory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);

  const subscriptionPauseOptions = [
    { label: "Pause for 1 Month", value: 1 },
    { label: "Pause for 2 Month", value: 2 },
    { label: "Pause for 3 Month", value: 3 },
  ];

  //console.log("Pagination", totalCount);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        addBrand({ token, values: { brandName: "" }, requestType: "get" })
      );
    }
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      //console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ==
          pageNumber
        ) {
          //console.log("THen this");
          dispatch(
            getSizes({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: userSearchVal,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        //console.log("This");
        dispatch(getSizes({ token, p: pageNumber, search: userSearchVal }));
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab]);

  const updateDetails = (e) => {
    e.preventDefault();
    if (currentId) {
      let token = localStorage.getItem("clttoken");
      if (token) {
        if (currentSizes) {
          if (parentCategory.length > 0) {
            let parentctg = [];
            let parent = parentCategory.map((pt) => {
              let ct = pt.value.slice(pt.value.length - 1);
              if (ct === "W") {
                if (!parentctg.includes("1")) parentctg.push("1");
              } else if (ct === "M") {
                if (!parentctg.includes("2")) parentctg.push("2");
              } else if (ct === "B") {
                if (!parentctg.includes("3")) parentctg.push("3");
              } else if (ct === "G") {
                if (!parentctg.includes("4")) parentctg.push("4");
              } else if (ct === "S") {
                if (!parentctg.includes("6")) parentctg.push("6");
              } else {
                if (!parentctg.includes("5")) parentctg.push("5");
              }
              return pt.id;
            });

            dispatch(
              updateSize({
                token,
                id: currentId,
                payload: {
                  categoryName: currentSizes,
                  categoryReference: parent,
                  categoryParent: parentctg,
                },
                p: pageNumber,
                search: userSearchVal,
              })
            );
          } else {
            toast.error("Category Cannot be Empty", toastOption);
          }
        } else {
          toast.error("Please Enter Valid Size Name", toastOption);
        }
      } else {
        navigate("/404", { replace: true });
      }
    } else {
      toast.error("Something went wrong!! Please Try Again", toastOption);
      setIsOpen(false);
    }
  };

  const categoryDelete = (id) => {
    swal({
      title: "Delete Size",
      text: "Once Deleted, this Size will not be visible to Users.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            deleteSize({ token, id, p: pageNumber, search: userSearchVal })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const cancelUserSub = (id) => {
    swal({
      title: "Cancel User Subscription",
      text: "Once Deleted, User will be transferred to Guest User",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            cancelSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const pauseUserSub = (id, month) => {
    swal({
      title: "Pause User Subscription",
      text: `You want to Pause User Subscription for ${month} month`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            pauseSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
              month,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const sizeAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("clttoken");
    if (token) {
      if (currentSizes) {
        if (parentCategory.length > 0) {
          let parentctg = [];
          let parent = parentCategory.map((pt) => {
            let ct = pt.value.slice(pt.value.length - 1);
            if (ct === "W") {
              if (!parentctg.includes("1")) parentctg.push("1");
            } else if (ct === "M") {
              if (!parentctg.includes("2")) parentctg.push("2");
            } else if (ct === "B") {
              if (!parentctg.includes("3")) parentctg.push("3");
            } else if (ct === "G") {
              if (!parentctg.includes("4")) parentctg.push("4");
            } else if (ct === "S") {
              if (!parentctg.includes("6")) parentctg.push("6");
            } else {
              if (!parentctg.includes("5")) parentctg.push("5");
            }
            return pt.id;
          });

          dispatch(
            addSize({
              token,
              payload: {
                categoryName: currentSizes,
                categoryReference: parent,
                categoryParent: parentctg,
              },
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          toast.error("Category Cannot be Empty", toastOption);
        }
      } else {
        toast.error("Please Enter Valid Size Name", toastOption);
      }
    } else {
      navigate("/404", { replace: true });
    }
  };

  if (updateUserStatus === "succeeded") {
    toast.success("Category Details Updated Successfully", toastOption);
    dispatch(resetBrandError());
    dispatch(resetUpdateStatus());
    setCurrentSizes(null);
    // setCurrentId(null);
    // setCurrentUserName(null);
    // setHangerCredits(0);
    // setRole(null);
    setIsOpen(false);
    setSubModalIsOpen({ display: false, user: null });
  }

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/sizes?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(getSizes({ token, p: 0, status: tab }));
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  const category = [
    { label: "Women", value: "Women" },
    { label: "Men", value: "Men" },
    { label: "Boy", value: "Boy" },
    { label: "Girl", value: "Girl" },
    { label: "Sports Equipment", value: "Sports Equipment" },
  ];

  if (brandInfo) {
    let menCat = [];
    let womenCat = [];
    let boysCat = [];
    let girlsCat = [];
    let notApp = [];
    let sportsCat = [];

    let temp3 = categoryInfo.map((brandVal) => {
      if (brandVal.categoryParent.includes("1")) {
        womenCat.push({
          value: brandVal.categoryName + "W",
          label: brandVal.categoryName,
          pt: ["1"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("2")) {
        menCat.push({
          value: brandVal.categoryName + "M",
          label: brandVal.categoryName,
          pt: ["2"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("3")) {
        boysCat.push({
          value: brandVal.categoryName + "B",
          label: brandVal.categoryName,
          pt: ["3"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("4")) {
        girlsCat.push({
          value: brandVal.categoryName + "G",
          label: brandVal.categoryName,
          pt: ["4"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("6")) {
        sportsCat.push({
          value: brandVal.categoryName + "S",
          label: brandVal.categoryName,
          pt: ["6"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("5")) {
        notApp.push({
          value: brandVal.categoryName + "O",
          label: brandVal.categoryName,
          pt: ["5"],
          id: brandVal._id,
        });
      }
      return {
        value: brandVal.categoryName,
        label: brandVal.categoryName,
        pt: brandVal.categoryParent,
        id: brandVal._id,
      };
    });
    let temp2 = [
      {
        label: "Women",
        options: womenCat,
      },
      {
        label: "Men",
        options: menCat,
      },
      {
        label: "Girls",
        options: girlsCat,
      },
      {
        label: "Boys",
        options: boysCat,
      },
      {
        label: "Other",
        options: notApp,
      },
      {
        label: "Sports Equipment",
        options: sportsCat,
      },
    ];
    // //console.log(temp);
    // setBrands([...temp]);
    setCurrentCategory([...temp2]);
    // formik.setFieldValue("brand", brandName);
    // setSelectedBrand({ label: brandName, value: brandName });
    // setSizeList(sizeInfo);
    //   formik.setValues({
    //     "brand": brandName,
    //   })
    dispatch(resetBrand());
    // closeModal();
  }

  //   //console.log(allBrandInfo)
  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={subModalIsOpen?.display}
        onRequestClose={() => setSubModalIsOpen({ display: false, user: null })}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            zIndex: 1,
            minHeight: "maxcontent",
            marginTop: 0,
            paddingTop: 0,
          }}
        >
          <div className="terms-modal-con">
            <div className="terms-modal-wrapper">
              <h3>Add New Size</h3>
              {/* <hr
                style={{
                  borderStyle: "solid",
                  borderColor: "var(--secondary-color)",
                  width: "50px",
                  borderWidth: "2px",
                  marginBottom: "10px",
                }}
              ></hr> */}
              <form onSubmit={(e) => sizeAdd(e)}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Size Name
                    </label>
                    <input
                      type="text"
                      id="userName"
                      name="userName"
                      onChange={(e) => setCurrentSizes(e.target.value)}
                      value={currentSizes}
                      // disabled={true}
                      style={{
                        padding: "9.5px",
                        //   cursor: "not-allowed",
                        //   background: "#eee",
                        fontSize: "14px",
                      }}
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="category" className="item_title">
                      Choose Category
                    </label>
                    <Select
                      options={currentCategory.sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )}
                      isMulti
                      //   onChange={(value) => formik.setFieldValue("category", value)}
                      onChange={(value) => {
                        //console.log(parentCategory);
                        //console.log(currentCategory);
                        setParentCategory(value);
                      }}
                      value={parentCategory}
                      styles={customStyles}
                      formatGroupLabel={formatGroupLabel}
                      components={{ MultiValueContainer }}
                    />
                  </div>
                </div>

                <button
                  className="btn dark-btn"
                  type="submit"
                  style={{ borderRadius: "2px" }}
                >
                  Add Category
                </button>
              </form>
            </div>
          </div>

          {/* <br />
          <br />
          <br /> */}
          {/* <div className="h5 bold-text background-text">
            Cancel Subscription
          </div> */}
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => this.setState({ modalIsOpen: false })}
          >
            X
          </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <h3>Size Edit</h3>
            <hr
              style={{
                borderStyle: "solid",
                borderColor: "var(--secondary-color)",
                width: "50px",
                borderWidth: "2px",
                marginBottom: "10px",
              }}
            ></hr>
            <form onSubmit={(e) => updateDetails(e)}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    Size Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={(e) => setCurrentSizes(e.target.value)}
                    value={currentSizes}
                    // disabled={true}
                    style={{
                      padding: "9.5px",
                      //   cursor: "not-allowed",
                      //   background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="category" className="item_title">
                    Choose Category
                  </label>
                  <Select
                    options={currentCategory.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    isMulti
                    //   onChange={(value) => formik.setFieldValue("category", value)}
                    onChange={(value) => {
                      //console.log(parentCategory);
                      //console.log(currentCategory);
                      setParentCategory(value);
                    }}
                    value={parentCategory}
                    styles={customStyles}
                    formatGroupLabel={formatGroupLabel}
                    components={{ MultiValueContainer }}
                  />
                </div>
              </div>
              {/* <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {brandErrorDisplay}
              </div> */}
              <button
                className="btn dark-btn"
                type="submit"
                style={{ borderRadius: "2px" }}
              >
                Update Size
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <div className="users-header">
        <div
          className="h2 background-text bold-text"
          style={{ minWidth: "30%", margin: "1rem auto" }}
        >
          Manage Sizes
        </div>
        <div className="input_container search_closet">
          <form
            className="input_container_wrapper"
            style={{ gap: 0 }}
            onSubmit={(e) => {
              e.preventDefault();
              let token = localStorage.getItem("clttoken");
              if (token) {
                // setPageNumber(0)
                navigate(`/admin/sizes?page=1`, { replace: true });
                if (
                  location.search.slice(-1) &&
                  location.search.slice(location.search.indexOf("=") + 1) - 1 ==
                    0
                ) {
                  dispatch(
                    getSizes({
                      token,
                      p: 0,
                      search: userSearchVal,
                    })
                  );
                }
              } else {
                navigate("/404", { replace: true });
              }
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <input
                type="text"
                id="userSearch"
                name="userSearch"
                onChange={(e) => setUserSearchVal(e.target.value)}
                value={userSearchVal}
                placeholder="Enter Size Name"
                style={{
                  marginTop: "0px",
                  fontFamily: "inherit",
                  padding: "10px 12px",
                }}
              />
              <button
                type="submit"
                className="btn"
                style={{ borderRadius: "0 5px 5px 0" }}
              >
                <i class="fas fa-search fa-lg"></i>
              </button>
            </div>
            <div>
              {userSearchVal && (
                <span
                  className="small-text"
                  style={{
                    marginRight: 0,
                    // float: "right",
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "var(--background-color)",
                    fontSize: "13px",
                    fontFamily: "LATO",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    resetSearch();
                    setUserSearchVal("");
                  }}
                >
                  Reset <FaUndo style={{ fontSize: "11px" }} />
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="users">
        <div className="add-new-brand-btn-con">
          <button
            className="btn"
            style={{
              padding: "10px 12px",
              fontSize: "16px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSubModalIsOpen({
                display: true,
              });
            }}
          >
            Add New Size
          </button>
        </div>

        <table className="users-table">
          {allUserStatus === "loading" ? (
            <Spinner1 />
          ) : (
            <>
              {allSizeInfo && allSizeInfo.length === 0 ? (
                <div
                  style={{ marginTop: "1rem", marginBottom: "4rem" }}
                  className="no_item"
                >
                  <h1 className="h1" style={{ marginTop: "1rem" }}>
                    No Size Found
                  </h1>
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Sr. No
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Size
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Category
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSizeInfo &&
                      allSizeInfo.length > 0 &&
                      allSizeInfo.map((size, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {pageNumber * 20 + index + 1}
                            </td>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {size.sizeName}
                            </td>
                            <td
                              className="h5 category-width"
                              // style={{ lineBreak: "anywhere", width: "60%" }}
                            >
                              {size.populatedCategories.length > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  {size.populatedCategories.map(
                                    (ctg, index) => {
                                      return (
                                        <div class="badge_parent">
                                          {ctg.categoryName +
                                            " - " +
                                            ctg.categoryParent
                                              .map((parent) => {
                                                if (
                                                  size.categoryParent.includes(
                                                    parent
                                                  )
                                                ) {
                                                  return parent === "1"
                                                    ? "Women "
                                                    : parent === "2"
                                                    ? "Men "
                                                    : parent === "3"
                                                    ? "Boy "
                                                    : parent === "4"
                                                    ? "Girl "
                                                    : parent === "6"
                                                    ? "Sports Equipment":"";
                                                }
                                                return "";
                                              })
                                              .filter((value) => value !== "")
                                              .join(", ")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                "No Category"
                              )}
                            </td>

                            <td className="h5">
                              <button
                                title="Edit Size"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setCurrentId(size._id);
                                  setCurrentSizes(size.sizeName);

                                  let tmp = size.populatedCategories.flatMap(
                                    (ctg) => {
                                      let nestCtg = size.categoryParent
                                        .filter((nst) =>
                                          ctg.categoryParent.includes(nst)
                                        )
                                        .map((nst) => ({
                                          value: `${ctg.categoryName}${
                                            nst == "1"
                                              ? "W"
                                              : nst == "2"
                                              ? "M"
                                              : nst == "3"
                                              ? "B"
                                              : nst == "4"?"G": nst == "6"? "S" : "G"
                                          }`,
                                          label: `${ctg.categoryName}`,
                                          pt: ctg.categoryParent,
                                          id: ctg._id,
                                        }));
                                      //console.log(nestCtg);
                                      return nestCtg;
                                    }
                                  );
                                  //console.log(tmp);
                                  setParentCategory(tmp);
                                  setIsOpen((prev) => !prev);
                                }}
                              >
                                <FaPen size={"1.4em"} />
                              </button>
                              <button
                                title="Delete Size"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  categoryDelete(size._id);
                                }}
                              >
                                <AiFillDelete size={"1.8em"} />
                              </button>
                            </td>
                            {/* </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>

        {totalCount > 20 && (
          <Pagination
            pageCount={Math.ceil(totalCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </>
  );
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const MultiValueContainer = (value) => {
  let ct =
    value.data && value.data["value"].slice(value.data["value"].length - 1);
  return (
    value.data && (
      <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
        <div>
          {ct === "M"
            ? "🧔‍♂️"
            : ct === "W"
            ? "👩"
            : ct === "B"
            ? "👦"
            : ct === "G"
            ? "👧"
            : ct === "S"?"🏅":"⭐️"}
        </div>
        <components.MultiValueContainer {...value} />
      </div>
    )
  );
};

export default Sizes;
