import React, { useState } from "react";
import "./StarRating.css";
import { productrating } from "../StateSlices/productRatingSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { closetrating } from "../StateSlices/closetRatingSlice";

const StarRating = ({ rating, onRatingChange, productInfo, closetInfo }) => {
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const dispatch = useDispatch();
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleStarClick = (clickedRating) => {
    if (onRatingChange) {
      let token = localStorage.getItem("clttoken");
      if (token) {
        onRatingChange(clickedRating);
        if (productInfo) {
          dispatch(
            productrating({
              id: productInfo._id,
              rating: clickedRating,
              token: token,
            })
          );
        }
        if (closetInfo) {
          // alert(closetInfo._id);
          dispatch(
            closetrating({
              id: closetInfo._id,
              rating: clickedRating,
              token: token,
            })
          );
        }

        toast.success("Thanks for valuable feedback!", toastOption);
      }
    }
  };

  const handleStarHover = (hoveredRating) => {
    setHoveredRating(hoveredRating);
  };

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const starClass =
      (hoveredRating !== null && i <= hoveredRating) ||
      (hoveredRating === null && i <= rating)
        ? "star filled"
        : "star";
    stars.push(
      <span
        key={i}
        style={
          closetInfo && {
            fontSize: "20px",
            position: "relative",
            bottom: "5px",
            cursor: "pointer",
          }
        }
        className={starClass}
        onClick={() => handleStarClick(i)}
        onMouseEnter={() => handleStarHover(i)}
        onMouseLeave={() => handleStarHover(null)}
      >
        &#9733;
      </span>
    );
  }

  return <div className="star-rating">{stars}</div>;
};

export default StarRating;
