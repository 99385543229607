import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import {
//   getUsers,
//   updateUser,
//   resetUpdateStatus,
//   deleteUser,
//   unbanUser,
//   searchUsers,
//   cancelSubscription,
//   pauseSubscription,
// } from "../../../components/StateSlices/adminSlice";
import Spinner1 from "../../components/Spinner1/Spinner1";

import "../components/Users/Users.css";
import Modal from "react-modal";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { FaPen, FaUndo } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { MdOutlineCancel, MdSubscriptions } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteCMSContent,
  getAllCMSContent,
  resetCMS,
} from "../../components/StateSlices/cmsSlice";

function CMS({ tab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  //   const {
  //     allUserStatus,
  //     allUsersInfo,
  //     allUserError,
  //     updateUserStatus,
  //     totalCount,
  //   } = useSelector((state) => state.admin);
  const { cmsStatus, cmsInfo, cmsError, cmsFetchStatus, cmsMessage } =
    useSelector((state) => state.cms);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("clttoken");
  //   if (token) {
  //     dispatch(getUsers({ token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/404", { replace: true });
  //   }
  // }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [subModalIsOpen, setSubModalIsOpen] = useState(null);
  const [role, setRole] = useState(null);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [currentId, setCurrentId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [userSearchVal, setUserSearchVal] = useState(null);
  const [pauseMonth, setPauseMonth] = useState({});
  const [cmsList, setCmsList] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getAllCMSContent({ token }));
    } else {
      navigate("/404", { replace: true });
    }
  }, []);

  if (cmsInfo) {
    setCmsList(cmsInfo);
    dispatch(resetCMS());
  }

  if (cmsMessage === "Succesfully Deleted CMS") {
    toast.success("Sucessfully Delete the Page", toastOption);
    dispatch(resetCMS());
    let token = localStorage.getItem("clttoken");
    dispatch(getAllCMSContent({ token }));
  }

  const deleteCMSFunc = (id) => {
    swal({
      title: "Delete CMS",
      text: "Once Deleted, User will be not able to see this Page.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(deleteCMSContent({ token, id }));
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  //   if (updateUserStatus === "succeeded") {
  //     toast.success("User Details Updated Successfully", toastOption);
  //     dispatch(resetUpdateStatus());
  //     setCurrentEmail(null);
  //     setCurrentId(null);
  //     setCurrentUserName(null);
  //     setHangerCredits(0);
  //     setRole(null);
  //     setIsOpen(false);
  //   }

  //   const resetSearch = () => {
  //     let token = localStorage.getItem("clttoken");
  //     if (token) {
  //       // setPageNumber(0)
  //       navigate(`/admin/users?page=1`, { replace: true });
  //       if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
  //         dispatch(getUsers({ token, p: 0, status: tab }));
  //       }
  //       //
  //     } else {
  //       navigate("/404", { replace: true });
  //     }
  //   };

  return (
    <>
      <div className="users-header">
        <div
          className="h2 background-text bold-text"
          style={{ marginTop: "1rem", minWidth: "30%" }}
        >
          Manage CMS
        </div>
      </div>

      <div className="users">
        <div className="add-new-brand-btn-con">
          <button
            className="btn "
            style={{
              padding: "10px 12px",
              fontSize: "16px",
              borderRadius: "5px",
            }}
            onClick={() => {
              navigate("/admin/addcms");
            }}
          >
            Add New CMS
          </button>
        </div>
        <table className="users-table">
          {cmsStatus === "loading" || cmsFetchStatus === "loading" ? (
            <Spinner1 />
          ) : (
            <>
              {cmsList && cmsList.length === 0 ? (
                <div
                  style={{ marginTop: "1rem", marginBottom: "4rem" }}
                  className="no_item"
                >
                  <h1 className="h1" style={{ marginTop: "1rem" }}>
                    No CMS Found
                  </h1>
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th>PageName</th>
                      <th>PageURL</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cmsList &&
                      cmsList.length > 0 &&
                      cmsList.map((cms, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="h6"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {cms.pageName}
                            </td>
                            <td
                              className="h6"
                              style={{ lineBreak: "anywhere" }}
                            >
                              <a
                                href={
                                  window.location.origin + "/" + cms.pageURL
                                }
                                style={{
                                  color: "var(--primary-color)",
                                  textDecoration: "underline",
                                }}
                                target="_blank"
                              >
                                {window.location.origin + "/" + cms.pageURL}
                              </a>
                            </td>
                            <td className="h6">
                              {cms.userId.userDetailsId.userEmail}
                            </td>
                            <td
                              className="h6"
                              style={{ textTransform: "capitalize" }}
                            >
                              {cms.status === true ? "Active" : "Disabled"}
                            </td>

                            <td
                              className="h6"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderLeft: "none",
                                borderTop: "none",
                              }}
                            >
                              <button
                                title="Edit CMS"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  // deleteCMSFunc(cms._id);
                                  navigate("/admin/editcms", {
                                    state: { id: cms._id },
                                  });
                                }}
                              >
                                <FaPen size={"1.2em"} />
                              </button>
                              <button
                                title="Delete CMS"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  deleteCMSFunc(cms._id);
                                }}
                              >
                                <AiFillDelete size={"1.4em"} />
                              </button>
                            </td>
                            {/* </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
}

export default CMS;
