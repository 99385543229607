import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  userInfo: null,
  error: null,
  profile: null,
  userProductCountForAdmin: null,
};

export const getuserInfoForAdmin = createAsyncThunk(
  "user/getuserInfoForAdmin",
  async ({ token, p = 0, search = "", id = "" }, { rejectWithValue }) => {
    try {
      console.log(search);
      let s = "";
      if (search) {
        s = `&search=${search}`;
      }
      if (id) {
        s = `&id=${id}`;
      }
      const { data } = await axios.get(`/api/users/info?p=${p}${s}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const userInfoSliceForAdmin = createSlice({
  name: "userForAdmin",
  initialState,
  reducers: {
    reset(state, action) {
      state.error = null;
      state.profileForAdmin = null;
      state.userInfoForAdmin = null;
      state.userProductCountForAdmin = null;
    },
    resetStatus(state, action) {
      state.status = "idle";
    },
  },
  extraReducers: {
    [getuserInfoForAdmin.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [getuserInfoForAdmin.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.userInfoForAdmin = {
        ...action.payload.user,
        totalProduct: action.payload.totalProduct,
      };
      state.profileForAdmin = action.payload.user.userDetailsId.profileStatus;
      state.userProductCountForAdmin = action.payload.userProductsCount;
    },
    [getuserInfoForAdmin.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reset, resetStatus } = userInfoSliceForAdmin.actions;
export default userInfoSliceForAdmin.reducer;
