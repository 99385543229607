import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetUpdateStatus,
  cancelSubscription,
  pauseSubscription,
  getBrands,
  updateBrand,
  resetBrandError,
  deleteBrand,
  addBrand,
  mergeBrand,
} from "../../components/StateSlices/adminSlice";
import {
  addBrand as addAllBrand,
  resetBrand,
} from "../../components/StateSlices/brandSlice";

import Spinner1 from "../../components/Spinner1/Spinner1";
import "./Brands.css";
import Modal from "react-modal";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { FaPen, FaUndo } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { MdCallMerge } from "react-icons/md";

import { MdOutlineCancel, MdSubscriptions } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "../../components/Pagination/Pagination";

function Brands({ tab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const {
    allUserStatus,
    allUsersInfo,
    allUserError,
    updateUserStatus,
    totalCount,
    allBrandInfo,
    brandErrorDisplay,
  } = useSelector((state) => state.admin);

  const { brandStatus, brandInfo, brandError, categoryInfo, sizeInfo } =
    useSelector((state) => state.brand);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("clttoken");
  //   if (token) {
  //     dispatch(getUsers({ token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/404", { replace: true });
  //   }
  // }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenMerge, setIsOpenMerge] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [subModalIsOpen, setSubModalIsOpen] = useState(null);
  const [role, setRole] = useState(null);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [currentId, setCurrentId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [userSearchVal, setUserSearchVal] = useState(null);
  const [pauseMonth, setPauseMonth] = useState({});
  const [currentBrand, setCurrentBrand] = useState(null);
  const [allBrands, setAllBrands] = useState([]);
  const [mergeWith, setMergeWith] = useState("");

  const subscriptionPauseOptions = [
    { label: "Pause for 1 Month", value: 1 },
    { label: "Pause for 2 Month", value: 2 },
    { label: "Pause for 3 Month", value: 3 },
  ];

  useEffect(() => {
    let token = localStorage.getItem("clttoken");

    if (token) {
      // //console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ==
          pageNumber
        ) {
          // //console.log("THen this");
          dispatch(
            getBrands({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: userSearchVal,
            })
          );
          dispatch(
            addAllBrand({
              token,
              values: { brandName: "" },
              requestType: "get",
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        // //console.log("This");
        dispatch(getBrands({ token, p: pageNumber, search: userSearchVal }));
        dispatch(
          addAllBrand({ token, values: { brandName: "" }, requestType: "get" })
        );
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab]);

  useEffect(() => {
    if (brandInfo) {
      let temp = brandInfo.map((brandVal) => {
        return { value: brandVal.brandName, label: brandVal.brandName };
      });
      setAllBrands(temp);
    }
  }, [brandInfo]);

  const updateDetails = (e) => {
    e.preventDefault();
    if (currentId) {
      let token = localStorage.getItem("clttoken");
      if (token) {
        if (currentBrand) {
          dispatch(
            updateBrand({
              token,
              id: currentId,
              payload: { brandName: currentBrand },
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          toast.error("Please Enter Valid Brand Name", toastOption);
        }
      } else {
        navigate("/404", { replace: true });
      }
    } else {
      toast.error("Something went wrong!! Please Try Again", toastOption);
      setIsOpen(false);
    }
  };

  const brandDelete = (id) => {
    swal({
      title: "Delete Brand",
      text: "Once Deleted, this Brand will not be visible to Users.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            deleteBrand({ token, id, p: pageNumber, search: userSearchVal })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const cancelUserSub = (id) => {
    swal({
      title: "Cancel User Subscription",
      text: "Once Deleted, User will be transferred to Guest User",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            cancelSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const pauseUserSub = (id, month) => {
    swal({
      title: "Pause User Subscription",
      text: `You want to Pause User Subscription for ${month} month`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            pauseSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
              month,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const brandAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("clttoken");
    if (token) {
      if (currentBrand) {
        dispatch(
          addBrand({
            token,
            payload: { brandName: currentBrand },
            p: pageNumber,
            search: userSearchVal,
          })
        );
      } else {
        toast.error("Please Enter Valid Brand Name", toastOption);
      }
    } else {
      navigate("/404", { replace: true });
    }
  };

  const mergeBrandHandle = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("clttoken");
    if (mergeWith) {
      dispatch(
        mergeBrand({
          token,
          id: currentId,
          payload: { brandName: mergeWith.value },
          p: pageNumber,
          search: userSearchVal,
        })
      );
    } else {
      toast.error("Please Select Brand to Merge", toastOption);
    }
  };

  if (updateUserStatus === "succeeded") {
    toast.success("Brand Details Updated Successfully", toastOption);
    dispatch(resetBrandError());
    dispatch(resetUpdateStatus());
    setCurrentBrand(null);
    // setCurrentId(null);
    // setCurrentUserName(null);
    // setHangerCredits(0);
    // setRole(null);
    setIsOpenMerge(false);
    setIsOpen(false);
    setSubModalIsOpen({ display: false, user: null });
  }

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/brands?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(getBrands({ token, p: 0, status: tab }));
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  //   //console.log(allBrandInfo)
  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={subModalIsOpen?.display}
        onRequestClose={() => setSubModalIsOpen({ display: false, user: null })}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            zIndex: 1,
            minHeight: "maxcontent",
          }}
        >
          <div className="terms-modal-con">
            <div className="terms-modal-wrapper">
              <h3>Add New Brand</h3>
              <hr
                style={{
                  borderStyle: "solid",
                  borderColor: "var(--secondary-color)",
                  width: "50px",
                  borderWidth: "2px",
                  marginBottom: "10px",
                }}
              ></hr>
              <form onSubmit={(e) => brandAdd(e)}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Brand Name
                    </label>
                    <input
                      type="text"
                      id="userName"
                      name="userName"
                      onChange={(e) => setCurrentBrand(e.target.value)}
                      value={currentBrand}
                      // disabled={true}
                      style={{
                        padding: "9.5px",
                        //   cursor: "not-allowed",
                        //   background: "#eee",
                        fontSize: "14px",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="brand_error"
                  style={{ color: "red", marginBottom: "10px" }}
                >
                  {brandErrorDisplay}
                </div>
                <button
                  className="btn dark-btn"
                  type="submit"
                  style={{ borderRadius: "2px" }}
                >
                  Add Brand
                </button>
              </form>
            </div>
          </div>

          {/* <br />
          <br />
          <br /> */}
          {/* <div className="h5 bold-text background-text">
            Cancel Subscription
          </div> */}
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => this.setState({ modalIsOpen: false })}
          >
            X
          </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <h3>Brand Edit</h3>
            <hr
              style={{
                borderStyle: "solid",
                borderColor: "var(--secondary-color)",
                width: "50px",
                borderWidth: "2px",
                marginBottom: "10px",
              }}
            ></hr>
            <form onSubmit={(e) => updateDetails(e)}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    Brand Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={(e) => setCurrentBrand(e.target.value)}
                    value={currentBrand}
                    // disabled={true}
                    style={{
                      padding: "9.5px",
                      //   cursor: "not-allowed",
                      //   background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>
              <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {brandErrorDisplay}
              </div>
              <button
                className="btn dark-btn"
                type="submit"
                style={{ borderRadius: "2px" }}
              >
                Update Brand
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpenMerge}
        onRequestClose={() => setIsOpenMerge(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "400px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => this.setState({ modalIsOpen: false })}
          >
            X
          </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <h3>Brand Merge</h3>
            <hr
              style={{
                borderStyle: "solid",
                borderColor: "var(--secondary-color)",
                width: "50px",
                borderWidth: "2px",
                marginBottom: "10px",
              }}
            ></hr>
            <form onSubmit={(e) => mergeBrandHandle(e)}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    Brand Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    disabled={true}
                    onChange={(e) => setCurrentBrand(e.target.value)}
                    value={currentBrand}
                    // disabled={true}
                    style={{
                      padding: "9.5px",
                      //   cursor: "not-allowed",
                      //   background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="item_title"
                  style={{ display: "block" }}
                >
                  Merge With
                </label>
                <Select
                  options={allBrands}
                  onChange={(selectedOption) => {
                    setMergeWith(selectedOption);
                  }}
                  value={mergeWith}
                  styles={{
                    ...customStyles,
                  }}
                  placeholder="Other Brands"
                ></Select>
              </div>
              <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {brandErrorDisplay}
              </div>
              <button
                className="btn dark-btn"
                type="submit"
                style={{ borderRadius: "2px" }}
              >
                Merge Brand
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <div className="users-header" style={{ marginTop: "2rem" }}>
        <div
          className="h2 background-text bold-text"
          style={{ minWidth: "30%", marginBottom: "1rem" }}
        >
          Manage Brands
        </div>
        <div className="input_container search_closet">
          <form
            className="input_container_wrapper"
            style={{ gap: 0 }}
            onSubmit={(e) => {
              e.preventDefault();
              let token = localStorage.getItem("clttoken");
              if (token) {
                // setPageNumber(0)
                navigate(`/admin/brands?page=1`, { replace: true });
                if (
                  location.search.slice(-1) &&
                  location.search.slice(location.search.indexOf("=") + 1) - 1 ==
                    0
                ) {
                  dispatch(
                    getBrands({
                      token,
                      p: 0,
                      search: userSearchVal,
                    })
                  );
                }
              } else {
                navigate("/404", { replace: true });
              }
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <input
                type="text"
                id="userSearch"
                name="userSearch"
                onChange={(e) => setUserSearchVal(e.target.value)}
                value={userSearchVal}
                placeholder="Enter Brand Name"
                style={{
                  marginTop: "0px",
                  fontFamily: "inherit",
                  borderRadius: "5px 0 0 5px",
                  padding: "10px 12px",
                }}
              />
              <button
                type="submit"
                className="btn"
                style={{ borderRadius: "0 5px 5px 0" }}
              >
                <i class="fas fa-search fa-lg"></i>
              </button>
            </div>
            <div>
              {userSearchVal && (
                <span
                  className="small-text"
                  style={{
                    marginRight: 0,
                    // float: "right",
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "var(--background-color)",
                    fontSize: "13px",
                    fontFamily: "LATO",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    resetSearch();
                    setUserSearchVal("");
                  }}
                >
                  Reset <FaUndo style={{ fontSize: "11px" }} />
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="users">
        <div className="add-new-brand-btn-con">
          <button
            className="btn"
            style={{
              padding: "10px 12px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSubModalIsOpen({
                display: true,
              });
            }}
          >
            Add New Brand
          </button>
        </div>

        <table className="users-table">
          {allUserStatus === "loading" ? (
            <Spinner1 />
          ) : (
            <>
              {allBrandInfo && allBrandInfo.length === 0 ? (
                <div
                  style={{ marginTop: "1rem", marginBottom: "4rem" }}
                  className="no_item"
                >
                  <h1 className="h1" style={{ marginTop: "1rem" }}>
                    No Brands Found
                  </h1>
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Sr. No
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Name
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allBrandInfo &&
                      allBrandInfo.length > 0 &&
                      allBrandInfo.map((brand, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {pageNumber * 20 + index + 1}
                            </td>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {brand.brandName}
                            </td>

                            <td className="h5">
                              <button
                                title="Edit Brand"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setCurrentId(brand._id);
                                  setCurrentBrand(brand.brandName);
                                  setIsOpen((prev) => !prev);
                                }}
                              >
                                <FaPen size={"1.4em"} />
                              </button>
                              <button
                                title="Delete Brand"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  brandDelete(brand._id);
                                }}
                              >
                                <AiFillDelete size={"1.8em"} />
                              </button>

                              <button
                                title="Delete Brand"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setCurrentId(brand._id);
                                  setCurrentBrand(brand.brandName);
                                  setIsOpenMerge((prev) => !prev);
                                  if (brand.merge) {
                                    setMergeWith({
                                      label: brand.merge,
                                      value: brand.merge,
                                    });
                                  } else {
                                    setMergeWith("");
                                  }
                                }}
                              >
                                <MdCallMerge size={"1.8em"} />
                              </button>
                            </td>
                            {/* </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>

        {totalCount > 20 && (
          <Pagination
            pageCount={Math.ceil(totalCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </>
  );
}

export default Brands;
