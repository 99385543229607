import React, { useMemo, useState } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import Pagination from "../../../components/Pagination/Pagination";
import { AgGridReact } from "ag-grid-react";

const ProductAnalytics = ({
  loading,
  data,
  analyticsCount,
  pageNumber,
  setPageNumber,
}) => {
  // const tableColLabels = [
  //   "Name",
  //   "Description",
  //   "Money Value",
  //   "Hanger Value",
  //   "stock",
  //   "Color",
  //   "Size",
  //   "Brand",
  //   "Condition",
  //   "category",
  //   "spam count",
  //   "Archive (T/F)",
  //   "Banned (T/F)",
  //   "Creation Date",
  //   "Last Update Date",
  //   "Sold date",
  // ];
  // let tableTemp = [];
  // tableColLabels.forEach((item) =>
  //   tableTemp.push({ headerName: item, field: "" })
  // );
  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const capitalize = (params) => {
    if (params !== "")
      return params.value.replace(/\b\w/g, (char) => char.toUpperCase());
    return "";
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "LATO" },
  }));
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Name",
      field: "productDetails.productName",
    },
    {
      headerName: "Description",
      field: "productDetails.productDesc",
    },
    {
      headerName: "Money Value",
      field: "productDetails.productPrice",
      valueFormatter: (params) => params && "$" + params.value,
    },
    {
      headerName: "Hanger Value",
      field: "productDetails.productHanger",
    },
    {
      headerName: "Stock",
      field: "productDetails.productStock",
    },
    {
      headerName: "Color",
      field: "productDetails.productStats.color",
      valueFormatter: (params) =>
        params &&
        params.value.join(", ").replace(/\b\w/g, (char) => char.toUpperCase()),
    },
    {
      headerName: "Size",
      field: "productDetails.productStats.size",
    },
    {
      headerName: "Brand",
      field: "productDetails.productStats.brand",
    },
    {
      headerName: "Condition",
      field: "productDetails.productStats.condition",
    },
    {
      headerName: "Category",
      field: "productDetails.productStats.category",
      valueFormatter: (params) => params && params.value.join(", "),
    },
    {
      headerName: "Spam Count",
      field: "productDetails.productSpamCount",
      valueFormatter: (params) => (params ? params.value : 0),
    },
    {
      headerName: "Archive (T/F)",
      field: "productDetails.productArchive",
      valueFormatter: (params) => (params ? params.value : 0),
    },
    {
      headerName: "Banned (T/F)",
      field: "productDetails.adminBan",
    },
    {
      headerName: "Creation Date",
      field: "productDetails.createdAt",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Last Update Date",
      field: "productDetails.updatedAt",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Sold date",
      field: "lastSoldDate",
      valueFormatter: (params) => (params.value ? getDate(params) : "Not Sold"),
    },
  ]);
  return (
    <div className="analytics-con" style={{ marginTop: "1rem" }}>
      {loading && data.length == 0 ? (
        <div style={{ borderRadius: "10px", overflow: "hidden" }}>
          <Spinner1 />
        </div>
      ) : (
        <>
          <div className="ag-theme-quartz" style={{ height: 900 }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={data}
              columnDefs={colDefs}
            />
          </div>
          <Pagination
            pageCount={Math.ceil(analyticsCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default ProductAnalytics;
