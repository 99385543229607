import React, { useEffect, useState } from "react";
import "./Hangers.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import {
  getOrders,
  seenBoughtOrders,
  reseto,
} from "../components/StateSlices/getOrderSlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import OrderTable from "../components/OrderTable/OrderTable";
import PurchaseTable from "../components/PurchaseTable/PurchaseTable";
import NoClosetItem from "../assets/images/others_closet_empty.svg";
import Collapse, { Panel } from "rc-collapse";
import PurchaseTableAccordion from "../components/PurchaseTable/PurchaseTableAccordion";
import {
  fulfilled,
  resetFulfill,
} from "../components/StateSlices/fulfillSlice";
import { Helmet } from "react-helmet";

const Purchases = () => {
  const [orderHistory, setOrderHistory] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(false);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { fulFillError, fulFillStatus, fulFillInfo } = useSelector(
    (state) => state.fulfilled
  );
  const { orderstatus, orderInfo, boughtHistoryStatusMessage } = useSelector(
    (state) => state.orders
  );

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getOrders({ token }));
      window.scrollTo(0, 480);
      setSpinner(true);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(() => {
    console.log(orderInfo, userInfo, orderstatus);
    if (userInfo && (orderInfo.length > 0 || orderstatus === "succeeded")) {
      setOrderHistory(orderInfo);
      dispatch(reseto());
      setSpinner(false);
    } else if (orderInfo.length === 0 && orderstatus === "succeeded") {
      setSpinner(false);
    }
  }, [orderInfo, userInfo]);

  useEffect(() => {
    if (orderHistory.length > 0) {
      if (userInfo && userInfo.boughtUnreadCount > 0) {
        let token = localStorage.getItem("clttoken");
        // get an array of  _id for which seen is false in orderInfo
        let ids = orderHistory
          .filter((item) => item.read === false)
          .map((item) => item.checkoutId);
        console.log(ids);
        dispatch(seenBoughtOrders({ token, ordersId: ids }));
      }
    }
  }, [orderHistory]);

  const handleFulfill = (pId, cId) => {
    swal({
      title: "Mark this item Fulfilled?",
      text: "Are you sure you have received this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        dispatch(fulfilled({ token, pId, cId, userType: "fulfilledBuyer" }));
        setSpinner(true);
      }
    });
  };

  useEffect(() => {
    if (fulFillInfo === "fulfilled") {
      toast.success("Item Sucessfully marked as Fulfilled!", toastOption);
      dispatch(resetFulfill());
      let token = localStorage.getItem("clttoken");
      dispatch(getOrders({ token }));
    }
  }, [fulFillInfo]);

  if (boughtHistoryStatusMessage == "Succesfully Seen Bought History!!!") {
    dispatch(reseto());
    let token = localStorage.getItem("clttoken");

    dispatch(getuserInfo({ token: token }));
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for analyzing the purchases of products to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for analyzing the purchases of products to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for analyzing the purchases of products to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <div
        className="page-container"
        style={{ position: "relative", minHeight: "520px" }}
      >
        {spinner ? (
          <Spinner />
        ) : orderHistory && orderHistory.length > 0 ? (
          <div className="hangers_container">
            <div>
              {/* <h1
                className="section_title bold-text"
                style={{ margin: "2rem auto" }}
              >
                Purchase History
              </h1> */}
              {/* <PurchaseTable orderInfo={orderHistory} /> */}
              <PurchaseTableAccordion
                orderInfo={orderHistory}
                handleFulfill={handleFulfill}
              />
            </div>
          </div>
        ) : (
          <div className="no_item">
            <h1
              className="h1 text-center"
              style={{ marginTop: "1rem", color: "var(--background-color)" }}
            >
              You haven’t made any purchases yet
            </h1>
            <br />
            <button className="btn">
              <Link to={"/shop"}>START SHOPPING</Link>
            </button>
            <br />
            <img
              style={{ marginTop: "4rem" }}
              src={NoClosetItem}
              alt="no-closet-icon"
            />
            {/* <div className="h5 text-center" style={{ marginTop: "1rem" }}>
    <Link className="add_item" to="../add-items">
      Click Here
    </Link>{" "}
    to Add Item in your Closet
  </div> */}
          </div>
        )}

        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      </div>
    </>
  );
};

export default Purchases;
