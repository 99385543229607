import React, { useEffect, useRef, useState } from "react";
import "./VideoUploadNew.css";
import { FaPlusSquare, FaTrash } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import shortid from "shortid";
import axios from "axios";
import { addBrand } from "../StateSlices/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateStaticContent } from "../StateSlices/staticContentSlice";
import CryptoJS from "crypto-js";

import "./VideoUploadNew.css";
import { MdChangeCircle } from "react-icons/md";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Spinner from "../Spinner/Spinner";

const VideoUploadNew = ({ staticState }) => {
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const videoFormats = ["mp4", "avi", "mkv", "mov", "webm"];
  const imageFormats = ["jpg", "jpeg", "png", "gif", "webp"];
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const [saveAction, setSaveAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialUploads, setInitialUploads] = useState(staticState || []);
  const [uploads, setUploads] = useState(staticState || []);
  const [fileChangeIndex, setFileChangeIndex] = useState(null);
  const [forceRerender, setForceRerender] = useState(false);
  const [changeId, setChangeId] = useState("new");
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [currentUpload, setCurrentUpload] = useState({
    id: "",
    title: "",
    subtitle: "",
    video: null,
    buttons: [{ url: "", btn: "" }],
    size: "",
    ind: uploads && uploads.length > 0 ? uploads.length + 1 : 1,
  });
  const [initialCurrentUploadState, setInitialCurrentUploadState] = useState({
    id: "",
    title: "",
    subtitle: "",
    video: null,
    buttons: [{ url: "", btn: "" }],
    size: "",
    ind: uploads && uploads.length > 0 ? uploads.length + 1 : 1,
  });
  const {
    brandStatus,
    brandInfo,
    brandError,
    categoryInfo,
    signature,
    timestamp,
    sizeInfo,
  } = useSelector((state) => state.brand);

  useEffect(() => {
    const token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        addBrand({ token, values: { brandName: "" }, requestType: "get" })
      );
    }
  }, []);

  useEffect(() => {
    setInitialUploads(staticState);
    setUploads(staticState);

    setLoading(false);
    setChangeId("new");
    setDeleteFlag(false);
  }, [staticState]);

  useEffect(() => {
    if (saveAction) {
      const token = localStorage.getItem("clttoken");
      // console.log("final files", Files);
      if (token) {
        const sortedFiles = [...uploads].sort(
          (a, b) => parseInt(a.ind) - parseInt(b.ind)
        );
        setLoading(true);
        dispatch(
          updateStaticContent({
            token,
            values: {
              section: "loggedOutHomePageCarousel",
              data: sortedFiles,
              changeId,
              deleteFlag,
            },
            requestType: "post",
          })
        );
        setSaveAction(false);
        // setLoading(false);

        setInitialUploads(uploads);
      }
    }
  }, [saveAction === true]);

  const handleSave = async () => {
    try {
      const res = await FileUploadSubmit();

      if (uploads && res) {
        setSaveAction(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  const handleSaveForExisting = async (id) => {
    try {
      // const res = await FileUploadSubmit();
      setChangeId(id);

      if (uploads) {
        setSaveAction(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  const openFileUploadDialog = (index) => {
    console.log("sd", fileInputRef);
    if (fileInputRef.current) {
      setFileChangeIndex(index);
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    // console.log("updated", uploads);
  }, [uploads]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    toast.info("Please wait for file upload and preview ", toastOption);

    if (file && uploads) {
      console.log("here k2l ", file, uploads);
    }

    if (file && uploads) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "closet-closest");
        formData.append("cloud_name", "closet-closest");
        formData.append("api_key", "738997887579849");

        // Use await to wait for the axios.post to complete
        const fileExtension = file.name.split(".").pop().toLowerCase();
        console.log("fieEctexion", fileExtension);
        let response;

        const timestampV = Math.round(new Date().getTime() / 1000);
        const signatureV = CryptoJS.SHA1(
          `timestamp=${timestampV}&upload_preset=closet-closest5Tr2RNxBz-S8UBp-Y9c9SSo2S04`
        );
        const transformation = "f_webp,q_70,c_scale,w_1000";

        if (videoFormats.includes(fileExtension)) {
          const formDataV = new FormData();
          formDataV.append("file", file);

          // Make sure to replace the URL with your server endpoint
          response = await axios.post(
            process.env.REACT_APP_VIDEO_UPLOAD_SERVER_URL +
              "api/static/videoUpload",
            formDataV,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("aa gaya mein ", response.data);
        } else if (imageFormats.includes(fileExtension)) {
          const timestampQ = Math.round(new Date().getTime() / 1000);

          const signatureQ = CryptoJS.SHA1(
            `timestamp=${timestampQ}&transformation=${transformation}&upload_preset=closet-closest${"5Tr2RNxBz-S8UBp-Y9c9SSo2S04"}`
          ).toString();
          formData.append("timestamp", timestampQ);
          formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
          formData.append("signature", signatureQ);
          response = await axios.post(
            "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
            formData,
            {
              headers: { "X-Requested-With": "XMLHttpRequest" },
            }
          );
        } else {
          alert("File Type Not Supported");
          return;
        }

        const dataN = response.data;
        const fileURLN = dataN.secure_url;

        setUploads((prev) => {
          const prevContent = [...prev];
          prevContent[fileChangeIndex] = {
            ...prevContent[fileChangeIndex],
            videoUrl: fileURLN,
            size: filesizes(file.size),
            extension: fileExtension,
          };
          return prevContent;
        });

        setForceRerender((prev) => !prev);
        // console.log("King for a reason ", fileURLN);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const FileUploadSubmit = async () => {
    setLoading(true);
    let uploadTemp = null;
    if (!currentUpload.videoUrl) {
      uploadTemp = [...uploads, currentUpload];
      try {
        const timestamp = Math.round(new Date().getTime() / 1000);
        const signature = CryptoJS.SHA1(
          `timestamp=${timestamp}&upload_preset=closet-closest5Tr2RNxBz-S8UBp-Y9c9SSo2S04`
        );

        const formData = new FormData();
        formData.append("file", currentUpload.video);
        formData.append("upload_preset", "closet-closest");
        formData.append("cloud_name", "closet-closest");
        formData.append("timestamp", timestamp);
        formData.append("api_key", "738997887579849");

        // Use await to wait for the axios.post to complete
        const fileExtension = currentUpload.extension;
        let response;
        if (videoFormats.includes(fileExtension)) {
          const formDataV = new FormData();
          formDataV.append("file", currentUpload.video);

          // Make sure to replace the URL with your server endpoint
          response = await axios.post(
            process.env.REACT_APP_VIDEO_UPLOAD_SERVER_URL +
              "api/static/videoUpload",
            formDataV,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else if (imageFormats.includes(fileExtension)) {
          formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
          const signatureV = CryptoJS.SHA1(
            `timestamp=${timestamp}&transformation=f_webp,q_70,c_scale,w_1000&upload_preset=closet-closest5Tr2RNxBz-S8UBp-Y9c9SSo2S04`
          ).toString();
          formData.append("signature", signatureV);
          response = await axios.post(
            "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
            formData,
            {
              headers: { "X-Requested-With": "XMLHttpRequest" },
            }
          );
        } else {
          swal({
            title: `File Error`,
            text: `Upload a file or file format is not supported`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
          }).then((willReactive) => {
            return false;
          });
          return false;
        }

        const data = response.data;
        const fileURL = data.secure_url;
        currentUpload.video = {};
        currentUpload.videoUrl = fileURL;

        setUploads((prev) => [...prev, currentUpload]);

        // Reset selected files after successful upload
        // setCurrentUpload([]);
        setCurrentUpload({
          title: "",
          subtitle: "",
          video: null,
          dimensions: null,
          size: null,
          lastModifiedDate: null,
          buttons: [{ url: "", btn: "" }],
          ind: uploads.length + 2,
        });
        setInitialCurrentUploadState({
          title: "",
          subtitle: "",
          video: null,
          dimensions: null,
          size: null,
          lastModifiedDate: null,
          buttons: [{ url: "", btn: "" }],
          ind: uploads.length + 2,
        });
        // Reset the file input value
        const fileInput = document.getElementById("videoInput");
        if (fileInput) {
          fileInput.value = "";
        }
        return true;
      } catch (error) {
        // If an error occurs during file upload, propagate the error
        throw error;
      }
    }
    return true;
  };

  const handleInputChange = (e) => {
    setCurrentUpload({
      ...currentUpload,
      [e.target.name]: e.target.value,
    });
  };

  const handleVideoChange = (e) => {
    const selectedVideo = e.target.files[0];
    if (selectedVideo) {
      const extension = selectedVideo.name.split(".").pop().toLowerCase();
      if (videoFormats.includes(extension)) {
        setCurrentUpload({
          ...currentUpload,
          video: e.target.files[0],
          size: selectedVideo ? `${filesizes(selectedVideo.size)}` : null,
          extension,
        });
      } else if (imageFormats.includes(extension)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCurrentUpload({
            ...currentUpload,
            video: reader.result,
            size: selectedVideo ? `${filesizes(selectedVideo.size)}` : null,
            extension,
          });
        };
        reader.readAsDataURL(selectedVideo);
      }
    }
  };

  const handleClearVideo = () => {
    setCurrentUpload({
      ...currentUpload,
      video: null,
      size: null,
      id: null,
    });
    // Reset the file input value
    const fileInput = document.getElementById("videoInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  //Buttons START
  const handleLabelChange = (imageIndex, buttonIndex, label, contentType) => {
    // console.log(imageIndex, buttonIndex, label);
    if (contentType === "modify") {
      setUploads((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
            return index === buttonIndex ? { ...button, btn: label } : button;
          }),
        };
        return updatedFiles;
      });
      // console.log(uploads[imageIndex].buttons[buttonIndex]);
    }
    if (contentType === "new") {
      setCurrentUpload((prevUpload) => {
        const updatedUpload = { ...prevUpload };
        const currentButtons = updatedUpload.buttons || [];

        if (currentButtons.length === 0) {
          updatedUpload.buttons = [{ btn: label }];
        } else {
          // If the buttons array is not empty, append the new item
          updatedUpload.buttons = currentButtons.map((button, index) => {
            return index === buttonIndex ? { ...button, btn: label } : button;
          });
        }

        return updatedUpload;
      });
    }
  };

  const handleLinkChange = (imageIndex, buttonIndex, link, contentType) => {
    if (contentType === "modify") {
      setUploads((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: updatedFiles[imageIndex].buttons.map((button, index) => {
            return index === buttonIndex ? { ...button, url: link } : button;
          }),
        };
        return updatedFiles;
      });
      // console.log(uploads[imageIndex].buttons[buttonIndex]);
    }
    if (contentType === "new") {
      setCurrentUpload((prevUpload) => {
        const updatedUpload = { ...prevUpload };
        const currentButtons = updatedUpload.buttons || [];

        if (currentButtons.length === 0) {
          updatedUpload.buttons = [{ url: link }];
        } else {
          // If the buttons array is not empty, append the new item
          updatedUpload.buttons = currentButtons.map((button, index) => {
            return index === buttonIndex ? { ...button, url: link } : button;
          });
        }
        return updatedUpload;
      });
    }
  };

  const handleAddButton = (imageIndex, contentType) => {
    if (contentType === "modify") {
      setUploads((prevFiles) => {
        const updatedFiles = [...prevFiles];
        const currentButtons = updatedFiles[imageIndex].buttons || [];

        updatedFiles[imageIndex] = {
          ...updatedFiles[imageIndex],
          buttons: [...currentButtons, { url: "", btn: "" }],
        };

        return updatedFiles;
      });
    }
    if (contentType === "new") {
      setCurrentUpload((prevUpload) => {
        const updatedUpload = { ...prevUpload };
        const currentButtons = updatedUpload.buttons || [];

        updatedUpload.buttons = [...currentButtons, { url: "", btn: "" }];

        return updatedUpload;
      });
    }
  };

  const handleRemoveButton = (imageIndex, buttonIndex, contentType) => {
    if (contentType === "modify") {
      setUploads((prevFiles) => {
        return prevFiles.map((file, i) => {
          if (i === imageIndex) {
            const updatedButtons = file.buttons.filter(
              (btn, j) => j !== buttonIndex
            );
            return { ...file, buttons: updatedButtons };
          }
          return file;
        });
      });
    }
    if (contentType === "new") {
      setCurrentUpload((prevUpload) => {
        return {
          ...prevUpload,
          buttons: prevUpload.buttons.filter(
            (btn, index) => index !== buttonIndex
          ),
        };
      });
    }
  };

  //Buttons END

  const DeleteFile = async (id) => {
    swal({
      title: `Delete Slide`,
      text: `Are you sure you want to delete this Slide?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        const result = uploads.filter((data) => data._id !== id);
        setUploads(result);
        setDeleteFlag(true);
        handleSaveForExisting(id);

        setLoading(true);
        setCurrentUpload((prev) => {
          return {
            ...prev,
            ind: prev.ind - 1,
          };
        });
      }
    });
  };

  return (
    <>
      {loading ? (
        <div style={{ position: "relative", width: "100%", height: "100vh" }}>
          <Spinner />
        </div>
      ) : (
        <div className="video-upload-container">
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h3 className="bold-text background-text" style={{ margin: "0" }}>
                Upload Content
              </h3>
            </div>
          </div>
          <div className="video-upload-con">
            <div className="video-upload-form">
              <label>Index</label>
              <input
                name="ind"
                type="text"
                value={currentUpload.ind}
                onChange={handleInputChange}
              ></input>
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={currentUpload.title}
                onChange={handleInputChange}
              />

              <label>Subtitle:</label>
              <input
                name="subtitle"
                value={currentUpload.subtitle}
                onChange={handleInputChange}
              />

              <div>
                <p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>Buttons:</label>
                    </div>

                    <div>
                      {currentUpload.buttons &&
                        currentUpload.buttons.length < 2 && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddButton(-111, "new");
                            }}
                            style={{
                              background: "transparent",
                              border: "none",
                              // padding: "5px",
                            }}
                          >
                            <FaPlusSquare
                              size={"1.5em"}
                              color="var(--background-color)"
                              style={{
                                background: "transparent",
                                paddingTop: "3px",
                                cursor: "pointer",
                              }}
                            />
                          </button>
                        )}
                    </div>
                  </div>
                  {currentUpload.buttons &&
                    currentUpload.buttons.length > 0 &&
                    currentUpload.buttons.map((btn, btnIndex) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="static-cms-btn-label"
                            type="text"
                            style={{
                              marginRight: "5px",
                            }}
                            name="label"
                            placeholder="Button Label"
                            value={btn.btn}
                            onChange={(e) =>
                              handleLabelChange(
                                -111,
                                btnIndex,
                                e.target.value,
                                "new"
                              )
                            }
                          />
                          <input
                            className="static-cms-btn-link"
                            style={{
                              marginLeft: "5px",
                            }}
                            type="text"
                            name="link"
                            placeholder="Hyperlink"
                            value={btn.url}
                            onChange={(e) =>
                              handleLinkChange(
                                -111,
                                btnIndex,
                                e.target.value,
                                "new"
                              )
                            }
                          />
                          <button
                            onClick={() =>
                              handleRemoveButton(-111, btnIndex, "new")
                            }
                            style={{
                              background: "transparent",
                              border: "none",
                            }}
                          >
                            <IoMdCloseCircle
                              size={"1.4em"}
                              color="var(--background-color)"
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>
                        </div>
                      </>
                    ))}
                </p>
              </div>

              <label>Video:</label>
              <input
                id="videoInput"
                type="file"
                accept="image/*, video/*"
                onChange={handleVideoChange}
              />

              <button className="btn" onClick={handleSave}>
                Save & Publish
              </button>
            </div>
            <div
              // style={{
              //   width: "40%",
              //   margin: "20px",
              //   marginRight: "0",
              //   borderRadius: "10px",
              // }}
              className="video-preview-con"
            >
              {currentUpload.video ? (
                <>
                  {videoFormats.includes(currentUpload.extension) ? (
                    <video width="100%" height="80%" controls>
                      <source
                        src={URL.createObjectURL(currentUpload.video)}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : imageFormats.includes(currentUpload.extension) ? (
                    <img
                      alt={currentUpload.video}
                      src={currentUpload.video}
                      width="100%"
                      height="80%"
                    />
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Size: {currentUpload.size}</p>
                    <button className="btn" onClick={handleClearVideo}>
                      Clear File
                    </button>
                  </div>
                </>
              ) : (
                <div
                  className="empty-video-upload"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  <h3 className="background-text bold-text">
                    Please Upload A Video/Image File For Preview
                  </h3>
                </div>
              )}
            </div>
          </div>
          <br />

          {uploads && uploads.length > 0 && (
            <h3 className="bold-text background-text">Uploaded Content</h3>
          )}
          {uploads &&
            uploads.map((upload, index) => {
              // console.log("king", upload);
              return (
                // <div key={index} className="upload-item">
                //   <h3>{upload.title}</h3>
                //   <p>{upload.subtitle}</p>

                //   {upload.video && (
                //     <video width="320" height="240" controls>
                //       <source
                //         src={URL.createObjectURL(upload.video)}
                //         type="video/mp4"
                //       />
                //       Your browser does not support the video tag.
                //     </video>
                //   )}
                // </div>
                <>
                  <div className="video-upload-con">
                    <div className="video-upload-form">
                      <label>Index</label>
                      <input
                        className=""
                        name="ind"
                        type="text"
                        value={upload.ind}
                        onChange={(e) =>
                          setUploads((prev) => {
                            const prevContent = [...prev];
                            prevContent[index] = {
                              ...prevContent[index],
                              ind: e.target.value,
                            };
                            return prevContent;
                          })
                        }
                      ></input>
                      <label>Title:</label>
                      <input
                        type="text"
                        name="title"
                        value={upload.title}
                        onChange={(e) =>
                          setUploads((prev) => {
                            const prevContent = [...prev];
                            prevContent[index] = {
                              ...prevContent[index],
                              title: e.target.value,
                            };
                            return prevContent;
                          })
                        }
                      />

                      <label>Subtitle:</label>
                      <input
                        name="subtitle"
                        value={upload.subtitle}
                        onChange={(e) =>
                          setUploads((prev) => {
                            const prevContent = [...prev];
                            prevContent[index] = {
                              ...prevContent[index],
                              subtitle: e.target.value,
                            };
                            return prevContent;
                          })
                        }
                      />

                      <div>
                        <p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <label>Buttons:</label>
                            </div>

                            <div>
                              {upload.buttons && upload.buttons.length < 2 && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddButton(index, "modify");
                                  }}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    // padding: "5px",
                                  }}
                                >
                                  <FaPlusSquare
                                    size={"1.5em"}
                                    color="var(--background-color)"
                                    style={{
                                      background: "transparent",
                                      paddingTop: "3px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                          {upload.buttons &&
                            upload.buttons.length > 0 &&
                            upload.buttons.map((btn, btnIndex) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    className="static-cms-btn-label"
                                    type="text"
                                    style={{
                                      marginRight: "5px",
                                    }}
                                    name="label"
                                    placeholder="Button Label"
                                    value={btn.btn}
                                    onChange={(e) =>
                                      handleLabelChange(
                                        index,
                                        btnIndex,
                                        e.target.value,
                                        "modify"
                                      )
                                    }
                                  />
                                  <input
                                    className="static-cms-btn-link"
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                    type="text"
                                    name="link"
                                    placeholder="Hyperlink"
                                    value={btn.url}
                                    onChange={(e) =>
                                      handleLinkChange(
                                        index,
                                        btnIndex,
                                        e.target.value,
                                        "modify"
                                      )
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      handleRemoveButton(
                                        index,
                                        btnIndex,
                                        "modify"
                                      )
                                    }
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                    }}
                                  >
                                    <IoMdCloseCircle
                                      size={"1.4em"}
                                      color="var(--background-color)"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </button>
                                </div>
                              </>
                            ))}
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={async (e) => await handleFileUpload(e)}
                            accept="video/*,image/*"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="video-preview-con">
                      {upload.video ? (
                        <>
                          {upload.videoUrl ? (
                            videoFormats.includes(
                              upload.videoUrl.split(".").pop().toLowerCase()
                            ) ? (
                              <video
                                key={forceRerender}
                                width="100%"
                                height="80%"
                                controls
                              >
                                <source
                                  src={
                                    upload.videoUrl
                                      ? process.env
                                          .REACT_APP_VIDEO_UPLOAD_SERVER_URL +
                                        "uploads/" +
                                        upload.videoUrl
                                      : ""
                                  }
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : imageFormats.includes(
                                upload.videoUrl.split(".").pop().toLowerCase()
                              ) ? (
                              <img
                                alt={upload.videoUrl}
                                key={forceRerender}
                                src={upload.videoUrl}
                                width="100%"
                                height="80%"
                              />
                            ) : (
                              <h6>File Type Not Supported</h6>
                            )
                          ) : videoFormats.includes(upload.extension) ? (
                            <video
                              key={forceRerender}
                              width="100%"
                              height="80%"
                              controls
                            >
                              <source
                                src={URL.createObjectURL(upload.video)}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : imageFormats.includes(upload.extension) ? (
                            <img
                              key={forceRerender}
                              src={upload.video}
                              alt={upload.video}
                              width="100%"
                              height="80%"
                            />
                          ) : (
                            <h6>File Type Not Supported</h6>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p>Size: {upload.size}</p>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {initialUploads[index] === uploads[index] ? (
                                ""
                              ) : (
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    handleSaveForExisting(upload._id)
                                  }
                                  style={{
                                    marginLeft: "1rem",
                                    padding: "5px 10px",
                                  }}
                                >
                                  Save & Publish
                                </button>
                              )}
                              <button
                                onClick={() => openFileUploadDialog(index)}
                                style={{
                                  padding: "5px 10px",
                                  border: "none",
                                  background: "transparent",
                                  cursor: "pointer",
                                  height: 30,
                                }}
                                title="Change File"
                              >
                                <MdChangeCircle
                                  size={"1.5em"}
                                  color="var(--primary-color)"
                                />
                              </button>
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "5px 10px",
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                title="Delete Slide"
                                onClick={() => DeleteFile(upload._id)}
                              >
                                <FaTrash
                                  size={"1.2em"}
                                  color="var(--primary-color)"
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="empty-video-upload"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: 5,
                          }}
                        >
                          {" "}
                          <h3 className="background-text bold-text">
                            Please Upload A Video/Image For Preview
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
    </>
  );
};

export default VideoUploadNew;
