import React, { useEffect, useState } from "react";
import { ChatEngineWrapper, ChatSocket, ChatFeed } from "react-chat-engine";
import { styles } from "../styles";
import "../index.css";
const ChatEngine = (props) => {
  const [showChat, setShowChat] = useState(false);
  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        setShowChat(true);
      }, 500);
    }
  }, [props]);
  //consolele.log(props);
  return (
    <div
      style={{
        ...styles.chatEngineWindow,
        ...{
          height: props.visible ? "100%" : "0%",
          zIndex: props.visible ? "1" : "0",
          width: "100%",
          backgroundColor: "#fff",
        },
      }}
      className="transition-5"
    >
      {showChat && props && (
        <ChatEngineWrapper>
          <ChatSocket
            // offset={6}
            projectID={process.env.REACT_APP_CE_PROJECT_ID}
            chatID={props.chat.id}
            chatAccessKey={props.chat.access_key}
            senderUsername={props.user.email}
          />
          <ChatFeed
            renderChatHeader={() => (
              <div
                class="ce-chat-title-text"
                style={{ fontSize: "24px", fontWeight: 600 }}
              >
                {`${props.receiverUserName}`}
              </div>
            )}
            activeChat={props.chat.id}
          />
        </ChatEngineWrapper>
      )}
    </div>
  );
};

export default ChatEngine;
