import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../components/Spinner1/Spinner1";
import { verifyAccount, reset } from "../components/StateSlices/verifySlice";
import { ToastContainer, toast } from "react-toastify";
import TagManager from "react-gtm-module";

import "react-toastify/dist/ReactToastify.css";

const Verify = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [spinner, setSpinner] = useState(true);
  const { status, info, message, error } = useSelector((state) => state.verify);
  const [requested, setRequested] = useState(false);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    setRequested(true);
    if (status === "idle") dispatch(verifyAccount({ id: id }));
    console.log(status);
    // if(productInfo !== {}){
    //     productInfo.productImage.map((val)=>{
    //         setImages(prevState => {
    //             return [...prevState,{original:val,thumbnail:val}]
    //         })
    //     })
    // }
  }, []);

  useEffect(() => {
    if (message) {
      console.log(message);

      if (message === "success") {
        if (info && info.verified !== true) {
          toast.success("Account Verified Succesfully", toastOption);
        } else {
          toast.error("User Already Verified", toastOption);
        }
      } else {
        toast.error("Something Went Wrong", toastOption);
      }

      console.log(message);
      if (message === "success") {
        console.log("HERE");
        dispatch(reset());
        navigate("/setup-closest");
      } else {
        dispatch(reset());
        navigate("/signup");
      }
    }
  }, [message]);

  if (error) {
    toast.error("Verification Link Expired or Invalid", toastOption);
    setTimeout(() => {
      navigate("/confirmation?error=AccountNotVerified", { state: "Verified" });
    }, 5000);
  }

  return (
    <div style={{ height: "43vh" }}>
      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      <Spinner1 />
    </div>
  );
};

export default Verify;
