import React from 'react'
import "./QualityItemsOnly.css"
import qintro from "../assets/images/qualityintro.png"
import qc1 from "../assets/images/qc1.jpeg"
import qc2 from "../assets/images/qc2.jpeg"
import qc3 from "../assets/images/qc3.png"
import qc4 from "../assets/images/qc4.png"
import qc5 from "../assets/images/qc5.png"
import { useNavigate } from 'react-router-dom'

const QualityItemsOnly = () => {
    const navigate = useNavigate()
  return (
    <div className='page-container quality-items-container'>
        <h1 className='section_title'>Closest Closet Guidelines – Quality Items Only</h1>
        <div className='quality-items-intro'>
            <div className='intro-text'>
                Your Closest Closet reputation and membership status depend on the authenticity of the items you add to your closet and your reliability as the seller. Follow these tips to ensure a successful experience and gain followers.
            </div>
            <div className='intro-img'>
                <img src={qintro} alt="Quality Item Intro"/>
            </div>
        </div>
        <div className='quality-item-card-container'>
            <div className='quality-item-card'>
                <div className='quality-item-card-text'>
                    <h2>Show Off Your Style</h2>
                    <div>
                        CC is a space where you can display items that are no longer serving your needs. There are no restrictions on what can be posted as long as the items are high-quality, gently used, and described accurately in your product description.  
                    </div>
                </div>
                <div className='quality-item-card-img'>
                    <img src={qc1} alt="Quality Item 1" />
                </div>
                
            </div>
            <div className='quality-item-card'>
                <div className='quality-item-card-text'>
                    <h2>Curb Appeal</h2>
                    <div>
                        Take a photo that accurately represents the item you are listing and makes it stand out. Remember the goal is to find a new home for something that has been part of your home, so honor the item in a way that makes it appeaing to another member. If the item has unique details be sure to highlight those details with additional images. Your closet and the items you post are curation of who you are, so be sure to put your best foot forward and show us who you are! 
                    </div>
                </div>
                <div className='quality-item-card-img'>
                    <img src={qc2} alt="Quality Item 2" />
                </div>
            </div>
            <div className='quality-item-card'>
                <div className='quality-item-card-text'>
                    <h2>Be An Authentic Seller</h2>
                    <div>
                        When you upload an item be sure that you are being as honest as possible when describing the quality, condition, and fit. Upload photos of the actual item instead of using photos of the item that are online. This will reduce the risk of the a complaint with a buyer and will help in earning elite member status. 
                    </div>
                </div>
                <div className='quality-item-card-img'>
                    <img src={qc3} alt="Quality Item 3" />
                </div>
            </div>
            <div className='quality-item-card'>
                <div className='quality-item-card-text'>
                    <h2>Social Conscious</h2>
                    <div>
                        CC is more than just a one stop shop for finding the perfect piece to complement your life. The concept is rooted in a desire to create a space for those of us who value the recycling of goods and the health of our planet.
                    </div>
                </div>
                <div className='quality-item-card-img'>
                    <img src={qc4} alt="Quality Item 4" />
                </div>
            </div>
            <div className='quality-item-card'>
                <div className='quality-item-card-text'>
                    <h2>The Possibilites Are Endless</h2>
                    <div>
                        CC gives you a look into the closets’ of your friends near and far and an opportunity to make with new friends. Stop running out to the mall or online shopping when you need the perfect summer dress for your weekend away. Your closest closet is at your fingertips.
                    </div>
                </div>
                <div className='quality-item-card-img'>
                    <img src={qc5} alt="Quality Item 5" />
                </div>
            </div>
        </div>
        <div className='actn-btn'>
            <button className='btn dark-btn' onClick={()=>navigate("/add-items")}>+ Add Items</button>
            <button className='btn dark-btn' onClick={()=>navigate("/shop")}>Shop Closet Here</button>
        </div>
    </div>
  )
}

export default QualityItemsOnly