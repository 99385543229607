import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderboardResults } from "../components/StateSlices/getLeaderboardResultsSlice";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "./LeaderboardNew.css";
import { FaEarthAmericas } from "react-icons/fa6";
import { BsFillSendArrowUpFill, BsHandbagFill } from "react-icons/bs";
import { MdSell } from "react-icons/md";
import noCloset1 from "../assets/images/qc1.jpeg";
import noCloset2 from "../assets/images/qc4.png";
import noCloset3 from "../assets/images/qc3.png";

const Leaderboard = () => {
  const classes = { 1: "first", 2: "second", 3: "third" };
  const suffix = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
  };
  const badges = { 1: "🥇", 2: "🥈", 3: "🥉" };
  const noClosetIconList = { 1: noCloset1, 2: noCloset2, 3: noCloset3 };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { status, leaderboardResults, error } = useSelector(
    (state) => state.getleaderboardresults
  );
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    // Simulate an API request
    dispatch(getLeaderboardResults({}));
    setLoading(true);
  }, []);

  useEffect(() => {
    if (leaderboardResults) {
      setLoading(false);
      setData(leaderboardResults);
      console.log("Here is leaderboard results ", leaderboardResults);
    }
  }, [leaderboardResults]);

  return (
    <div style={{ minHeight: "40vh" }}>
      {loading ? (
        <Spinner />
      ) : (
        <section>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <>
              <div className="lb-title large-text text-center bold-text">
                Closest Closet Total Impact Statistics
              </div>
              <div className="leaderboard-con">
                <div className="impact-score">
                  <h2
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      letterSpacing: 1,
                      marginBottom: "1rem",
                    }}
                    className="h4 bold-text background-text"
                  >
                    <FaEarthAmericas />
                    &nbsp; &nbsp;Environmental Impact Score Member
                    Leaderboard&nbsp; &nbsp;
                    <FaEarthAmericas />
                    {/* <FaEarthAmericas size={"1rem"} style={{ color: "green" }} /> */}
                  </h2>
                  <div
                    style={{
                      marginTop: ".5rem",
                      height: "150vh",
                      overflowY: "auto",
                      border: "1px solid var(--grey-color)",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="leaderboard-left">
                      {data.leaderboard?.map(
                        (item, ind) =>
                          item.rank <= 25 && (
                            <>
                              <div
                                className={`leader-row h5 ${
                                  classes[item.rank] && classes[item.rank]
                                }`}
                                key={ind}
                              >
                                <div className="rank">
                                  <span className="rank-icon"
                                    style={{ position: "absolute", left: 8 }}
                                  >
                                    {badges[item.rank] && badges[item.rank]}
                                  </span>{" "}
                                  {item?.rank}
                                  {suffix[item.rank] && suffix[item.rank]}
                                </div>
                                {item.rank <= 3 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "5rem",
                                      top: 5,
                                    }}
                                    className="user-icon"
                                  >
                                    <img
                                      width={"25px"}
                                      height={"25px"}
                                      style={{ borderRadius: "50%" }}
                                      src={
                                        item.user.userDetailsInfo.userIcon ||
                                        noClosetIconList[item.rank]
                                      }
                                    />
                                  </div>
                                )}
                                <Link
                                  to={
                                    userInfo && userInfo._id == item._id
                                      ? { pathname: "/user/my-closet" }
                                      : {
                                          pathname: `/closet/${item?.user?.userDetailsInfo?.closetName}`,
                                          search: `?closet=${item?.user?.userMasterInfo?._id}`,
                                        }
                                  }
                                  state={{
                                    id: item?.user?.userMasterInfo?._id,
                                  }}
                                >
                                  <div className="member">
                                    {item?.user?.userDetailsInfo?.closetName ||
                                      "Anonymous"}
                                  </div>
                                </Link>
                                <div className="score" title="Ecocycle Score">
                                  {item?.user?.ecoCycleScoreNum}
                                </div>
                              </div>
                            </>
                          )
                      )}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>
                </div>

                <div className="metrics">
                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <BsFillSendArrowUpFill /> &nbsp; &nbsp;Most Items Posted
                      Last Month&nbsp; &nbsp;
                      <BsFillSendArrowUpFill />
                    </h3>
                    <div className="leaderboard">
                      <div className={`leader-row h5`} style={{padding: "5px"}}>
                        <div className="rank">&nbsp;</div>
                        <div className="rank-icon"
                          style={{ position: "absolute", left: "5rem", top: 5 }}
                        >
                          &nbsp;
                        </div>

                        <div className="member">&nbsp;</div>
                        <div className="score small-text text-center" style={{width: "90px"}}>
                          <em>Items Posted</em>
                        </div>
                        <div className="score small-text text-center" style={{width: "90px"}}>
                          <em>Hangers Earned</em>
                        </div>
                      </div>
                      {data?.mostPostedLastMonth?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">
                              {item.closetName || "Anonymous"}
                              {/* {ind > 0 && "Show"} */}
                            </div>
                          </Link>
                          <div className="score" title="Total Products Posted">
                            {item.totalProductsPosted}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">{item.totalHangersEarned}</div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>

                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <BsHandbagFill /> &nbsp; &nbsp;Most Items Bought Last
                      Month &nbsp; &nbsp;
                      <BsHandbagFill />
                    </h3>
                    <div className="leaderboard">
                      {data?.mostBoughtLastMonth?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">{item.closetName}</div>
                          </Link>
                          <div className="score" title="Total Products Bought">
                            {item.totalProductsBought}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">
                            {item.totalHangersRedeemed}
                          </div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>

                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <MdSell /> &nbsp; &nbsp;Most Items Sold Last Month &nbsp;
                      &nbsp;
                      <MdSell />
                    </h3>
                    <div className="leaderboard">
                      {data?.mostSoldLastMonth?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">{item.closetName}</div>
                          </Link>
                          <div className="score" title="Total Products Sold">
                            {item.commonProductCount.length}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">{item.hangersRedeemed}</div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>

                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <BsFillSendArrowUpFill /> &nbsp; &nbsp;Most Items Posted
                      This Year &nbsp; &nbsp; <BsFillSendArrowUpFill />
                    </h3>
                    <div className="leaderboard">
                      {data?.mostPostedThisYear?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">{item.closetName}</div>
                          </Link>
                          <div className="score" title="Total Products Posted">
                            {item.totalProductsPosted}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">{item.totalHangersEarned}</div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>

                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <BsHandbagFill /> &nbsp; &nbsp;Most Items Bought This Year
                      &nbsp; &nbsp;
                      <BsHandbagFill />
                    </h3>
                    <div className="leaderboard">
                      {data?.mostBoughtThisYear?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">{item.closetName}</div>
                          </Link>
                          <div className="score" title="Total Products Bought">
                            {item.totalProductsBought}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">
                            {item.totalHangersRedeemed}
                          </div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>

                  <div className="metric">
                    <h3
                      className="h4 bold-text background-text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      <MdSell /> &nbsp; &nbsp;Most Items Sold This Year &nbsp;
                      &nbsp;
                      <MdSell />
                    </h3>
                    <div className="leaderboard">
                      {data?.mostSoldThisYear?.map((item, ind) => (
                        <div
                          className={`leader-row h5 ${
                            classes[ind + 1] && classes[ind + 1]
                          }`}
                          key={ind}
                        >
                          <div className="rank">
                            <span className="rank-icon" style={{ position: "absolute", left: 8 }}>
                              {badges[ind + 1] && badges[ind + 1]}
                            </span>{" "}
                            {ind + 1}
                            {suffix[ind + 1] && suffix[ind + 1]}
                          </div>
                          {ind + 1 <= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                left: "5rem",
                                top: 5,
                              }}
                              className="user-icon"
                            >
                              <img
                                width={"25px"}
                                height={"25px"}
                                style={{ borderRadius: "50%" }}
                                src={
                                  item.closetIcon || noClosetIconList[ind + 1]
                                }
                              />
                            </div>
                          )}
                          <Link
                            to={
                              userInfo && userInfo._id == item.userMasterId
                                ? { pathname: "/user/my-closet" }
                                : {
                                    pathname: `/closet/${item?.closetName}`,
                                    search: `?closet=${item?.userMasterId}`,
                                  }
                            }
                            state={{ id: item?.userMasterId }}
                          >
                            <div className="member">{item.closetName}</div>
                          </Link>
                          <div className="score" title="Total Products Sold">
                            {item.commonProductCount.length}
                          </div>
                          <div className="score" title="Total Hangers Redeemed">{item.hangersRedeemed}</div>
                        </div>
                      ))}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ScrollAnimation>
          {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="leaderboard-container">
            <h2 className="leaderboard-title">
              ClosestCloset Impact Score Leaderboard
            </h2>
            <div className="leaderboard-table site-width">
              {data.map((item, index) => (
                <div key={item.id} className="leaderboard-row">
                  <div className="tile rank-score">
                    #{item.rank} | {item?.user?.ecoCycleScoreNum} Impact Score
                  </div>
                  <div className="tile details">
                    {item?.user?.userDetailsInfo?.closetName || "Anonymous Closet"} | {item?.user?.itemPostedNum} Items Posted |{" "}
                    {item?.user?.itemPostedNum * 25} kg of CO2 Saved
                  </div>
                  <Link
                  to={
                    userInfo && userInfo._id == item._id
                      ? { pathname: "/user/my-closet" }
                      : {
                          pathname: `/closet/${item?.user?.userDetailsInfo?.closetName}`,
                          search: `?closet=${item?.user?.userMasterInfo?._id}`,
                        }
                  }
                  state={{ id: item?.user?.userMasterInfo?._id }}
                >
                  <button className="view-closet-btn">View Closet</button>
                </Link>
                </div>
              ))}
            </div>
          </div>
          </ScrollAnimation> */}
        </section>
      )}
    </div>
  );
};

export default Leaderboard;
