import React, { useEffect, useState } from "react";
import "./ProductCloset.css";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";

const ProductCloset = ({ closetInfo, userInfo }) => {
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const [rating, setRating] = useState(
    closetInfo?.closetRating?.find(
      (ratingObj) => ratingObj.userId === userInfo?._id
    )?.rating || 0
  );

  const avgRating =
    closetInfo?.closetRating?.reduce(
      (sum, ratingObj) => sum + ratingObj.rating,
      0
    ) / closetInfo?.closetRating?.length;
  return (
    closetInfo && (
      <div className="product_closet_container">
        <div className="closet_icon">
          <Link
            to={`/`}
            style={{ textDecoration: "none" }}
            className="icon_container"
          >
            {" "}
            <img
              src={
                // closetInfo.userIcon
                //   ? closetInfo.userIcon
                //   :
                "https://www.instyle.com/thmb/ciGuAwe4dfMdkFBuaGwnavsRSaw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/closet-lead-2000-5b8bd03a1a6044aeb146a92b5e52d24c.jpg"
              }
              alt="closet Icon"
              style={{ width: "28px", height: "28px", borderRadius: "50%" }}
            />
          </Link>
        </div>
        <div className="closet_info">
          <div className="closet_meta">
            <div className="left">
              <div className="closet_name">
                Shared By:{" "}
                <Link
                  to={
                    userInfo && userInfo._id == closetInfo.masterId
                      ? { pathname: "/user/my-closet" }
                      : {
                          pathname: `/closet/${closetInfo.closetName}`,
                          search: `?closet=${closetInfo.masterId}`,
                        }
                  }
                  state={{ id: closetInfo.masterId }}
                  style={{ color: "#F26654" }}
                >
                  {closetInfo.closetName}
                </Link>
              </div>
              {!closetInfo.userFName + " " + closetInfo.userLName ==
                closetInfo.closetInfo && (
                <div className="closet_author">
                  - {closetInfo.userFName} {closetInfo.userLName}
                </div>
              )}
            </div>
            <div className="right">
              <div className="closet_location">
                {closetInfo.userCity + ", " + closetInfo.userState}
              </div>
            </div>
          </div>
          <div className="closet_stats">
            <div className="left" style={{flexDirection:"column"}}>
              <div style={{display:"flex"}}>
                <div className="closet_rating">
                  Closet Rating: &nbsp;
                  <div className="rating_container">
                    <StarRating
                      rating={rating}
                      onRatingChange={handleRatingChange}
                      closetInfo={closetInfo}
                    />
                  </div>
                </div>
                <div className="closet_reviews grey-text">
                  {avgRating ? avgRating : 0} (
                  <span>{closetInfo.closetRating?.length}</span>)
                </div>
              </div>
              {
                closetInfo.ecoCycleScore && (
                  <div>
                    Environmental Impact Score: {closetInfo.ecoCycleScore}
                  </div>
                )
              }
            </div>
            <div className="right">
              <div className="closet_items">
                {" "}
                Total Items :{closetInfo.soldItems +
                  closetInfo.availableItems}{" "}
                | Items Sold : {closetInfo.soldItems} | Available Items :{" "}
                {closetInfo.availableItems}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProductCloset;
