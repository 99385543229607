import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "idle",
  products: [],
  productCount: 0,
  error: null,
  filters:null,
  userList:[]
};

export const getallProducts = createAsyncThunk(
  "product/getallproducts",
  async (filters = "", { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getallproducts?${filters}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const allProductsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset(state, action) {
      state.error = null;
      state.products = [];
      state.status = "idle";
      
    },
    resetError(state, action) {
      state.error = null;
    },
  },
  extraReducers: {
    [getallProducts.pending]: (state, action) => {
      state.status = "loading";
    },
    [getallProducts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.products = action.payload.products;
      state.productCount = action.payload.productCount;
      state.filters = action.payload.filters;
      state.userList = action.payload.userList;
    },
    [getallProducts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reset, resetError } = allProductsSlice.actions;
export default allProductsSlice.reducer;
