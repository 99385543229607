import React, { useEffect, useState } from "react";
import "./ShopItem.css";
import FP1 from "../../assets/images/LP1.png";
import hanger from "../../assets/images/hanger.svg";
import Spinner1 from "../Spinner1/Spinner1";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoItem from "../../assets/images/others_closet_empty.svg";
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineEdit,
} from "react-icons/ai";
import { addtoCart, resetCartMessage } from "../StateSlices/addtocartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import { FaPen } from "react-icons/fa";
import { handleLike, resetLikeMessage } from "../StateSlices/handleLikeSlice";
import Shimmer from "../../shimmer/Shimmer";
import loadImgGIf from "../../assets/images/load-img.gif";
import { PiCoatHangerFill } from "react-icons/pi";

const ShimmerEffect = () => {
  return <div className="shimmer-effect" />;
};

const ShopItem = ({
  products,
  brandFilter,
  colorFilter,
  sizeFilter,
  categoryFilter,
  spinner,
  sort,
  setSort,
  handleSort,
  closetFilter,
  conditionFilter,
  exclusiveFilter,
  hangerFilter,
  pageNumber,
  productCount,
  setCartVal,
  cartVal,
  pstatus,
}) => {
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { likeMessage, likeStatus, likeError } = useSelector(
    (state) => state.like
  );

  const { cstatus, message } = useSelector((state) => state.cart);
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toTrue, setToTrue] = useState(false);
  const [like, setLike] = useState({});
  const [likeArr, setLikeArr] = useState([]);

  const likeHandler = (id) => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      if (likeArr.includes(id)) {
        let liketemp = likeArr.filter((val) => val !== id);
        setLikeArr(liketemp);
      } else {
        setLikeArr((prev) => {
          return [...prev, id];
        });
      }
      dispatch(handleLike({ id, token }));
    }
  };

  useEffect(() => {
    if (products) {
      setTimeout(() => setToTrue(true), 1000);
    }
  });
  useEffect(() => {
    dispatch(getuserInfo({ token: localStorage.getItem("clttoken") }));
  }, []);

  const AddToCart = (id) => {
    //consolele.log(id);
    let token = localStorage.getItem("clttoken");
    setLoading(true);
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    cart.push(id);
    sessionStorage.setItem("closetcart", JSON.stringify(cart));
    setCartVal(cart);
    dispatch(addtoCart({ token, id }));
    // toast.success("Product added to bag successfully!", toastOption);
  };

  const addtoSessionCart = (pid) => {
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    cart.push(pid);
    sessionStorage.setItem("closetcart", JSON.stringify(cart));
    setCartVal(cart);
    toast.success("Product added to bag successfully!", toastOption);
  };

  if (message) {
    //consolele.log(message);
    toast.success("Product added to bag successfully!", toastOption);
    dispatch(resetCartMessage());
    setLoading(false);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token }));
  }

  if (likeStatus === "succeeded") {
    dispatch(resetLikeMessage());
    toast.success("Like preference updated successfully!", toastOption);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token }));
  }

  if (likeStatus === "failed") {
    let liketemp = likeArr.slice(0, likeArr.length - 1);
    setLikeArr(liketemp);
    dispatch(resetLikeMessage());
    toast.error("Failed to like the product!", toastOption);
  }

  useEffect(() => {
    if (userInfo) {
      setLikeArr(userInfo.userProductsId.favouritesId);
    }
  }, [userInfo]);

  const removeSpace = (url) => {
    return url.replace(/[\s#&\/]+/g, "-");
  };

  return (
    <div
      className="shop-items-con"
      style={{ height: "100%", marginTop: "2rem" }}
    >
      <h1
        className="h3 bold-text background-text text-uppercase all-items-title"
        style={{ fontWeight: 500 }}
      >
        All Items
      </h1>
      {/* <br /> */}
      <div className="h6 all-items-title">
        Showing{" "}
        {/* {(location.search.slice(-1)
              ? location.search.slice(location.search.indexOf("=") + 1) - 1
              : 0) *
              20 +
              1}
            {"-"} */}
        {/* {(location.search.slice(-1)
              ? location.search.slice(location.search.indexOf("=") + 1) - 1
              : 0) ===
            Math.ceil(productCount / 20) - 1
              ? productCount
              : (location.search.slice(-1)
                  ? location.search.slice(location.search.indexOf("=") + 1) - 1
                  : 0) *
                  20 +
                20}{" "} */}
        {/* of  */}
        {productCount} Items
      </div>

      <div className="sortCategory">
        <div className="categorySorter">
          <br />
          <a className="sortCategoryDropdown" href="javascript:void(0);">
            Sort by <i class="fa-solid fa-angle-down"></i>
          </a>
          <div className="drop-down">
            <a
              href="javascript:void(0);"
              onClick={() => {
                handleSort("price");
              }}
            >
              Price: Low to High
            </a>
            <a
              href="javascript:void(0);"
              onClick={() => {
                handleSort("price-desc");
              }}
            >
              Price: High to Low
            </a>
            <a
              href="javascript:void(0);"
              onClick={() => {
                handleSort("newest");
              }}
            >
              Newest
            </a>
            <a
              href="javascript:void(0);"
              onClick={() => {
                handleSort("oldest");
              }}
            >
              Oldest
            </a>
          </div>
        </div>
      </div>
      {pstatus === false && (
        <>
          {products && products.length > 0 ? (
            <>
              <div className="shop-items-con-wrapper">
                {products.length > 0 &&
                  products.map((val, index) => {
                    let productCreatedAt = new Date(val.createdAt);
                    let currDate = new Date();
                    let timeDifference =
                      currDate.getTime() - productCreatedAt.getTime();
                    let numberOfDays = timeDifference / (1000 * 3600 * 24);
                    //consolele.log(parseInt(numberOfDays));

                    return (
                      <div className="shop-item-card">
                        {numberOfDays <= 30 && (
                          <>
                            {/* <div class="ribbon-pop small-text">Members Only</div> */}
                            <p class="ribbon">
                              <span class="text">Members Only</span>
                            </p>
                          </>
                        )}

                        <div className="card_image">
                          <Link
                            to={{
                              pathname: `/product/${encodeURIComponent(
                                removeSpace(val.productStats.brand)
                              )}-${encodeURIComponent(
                                removeSpace(val.productName)
                              )}/${val._id}`,
                              // search: `?product=${val._id}`,
                            }}
                            state={{ id: val._id }}
                            style={{ textDecoration: "none" }}
                            title={val.productName}
                          >
                            <LazyLoadImage
                              src={
                                val.productImage[0]
                                  ? val.productImage[0]
                                  : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                              }
                              // src={loadImgGIf}
                              alt="FP1"
                              effect="blur"
                              style={{ maxWidth: "250px" }}
                              height="260px"
                              placeholderSrc={loadImgGIf}
                            />
                          </Link>
                          <div className="hidden-card-icons">
                            {/* <Link to={new URL(window.location.href).pathname}> */}
                            <div
                              className="hidden-card-icon-wrapper"
                              onClick={() => {
                                if (likeStatus !== "loading")
                                  likeHandler(val._id);
                              }}
                            >
                              {likeArr.includes(val._id) ? (
                                <AiFillHeart
                                  size={"1.2em"}
                                  color="var(--primary-color)"
                                  className="heart-icon"
                                />
                              ) : (
                                <AiOutlineHeart
                                  size={"1.2em"}
                                  color="var(--primary-color)"
                                  className="heart-icon heart-icon-animation"
                                />
                                // <svg class="heart-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                //   <path class="heart-outline" d="M12 21.35l-5.5-5.5C3.58 13.47 2 11.55 2 9C2 6.24 4.24 4 7 4c1.43 0 2.75 0.62 3.67 1.61C11.01 4.62 12.33 4 13.77 4C16.53 4 18.77 6.24 18.77 9c0 2.55-1.58 4.47-4.5 6.85L12 21.35z"/>
                                //   <path class="heart-fill" d="M12 21.35l-5.5-5.5C3.58 13.47 2 11.55 2 9C2 6.24 4.24 4 7 4c1.43 0 2.75 0.62 3.67 1.61C11.01 4.62 12.33 4 13.77 4C16.53 4 18.77 6.24 18.77 9c0 2.55-1.58 4.47-4.5 6.85L12 21.35z"/>
                                // </svg>
                              )}
                            </div>
                            {/* </Link> */}
                            {/* <Link to={new URL(window.location.href).pathname}> */}

                            {userInfo &&
                              userInfo.role === "admin" &&
                              userInfo.totalProduct.some(
                                (el) => el == val._id
                              ) && (
                                <div
                                  className="hidden-card-icon-wrapper"
                                  style={
                                    userInfo.userProductsId.cartId.some(
                                      (cid) => cid === val._id
                                    ) || cstatus === "loading"
                                      ? {
                                          cursor: "not-allowed",
                                          background: "#ddd",
                                        }
                                      : {}
                                  }
                                >
                                  <AiOutlineShoppingCart
                                    onClick={() => {
                                      //consolele.log("cstatus", cstatus);
                                      if (cstatus === "loading") {
                                        return false;
                                      }
                                      if (
                                        userInfo.userProductsId.cartId.some(
                                          (cid) => cid === val._id
                                        )
                                      ) {
                                        toast.error(
                                          "Item Already Added to Bag. Please go to Checkout.",
                                          toastOption
                                        );
                                        return false;
                                      }
                                      // if(!userInfo){
                                      //   //consolele.log(
                                      //     new Date(new Date().setDate(new Date().getDate() + 30))
                                      //   );
                                      //   //consolele.log(new Date(productInfo.createdAt));
                                      //   if (
                                      //     new Date().getTime() <=
                                      //     new Date(
                                      //       new Date(productInfo.createdAt).setDate(
                                      //         new Date(productInfo.createdAt).getDate() + 30
                                      //       )
                                      //     ).getTime()
                                      //   ) {
                                      //     if (
                                      //       !userInfo ||
                                      //       new Date(
                                      //         userInfo.userDetailsId.membershipExpiration
                                      //       ).getTime() <= new Date().getTime()
                                      //     ) {
                                      //       toast.error(
                                      //         "You need to be a member to add this item to your cart",
                                      //         toastOption
                                      //       );
                                      //       return false;
                                      //     }
                                      //   }
                                      //   addtoSessionCart();
                                      // }
                                      // //consolele.log(
                                      //   new Date(
                                      //     new Date().setDate(
                                      //       new Date().getDate() + 30
                                      //     )
                                      //   )
                                      // );
                                      //consolele.log(new Date(val.createdAt));
                                      if (
                                        new Date().getTime() <=
                                        new Date(
                                          new Date(val.createdAt).setDate(
                                            new Date(val.createdAt).getDate() +
                                              30
                                          )
                                        ).getTime()
                                      ) {
                                        if (
                                          !userInfo ||
                                          new Date(
                                            userInfo.userDetailsId.membershipExpiration
                                          ).getTime() <= new Date().getTime()
                                        ) {
                                          toast.error(
                                            "You need to be a member to add this item to your cart",
                                            toastOption
                                          );
                                          return false;
                                        }
                                      }
                                      // addtoSessionCart(val._id);
                                      AddToCart(val._id);
                                    }}
                                    size={"1.2em"}
                                    color="var(--primary-color)"
                                  />
                                </div>
                              )}

                            {userInfo &&
                            userInfo.userProductsId &&
                            !userInfo.totalProduct.some(
                              (el) => el == val._id
                            ) ? (
                              <div
                                className="hidden-card-icon-wrapper"
                                style={
                                  userInfo.userProductsId.cartId.some(
                                    (cid) => cid === val._id
                                  ) || cstatus === "loading"
                                    ? {
                                        cursor: "not-allowed",
                                        background: "#ddd",
                                      }
                                    : {}
                                }
                                title={
                                  userInfo.userProductsId.cartId.some(
                                    (cid) => cid === val._id
                                  )
                                    ? "Item Already Added"
                                    : "Add to Bag"
                                }
                              >
                                <AiOutlineShoppingCart
                                  onClick={() => {
                                    // //consolele.log('cstatus',cstatus)
                                    if (cstatus === "loading") {
                                      return false;
                                    }
                                    if (
                                      userInfo.userProductsId.cartId.some(
                                        (cid) => cid === val._id
                                      )
                                    ) {
                                      toast.error(
                                        "Item Already Added to Bag. Please go to Checkout.",
                                        toastOption
                                      );
                                      return false;
                                    }
                                    // if(!userInfo){
                                    //   //consolele.log(
                                    //     new Date(new Date().setDate(new Date().getDate() + 30))
                                    //   );
                                    //   //consolele.log(new Date(productInfo.createdAt));
                                    //   if (
                                    //     new Date().getTime() <=
                                    //     new Date(
                                    //       new Date(productInfo.createdAt).setDate(
                                    //         new Date(productInfo.createdAt).getDate() + 30
                                    //       )
                                    //     ).getTime()
                                    //   ) {
                                    //     if (
                                    //       !userInfo ||
                                    //       new Date(
                                    //         userInfo.userDetailsId.membershipExpiration
                                    //       ).getTime() <= new Date().getTime()
                                    //     ) {
                                    //       toast.error(
                                    //         "You need to be a member to add this item to your cart",
                                    //         toastOption
                                    //       );
                                    //       return false;
                                    //     }
                                    //   }
                                    //   addtoSessionCart();
                                    // }
                                    // //consolele.log(
                                    //   new Date(
                                    //     new Date().setDate(
                                    //       new Date().getDate() + 30
                                    //     )
                                    //   )
                                    // );
                                    //consolele.log(new Date(val.createdAt));
                                    if (
                                      new Date().getTime() <=
                                      new Date(
                                        new Date(val.createdAt).setDate(
                                          new Date(val.createdAt).getDate() + 30
                                        )
                                      ).getTime()
                                    ) {
                                      if (
                                        !userInfo ||
                                        new Date(
                                          userInfo.userDetailsId.membershipExpiration
                                        ).getTime() <= new Date().getTime()
                                      ) {
                                        toast.error(
                                          "You need to be a member to add this item to your cart",
                                          toastOption
                                        );
                                        return false;
                                      }
                                    }
                                    // addtoSessionCart(val._id);
                                    AddToCart(val._id);
                                  }}
                                  size={"1.2em"}
                                  color="var(--primary-color)"
                                />
                              </div>
                            ) : userInfo &&
                              userInfo.userProductsId &&
                              userInfo.totalProduct.some(
                                (el) => el == val._id
                              ) ? (
                              <Link
                                to={`/editproduct/${encodeURIComponent(
                                  val.productStats && val.productStats.brand
                                )}-${encodeURIComponent(val.productName)}`}
                                state={{ id: val._id }}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <div className="hidden-card-icon-wrapper">
                                  <AiOutlineEdit
                                    size={"1.2em"}
                                    color="var(--primary-color)"
                                  />
                                </div>
                              </Link>
                            ) : (
                              <div
                                className="hidden-card-icon-wrapper"
                                style={
                                  cartVal.includes(val._id) ||
                                  cstatus === "loading"
                                    ? {
                                        cursor: "not-allowed",
                                        background: "#ddd",
                                      }
                                    : {}
                                }
                                title={
                                  cartVal.includes(val._id)
                                    ? "Item Already Added"
                                    : "Add to Bag"
                                }
                              >
                                <AiOutlineShoppingCart
                                  onClick={() => {
                                    if (cstatus === "loading") {
                                      return false;
                                    }
                                    if (cartVal.includes(val._id)) {
                                      toast.error(
                                        "Item Already Added to Bag. Please go to Checkout.",
                                        toastOption
                                      );
                                      return false;
                                    }
                                    // if(!userInfo){
                                    //   //consolele.log(
                                    //     new Date(new Date().setDate(new Date().getDate() + 30))
                                    //   );
                                    //   //consolele.log(new Date(productInfo.createdAt));
                                    //   if (
                                    //     new Date().getTime() <=
                                    //     new Date(
                                    //       new Date(productInfo.createdAt).setDate(
                                    //         new Date(productInfo.createdAt).getDate() + 30
                                    //       )
                                    //     ).getTime()
                                    //   ) {
                                    //     if (
                                    //       !userInfo ||
                                    //       new Date(
                                    //         userInfo.userDetailsId.membershipExpiration
                                    //       ).getTime() <= new Date().getTime()
                                    //     ) {
                                    //       toast.error(
                                    //         "You need to be a member to add this item to your cart",
                                    //         toastOption
                                    //       );
                                    //       return false;
                                    //     }
                                    //   }
                                    //   addtoSessionCart();
                                    // }
                                    // //consolele.log(
                                    //   new Date(
                                    //     new Date().setDate(
                                    //       new Date().getDate() + 30
                                    //     )
                                    //   )
                                    // );
                                    //consolele.log(new Date(val.createdAt));
                                    if (
                                      new Date().getTime() <=
                                      new Date(
                                        new Date(val.createdAt).setDate(
                                          new Date(val.createdAt).getDate() + 30
                                        )
                                      ).getTime()
                                    ) {
                                      if (
                                        !userInfo ||
                                        new Date(
                                          userInfo.userDetailsId.membershipExpiration
                                        ).getTime() <= new Date().getTime()
                                      ) {
                                        toast.error(
                                          "You need to be a member to add this item to your cart",
                                          toastOption
                                        );
                                        return false;
                                      }
                                    }
                                    addtoSessionCart(val._id);
                                    // AddToCart(val._id);
                                  }}
                                  size={"1.2em"}
                                  color="var(--primary-color)"
                                />
                              </div>
                            )}

                            {/* </Link> */}
                          </div>
                        </div>
                        <Link
                          to={{
                            pathname: `/product/${encodeURIComponent(
                              removeSpace(val.productStats.brand)
                            )}-${encodeURIComponent(
                              removeSpace(val.productName)
                            )}/${val._id}`,
                            // search: `?product=${val._id}`,
                          }}
                          state={{ id: val._id }}
                          style={{ textDecoration: "none" }}
                          title={val.productName}
                        >
                          <div className="card_text">
                            <div className="product_title h6">
                              {val.productName}
                            </div>
                            <hr
                              style={{
                                margin: "1rem .2rem",
                                borderStyle: "solid",
                                color: "#ddd",
                              }}
                            />
                            <div className="product_price h6 bold-text">
                              {val.productHanger}
                              <PiCoatHangerFill
                                style={{ marginLeft: "3px" }}
                                size={"1.3em"}
                              />{" "}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : !spinner ? (
            <div className="no_item">
              <img src={NoItem} alt="no-item-icon" />
              <h1 className="h1" style={{ marginTop: "2rem" }}>
                No Items to Display
              </h1>
            </div>
          ) : (
            ""
          )}
        </>
      )}

      {spinner && <Shimmer />}
    </div>
  );
};

export default ShopItem;
