import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { hydrate, render } from "react-dom";

const APP = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// Create a debounced version of the ResizeObserver for CKEditor
// function debounceResizeObserver(callback, delay) {
//   let timeout;
//   return (entries) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       callback(entries);
//     }, delay);
//   };
// }

// Patch ResizeObserver specifically for CKEditor
// class CKEditorResizeObserver {
//   constructor(callback) {
//     this.observer = new ResizeObserver(debounceResizeObserver(callback, 200)); // Debouncing with 200ms delay
//   }

//   observe(target, options) {
//     this.observer.observe(target, options);
//   }

//   unobserve(target) {
//     this.observer.unobserve(target);
//   }

//   disconnect() {
//     this.observer.disconnect();
//   }
// }

// // Apply the patch only to CKEditor’s ResizeObserver
// window.CKEDITOR_RESIZE_OBSERVER = CKEditorResizeObserver;

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
