import React, { useEffect } from "react";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCoupen,
  resetCoupen,
  getCoupen,
  deleteCoupen,
} from "../../../components/StateSlices/coupenSlice";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";

const CoupensDisplay = ({ tab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { coupenStatus, coupenInfo, coupenError, coupenMessage } = useSelector(
    (state) => state.coupen
  );

  const [spinner, setSpinner] = useState(false);
  const [coupenList, setCoupenList] = useState([]);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    setSpinner(true);
    let token = localStorage.getItem("clttoken");
    dispatch(getCoupen({ token, values: { status: tab } }));
  }, [tab]);

  useEffect(() => {
    if ((coupenInfo && coupenInfo.length > 0) || coupenStatus === "succeeded") {
      setSpinner(false);
      setCoupenList(coupenInfo);
      dispatch(resetCoupen());
    }
  }, [coupenInfo]);

  const deleteCoupenAction = (coupenCode) => {
    swal({
      title: `Delete Coupon`,
      text: `Once Deleted, Coupon Cannot be Reactivated`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(deleteCoupen({ token, values: { coupenCode } }));
          setSpinner(true);
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  useEffect(() => {
    if (coupenMessage === "Coupon Deleted Succesfully") {
      dispatch(resetCoupen());
      // console.log("HEHEHEHE");
      toast.success("Coupon Deleted Succesfully", toastOption);
      let token = localStorage.getItem("clttoken");
      dispatch(getCoupen({ token, values: { status: tab } }));
    }
  }, [coupenMessage]);

  useEffect(() => {
    if (coupenError) {
      setSpinner(false);
      dispatch(resetCoupen());
      toast.error(coupenError, toastOption);
    }
  }, [coupenError]);

  return (
    <>
      <br />
      <br />
      {spinner ? (
        <Spinner1 />
      ) : (
        <div className="users">
          <table className="users-table">
            <thead>
              <tr>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Coupon Name
                </th>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Code
                </th>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Coupon Type
                </th>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Subscription Type
                </th>
                <th
                  className="h5 background-text bold-text"
                  style={{ fontWeight: 700 }}
                >
                  Used Count
                </th>
                <th
                  className="h5 background-text bold-text"
                  style={{ fontWeight: 700 }}
                >
                  User Limit
                </th>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Created At
                </th>
                <th
                  style={{ fontWeight: 700 }}
                  className="h5 background-text bold-text"
                >
                  Redeem By
                </th>
                {tab === "active" && (
                  <th
                    style={{ fontWeight: 700 }}
                    className="h5 background-text bold-text"
                  >
                    Delete
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {coupenList &&
                coupenList.length > 0 &&
                coupenList.map((coupen, index) => {
                  return (
                    <tr key={index}>
                      <td className="h6">{coupen.coupenName}</td>
                      <td className="h6">{coupen.coupenCode}</td>
                      <td
                        className="h6"
                        style={{ textTransform: "capitalize" }}
                      >
                        {coupen.coupenType}
                      </td>
                      <td
                        className="h6"
                        style={{ textTransform: "capitalize" }}
                      >
                        {coupen.subscriptionType
                          ? coupen.subscriptionType
                          : "-"}
                      </td>
                      <td
                        className="h6"
                        style={{ textTransform: "capitalize" }}
                      >
                        {coupen.coupenRedeemed}
                      </td>
                      <td
                        className="h6"
                        style={{ textTransform: "capitalize" }}
                      >
                        {coupen.userLimit ? coupen.userLimit : "-"}
                      </td>
                      <td className="h6" style={{ minWidth: "120px" }}>
                        {(new Date(coupen.createdAt).getMonth() + 1)
                          .toString()
                          .padStart(2, "0") +
                          "-" +
                          new Date(coupen.createdAt)
                            .getDate()
                            .toString()
                            .padStart(2, "0") +
                          "-" +
                          new Date(coupen.createdAt).getFullYear()}
                      </td>
                      <td className="h6" style={{ minWidth: "120px" }}>
                        {coupen.coupenRedeem
                          ? (new Date(coupen.coupenRedeem).getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            new Date(coupen.coupenRedeem)
                              .getDate()
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            new Date(coupen.coupenRedeem).getFullYear()
                          : "-"}
                      </td>
                      {tab === "active" && (
                        <td className="h6">
                          <button
                            title="Delete User"
                            style={{
                              borderRadius: "2px",
                              background: "transparent",
                              color: "var(--primary-color)",
                              padding: "5px",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteCoupenAction(coupen.coupenCode);
                            }}
                          >
                            <AiFillDelete size={"1.4em"} />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CoupensDisplay;
