import React, { useEffect, useRef, useState } from "react";
import "./Coupens.css";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCoupen,
  resetCoupen,
} from "../../../components/StateSlices/coupenSlice";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../../components/Spinner/Spinner";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#F26654" : "inherit",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#F26654",
    },
    fontSize: "14px",
    borderRadius: "5px",
    marginTop: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    marginTop: "3px",
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "#F26654" : "#fff",
    "&:hover": {
      backgroundColor: state.isSelected ? "#F26654" : "rgba(242, 102, 84, 0.5)",
      color: "#fff",
    },
  }),
  // placeholder:(provided,state) => ({
  //   ...provided,
  //   textTransform: "capitalize",
  // }),
  singleValue: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
};

const CoupensCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { coupenStatus, coupenInfo, coupenError, coupenMessage } = useSelector(
    (state) => state.coupen
  );

  const durationOptions = [
    { label: "Once", value: "once" },
    { label: "Repeating", value: "repeating" },
    { label: "Forever", value: "forever" },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const coupenTypeOptions = [
    { label: "Checkout Coupon", value: "checkout" },
    { label: "Signup Coupon", value: "signup" },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const subscriptionTypeOptions = [
    { label: "Annual Subscription", value: "annual" },
    { label: "Monthly Subscription", value: "monthly" },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const [duration, setDuration] = useState(null);
  const [coupenType, setCoupenType] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const formikRef = useRef();
  const [spinner, setSpinner] = useState(false);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const formik = useFormik({
    innerRef: formikRef,
    initialValues: {
      coupenType: "",
      coupen: "",
      coupenName: "",
      userLimit:"",
      offer: "",
      duration: "",
      durationByMonth: "",
      redeemBy: "",
      subscriptionType:""
    },
    validationSchema: Yup.object({
      coupenType: Yup.string().required("Please Specify Coupon Type"),
      coupen: Yup.string().required("Please Enter Coupon"),
      coupenName: Yup.string().required("Please Enter Coupon Name"),
      offer: Yup.number().required("Please Enter Valid Offer"),
      userLimit: Yup.number().typeError("Please Enter Valid Number"),
      duration: Yup.string().required("Please Enter Duration"),
      durationByMonth: Yup.string().when("duration", {
        is: "repeating",
        then: Yup.string().required("Please Enter Duration By Month"),
        otherwise: Yup.string().notRequired(),
      }),
      subscriptionType: Yup.string().when("coupenType", {
        is: "signup",
        then: Yup.string().required("Please Specify Subscription Type"),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    onSubmit: (values) => {
      let token = localStorage.getItem("clttoken");
      dispatch(addCoupen({ token, values }));
      setSpinner(true);
    },
  });

  useEffect(() => {
    if (coupenMessage) {
      setSpinner(false);
      formik.resetForm();
      dispatch(resetCoupen());
      setCoupenType(null);
      setDuration(null);
      toast.success("New Coupon Created Succesfully", toastOption);
    }
  }, [coupenMessage]);

  useEffect(() => {
    if (coupenError) {
      setSpinner(false);
      dispatch(resetCoupen());
      toast.error(coupenError, toastOption);
    }
  }, [coupenError]);

  return (
    <>
      {spinner ? (
        <Spinner1 />
      ) : (
        <>
          <div className="h2 background-text bold-text m-1">Manage Coupons</div>

          <div className="m-1">
            <form onSubmit={formik.handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="h3 background-text">New Coupon Creation</div>
                <div>
                  <button className="btn" type="submit">
                    Activate {/*<IoCheckmarkCircleSharp size={"1.2em"} /> */}
                  </button>
                </div>
              </div>
              <br />
              <div className="input_container my-1 mr-1">
                <label className="bold-text">
                  Coupon Type{" "}
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  ></span>
                </label>
                <Select
                  options={coupenTypeOptions}
                  onChange={(value) => {
                    setCoupenType(value);
                    formik.values.coupenType = value.value;
                  }}
                  value={coupenType}
                  styles={customStyles}
                  className="coupen_select"
                  placeholder="Enter Coupon Type"
                ></Select>
                {formik.touched.coupenType && formik.errors.coupenType ? (
                  <small className="form-text text-danger">
                    {formik.errors.coupenType}
                  </small>
                ) : null}
              </div>
              <div className="input_container my-1">
                <label className="bold-text">
                  Coupon{" "}
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  >
                    Eg. CCNEW
                  </span>
                </label>
                <input
                  className=""
                  type="text"
                  placeholder="Enter Coupon"
                  id="coupen"
                  name="coupen"
                  onChange={formik.handleChange}
                  value={formik.values.coupen}
                ></input>
                {formik.touched.coupen && formik.errors.coupen ? (
                  <small className="form-text text-danger">
                    {formik.errors.coupen}
                  </small>
                ) : null}
              </div>
              <div className="input_container my-1">
                <label className="bold-text">
                  Coupon Name{" "}
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  >
                    Eg. ClosestCloset New Signup Coupon
                  </span>
                </label>
                <input
                  className=""
                  type="text"
                  placeholder="Enter Coupon Name"
                  id="coupenName"
                  name="coupenName"
                  onChange={formik.handleChange}
                  value={formik.values.coupenName}
                ></input>
                {formik.touched.coupenName && formik.errors.coupenName ? (
                  <small className="form-text text-danger">
                    {formik.errors.coupenName}
                  </small>
                ) : null}
              </div>
              <div className="input_container my-1">
                <label className="bold-text">
                  Offer (%)
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  >
                    Eg. 20
                  </span>
                </label>
                <input
                  className=""
                  type="text"
                  placeholder="Enter Coupon Offer"
                  id="offer"
                  name="offer"
                  onChange={formik.handleChange}
                  value={formik.values.offer}
                ></input>
                {formik.touched.offer && formik.errors.offer ? (
                  <small className="form-text text-danger">
                    {formik.errors.offer}
                  </small>
                ) : null}
              </div>
              <div className="col-2">
                <div className="input_container my-1 mr-1">
                  <label className="bold-text">
                    Duration{" "}
                    <span
                      style={{
                        float: "right",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                      className="grey-text"
                    ></span>
                  </label>
                  <Select
                    options={durationOptions}
                    onChange={(value) => {
                      setDuration(value);
                      formik.values.duration = value.value;
                    }}
                    value={duration}
                    styles={customStyles}
                    className="coupen_select"
                    placeholder="Enter Coupon Duration"
                  ></Select>
                  {formik.touched.duration && formik.errors.duration ? (
                    <small className="form-text text-danger">
                      {formik.errors.duration}
                    </small>
                  ) : null}
                </div>
                <div className="input_container my-1 ml-1">
                  <label className="bold-text">
                    Duration in Month{" "}
                    <span
                      style={{
                        float: "right",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                      className="grey-text"
                    >
                      Eg. 2
                    </span>
                  </label>
                  <input
                    className=""
                    type="text"
                    placeholder="Enter Coupon Duration in Months"
                    id="durationByMonth"
                    name="durationByMonth"
                    onChange={formik.handleChange}
                    value={formik.values.durationByMonth}
                  ></input>
                  {formik.touched.durationByMonth &&
                  formik.errors.durationByMonth ? (
                    <small className="form-text text-danger">
                      {formik.errors.durationByMonth}
                    </small>
                  ) : null}
                </div>
              </div>
              {
                (coupenType && coupenType.value === "signup") && (
                    <div className="input_container my-1 mr-1">
                    <label className="bold-text">
                      Subscription Type{" "}
                      <span
                        style={{
                          float: "right",
                          fontSize: "12px",
                          fontWeight: "normal",
                        }}
                        className="grey-text"
                      ></span>
                    </label>
                    <Select
                      options={subscriptionTypeOptions}
                      onChange={(value) => {
                        setSubscriptionType(value);
                        formik.values.subscriptionType = value.value;
                      }}
                      value={subscriptionType}
                      styles={customStyles}
                      className="coupen_select"
                      placeholder="Enter Subscription Type"
                    ></Select>
                    {formik.touched.subscriptionType && formik.errors.subscriptionType ? (
                      <small className="form-text text-danger">
                        {formik.errors.subscriptionType}
                      </small>
                    ) : null}
                  </div>
                )
              }
              
              <div className="input_container my-1">
                <label className="bold-text">
                  Redeem By
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  ></span>
                </label>
                <input
                  className=""
                  type="date"
                  placeholder="Enter Redeem By Date"
                  id="redeemBy"
                  name="redeemBy"
                  onChange={formik.handleChange}
                  value={formik.values.redeemBy}
                ></input>
                {formik.touched.offer && formik.errors.offer ? (
                  <small className="form-text text-danger">
                    {formik.errors.redeemBy}
                  </small>
                ) : null}
              </div>
              <div className="input_container my-1">
                <label className="bold-text">
                  User Limit{" "}
                  <span
                    style={{
                      float: "right",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    className="grey-text"
                  >
                    Eg. 10; Note: if Leaved Blank than unlimited user limit
                  </span>
                </label>
                <input
                  className=""
                  type="text"
                  placeholder="Enter Maximum Number of times Coupen can be Redeemed"
                  id="userLimit"
                  name="userLimit"
                  onChange={formik.handleChange}
                  value={formik.values.userLimit}
                ></input>
                {formik.touched.userLimit && formik.errors.userLimit ? (
                  <small className="form-text text-danger">
                    {formik.errors.userLimit}
                  </small>
                ) : null}
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CoupensCreation;
