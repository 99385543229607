import React, { useState } from "react";
import imgae from "../../../assets/images/login-car-1.png";
import "./ImageResize.css";
const ImageResizer = () => {
  const [imageSize, setImageSize] = useState({ width: 200, height: 200 });
  const [isResizing, setIsResizing] = useState(false);
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Only handle left mouse button (button code: 0)
      e.preventDefault(); // Prevent text selection during drag
      setIsResizing(true);
      setInitialSize({ width: imageSize.width, height: imageSize.height });
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = initialSize.width + (e.clientX - initialSize.width);
      const newHeight = initialSize.height + (e.clientY - initialSize.height);
      setImageSize({ width: newWidth, height: newHeight });
    }
  };

  return (
    <>
      <div
        className="image-container"
        style={{ width: imageSize.width, height: imageSize.height }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <img
          src={imgae}
          alt="Resizable Image"
          style={{ width: "100%", height: "100%" }}
        />
        <div className="resize-handle top-left" />
        <div className="resize-handle top-right" />
        <div className="resize-handle bottom-left" />
        <div className="resize-handle bottom-right" />
      </div>
      <div className="dimensions">
        <span>Width: {imageSize.width}px</span>
        <span>Height: {imageSize.height}px</span>
      </div>
      <div className="markdown-content">
        {/* Your Markdown content goes here */}
      </div>
    </>
  );
};

export default ImageResizer;
