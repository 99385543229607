import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./StaticCMS.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStaticContent,
  getStaticContent,
  resetstaticContent,
  updateStaticContent,
} from "../components/StateSlices/staticContentSlice";
import Carousel from "../components/StaticContentComponents/Carousel/Carousel";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner/Spinner";
import VideoUpload from "../components/StaticContentComponents/VideoUpload";
import VideoUploadNew from "../components/StaticContentComponents/VideoUploadNew";
// import { footerContent } from "../admin/utils/static jsons/footerContent";

const StaticCMS = () => {
  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const dispatch = useDispatch();
  const { staticContentInfoAdmin, updateMsg } = useSelector(
    (state) => state.staticContent
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
    }),
  };
  const [staticState, setStaticState] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [section, setSection] = useState({
    label: "Logged-In Homepage Carousel",
    value: "loggedInHomePageCarousel",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (section) {
      dispatch(
        getStaticContent({
          values: { section: section.value },
        })
      );
    }
    return () => {
      setStaticState(null);
    };
  }, [section]);

  useEffect(() => {
    if (staticContentInfoAdmin) {
      setStaticState(staticContentInfoAdmin);
      console.log("k2l,=", staticContentInfoAdmin);
      setLoading(false);
    }
  }, [staticContentInfoAdmin]);

  useEffect(() => {
    if (updateMsg) {
      toast.success("Saved Successfully! ", toastOption);
      dispatch(resetstaticContent());
      dispatch(getAllStaticContent({ values: "All" }));
      dispatch(
        getStaticContent({
          values: { section: section.value },
        })
      );
    }
  }, [updateMsg]);

  const handleSave = () => {
    if (section?.value) {
      // alert(JSON.stringify(staticState));
      const token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(
          updateStaticContent({
            token,
            values: { section: section.value, data: staticState },
            requestType: "post",
          })
        );
      }
    }
  };

  return (
    <>
      <div className="users-header">
        <div
          className="h2 background-text bold-text"
          style={{ minWidth: "30%", marginTop: "1rem", marginBottom: "1rem" }}
        >
          Static CMS
        </div>
      </div>
      <div className="static-cms-wrapper">
        <Select
          options={[
            { label: "Footer", value: "footer" },
            { label: "Testimonials", value: "testimonials" },
            // {
            //   label: "Logged-Out Homepage",
            //   value: "loggedOutHomePage",
            // },
            {
              label: "Logged-Out Homepage Carousel",
              value: "loggedOutHomePageCarousel",
            },
            {
              label: "Logged-In Homepage Carousel",
              value: "loggedInHomePageCarousel",
            },
            // { label: "Terms and Conditions", value: "termsAndConditions" },
            // { label: "How It Works", value: "howItWorks" },
          ].sort((a, b) => a.label.localeCompare(b.label))}
          onChange={(value) => {
            setStaticState(null);
            setSection(value);
          }}
          defaultValue={{ label: "Footer", value: "footer" }}
          value={section}
          styles={customStyles}
          placeholder="Choose Section"
        ></Select>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "75vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Loading...</h3>
          </div>
        ) : (
          <>
            {staticState && (
              <>
                {section.value === "footer" && (
                  <>
                    {staticState &&
                      staticState.length > 0 &&
                      staticState.map((col, ind) => {
                        return (
                          <>
                            <div className="footer-section input_container">
                              <label
                                className="h3 background-text"
                                htmlFor={"col" + ind}
                              >
                                Column {ind + 1}
                              </label>
                              <input
                                name={"col" + ind}
                                type="text"
                                value={col.name}
                                // defaultValue={col.name}
                                onChange={(e) => {
                                  setStaticState((prevContent) => {
                                    const updatedContent = [...prevContent];
                                    updatedContent[ind] = {
                                      ...updatedContent[ind],
                                      name: e.target.value,
                                    };
                                    return updatedContent;
                                  });
                                }}
                              />
                              <br />
                              {col.links &&
                                col.links.map((link, ind1) => (
                                  <>
                                    {ind1 == 0 && (
                                      <>
                                        <div className="row">
                                          <div className="col-1">
                                            <h6>Display</h6>
                                          </div>
                                          <div className="col-1">
                                            <h6>Hyperlinks</h6>
                                          </div>
                                        </div>{" "}
                                      </>
                                    )}
                                    <div className="row">
                                      <div className="col-1">
                                        <label
                                          htmlFor={"display" + ind1}
                                        ></label>
                                        <input
                                          name={"display" + ind1}
                                          style={{ maxWidth: "450px" }}
                                          disabled={ind == 2}
                                          type="text"
                                          value={link.display}
                                          onChange={(e) => {
                                            return setStaticState(
                                              (prevState) => {
                                                const updatedFooterContent =
                                                  prevState.map(
                                                    (item, index) => {
                                                      if (index === ind) {
                                                        return {
                                                          ...item,
                                                          links: item.links.map(
                                                            (
                                                              link,
                                                              linkIndex
                                                            ) => ({
                                                              ...link,
                                                              display:
                                                                linkIndex ===
                                                                ind1
                                                                  ? e.target
                                                                      .value
                                                                  : link.display,
                                                            })
                                                          ),
                                                        };
                                                      }
                                                      return item;
                                                    }
                                                  );
                                                return updatedFooterContent;
                                              }
                                            );
                                          }}
                                          // onChange={(e) => {
                                          //   return setStaticState(
                                          //     (prevState) => {
                                          //       const updatedFooterContent = [];
                                          //       for (
                                          //         let i = 0;
                                          //         i < prevState.length;
                                          //         i++
                                          //       ) {
                                          //         let obj = {};
                                          //         obj.name = prevState[i].name;

                                          //         if (i === ind) {
                                          //           let newLinks = [];
                                          //           for (
                                          //             let j = 0;
                                          //             j <
                                          //             prevState[i].links.length;
                                          //             j++
                                          //           ) {
                                          //             let linkObj = {};
                                          //             if (j === ind1) {
                                          //               linkObj.display =
                                          //                 e.target.value;
                                          //               linkObj.link =
                                          //                 prevState[i].links[
                                          //                   j
                                          //                 ].link;
                                          //             } else {
                                          //               linkObj.display =
                                          //                 prevState[i].links[
                                          //                   j
                                          //                 ].display;
                                          //               linkObj.link =
                                          //                 prevState[i].links[
                                          //                   j
                                          //                 ].link;
                                          //             }
                                          //             newLinks.push(linkObj);
                                          //           }
                                          //           obj.links = newLinks;
                                          //         } else {
                                          //           obj.links =
                                          //             prevState[i].links;
                                          //         }
                                          //         updatedFooterContent.push(
                                          //           obj
                                          //         );
                                          //       }
                                          //       return updatedFooterContent;
                                          //     }
                                          //   );
                                          // }}
                                        />
                                      </div>
                                      <div className="col-2">
                                        <label htmlFor={"link" + ind1}></label>
                                        <input
                                          name={"link" + ind1}
                                          type="text"
                                          style={{ maxWidth: "450px" }}
                                          value={link.link}
                                          disabled={ind == 1}
                                          onChange={(e) => {
                                            return setStaticState(
                                              (prevState) => {
                                                const updatedFooterContent =
                                                  prevState.map(
                                                    (item, index) => {
                                                      if (index === ind) {
                                                        return {
                                                          ...item,
                                                          links: item.links.map(
                                                            (
                                                              link,
                                                              linkIndex
                                                            ) => ({
                                                              ...link,
                                                              link:
                                                                linkIndex ===
                                                                ind1
                                                                  ? e.target
                                                                      .value
                                                                  : link.link,
                                                            })
                                                          ),
                                                        };
                                                      }
                                                      return item;
                                                    }
                                                  );
                                                return updatedFooterContent;
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </>
                        );
                      })}
                  </>
                )}
                {section.value === "loggedOutHomePage" && (
                  <>
                    {/* {staticState &&
                      staticState.length > 0 &&
                      staticState.map((section, ind) => (
                        <div>
                          <h3 className="large-content">
                            Section {section.section}
                          </h3>
                          <div style={{ margin: "1rem 0" }}>
                            <label htmlFor={"title" + ind}>Title</label>
                            <input
                              name={"title" + ind}
                              className="input_container"
                              value={section.title}
                              onChange={(e) =>
                                setInitialState((prev) => {
                                  const prevContent = [...prev];
                                  prevContent[ind].title = e.target.value;
                                  return prevContent;
                                })
                              }
                            />
                          </div>
                          {section.desc !== "" && (
                            <div style={{ margin: "1rem 0" }}>
                              <label htmlFor={"title" + ind}>Desc</label>
                              <input
                                name={"title" + ind}
                                className="input_container"
                                value={section.desc}
                              />
                            </div>
                          )}
                          {section.cards && section.cards.length > 0 && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between",
                                }}
                              >
                                {section.cards.map((card, ind1) => (
                                  <div
                                    style={{
                                      margin: "1rem 0",
                                      maxWidth: "200px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      name={"title" + ind + ind1}
                                      className="input_container"
                                      value={card.title}
                                    />
                                    <textarea
                                      rows={8}
                                      className="input_container"
                                      value={card.desc}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      ))} */}
                  </>
                )}
                {section.value === "testimonials" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {staticState &&
                        staticState.cards &&
                        staticState.cards.length > 0 &&
                        staticState.cards.map((card, ind) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "2rem .5rem",
                                width: "250px",
                              }}
                              className="input_container"
                            >
                              <textarea
                                className="textarea_input"
                                rows={10}
                                cols={10}
                                onChange={(e) => {
                                  const updatedTestimonials = {
                                    ...staticState,
                                    cards: staticState.cards.map((c, ind1) =>
                                      ind1 === ind
                                        ? { ...c, desc: e.target.value }
                                        : c
                                    ),
                                  };
                                  setStaticState(updatedTestimonials);
                                }}
                              >
                                {card.desc}
                              </textarea>
                              <input
                                type="text"
                                value={card.title}
                                onChange={(e) =>
                                  setStaticState((prevState) => {
                                    const updatedCards = [...prevState.cards];
                                    updatedCards[ind] = {
                                      ...card,
                                      title: e.target.value,
                                    };
                                    return {
                                      ...prevState,
                                      cards: updatedCards,
                                    };
                                  })
                                }
                              />
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                )}
                {section.value === "loggedInHomePageCarousel" && (
                  <>
                    <Carousel
                      staticState={staticState}
                      setStaticState={setStaticState}
                    />
                  </>
                )}
                {section.value === "loggedOutHomePageCarousel" && (
                  <>
                    {/* <VideoUpload staticState={staticState} /> */}
                    <VideoUploadNew staticState={staticState} />
                  </>
                )}
              </>
            )}
          </>
        )}
        {section.value !== "loggedInHomePageCarousel" &&
          section.value !== "loggedOutHomePageCarousel" && (
            <button
              className="btn"
              style={{ width: "100%" }}
              onClick={handleSave}
            >
              Save
            </button>
          )}
      </div>
    </>
  );
};

export default StaticCMS;
