import React, { useEffect, useState } from "react";
import "./Product.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addtoCart,
  resetCartMessage,
} from "../components/StateSlices/addtocartSlice";
import { getProduct, reset } from "../components/StateSlices/getproductSlice";
import PageLocation from "../components/PageLocation/PageLocation";
import ProductDescription from "../components/ProductDescription/ProductDescription";
import product from "../assets/images/product.png";
import ProductCloset from "../components/ProductCloset/ProductCloset";
import ProductTabs from "../components/ProductTabs/ProductTabs";
import Spinner1 from "../components/Spinner1/Spinner1";
import { getuserInfo } from "../components/StateSlices/getuserSlice";
import {
  addComment,
  deleteComment,
  resetComment,
} from "../components/StateSlices/commentSlice";
import "./react-inner-zoom.css";
import InnerImageZoom from "react-inner-image-zoom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import {
  getClosetProduct,
  resetClosetProduct,
} from "../components/StateSlices/getClosetProductSlice";

import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const Product = ({ setCartVal, cartVal }) => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [smallLoading, setSmallLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [closProduct, setClosProduct] = useState([]);

  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { commentStatus, commentInfo, commentError } = useSelector(
    (state) => state.comment
  );
  const { cstatus, message } = useSelector((state) => state.cart);

  const { pstatus, productInfo, images, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );

  const { closetProductStatus, closetProduct, closetProductError } =
    useSelector((state) => state.closetproduct);

  const [productDetails, setProductDetails] = useState(null);

  let { productId } = useParams();

  // write code to pass product id in
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    dispatch(reset());
    setLoading(true);
    console.log(location.state);
    if (location.state && location.state.id) {
      console.log("Yesssss");
      dispatch(getProduct(location.state.id));
    } else if (productId) {
      dispatch(getProduct(productId));
    } else {
      console.log(searchParams.values());
      searchParams.forEach((value, key) => {
        console.log(value, key);
      });
      navigate("/404");
    }
    // if(productInfo !== {}){
    //     productInfo.productImage.map((val)=>{
    //         setImages(prevState => {
    //             return [...prevState,{original:val,thumbnail:val}]
    //         })
    //     })
    // }
  }, [location.state]);

  // useEffect(() => {
  //   if (pstatus == "loading" || pstatus == "idle") {
  //     console.log(pstatus);
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [pstatus]);

  if (productInfo) {
    setLoading(false);
    setProductDetails(productInfo);
    dispatch(reset());
  }

  // useEffect(()=>{

  // },[productInfo])

  // const images = [
  //     {
  //       original: product,
  //       thumbnail: product,
  //       originalHeight:"100%",
  //     },
  //     {
  //         original: product,
  //         thumbnail: product,
  //         originalHeight:"100%",

  //     },
  //     {
  //         original: product,
  //         thumbnail: product,
  //         originalHeight:"100%",

  //     },
  //   ];

  const AddToCart = (id) => {
    console.log(id);
    let token = localStorage.getItem("clttoken");
    setLoading(true);
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    cart.push(id);
    sessionStorage.setItem("closetcart", JSON.stringify(cart));
    setCartVal(cart);
    dispatch(addtoCart({ token, id }));
  };

  if (message) {
    console.log(message);
    dispatch(resetCartMessage());
    setLoading(false);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token }));
  }

  if (error) {
    dispatch(reset());
    navigate("/404");
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const postComment = (commentbody) => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      let data = {
        productId: productDetails._id,
        comment: commentbody,
      };
      dispatch(addComment({ token, values: data, id: productDetails._id }));
    } else {
      navigate("/login");
    }

    // setLoading(true);
  };

  const getMoreProductsFromCloset = (id) => {
    dispatch(getClosetProduct({ pId: id }));
    setSmallLoading(true);
  };

  useEffect(() => {
    if (closetProduct) {
      console.log(closetProduct);
      setClosProduct(closetProduct);
      dispatch(resetClosetProduct());
      setSmallLoading(false);
    }
  }, [closetProduct]);

  const removeComment = (id) => {
    swal({
      title: "Are you sure?",
      text: "This Comment will be Deleted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        // setLoading(true);
        dispatch(
          deleteComment({ token, id: productDetails._id, commentId: id })
        );
      }
    });
  };

  if (commentStatus == "succeeded") {
    dispatch(resetComment());
    setLoading(false);
    toast.success("Comment Updated Successfully", toastOption);
    dispatch(getProduct(location.state.id));
  }

  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: window.location.href,
          },
        ]}
      ></Helmet>
      <Helmet>
        <title>
          {decodeURIComponent(
            window.location.pathname.split("/").filter((part) => part !== "")[1]
          )}
        </title>
      </Helmet>
      <div className="page-container">
        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        <div className="product-con-wrapper">
          {loading ? (
            <Spinner1 style={{ minHeight: "100vh" }} />
          ) : (
            <>
              <Helmet>
                {console.log(productDetails.productStats.category.join(","))}
                {/* <title>{`${productDetails.productName}`}</title> */}
                <meta
                  name="description"
                  content={
                    productDetails && productDetails.productDesc
                      ? // Product description is available
                        productDetails.productDesc.length +
                          (productDetails?.productStats?.brand
                            ? productDetails.productStats.brand.length
                            : 0) <
                        110
                        ? // Short description with additional details
                          `${productDetails.productDesc}. Brand: ${
                            productDetails?.productStats?.brand || ""
                          }. Category: ${
                            productDetails?.productStats.category &&
                            productDetails.productStats.category.length > 0
                              ? productDetails.productStats.category.join(",")
                              : "N/A"
                          }. Size: ${
                            productDetails?.productStats?.size || "N/A"
                          }`
                        : // Longer description with brand only
                          `${productDetails.productDesc}. Brand: ${
                            productDetails?.productStats?.brand || "N/A"
                          }`
                      : // Default description when product details are not available
                        "Create an infinite closet by using your own items as currency. Every member earns one hanger for every item they post which they can use to buy other items."
                  }
                />
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="product" />
                <meta
                  property="og:title"
                  content={decodeURIComponent(
                    window.location.pathname
                      .split("/")
                      .filter((part) => part !== "")[1]
                  )}
                />
                <meta
                  property="og:description"
                  content={
                    productDetails.productDesc.length +
                      productDetails?.productStats.brand.length <
                    110
                      ? `${productDetails.productDesc}. Brand: ${
                          productDetails?.productStats?.brand
                        }. Category: ${productInfo?.productStats?.category
                          .toString()
                          .replaceAll(",", ", ")}. Size: ${
                          productDetails?.productStats?.size
                        }`
                      : `${productDetails.productDesc}. Brand: ${productDetails?.productStats?.brand}`
                  }
                />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta
                  property="og:image"
                  content={productDetails.productImage[0]}
                />
                <meta property="og:site_name" content="ClosestCloset" />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  name="twitter:title"
                  content={decodeURIComponent(
                    window.location.pathname
                      .split("/")
                      .filter((part) => part !== "")[1]
                  )}
                />
                <meta
                  name="twitter:description"
                  content={
                    productDetails.productDesc.length +
                      productDetails?.productStats.brand.length <
                    110
                      ? `${productDetails.productDesc}. Brand: ${
                          productDetails?.productStats?.brand
                        }. Category: ${productInfo?.productStats?.category
                          .toString()
                          .replaceAll(",", ", ")}. Size: ${
                          productDetails?.productStats?.size
                        }`
                      : `${productDetails.productDesc}. Brand: ${productDetails?.productStats?.brand}`
                  }
                />
                <meta
                  name="twitter:image"
                  content={productDetails.productImage[0]}
                />
              </Helmet>
              <PageLocation productDetails={productDetails} />
              <section
                className="product_section"
                style={{ padding: 0, margin: 0 }}
              >
                <figure
                  className="product_image_gallery"
                  style={{ margin: "1rem 0" }}
                >
                  <Slider {...settings}>
                    {/* {"HEY KETUL " + console.log(images)} */}
                    {images.map((img, index) => (
                      <InnerImageZoom
                        src={img.original}
                        imgAttributes={{ alt: img.thumbnail }}
                        //   fullscreenOnMobile={true}
                        hideCloseButton={true}
                        hasSpacer={true}
                      />
                    ))}
                  </Slider>
                </figure>
                <div className="product_stats">
                  <ProductDescription
                    productInfo={productDetails}
                    userInfo={userInfo}
                    closetInfo={closetInfo}
                    pid={
                      location.state && location.state.id
                        ? location.state.id
                        : productId
                    }
                    AddToCart={AddToCart}
                    setCartVal={setCartVal}
                    cartVal={cartVal}
                    toast={toast}
                    toastOption={toastOption}
                  />
                </div>
              </section>
              <ProductCloset closetInfo={closetInfo} userInfo={userInfo} />
              <ProductTabs
                closetInfo={closetInfo}
                userInfo={userInfo}
                productInfo={productDetails}
                postComment={postComment}
                removeComment={removeComment}
                getMoreProductsFromCloset={getMoreProductsFromCloset}
                closProduct={closProduct}
                smallLoading={smallLoading}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
