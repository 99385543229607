import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  dataStatus: "idle",
  dataInfo: null,
  dataError: null,
};

export const getAnalyticsDataExport = createAsyncThunk(
  "admin/getUserAnalyticsDataExport",
  async ({ token, model, search = "all" }, { rejectWithValue }) => {
    const analyticsFor =
      model === "user-analytics"
        ? "user"
        : model === "item-product-analytics"
        ? "product"
        : model === "overall-cc-analytics"
        ? "overall"
        : "order";
    try {
      if (search === "") {
        search = "all";
      }
      const { data } = await axios.get(
        analyticsFor === "overall"
          ? `/api/analytics/${analyticsFor}/dataExport/${search}`
          : `/api/analytics/${analyticsFor}/dataExport`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      console.log("asdasda", model);
      if (model === "user-analytics") {
        a.download = "userAnalytics.csv";
      } else if (model === "item-product-analytics") {
        a.download = "productAnalytics.csv";
      } else if (model === "order-analytics") {
        a.download = "orderAnalytics.csv";
      } else {
        a.download = "overallAnalytics.csv";
      }
      //   a.download = "dbAnalyticsExport.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      return new Blob(data);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const analyticsDataExportSlice = createSlice({
  name: "getAnalyticsDataExport",
  initialState,
  reducers: {
    reset(state, action) {
      state.dataInfo = null;
      state.dataStatus = "idle";
      state.dataError = null;
    },
  },
  extraReducers: {
    [getAnalyticsDataExport.pending]: (state, action) => {
      state.dataStatus = "loading";
    },
    [getAnalyticsDataExport.fulfilled]: (state, action) => {
      state.dataStatus = "succeeded";
      state.dataInfo = action.payload.product;
    },
    [getAnalyticsDataExport.rejected]: (state, action) => {
      state.dataStatus = "failed";
      state.dataError =
        action.payload && action.payload.message && action.payload.message;
    },
  },
});

export const { reset } = analyticsDataExportSlice.actions;
export default analyticsDataExportSlice.reducer;
