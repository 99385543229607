import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./Analytics.css";
import UserAnalytics from "./UserAnalytics";
import ProductAnalytics from "./ProductAnalytics";
import OrderAnalytics from "./OrderAnalytics";
import { useDispatch, useSelector } from "react-redux";
import {
  orderAnalytics,
  overallAnalytics,
  productAnalytics,
  resetanalytics,
  userAnalytics,
} from "../../../components/StateSlices/analyticsSlice";
import { useLocation } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { getAnalyticsDataExport } from "../../../components/StateSlices/analyticsDataExportSlice";
import { toast } from "react-toastify";
import OverallAnalytics from "./OverallAnalytics";

const toastOption = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#F26654" : "inherit",
    width: "100%",
    marginTop: ".5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#F26654",
    },
    fontSize: "14px",
    borderRadius: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    marginTop: "3px",
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "#F26654" : "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isSelected ? "#F26654" : "rgba(242, 102, 84, 0.5)",
      color: "#fff",
      borderRadius: 3,
    },
    borderRadius: 3,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 31,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
};

const Analytics = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userSearchVal, setUserSearchVal] = useState("");

  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );

  const { analyticsError, analyticsStatus, analyticsInfo, analyticsCount } =
    useSelector((state) => state.analytics);

  const analyticsOptions = [
    { label: "User Analytics", value: "user-analytics" },
    { label: "Item/Product Analytics", value: "item-product-analytics" },
    { label: "Order Analytics", value: "order-analytics" },
    { label: "Overall CC Analytics", value: "overall-cc-analytics" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    // label: "User Analytics",
    // value: "user-analytics",
    label: "Overall CC Analytics",
    value: "overall-cc-analytics",
  });

  let gridRef = useRef()

  useEffect(() => {
    if (selectedOption.value === "user-analytics") {
      let token = localStorage.getItem("clttoken");
      if (token) {
        // console.log(pageNumber);
        if (location.search.slice(-1)) {
          if (
            location.search.slice(location.search.indexOf("=") + 1) - 1 ==
            pageNumber
          ) {
            // console.log("THen this");

            dispatch(
              userAnalytics({
                token: token,
                p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              })
            );
            setData([]);
            setLoading(true);
            window.scrollTo(0, 0);
          } else {
            setPageNumber(
              location.search.slice(location.search.indexOf("=") + 1) - 1
            );
          }
        } else {
          // console.log("This");
          dispatch(userAnalytics({ token: token, p: pageNumber }));
          setData([]);
          setLoading(true);
          window.scrollTo(0, 0);
        }
      } else {
        // navigate("/404", { replace: true });
      }
    } else if (selectedOption.value === "item-product-analytics") {
      let token = localStorage.getItem("clttoken");
      if (token) {
        // console.log(pageNumber);
        if (location.search.slice(-1)) {
          if (
            location.search.slice(location.search.indexOf("=") + 1) - 1 ==
            pageNumber
          ) {
            // console.log("THen this");

            dispatch(
              productAnalytics({
                token: token,
                p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              })
            );
            setData([]);
            setLoading(true);
            window.scrollTo(0, 0);
          } else {
            setPageNumber(
              location.search.slice(location.search.indexOf("=") + 1) - 1
            );
          }
        } else {
          // console.log("This");
          dispatch(productAnalytics({ token: token, p: pageNumber }));
          setData([]);
          setLoading(true);
          window.scrollTo(0, 0);
        }
      } else {
        // navigate("/404", { replace: true });
      }
    } else if (selectedOption.value === "order-analytics") {
      let token = localStorage.getItem("clttoken");
      if (token) {
        // console.log(pageNumber);
        if (location.search.slice(-1)) {
          if (
            location.search.slice(location.search.indexOf("=") + 1) - 1 ==
            pageNumber
          ) {
            // console.log("THen this");

            dispatch(
              orderAnalytics({
                token: token,
                p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              })
            );
            setData([]);
            setLoading(true);
            window.scrollTo(0, 0);
          } else {
            setPageNumber(
              location.search.slice(location.search.indexOf("=") + 1) - 1
            );
          }
        } else {
          // console.log("This");
          dispatch(orderAnalytics({ token: token, p: pageNumber }));
          setData([]);
          setLoading(true);
          window.scrollTo(0, 0);
        }
      } else {
        // navigate("/404", { replace: true });
      }
    } else {
      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(overallAnalytics({ token: token }));
        setData([]);
        setLoading(true);
        window.scrollTo(0, 0);
      } else {
      }
    }
  }, [selectedOption, pageNumber, location?.search?.slice(-1)]);

  useEffect(() => {
    if (analyticsInfo) {
      console.log("ee rajaa", analyticsInfo);
      setData(analyticsInfo);
      setLoading(false);
      dispatch(resetanalytics());
    }
  }, [analyticsInfo]);

  const downloadAnalyticsCsv = () => {
    console.log("CHALNA",selectedOption.value);
    if(selectedOption.value == "overall-cc-analytics"){
      gridRef.current.api.exportDataAsCsv();
      return;
    }

    const token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        getAnalyticsDataExport({
          token: token,
          model: selectedOption.value,
          search: userSearchVal,
        })
      );
      toast.info(
        "Please wait for a While. The document will be downloaded automatically",
        toastOption
      );
    }
  };

  function resetSearch() {
    setUserSearchVal("");
    let token = localStorage.getItem("clttoken");
    setData([]);
    setLoading(true);
    dispatch(overallAnalytics({ token: token }));
  }

  function searchUser() {
    let token = localStorage.getItem("clttoken");
    setData([]);
    setLoading(true);
    dispatch(overallAnalytics({ token: token, search: userSearchVal }));
  }

  return (
    <div className="analytics-con">
      <div className="analytics-wrapper">
        <label style={{ marginBottom: ".5rem", fontSize: "16px" }}>
          Choose Analytics Option
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div style={{ width: "95%" }}>
            <Select
              name="userAnalyticsOptions"
              options={analyticsOptions}
              onChange={(opt) => {
                setData([]);
                setSelectedOption(opt);
              }}
              value={selectedOption}
              styles={customStyles}
            />
          </div>
          <div>
            <button
              style={{
                padding: "5.5px 8px",
                marginTop: "8px",
              }}
              className="btn"
              onClick={downloadAnalyticsCsv}
              title={`Download Analytics CSV File of ${
                selectedOption.value === "user-analytics"
                  ? "Users"
                  : selectedOption.value === "item-product-analytics"
                  ? "Products"
                  : "Orders"
              }`}
            >
              <FaFileDownload size={"1.5em"} />
            </button>
          </div>
        </div>

        {selectedOption.value === "user-analytics" && (
          <UserAnalytics
            loading={loading}
            data={data}
            analyticsCount={analyticsCount}
            pageNumber={[pageNumber]}
            setPageNumber={setPageNumber}
          />
        )}
        {selectedOption.value === "item-product-analytics" && (
          <ProductAnalytics
            loading={loading}
            data={data}
            analyticsCount={analyticsCount}
            pageNumber={[pageNumber]}
            setPageNumber={setPageNumber}
          />
        )}
        {selectedOption.value === "order-analytics" && (
          <OrderAnalytics
            loading={loading}
            data={data}
            analyticsCount={analyticsCount}
            pageNumber={[pageNumber]}
            setPageNumber={setPageNumber}
          />
        )}
        {selectedOption.value === "overall-cc-analytics" && (
        <OverallAnalytics
            loading={loading}
            data={data}
            analyticsCount={analyticsCount}
            pageNumber={[pageNumber]}
            setPageNumber={setPageNumber}
            userSearchVal={userSearchVal}
            setUserSearchVal={setUserSearchVal}
            resetSearch={resetSearch}
            searchUser={searchUser}
            gridRef={gridRef}
          />
        )}
      </div>
    </div>
  );
};

export default Analytics;
