import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import CCLogo from "../../../assets/images/CCfinallogo_boxed.png";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ isOpen, setCurrentTab, setIsOpen }) => {
  // const [current, setCurrent] = useState("")
  const location = useLocation();

  useEffect(() => {
    if (window.innerWidth < 640) setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <div className={isOpen ? "adm-sidebar" : "adm-sidebar collapsed"}>
        <div className="logo">
          <img
            src={CCLogo}
            style={{ width: "100%" }}
            alt="closest-closet-logo"
          />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <Link to="/user/my-closet" className="nav_link">
                Closet
              </Link>
            </li>
            <li>
              <Link
                to="users"
                className={`nav_link ${
                  location.pathname.indexOf("users") > -1 ? "active_admin" : ""
                }`}
              >
                Users
              </Link>
            </li>
            <li>
              <Link
                to="products"
                className={`nav_link ${
                  location.pathname.indexOf("products") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="orders"
                className={`nav_link ${
                  location.pathname.indexOf("orders") > -1 ? "active_admin" : ""
                }`}
              >
                Orders
              </Link>
            </li>
            <li>
              <Link
                to="brands"
                className={`nav_link ${
                  location.pathname.indexOf("brands") > -1 ? "active_admin" : ""
                }`}
              >
                Brands
              </Link>
            </li>
            <li>
              <Link
                to="category"
                className={`nav_link ${
                  location.pathname.indexOf("category") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Category
              </Link>
            </li>
            <li>
              <Link
                to="sizes"
                className={`nav_link ${
                  location.pathname.indexOf("sizes") > -1 ? "active_admin" : ""
                }`}
              >
                Sizes
              </Link>
            </li>
            <li>
              <Link
                to="cms"
                className={`nav_link ${
                  location.pathname.indexOf("cms") > -1 ? "active_admin" : ""
                }`}
              >
                CMS
              </Link>
            </li>
            <li>
              <Link
                to="static"
                className={`nav_link ${
                  location.pathname.indexOf("static") > -1 ? "active_admin" : ""
                }`}
              >
                Static CMS
              </Link>
            </li>
            <li>
              <Link
                to="coupons"
                className={`nav_link ${
                  location.pathname.indexOf("coupens") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Coupons
              </Link>
            </li>
            <li>
              <Link
                to="analytics"
                className={`nav_link ${
                  location.pathname.indexOf("analytics") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Analytics
              </Link>
            </li>
            <li>
              <Link
                to="subscriptions"
                className={`nav_link ${
                  location.pathname.indexOf("subscriptions") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Subscription
              </Link>
            </li>
            {/* <li>
              <a href="#">Settings</a>
            </li> */}
          </ul>
        </nav>
        <div className="user-info">{/* Add user information here */}</div>
      </div>
    </>
  );
};

export default Sidebar;
